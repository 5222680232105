import gql from 'graphql-tag'

export OrganizationBasicEditEnvironment = gql """
query ($organizationID: ID!) {
		organization(id: $organizationID){
			id
			name
			abbreviation
			type
		}
	}
"""

export OrganizationAdvancedEditEnvironment = gql """
query ($organizationID: ID!) {
		organization(id: $organizationID){
			id
			name
			abbreviation
			type
			website
			hedquarters {
				street
				buildingNumber
				postalCode
				city
			}
			nip
			regon
			krs
			description
			representationDescription
			representationType
		}
	}
"""
