import gql from 'graphql-tag'

# MUTATIONS
export AddOrganization = gql """
	mutation (
		$name: String!,
		$abbreviation: String,
		$type: OrganizationType!,
		$projectsIDs: [ID!]!,
		$website: String,
		$description: String,
		$nip: String,
    $regon: String,
    $krs: String,
		$representationDescription: String,
	  $representationType: String,
		$street: String,
    $buildingNumber: String,
    $postalCode: String,
    $city: String
	) {
		organizationCreate(
			name: $name,
			abbreviation: $abbreviation,
			type: $type,
			projectsIDs: $projectsIDs,
			website: $website,
			description: $description,
			nip: $nip,
			regon: $regon,
			krs: $krs,
			representationDescription: $representationDescription,
			representationType: $representationType,
			street: $street,
			buildingNumber: $buildingNumber,
			postalCode: $postalCode,
			city: $city
		) {
			id
			name
			type
			abbreviation
			website
			hedquarters {
				street
				buildingNumber
				postalCode
				city
			}
			nip
			regon
			krs
			description
			representationDescription
			representationType
		}
	}
"""

export EditOrganization = gql """
	mutation(
		$id: ID!,
		$name: String,
		$abbreviation: String,
		$type: OrganizationType
		$website: String,
		$description: String,
		$nip: String,
    $regon: String,
    $krs: String,
		$representationDescription: String,
	  $representationType: String,
		$street: String,
    $buildingNumber: String,
    $postalCode: String,
    $city: String
		) {
		organizationUpdate(
			id: $id,
			name: $name
			abbreviation: $abbreviation
			type: $type
			website: $website,
			description: $description,
			nip: $nip,
			regon: $regon,
			krs: $krs,
			representationDescription: $representationDescription,
			representationType: $representationType,
			street: $street,
			buildingNumber: $buildingNumber,
			postalCode: $postalCode,
			city: $city
		) {
			id
			name
			type
			abbreviation
			website
			hedquarters {
				street
				buildingNumber
				postalCode
				city
			}
			nip
			regon
			krs
			description
			representationDescription
			representationType
		}
	}
"""

