import _ from 'lodash'
import React from 'react'

# Renderables
import { div, span, img, p } from 'react-dom-factories'

import _Tooltip from '@bevy/tooltip'
Tooltip = React.createFactory _Tooltip

# Styles
import styles from '../index.styl'

name =
	key: 'name'
	label: 'Name'
	sortKey: 'Name'
	width: 200
	isSortable: true
	render: ({ item }) ->
		Tooltip
			content: item.name
			showTimeout: 200
			hideTimeout: 5000
		,
			span {className: styles.compactText},
				if item.name? then item.name else '-'

email =
	key: 'email'
	label: 'E-mail'
	sortKey: 'Email'
	width: 200
	isSortable: true
	render: ({ item }) ->
		span {className: styles.compactText},
			if item.email? then item.email else '-'

projects =
	key: 'projects'
	label: 'Member of projects'
	sortKey: 'Projects'
	width: 600
	# isSortable: true
	render: ({ item }) ->
		projects = _.filter item.memberships, 'project'
		projectsNames = _.compact (_.map (_.compact _.map projects, 'project'), 'name')
		span {className: styles.compactText},
			if !_.isEmpty projectsNames then _.join projectsNames, ', ' else '-'

export MEMBERS_COLUMNS = do ->
	columns =
		[
			{ ...name, selectedByDefault: true }
			{ ...email, selectedByDefault: true }
			{ ...projects, selectedByDefault: true }
		]
	_.map columns, (column) -> {...column, selected: column.selectedByDefault}
