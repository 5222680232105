# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import PropTypes from 'prop-types'
import DataAttribute from '@bevy/data-attribute'


# Renderables
import { div, p, ul, li, small, h1, h2, h3, span, img, button } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import _Button from '@bevy/button'
Button = React.createFactory _Button

import styles from './index.styl'

TextButtonProps = PropTypes.shape {
	..._Button.propTypes
	content: PropTypes.oneOfType([
		PropTypes.node
		PropTypes.string
	]).isRequired
}

IconButtonProps = PropTypes.shape {
	..._Button.propTypes
	content: PropTypes.shape
		icon: PropTypes.object
		size: PropTypes.number
}

export default class Toolbar extends React.Component
	@propTypes:
		primary: TextButtonProps
		secondary: PropTypes.arrayOf PropTypes.oneOfType [
			TextButtonProps
			PropTypes.arrayOf TextButtonProps
		]
		technical: PropTypes.arrayOf PropTypes.oneOfType [
			IconButtonProps
			PropTypes.arrayOf IconButtonProps
		]
		standard: PropTypes.arrayOf PropTypes.oneOfType [
			IconButtonProps
			PropTypes.arrayOf IconButtonProps
		]
	constructor: (props) ->
		super props
	renderItem: (item, key) =>
		if _.isArray item
			div {key: "group-#{key}", className: cnames styles.group, item.containerClassName},
				_.map item, @renderItem
		else
			div {key: item.key, className: cnames styles.action, item.containerClassName},
				Button {
					..._.omit item, 'containerClassName'
				}
	# Remove or add neccessary properties
	buttonsMappers:
		primary: (item) ->
			{
				..._.omit item, ['content']
				# iconBefore: if item.iconBefore? then Icon {...item.iconBefore, className: 'dupa'}
				# iconAfter: if item.iconAfter? then Icon {...item.iconAfter, className: 'dupa'}
				appearance: 'toolbar-action-primary'
				children: item.content
			}
		secondary: (item) ->
			{
				..._.omit item, ['content']
				appearance: 'toolbar-action'
				children: item.content
			}
		technical: (item) ->
			{
				..._.omit item, ['content']
				appearance: 'subtle'
				iconBefore: Icon {...item.content}
			}
		standard: (item) ->
			{
				..._.omit item, ['content']
				appearance: 'subtle'
				iconBefore: Icon {...item.content}
			}
	mapperWrapper: (item, fn) =>
		if _.isArray item
			_.map item, (subitem) => @mapperWrapper subitem, fn
		else
			fn item

	render: ->
		div {className: styles.toolbar},
			_.compact _.map _.pick(@props, ['primary', 'secondary', 'technical', 'standard']), (actionOrGroup, type) =>
				if @props[type]?
					div {key: type, className: cnames styles.container, styles[type]},
						@renderItem @mapperWrapper(@props[type], @buttonsMappers[type]), type
						if @props.dms and type is 'standard'
							@props.dmsCustomActions
			div {className: styles.childrenWrapper}, @props.children
