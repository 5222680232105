###
Atlaskit tabs (https://atlaskit.atlassian.com/packages/core/tabs) with react router support in tabs as links
TODO: Add prop types from atlaskit tab
###

# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import PropTypes from 'prop-types'
import {matchPath} from 'react-router-dom'
# Renderable
import { div, p, ul, li, small, h1, span } from 'react-dom-factories'

import _Tabs, {TabItem as _TabItem, TabContent as _TabContent} from '@atlaskit/tabs'
TabItem = React.createFactory _TabItem
Tabs = React.createFactory _Tabs
TabContent = React.createFactory _TabContent

import {Link as _Link, Redirect as _Redirect} from 'react-router-dom'
Link = React.createFactory _Link
Redirect = React.createFactory _Redirect

# Styles
import styles from './index.styl'

export default class RouterTabs extends React.Component
	@propTypes:
		hubNav: PropTypes.bool
		className: PropTypes.string
		location: PropTypes.shape
			pathname: PropTypes.string
		header: PropTypes.node
		footer: PropTypes.node
		tabs: PropTypes.arrayOf PropTypes.shape
			path: PropTypes.string
			href: PropTypes.string
			exact: PropTypes.bool
			catchAll: PropTypes.bool
			redirect: PropTypes.string
			aside: PropTypes.bool
	@defaultProps:
		hubNav: false
	constructor: (props) ->
		super props

	renderTabContent: (props) =>
		if props.data.redirect?
			Redirect to: props.data.redirect
		else
			TabContent props

	render: ->
		isCatchAllEnable = _.some @props.tabs, (tab) -> tab.catchAll is true
		# In catch all mode we need to check if any other tab is enabled
		isAnyRouteActive = _.some @props.tabs, (tab) => matchPath(@props.location.pathname, {path: tab.path || tab.href, exact: tab.exact || tab.catchAll})?
		if !isAnyRouteActive and @props.fallback?
			@props.fallback()
		else
			tabs = _.compact _.concat [@props.header], @props.tabs, [@props.footer]
			firstAside = _.findIndex tabs, aside: true
			if firstAside >= 0
				tabs = _.map tabs, (tab, index) ->
					if index isnt firstAside
						{
							...tab
							aside: null
						}
					else
						tab
			div {
				className: cnames [
					@props.className
					styles.base
					if @props.hubNav then styles.hubNav
				]
			},
				Tabs
					isSelectedTest: (selected, target, index) =>
						target = tabs[index]
						if target in [@props.header, @props.header]
							false
						else if !isAnyRouteActive and isCatchAllEnable
							target.catchAll
						else

							match = matchPath @props.location.pathname,
								path: target.path || target.href
								exact: target.exact || target.catchAll
							# console.log '--------------------------'
							# console.log 'match', match
							# console.log 'target', target
							# console.log 'index', index
							# console.log 'selected', selected
							# console.log 'target', target
							match?
					components:
						Content: @renderTabContent

						Item: (props) =>
							if props.data is @props.header
								@props.header
							else if props.data is @props.footer
								@props.footer
							else if props.data.redirect
								null
							else
								Link {
									to: props.data.href
									className: cnames [
										'router-tab-link'
										styles.link
										if @props.hubNav && props.isSelected then styles.hubLink
										if props.data.aside is true then styles.aside

									]
								},
									TabItem {
										...props
										elementProps: {
											...props.elementProps
											onClick: null
										}
									}
					tabs: tabs
