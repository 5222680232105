###

###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'

# Renderable
import { div, p, span } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import _Banner from '@atlaskit/banner'
Banner = React.createFactory _Banner

import _EmptyState from '@atlaskit/empty-state'
EmptyState = React.createFactory _EmptyState

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import _Button, { ButtonGroup as _ButtonGroup } from '@bevy/button'
Button = React.createFactory _Button
ButtonGroup = React.createFactory _ButtonGroup

import _Spinner from '@bevy/spinner'
Spinner = React.createFactory _Spinner

# Styles
import {
	alertTriangle
} from 'react-icons-kit/feather'
import styles from './index.styl'

import trPlatform from 'data/local/platform'

export default class ErrorDisplay extends React.Component
	@propTypes =
		query: PropTypes.shape
			error: PropTypes.object
			loading: PropTypes.bool
			refetch: PropTypes.func
		#you may also pass error, loading and refetch separately
		error: PropTypes.object
		loading: PropTypes.bool
		refetch: PropTypes.func
		onRedirect: PropTypes.func
		onRefresh: PropTypes.func
		withImage: PropTypes.bool
		authorizationErrorMsg: PropTypes.shape
			header: PropTypes.string
			description: PropTypes.string
	@defaultProps =
		withImage: false
		authorizationErrorMsg: trPlatform.error.noPermission

	render: ->
		{loading, error, refetch} = @props.query || @props

		if loading
			Spinner {isAbsolute: false}
		else if error?
			if _.find(error.graphQLErrors, (error) -> error.extensions?.code is 'UNAUTHENTICATED')? # user has no permission
				EmptyState
					header: @props.authorizationErrorMsg.header
					description: @props.authorizationErrorMsg.description
					imageUrl: require 'assets/empty-states/empty.svg'
					maxImageWidth: 300
					maxImageHeight: 300
			if error.networkError?.statusCode is 400 # bad request
				if @props.withImage then InternalErrorState {onRedirect: @props.onRedirect} else InternalErrorBanner {}
			else
				onRefresh = @props.onRefresh || refetch
				if @props.withImage then NetworkErrorState {onRefresh} else NetworkErrorMsg {onRefresh}
		else
			@props.children()



#withImage
export InternalErrorState = ({onRedirect, redirectLabel}) ->
	EmptyState
		header: trPlatform.error.internalError.header
		description: trPlatform.error.internalError.description
		imageUrl: require 'assets/empty-states/internal-error.svg'
		maxImageWidth: 300
		maxImageHeight: 300
		primaryAction:
			if onRedirect?
				Button {
					appearance: 'primary'
					onClick: () -> onRedirect()
				}, redirectLabel || 'Go back'

export NetworkErrorState = ({onRefresh}) ->
	EmptyState
		header: trPlatform.error.networkError.header
		description: trPlatform.error.networkError.description
		imageUrl: require 'assets/empty-states/internal-error.svg'
		maxImageWidth: 300
		maxImageHeight: 300
		primaryAction:
			if onRefresh?
				Button {
					appearance: 'primary'
					onClick: () -> onRefresh()
				}, 'Refresh'

#default
export InternalErrorBanner = ({}) ->
	Banner
		isOpen: true
		appearance: 'warning'
		icon: Icon { icon: alertTriangle, size: 18 }
	, 'Something went wrong'


export NetworkErrorMsg = ({onRefresh}) ->
	EmptyState
		header: div {className: styles.selectError},
			Icon { icon: alertTriangle, size: 18 }
			p { className: styles.errorMsg }, trPlatform.error.networkError.header
		primaryAction:
			if onRefresh?
				Button {
					onClick: () -> onRefresh()
				}, 'Click to refresh'



# other errors
export FileLoadErrorState = ({onRefresh, invertColor}) ->
	EmptyState
		header: div {className: if invertColor? then styles.inverted}, trPlatform.error.fileLoad.header
		# description: trPlatform.error.fileLoad.description
		imageUrl: require 'assets/empty-states/no-photo.svg'
		maxImageWidth: 300
		maxImageHeight: 300
		primaryAction:
			if onRefresh?
				Button {
					appearance: 'primary'
					onClick: () -> onRefresh()
				}, 'Try again'

export InvalidHubState = ({redirectLink}) ->
	EmptyState
		header: trPlatform.error.invalidHub.header
		imageUrl: require 'assets/empty-states/empty-result.svg'
		maxImageWidth: 300
		maxImageHeight: 300
		primaryAction:
			Button { href: redirectLink }, 'Go to Dashboard'

export InvalidProjectState = ({redirectLink}) ->
	EmptyState
		header: trPlatform.error.invalidProject.header
		imageUrl: require 'assets/empty-states/empty-result.svg'
		maxImageWidth: 300
		maxImageHeight: 300

export PlatformAcess = ({ability}) ->
	EmptyState
		header: trPlatform.error.noPlatformPermission.header
		imageUrl: require 'assets/empty-states/empty-result.svg'
		maxImageWidth: 300
		maxImageHeight: 300
		primaryAction:
			if ability.can 'read', 'Platform', 'production'
				Button { href: 'https://360.skanska.pl', appearance: 'primary' }, 'Go to production environment'
		secondaryAction: do ->
			envs = [
				name: 'staging'
				link: '//360-staging.skanska.pl'
			,
				name: 'test'
				link: '//360-test.skanska.pl'
			,
				name: 'development'
				link: '//360-dev.bevy.pl'
			]
			envs = _.filter envs, (env) -> ability.can 'read', 'Platform', env.name
			if !_.isEmpty envs
				ButtonGroup {},
					_.map envs, (env) ->
						Button {
							href: env.link
						}, "Go to #{env.name} environment"

