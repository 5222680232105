###
NoOptionsMessage for react select
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'

import _Button from '@bevy/button'
Button = React.createFactory _Button

import _Avatar from '@atlaskit/avatar'
Avatar = React.createFactory _Avatar

# Renderable
import { div, a } from 'react-dom-factories'

# Styles
import styles from './index.styl'

export default NoOptionsMessage = (props) =>
	{fieldName, contactPerson} = props

	div {className: styles.noOptionsMessage},
		div {className: styles.textField},
			div {}, "If you cannot find proper #{_.toLower fieldName}, please contact #{contactPerson.role} -"
			div {className: styles.bold}, contactPerson.name
			Avatar
				name: contactPerson.name
				src: contactPerson.picture.mini
				size: 'small'
		if contactPerson.email?
			a {className: styles.link, href: "mailto:#{contactPerson.email}?subject=Missing #{_.toLower fieldName} option"},
					Button {appearance: 'default'},
						"Contact #{contactPerson.role}"

