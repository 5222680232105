import gql from 'graphql-tag'


export DeleteUserGroups = gql """
	mutation($ids: [ID!]!) {
		userGroupsDelete(
			ids: $ids
		) {
			id
		}
	}
"""
