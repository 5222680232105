import _ from 'lodash'
import React from 'react'

# Renderable
import { div } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import {BevyModalsWrapper as _BevyModalsWrapper} from '@bevy/modal'
BevyModalsWrapper = React.createFactory _BevyModalsWrapper

import _EditCompanyModal from 'parts/Companies/EditCompanyModal'
EditCompanyModal = React.createFactory _EditCompanyModal

import _EditProjectModal from 'parts/Projects/EditProjectModal'
EditProjectModal = React.createFactory _EditProjectModal

import _AddManagementModal from 'parts/ProjectDashboard/AddManagementModal'
AddManagementModal = React.createFactory _AddManagementModal

import _DeleteManagementModal from 'parts/ProjectDashboard/DeleteManagementModal'
DeleteManagementModal = React.createFactory _DeleteManagementModal

import _AddBuildingAreaModal from 'parts/ProjectDashboard/AddBuildingAreaModal'
AddBuildingAreaModal = React.createFactory _AddBuildingAreaModal

import _DeleteBuildingAreaModal from 'parts/ProjectDashboard/DeleteBuildingAreaModal'
DeleteBuildingAreaModal = React.createFactory _DeleteBuildingAreaModal

import { NotificationConsumer } from '../../../application/components/NotificationManager'

selectedOrganizations = null

export default getModals = ({project}) ->
	if project?
		NotificationConsumer {}, ({setNotification}) =>
			BevyModalsWrapper {},
				switch @state.activeModal
					when 'editCompany'
						EditCompanyModal
							advancedView: true #TODO: change based on permission
							organizationID: if project.spv? then project.spv.id
							projectID: project.id
							onClose: => @setState activeModal: null
					when 'editProject'
						EditProjectModal
							advancedView: true #TODO: change based on permission
							projectID: project.id
							onUpdateComplete: (data) =>
								if @state.originalSlug isnt data.projectUpdate.slug
									@props.history.push "/projects/#{data.projectUpdate.slug}"
							onClose: => @setState activeModal: null
					when 'addProjectMember'
						AddManagementModal
							projectID: project.id
							type: @state.selectedManagementType
							phaseID: @state.selectedManagmentPhaseID
							onClose: =>
								@setState
									activeModal: null
									selectedManagementType: null
									selectedManagmentPhaseID: null
					when 'deleteProjectMember'
						DeleteManagementModal
							projectID: project.id
							type: @state.selectedManagementType
							id: @state.selectedManagementUserID
							phaseID: @state.selectedManagmentPhaseID
							onClose: =>
								@setState
									activeModal: null
									selectedManagementType: null
									selectedManagementUserID: null
									selectedManagmentPhaseID: null

					when 'editProjectMember'
						AddManagementModal
							projectID: project.id
							type: @state.selectedManagementType
							id: @state.selectedManagementUserID
							role: @state.selectedManagementRole
							phaseID: @state.selectedManagmentPhaseID
							onClose: =>
								@setState
									activeModal: null
									selectedManagementType: null
									selectedManagementUserID: null
									selectedManagementRole: null
									selectedManagmentPhaseID: null
					when 'addBuildingArea'
						AddBuildingAreaModal
							projectID: project.id
							onClose: =>
								@setState
									activeModal: null
									selectedManagementType: null
					when 'editBuildingArea'
						AddBuildingAreaModal
							projectID: project.id
							building: @state.selectedBuilding
							onClose: =>
								@setState
									activeModal: null
									selectedBuilding: null
					when 'deleteBuildingArea'
						DeleteBuildingAreaModal
							projectID: project.id
							building: @state.selectedBuilding
							onClose: =>
								@setState
									activeModal: null
									selectedBuilding: null
