###
List of all projects
TODO!: Replace custom view with LayoutList or LayoutGrid
###

# Libs
import _ from 'lodash'
import React from 'react'
import { UserInfoConsumer } from 'libs/userInfo'

# Renderable
import { div, p, ul, li } from 'react-dom-factories'

import _PageHeader from '@atlaskit/page-header'
PageHeader = React.createFactory _PageHeader

import { ButtonGroup as _ButtonGroup } from '@atlaskit/button'
ButtonGroup = React.createFactory _ButtonGroup

import { Query as _Query } from 'react-apollo'
Query = React.createFactory _Query

import _Button from '@bevy/button'
Button = React.createFactory _Button

import _Spinner from '@bevy/spinner'
Spinner = React.createFactory _Spinner

import _ProjectCard from '@bevy/project-card'
ProjectCard = React.createFactory _ProjectCard

import _EmptyState from '@atlaskit/empty-state'
EmptyState = React.createFactory _EmptyState


# Data
import { RootQuery } from './data'

# Elements
import getModals from './elements/modals'

# Styles
import styles from './index.styl'


export default class ProjectsView extends React.Component
	constructor: (props) ->
		super props
		@state =
			activeModal: null

	getModals: getModals

	render: ->
		UserInfoConsumer {}, ({ability, me}) =>
			div {className: @props.className},
				PageHeader {
					actions: ButtonGroup {},
						Button
							appearance: 'primary'
							isDisabled: !(ability.can 'create', 'Project')
							onClick: => @setState activeModal: 'addProjectModal'
						, 'Add Project'
				}, 'Projects'
				Query
					query: RootQuery
				, ({data, loading}) =>
					if loading
						Spinner {}
					else
						if _.isEmpty data.projects
							EmptyState
								header: "Unable to display projects"
								imageUrl: require 'assets/empty-states/empty-result.svg'
								maxImageWidth: 300
								maxImageHeight: 300
						else
							div {className: styles.container},
								@getModals {}
								do =>
									_.map data.projects, (project) =>
										ProjectCard
											key: project.id
											onClick: => @props.history.push "/projects/#{project.slug}"
											thumbnail: if project.photo?.mini? then project.photo.mini
											name: project.name
											country: if project.country? then project.country
											city: if project.city? then project.city
											# startDate: project.baseline.timeline.start
											# endDate: project.baseline.timeline.end
