import gql from 'graphql-tag'

export ProjectGroupEditEnvironment = gql """
query ( $projectGroupID: ID!) {
	projectUserGroup(id: $projectGroupID) {
		id
		name
		description
		picture {
			mini
		}
		roles {
			name
			info
		}
	}
}
"""

