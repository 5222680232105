import gql from 'graphql-tag'
import {
	Project
	User
	Document
} from './query.graphql'

export DocumentRegisterMutation = gql """
	mutation($basics: LegalDocumentBasicInput!, $details: LegalDocumentDetailsInput, $scans: [ID]) {
		legalDocumentCreateByCategory(basics: $basics, details: $details, scans: $scans) {
			id
			company { id, name, abbreviation }
			project { id, name }
			correspondent { id, name, abbreviation }
			dates { type, value }
			form { id, name, abbrev }
			topic { id, name }
			phases { id, name }
			path {
				id
				ancestors { id, name, code }
				code
				name
				full
			}
			createdAt
			createdBy {
				... on Person { id, name }
				... on Service { id, name }
			}
		}
	}
"""

export DocumentCategorizeMutation = gql """
	mutation($documentIDs: [ID!]!, $basics: LegalDocumentBasicInput!, $details: LegalDocumentDetailsInput) {
		legalDocumentUpdateCategory(documentIDs: $documentIDs, basics: $basics, details: $details) {
			id
			company { id, name }
			project { id, name }
			correspondent { id, name }
			dates { type, value }
			form { id, name }
			topic { id, name }
			phases { id, name}
			path {
				id
				ancestors { id, name, code }
				code
				name
				full
			}
			modifiedAt
			modifiedBy {
				... on Person { id, name }
				... on Service { id, name }
			}
			asignee {
				... on Person { id, name }
				... on Service { id, name }
			}
			approvedAt
			approvedBy { id, name }
			hasApproval
			freeText
			hasScan
			state
			scan { id, secured { original } }
			projectID
			description
			parentDocument {id}
			buildings
			identifiers { type, value }
			entities {
				recipient { id, name }
				participants { id, name }
			}

		}
	}
"""

export CreateOrganizationsMutation = gql """
	mutation($names: [String!]!, $projectsIDs: [ID!]!) {
		organizationsCreate(names: $names, projectsIDs: $projectsIDs) {
			id
			name
			abbreviation
		}
	}
"""

export DocumentRegisterEnvironment = gql """
	query RegisterEnvironment($projectSlug: String!){
		project(slug: $projectSlug) {
			id
			name
			slug
			code
			buildingsSets { name, code }
			legalDocumentNamePropertyOrder
			buildingsDownloadConfig {
				prefix
				buildings {
          code
        	downloadName
          hideInCombination
      	}
    	}
			spv { id, name }
			isMultiphase
			phases { id, name }
			leader {
				id
				email
				name
				picture {
					mini
				}
			}
		}
	}
"""

export getProjectMembers = gql """
	query($projectSlug: String!){
		project(slug: $projectSlug) {
			id
			members {
				... on ActorAccess{
					user {
						... on Person { id, name }
						... on Service { id, name }
					}
				}
			}
		}
	}
"""

export getOrganizations = gql """
	query(
		$filters: OrganizationsFilters,
		$projectSlug: String!,
		$sort: OrganizationsSort!,
		$pagination: PaginationInput,
		$search: String
		) {
		project(slug: $projectSlug) {
			id
			organizations(sort: $sort, filters: $filters, pagination: $pagination, search: $search) {
				name
				id
				abbreviation
			}
		}
	}
"""

export getBuildingsSets = gql """
	query($projectSlug: String!){
		project(slug: $projectSlug) {
			id
			buildingsSets { name, code}
			buildingsCombinations { name, codes, phaseID }
			phases { id, name }
		}
	}
"""

export getDocumentsCategories = gql """
	query DMS_RegisterModal_Categories ($projectID: ID!) {
		legalDocumentTopics(filters: {projectID: $projectID}) {
			id
			name
			orderingNumber
		}
		legalDocumentForms {
			id
			name
			abbrev
		}
		projectByID(id: $projectID) {
			id
			legalDocumentPaths(action: Use) {
				id
				code
				name
				ancestors {
					id
					code
					name
				}
				combinations {
					formID
					topicID
				}
			}
		}
	}
"""

export getDetailsFields = gql """
	query DMS_Register_FieldsDetails ($formID: ID!, $topicID: ID!, $pathID: ID!, $projectID: ID!) {
		legalDocumentAvailableParents(formID: $formID, topicID: $topicID) {
			id
			form { id, name	}
			topic { id, name }
			asignee {
				... on Person { id, name }
			}
			dates { type, value }
			identifiers { type, value }
			correspondent { id, name}
		}
		projectByID(id: $projectID) {
			id
			legalDocumentPath(id: $pathID) {
				id
				combinations {
					formID
					topicID
					building
					dates { type, isRequired }
					identifiers { type, isRequired }
					entities { type, isRequired, isMulti }
				}
			}
		}
	}
"""

export UpdateScan = gql """
	mutation($documentID: ID!, $scan: ID!){
		legalDocumentUpdateScan(documentID: $documentID, scan: $scan) {
			id
			hasScan
			scan { secured { original }, id }
			modifiedAt
			modifiedBy {
				...on Person { name, id }
				... on Service { id, name }
			}
		}
	}
"""
