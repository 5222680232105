import gql from 'graphql-tag'

# export GroupEditEnvironment = gql """
# query ($groupID: ID!) {
# 		userGroup(id: $groupID){
# 			id
# 			name
# 			description
# 			picture { original }
# 			roles
# 		}
# 	}
# """

export GroupEditEnvironment = gql """
query ( $groupID: ID!) {
		userGroup(id: $groupID) {
			id
			name
			description
			picture {
				mini
			}
			roles {
				name
			}
		}
}
"""

