###
document to delete list
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import adopt from 'libs/adopt'

# Renderable
import { div, a, span } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import { NotificationConsumer } from '../../../application/components/NotificationManager'

import _BevyModal from '@bevy/modal'
BevyModal = React.createFactory _BevyModal

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import _Spinner from '@bevy/spinner'
Spinner = React.createFactory _Spinner

import _Button from '@bevy/button'
Button = React.createFactory _Button

import {
	externalLink
} from 'react-icons-kit/feather'

import _Avatar from '@atlaskit/avatar'
Avatar = React.createFactory _Avatar

# Styles
import ErrorIcon from '@atlaskit/icon/glyph/error'

import {Link as _Link} from 'react-router-dom'
Link = React.createFactory _Link

import styles from './index.styl'
# Data
import {
	BlockedDocument
	ProjectLeader
} from './data'

REFETCH_QUERIES = [
	'ProjectCompaniesList'
	]

DataLayer = adopt
	queries:
		blockedDocument: ({id, projectID}) ->
			query: BlockedDocument
			variables:
				id: id
				projectID: projectID
		projectLeader: ({projectID}) ->
			query: ProjectLeader
			variables:
				projectID: projectID

export default class OpportunityDeleteModal extends React.Component
	@propTypes =
		onClose: PropTypes.func.isRequired

	constructor: (props) ->
		super props

	render: ->
		NotificationConsumer {}, ({setNotification}) =>
			DataLayer {
				setNotification: setNotification
				onConfirm: @props.onConfirm
				id: _.head @props.organizationsID
				projectID: @props.projectID
			}, (operations) =>
				BevyModal {
					onClose: @props.onClose
					header: 'You cannot delete ' + if operations.blockedDocument.data?.organization?.name? then operations.blockedDocument.data.organization.name else ''
					headerIcon: ErrorIcon {size: 'medium', primaryColor: '#DE350B'}
					headerSub: if operations.blockedDocument?.data?.organization?.documentsInUse?.visible?
						if operations.blockedDocument.data.organization.documentsInUse.visible isnt 0 then 'Documents below are assinged to this entity'
					else 'Documents are assigned to this entity'
					actions: [
						text: 'Cancel'
						onClick: @props.onClose
					]
				},
					if operations.blockedDocument.loading
						Spinner {}
					else
						more = operations.blockedDocument.data.organization.documentsInUse.total - 5
						isNotVisibleDocsPermisions = (operations.blockedDocument.data.organization.documentsInUse.total - operations.blockedDocument.data.organization.documentsInUse.visible) > 0
						Fragment {},
							_.map operations.blockedDocument.data.organization.documentsInUse.documents, (document) ->
								div
									key: document.id
									className: cnames styles.padding
								, Fragment {},
									div {className: cnames styles.inlineBlock, styles.tableWidth }, if document.form?.name? then document.form.name else document.scan.filename
									div {className: cnames styles.inlineBlock, styles.tableWidth }, if document.topic?.name? then document.topic.name
									div {className: styles.floatRight},
										Button
											spacing: 'none'
											href: 'legal/inbox/' + document.id
											target: '_blank'
											appearance: 'link'
											iconAfter: span {className: styles.ButtonLinkIcon}, Icon icon: externalLink, size: 14
							div {className: styles.more}, if more > 0 then "+#{more} more" else ''
							if isNotVisibleDocsPermisions
								Fragment {},
									div {className: styles.info}, 'Documents have to be changed by admin'
									Fragment {},
										div {className: styles.greyBackground},
											Fragment {},
												div {className: cnames styles.inlineBlock, styles.alignTop, styles.marginRight},
													Avatar
														src: if operations.projectLeader.data.projectByID?.leader?.picture?.mini? then operations.projectLeader.data.projectByID.leader.picture.mini
														size: 'small'
												div {className: cnames styles.inlineBlock, styles.alignTop, styles.leaderName},
													Fragment {},
														div {}, operations.projectLeader.data.projectByID.leader.name
														div {className: styles.projectLeader}, "Project Leader"


