import _ from 'lodash'
import React from 'react'

# Renderable
Fragment = React.createFactory React.Fragment

import {BevyModalsWrapper as _BevyModalsWrapper} from '@bevy/modal'
BevyModalsWrapper = React.createFactory _BevyModalsWrapper

import _EditDocument from 'parts/Legal/EditDocumentModal'
EditDocument = React.createFactory _EditDocument

import _ReassignDocument from 'parts/Legal/ReassignDocumentModal'
ReassignDocument = React.createFactory _ReassignDocument

import _DeleteDocument from 'parts/Legal/DeleteDocumentModal'
DeleteDocument = React.createFactory _DeleteDocument

import _MuteDocument from 'parts/Legal/MuteDocumentModal'
MuteDocument = React.createFactory _MuteDocument

import _ShareModal from 'parts/ShareModal'
ShareModal = React.createFactory _ShareModal

import _GenerateReport from 'parts/Legal/GenerateReportModal'
GenerateReport = React.createFactory _GenerateReport

import { REGISTRY_COLUMNS } from 'parts/Legal/DocumentsTableColumns/columns'


export default getModals = ({operations, selectedDocuments, currentFilters}) ->
	app = 'registry'
	BevyModalsWrapper {},
		switch @state.activeModal
			when 'delete'
				DeleteDocument
					app: app
					documentIDs: _.map @state.activeDocuments, 'id'
					onClose: => @setState
						selectedDocuments: _.difference @state.selectedDocuments, (_.map @state.activeDocuments, 'id')
						activeModal: null
						activeDocuments: []
			when 'mute'
				MuteDocument
					app: app
					documentIDs: _.map @state.activeDocuments, 'id'
					onClose: => @setState {activeModal: null, activeDocuments: []}
			when 'reassign'
				ReassignDocument
					app: app
					documentIDs: _.map @state.activeDocuments, 'id'
					projectSlug: @props.match.params.project
					onClose: => @setState
						activeModal: null
						activeDocuments: []
			when 'share'
				ShareModal
					app: app
					documentsIDs: _.map @state.activeDocuments, 'id'
					projectSlug: @props.match.params.project
					onClose: => @setState {activeModal: null, activeDocuments: []}
			when 'generate'
				GenerateReport
					selectedDocumentIDs: _.map @state.activeDocuments, 'id'
					currentColumnConfig: _.filter @state.documentsTableColumns, 'selected'
					allColumnConfig: REGISTRY_COLUMNS
					currentPerspective: {
						projectsIDs: [_.get operations, 'documentsList.data.project.id']
						states: ['Commited']
						...currentFilters
					}
					projectPerspective:
						projectsIDs: [_.get operations, 'documentsList.data.project.id']
					onConfirm: (config) =>
						@setState
							generateReport: true
							reportConfig: config
							activeModal: null
							activeDocuments: []
					onClose: () =>
						@setState
							activeModal: null
							activeDocuments: []
			when 'edit'
				EditDocument {
					...@props
					app: app
					active: @state.activeModal is 'edit'
					documentID: if !_.isEmpty @state.activeDocuments then @state.activeDocuments[0].id
					projectSlug: @props.match.params.project
					onClose: => @setState
						activeModal: null
						activeDocuments: []
				}

