###
New document registering modal
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import moment from 'moment-mini'
import printJS from 'print-js'
import DataAttribute from '@bevy/data-attribute'
import * as legalHelpers from 'libs/legal'

# Renderable
import { div, p, ul, li, small, strong, h1, span, h2, input, img } from 'react-dom-factories'

import { NotificationConsumer } from '../../../application/components/NotificationManager'

Fragment = React.createFactory React.Fragment

import { Query as _Query, Mutation as _Mutation } from 'react-apollo'
Query = React.createFactory _Query
Mutation = React.createFactory _Mutation


import Dropzone from 'react-dropzone'
DropzoneC = React.createFactory Dropzone

import { ButtonGroup as _ButtonGroup } from '@atlaskit/button'
ButtonGroup = React.createFactory _ButtonGroup

import _Button from '@bevy/button'
Button = React.createFactory _Button

import _Tooltip from '@bevy/tooltip'
Tooltip = React.createFactory _Tooltip

import __PDFPreview from '@bevy/pdf-preview'
PDFPreview = React.createFactory __PDFPreview

import _ErrorDisplay from 'components/ErrorDisplay'
ErrorDisplay = React.createFactory _ErrorDisplay

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import _BevyModal from '@bevy/modal'
BevyModal = React.createFactory _BevyModal

import _Select from '@bevy/select'
Select = React.createFactory _Select

import _DestinationForm from './components/DestinationForm'
DestinationForm = React.createFactory _DestinationForm
import _OriginForm from './components/OriginForm'
OriginForm = React.createFactory _OriginForm
import _DetailsForm from './components/DetailsForm'
DetailsForm = React.createFactory _DetailsForm
import _CategorySelector from './components/CategorySelector'
CategorySelector = React.createFactory _CategorySelector
import _ScansGallery from './components/ScansGallery'
ScansGallery = React.createFactory _ScansGallery




# Data
import {
	DocumentRegisterEnvironment
	ListLegalDocuments
	DocumentRegisterMutation
	DocumentCategorizeMutation
	getDetailsFields
	CreateOrganizationsMutation
	UpdateScan
} from './data'
import trLegal from 'data/local/legal'
import DataLayer from './elements/dataLayer'
import EmptyProcess from './elements/emptyProcess'

# Styles
import styles from './index.styl'
import ErrorIcon from '@atlaskit/icon/glyph/error'
import { info } from 'react-icons-kit/feather'

stage = if __STAGE__? then __STAGE__ else 'local'

export default class DocumentRegisterModal extends React.Component
	@propTypes =
		onClose: PropTypes.func.isRequired
	@defaultProps =
		fileChangeEnabled: true
		printQRCodeEnabled: false
		registerAnotherEnabled: true
		enableRecategorize: true
		showPreview: true
		confirmationName: 'Submit'

	@emptyProcess = EmptyProcess

	constructor: (props) ->
		super props
		@fileInput = React.createRef()
		@project = null
		initialProcess = if @props.initialSetup? then @props.initialSetup else DocumentRegisterModal.emptyProcess
		@state = {
			...initialProcess
			files: @props.files
			checkedScansIDs: @props.checkedScansIDs
			isDropValid: true
			advancedMode: true
			temporaryOrganizations: []
		}

	componentDidUpdate: (prevProps) ->
		if @props.initialSetup isnt prevProps.initialSetup
			@setState {...@state, ...@props.initialSetup}

	handleTemporaryOrganizationCreate: (newSource) =>
		newOrganizations = _.clone @state.temporaryOrganizations
		newOrganizations.push newSource
		@setState temporaryOrganizations: newOrganizations

	handleRegisterAnother: =>
		initialSetup = DocumentRegisterModal.emptyProcess
		_.find(initialSetup.steps, key: 'basic').done = true
		@setState {
			advancedMode: @state.advancedMode
			...initialSetup
			basicInfo:
				source: @state.basicInfo.source
				receivalDate: moment.utc().startOf('day').unix()
				creationDate: moment.utc().startOf('day').unix()
				company: @state.basicInfo.company
				description: ''
			files: null
			# isAnotherRegistration: true
		}

	setInitialSetup: =>
		@setState {
			advancedMode: true
			...DocumentRegisterModal.emptyProcess
		}

	canProceed: =>
		everyRequiredFieldIsFilled = _.every _.filter(@state.steps, (step) -> _.includes ['basic', 'category'], step.key), (step) => @isStepDone step.key, @state
		somethingChanged = @state.someValueChanged
		changedScan = @state.changedScan
		everyRequiredFieldIsFilled and (somethingChanged or changedScan)
		# if @state.advancedMode
		# 	_.every _.filter(@state.steps, (step) -> _.includes ['basic', 'category'], step.key), 'done'
		# else
		# 	_.find(@state.steps, key: @state.step).done

	isStepDone: (stepKey, state) =>
		switch stepKey
			when 'basic'
				hasPhases =
					if @project? and @project.isMultiphase and _.size(@project.phases) > 1 #isMultiphase with multiple options
						!_.isEmpty state.basicInfo.phases
					else
						true
				receivalDateNotInFuture = moment.unix(state.basicInfo.receivalDate).isSameOrBefore(moment.utc().endOf('day'))
				(!_.isEmpty state.basicInfo.source) and (!_.isEmpty state.basicInfo.company) and hasPhases and receivalDateNotInFuture
			when 'category'
				if !_.isEmpty(state.checkedScansIDs) and _.size(state.checkedScansIDs) > 1
					state.category.topic?
				else
					state.category.form? and state.category.topic? and (!state.parentRequired or state.category.parentDocument?)
			when 'details'
				# temporary condition for details step
				!_.isEmpty(state.details.dates) or !_.isEmpty(state.details.identifiers) or !_.isEmpty(state.details.dates)
			when 'review'
				true

	handleFormChange: (value, stepKey) =>
		inputData =
			switch stepKey
				when 'basic' then basicInfo: value
				when 'category' then category: value
				when 'details' then details: value
		nextState = {
			..._.cloneDeep @state
			...inputData
			someValueChanged: true
		}
		# setting new source
		if (value?.source?.id?)
			nextState.details.entities.Participants = _.reject nextState.details.entities.Participants, (participant) -> participant.fromContractor is true
			#check fromContractor
			if !_.includes nextState.details.entities.Participants, value.source
				nextState.details.entities.Participants = _.compact _.concat  nextState.details.entities.Participants, value.source
		# prevent clearing source from participants
		if stepKey is 'details' and (!_.isEmpty @state.details.entities.Participants) and _.isEmpty nextState.details.entities.Participants
			nextState.details.entities.Participants = [@state.basicInfo.source]
		# check if step is done after input change
		stepToUpdate = _.find nextState.steps, key: stepKey
		stepToUpdate.done = @isStepDone(stepKey, nextState)
		# update current step in advanced mode
		if @state.advancedMode
			firstToDoStep = _.find _.filter(nextState.steps, {advanced: true}), (step) -> step.done is false
			nextState.step =
				if firstToDoStep? then firstToDoStep.key
				else _.findLast(_.filter(nextState.steps, {advanced: true}), (step) -> step.done).key
		# FIXME probably unneded forceUpdate
		@setState nextState

	calculateFormProgress: =>
		hasPhases = if @project?.isMultiphase? and @project.isMultiphase then !_.isEmpty(@state.basicInfo.phases) else true
		formProgress =
			origin: [ @state.basicInfo.receivalDate?, @state.basicInfo.creationDate?, @state.basicInfo.source?, !_.isEmpty @state.basicInfo.company, hasPhases]
			destination: [ @state.basicInfo.company? ]
			category: [ @state.category.form?, @state.category.topic? ]
		if @state.parentRequired
			formProgress.category.push @state.parentDocument?
		result =
			total:
				filled: 0
				all: 0
		_.forEach formProgress, (value, key) ->
			result[key] =
				filled: _.size _.compact value
				all: _.size value
			result.total.filled += result[key].filled
			result.total.all += result[key].all
		result

	handleCreatingOrganizations: () =>
		if @state.details.entities['Participants']?
			participants = @state.details.entities['Participants']
			if _.isArray participants
				newOrganizations = _.filter participants, (item) -> if (item.id is item.name) or (!item.id?) then item.name
			else if !participants.id?
				newOrganizations = [{name: participants.name}]
		else if @state.details.entities['Recipient']?
			recipients = @state.details.entities['Recipient']?
			if _.isArray recipients
				newOrganizations = _.filter recipients, (item) -> if (item.id is item.name) or (!item.id?) then item.name
			else if !recipients.id?
				newOrganizations = [{name: recipients.name}]
		newOrganizations = _.map newOrganizations, (item) -> item.name
		if !@state.basicInfo.source.id?
			newSource = _.clone @state.basicInfo.source.name
		if !_.isEmpty newSource
			newOrganizations = _.concat newOrganizations, newSource
		_.uniq _.compact newOrganizations

	registerDocument: (newSource, newEntities) =>
		{ combination } = @state
		completeEntities = {}
		if @state.details.entities.Recipient? then completeEntities.Recipient = @state.details.entities.Recipient
		if newEntities? then completeEntities.Participants  = newEntities
		else if @state.details.entities.Participants? then completeEntities.Participants = @state.details.entities.Participants
		phaseIDs =
			if @project.isMultiphase and _.size(@project.phases) is 1 # case when phase select is visible but disabled
				[_.head(@project.phases).id]
			else
				_.map @state.basicInfo.phases, 'value'
		variables =
			basics:
				projectID: @project.id
				companyID: @state.basicInfo.company.id
				correspondentID: if newSource? then newSource.id else @state.basicInfo.source.id
				receivalDate: @state.basicInfo.receivalDate
				creationDate: @state.basicInfo.creationDate
				description: @state.basicInfo.description
				phaseIDs: phaseIDs
				topicID: @state.category.topic.id
				formID: _.get @state.category.form, 'id'
				pathID: _.get @state.category.path, 'id'
				parentDocumentID: if @state.category.parentDocument? then @state.category.parentDocument.id
			details:
				dates: _.map @state.details.dates, (date, key) -> {type: key, value: date}
				identifiers: _.map @state.details.identifiers, (identifier, key) -> {type: key, value: identifier}
				entities: _.reduce completeEntities, (acc, value, key) ->
					if key is 'Participants'
						acc.participantsIDs = _.map value, 'id'
					else if key is 'Recipient'
						acc.recipientID = (_.head value).id
					acc
				, {}
		if @state.files?
			checkedScans = _.filter Array.from(@state.files), (file, index) =>
				_.some @state.checkedScansIDs, (checkedID) -> checkedID is index
			variables.scans = checkedScans
		if combination?.building?
			variables.details.buildings = _.map @state.details.buildings, 'value'
		if @state.details.freeText? and !_.isEmpty @state.details.freeText
			variables.details.freeText = @state.details.freeText
		@registerMutation
			variables: variables

	categorizeDocument: (newSource, newEntities) =>
		{ combination } = @state
		updatedDetailsDates = _.reduce @state.details.dates, (acc, date, key) ->
				if _.includes ['CreationDate', 'ReceivalDate'], key
					acc
				else
					isPresentInCombination = (_.find combination.dates, (date) -> date.type is key)?
					{
						...acc,
						[key]: if isPresentInCombination then date else -1 #TODO: fixme
					}
			, {}

		completeEntities = {}
		if @state.details.entities.Recipient? then completeEntities.Recipient = @state.details.entities.Recipient
		if newEntities? then completeEntities.Participants  = newEntities
		else if @state.details.entities.Participants? then completeEntities.Participants = @state.details.entities.Participants
		documentIDs = if !_.isEmpty(@state.checkedScansIDs) then _.map(@state.checkedScansIDs, (selectionIndex) => @props.documentIDs[selectionIndex]) else @props.documentIDs

		variables =
			documentIDs: documentIDs # ONLY CHANGE WITH 'registerDocument' LOL
			basics:
				projectID: @project.id
				companyID: @state.basicInfo.company.id
				correspondentID: if newSource? then newSource.id else @state.basicInfo.source.id
				receivalDate: @state.basicInfo.receivalDate
				creationDate: @state.basicInfo.creationDate
				description: @state.basicInfo.description
				formID: @state.category.form.id
				topicID: @state.category.topic.id
				pathID: @state.category.path.id
				phaseIDs: _.map @state.basicInfo.phases, 'value'
				parentDocumentID: if @state.category.parentDocument? then @state.category.parentDocument.id else ''
			details:
				dates: _.map updatedDetailsDates, (date, key) -> {type: key, value: date}
				identifiers: _.map @state.details.identifiers, (identifier, key) ->
					if !_.isEmpty combination.identifiers
						isPresentInCombination =
							if key is 'ExternalID' then true
							else (_.find combination.identifiers, (identifier) -> identifier.type is key)?
					else
						isPresentInCombination = true
					{
						type: key
						value: if isPresentInCombination then identifier else ''
					}
				entities: _.reduce completeEntities, (acc, value, key) ->
					if key is 'Participants'
						acc.participantsIDs = _.map value, 'id'
					else if key is 'Recipient'
						isPresentInCombination = (_.find combination.entities, (entity) -> entity.type is key)?
						acc.recipientID = if isPresentInCombination then value.id else ''
					acc
				, {}
		variables.details.buildings =
			if combination.building
				_.map @state.details.buildings, 'value'
			else []
		if @state.details.freeText?
			variables.details.freeText = @state.details.freeText
		if @state.files? and @state.files[0]? and !_.isString(@state.files[0])
			@updateScanMutation
				variables:
					scan: @state.files[0]
					documentID: @props.documentIDs[0]
		@categorizeMutation
			variables: variables

	handleQRPrint: (format, data ) =>
		if @props.printQRCodeEnabled
			legalDocument = data.legalDocumentCreateByCategory
			document =
				id: legalDocument.id
				url: "#{window.location.origin}/legalDocuments/#{legalDocument.id}"
				project: legalDocument.project.name
				company: legalDocument.company.name
				source: legalDocument.correspondent.name
				date: _.find(legalDocument.dates, type: 'ReceivalDate').value
				form: legalDocument.form.name
				topic: legalDocument.topic.name
				fullPath: legalHelpers.getConjectPath legalDocument.path
				shortPath: legalHelpers.getConjectPath legalDocument.path, true
				createdBy: legalDocument.createdBy.name
				createdAt: legalDocument.createdAt

			_stage = if stage is 'local' then 'development' else stage
			serviceEndpoints =
				development: 'https://jn2nmd634m.execute-api.eu-central-1.amazonaws.com/development'
				test: 'https://80a54u0kaj.execute-api.eu-central-1.amazonaws.com/test'
				production: 'https://7xk8wri3dl.execute-api.eu-central-1.amazonaws.com/production'

			url = serviceEndpoints[_stage] + "?format=#{format}&document=#{encodeURIComponent JSON.stringify document}"
			fetch url,
				mode: 'no-cors'
			.then (data) =>
				printJS
					printable: "https://s3.eu-central-1.amazonaws.com/s360-dms-qr-tags/#{_stage}/#{legalDocument.id}/#{format}.pdf"
					type: 'pdf'
					onLoadingStart: () -> # leave to display print window

	renderOriginStep: (data) ->
		OriginForm
			onChange: (value) => @handleFormChange(value, 'basic')
			projectLeader: data.project.leader
			isMultiphase: data.project.isMultiphase
			highlightMissingValues: @state.highlightMissingValues
			onTemporaryCreate: (value) => @handleTemporaryOrganizationCreate value
			projectSlug: data.project.slug
			temporaryOrganizations: @state.temporaryOrganizations
			value: @state.basicInfo

	renderDestinationStep: (data) ->
		DestinationForm
			lockedCompany: if data.project?.spv? then data.project.spv
			highlightMissingValues: @state.highlightMissingValues
			projectName: data.project.name
			projectSlug: data.project.slug
			onChange: (value) => @handleFormChange(value, 'basic')
			value: @state.basicInfo

	renderCategoryStep: (data) ->
		projectID = data.project.id
		if !@state.category.topic? or !@state.category.form?
			CategorySelector
				projectID: projectID
				onChange: (value) => @handleFormChange(value, 'category')
				# projectLeader: data.project.leader
				highlightMissingValues: @state.highlightMissingValues
				value: @state.category
				advancedMode: @state.advancedMode
				enableRecategorize: @props.enableRecategorize
				formRequired: (_.size @state.checkedScansIDs) <= 1
		else
			Query
				query: getDetailsFields
				variables:
					formID: @state.category.form.id
					topicID: @state.category.topic.id
					pathID: @state.category.path.id
					projectID: projectID
				, ({loading, data}) =>
					if !loading and data?
						parentRequiredChanged = data.legalDocumentAvailableParents? isnt @state.parentRequired
						combination = if !@state.category.topic? or !@state.category.form?
							null
						else
							_.find data.projectByID.legalDocumentPath.combinations, {topicID: @state.category.topic.id, formID: @state.category.form.id}
						combinationChanged =
							(@state.combination? isnt combination?) or
							(@state.combination? and @state.combination.formID isnt @state.category.form.id) or
							(@state.combination? and @state.combination.topicID isnt @state.category.topic.id)

						if parentRequiredChanged or combinationChanged
							nextState = { ..._.cloneDeep @state }
							if parentRequiredChanged
								nextState.parentRequired = data.legalDocumentAvailableParents?
								stepToUpdate = _.find nextState.steps, key: 'category'
								stepToUpdate.done = @isStepDone('category', nextState)
							if combinationChanged
								nextState.combination = combination
							@setState nextState
					CategorySelector
						projectID: projectID
						onChange: (value) => @handleFormChange(value, 'category')
						# projectLeader: data.project.leader
						highlightMissingValues: @state.highlightMissingValues
						value: @state.category
						advancedMode: @state.advancedMode
						availableParents: if !loading then _.get data, 'legalDocumentAvailableParents'
						enableRecategorize: @props.enableRecategorize
						formRequired: (_.size @state.checkedScansIDs) <= 1

	renderDetailsStep: (data) ->
		DetailsForm
			highlightMissingValues: @state.highlightMissingValues
			projectSlug: data.project.slug
			projectLeader: data.project.leader
			phases: @state.basicInfo.phases
			contractor: @state.basicInfo.source
			active: @state.combination? and @state.category.path?
			combination: @state.combination
			value: @state.details
			onTemporaryCreate: (value, type) => @handleTemporaryOrganizationCreate value
			temporaryOrganizations: @state.temporaryOrganizations
			onChange: (value) => @handleFormChange(value, 'details')
			advancedMode: @state.advancedMode
			project: data.project
			multipleScans: (_.size @state.checkedScansIDs) > 1

	renderScansGallery: () =>
		ScansGallery
			scans: _.map @state.files, (file, index) -> {scan: file, id: index}
			selectedScanID: if @state.selectedScan? then @state.selectedScan.id else 0
			onScanPreviewChange: (item) => 	@setState selectedScan: item
			checkedScansIDs: @state.checkedScansIDs
			onCheckChange: (item) =>
				previousState = @state.checkedScansIDs
				nextState =
					if previousState? and (item.id in previousState)
						_.without previousState, item.id
					else
						_.concat previousState, item.id
				nextState = _.uniq nextState
				@setState checkedScansIDs: nextState

	render: ->
		NotificationConsumer {}, ({setNotification}) =>
			DataLayer
				props: @props
				projectSlug: @props.match.params.project
				app: @props.app
				isEdition: @props.isEdition
				isMulti: (_.size @state.checkedScansIDs) > 1
				setNotification: setNotification
				documentIDs: @props.documentIDs
				shouldRegisterAnother: @state.shouldRegisterAnother
				prevDocumentLegalState: if @props.initialSetup? then @props.initialSetup.state
				onRegisterCompleted: =>
					if @state.qrType?
						if @state.qrType is 'cover' or @state.qrType is 'sticker'
							@handleQRPrint @state.qrType, data
					if @state.shouldRegisterAnother then @handleRegisterAnother() else @setInitialSetup()
				onCreateOrganizations: (newOrganizations) =>
					newSource = _.find newOrganizations, (org) => org.name is @state.basicInfo.source.name
					newEntities = _.mapValues @state.details.entities, (value, key, object) ->
						value = _.map value, (organization) ->
							_.find newOrganizations, (org) ->
								org.name is organization.name
						_.compact value
					if !@props.documentIDs?
						@registerDocument(newSource, newEntities)
					else
						@categorizeDocument(newSource, newEntities)
			, (operations) =>
				mutationLoading = operations.registerDocument.mutation.loading or operations.categorizeDocument.mutation.loading
				if operations.documentRegisterEnvironment.data? and !_.isEmpty operations.documentRegisterEnvironment.data
					@project = operations.documentRegisterEnvironment.data.project
				@registerMutation = operations.registerDocument.mutation
				@categorizeMutation = operations.categorizeDocument.mutation
				@updateScanMutation = operations.updateScan.mutation
				BevyModal
					width: if @props.showPreview then '90%' else 'large'
					height: '100%'
					shouldCloseOnEscapePress: false
					shouldCloseOnOverlayClick: false
					onClose: @props.onClose
					autoFocus: false
					components:
						Header: =>
							div {className: styles.modalHeaderWrapper},
								div {className: styles.modalHeader},
									if @props.isEdition
										'Edit document'
									else if @props.documentIDs?
										if (_.size @state.checkedScansIDs) > 1 then "Batch categorize scans (#{_.size @state.checkedScansIDs})" else 'Categorize scan'
									else
										if (_.size @state.checkedScansIDs) > 1 then "Batch register documents (#{_.size @state.checkedScansIDs})" else 'Register document'
								do => if @props.fileChangeEnabled
									Fragment {},
										if @state.files?
											span {className: styles.fileInfo}, legalHelpers.stringifyLoadedPDFsNames _.filter @state.files, (file, index) =>
												index in @state.checkedScansIDs

										Tooltip {
											content:
												if @state.files?
													"Remove attached scan#{if (_.size @state.files) > 1 then 's' else ''}"
												else 'Select scan to attach'
										},
											Button
												className: styles.barButton
												onClick: =>
													if @state.files?
														@setState files: null
													else
														@fileInput.current.click()
											, if _.isEmpty @state.files then 'Select scan' else "Remove scan#{if (_.size @state.files) > 1 then 's' else ''}"

						Footer: =>
							progress = @calculateFormProgress()
							div {className: styles.modalFooterWrapper},
								div {},
									if !(operations.documentRegisterEnvironment.error? or @props.error?)
										Fragment {},
											span {className: if progress.total.filled < progress.total.all then styles.redLabel}, progress.total.filled
											"/#{progress.total.all} required fields filled"
								ButtonGroup {},
									do => if @props.printQRCodeEnabled
										printQROptions = [
													label: 'No QR code'
													value: 'no'
												,
												# 	label: 'Sticker QR code'
												# 	value: 'sticker'
												# ,
													label: 'Cover Page QR code'
													value: 'cover'
												]
										div {className: styles.selectWrapper},
											Select
												isDisabled: (_.size @state.checkedScansIDs) > 1
												disableColoring: true
												placeholder: 'Select QR code type'
												menuPlacement: 'top'
												options: printQROptions
												value: do => if @state.qrType?
													_.find printQROptions, value: @state.qrType
												onChange: (item) => @setState qrType: item.value
									Button
										appearance: 'primary'
										className: styles.buttonOffset
										onClick: =>
											if !@canProceed()
												@setState highlightMissingValues: true
											else
												newOrganizations = @handleCreatingOrganizations()
												if !_.isEmpty newOrganizations
													operations.createOrganizations.mutation
														variables:
															names: newOrganizations
															projectsIDs: if @project? then [@project.id]
												else if !@props.documentIDs?
													@registerDocument()
												else
													@categorizeDocument()
												@props.onClose()
									, @props.confirmationName
									do => if @props.registerAnotherEnabled
										Button
											appearance: 'primary'
											isDisabled: !@canProceed()
											className: styles.buttonOffset
											onClick: =>
												@setState {shouldRegisterAnother: true}, () =>
													newOrganizations = @handleCreatingOrganizations()
													if !_.isEmpty newOrganizations
														operations.createOrganizations.mutation
															variables:
																names: newOrganizations
																projectSlug: @props.match.params.project
													else if !@props.documentIDs?
														@registerDocument()
													else
														@categorizeDocument()
													# @handleRegisterAnother()
										, "#{@props.confirmationName} & new"
									Button
										appearance: 'subtle'
										onClick: =>
											@setState DocumentRegisterModal.emptyProcess
											@props.onClose()
									, 'Cancel'
					,
						ErrorDisplay {
							loading: operations.documentRegisterEnvironment.loading or @props.loading
							error: @props.error || operations.documentRegisterEnvironment.error
						}, () =>
							DropzoneC
								disabled: !@props.fileChangeEnabled
								onDrop: (files) =>
									@setState
										files: files
										checkedScansIDs: _.map files, (file, index) -> index
										changedScan: true
								multiple: _.size(@props.documentIDs) isnt 1
								accept: 'application/pdf'
							, ({getRootProps, getInputProps, isDragActive, isDragReject}) =>
								div {
									className: styles.modalContainer
									'data-test-id': DataAttribute 'register-document-modal'
									...getRootProps()
								},
									newName = legalHelpers.getName4Document @state, operations
									changeInName = newName? and newName isnt _.get(@props, 'initialSetup.name')

									Fragment {},
										input {
											...getInputProps()
											ref: @fileInput
											onChange: (event) =>
												files = if !_.isEmpty event.target.files then event.target.files else null
												@setState
													files: files
													checkedScansIDs: _.map files, (file, index) -> index
										}
										if isDragActive
											div {className: styles.dropOverlay},
												if isDragReject
													Fragment {},
														img src: require 'assets/upload-invalid.png'
														p {className: styles.dropCaption}, 'Scan must be a PDF file'
												else
													Fragment {},
														img src: require 'assets/upload-valid.png'
														p {className: styles.dropCaption}, 'Drop here to attach scan'
										div { className: styles.columnsContainer},
											if @props.showPreview
												Fragment {},
													if (!_.isEmpty @state.files) and (_.size @state.files) > 1
														div {className: cnames styles.column, styles.scansGallery},
															@renderScansGallery()
													div {className: cnames styles.column, styles.pdfPreview},
														if _.isEmpty @state.files
															div {className: styles.placeholder},
																img className: styles.imageSmall, src: require 'assets/upload-valid.png'
																Tooltip {content: 'Select scan to upload'},
																	Button
																		className: styles.button
																		onClick: => @fileInput.current.click()
																	, 'Select scan'
														else
															PDFPreview
																scan:
																	if @state.selectedScan?
																		@state.selectedScan.scan
																	else if @state.files?
																		_.first @state.files
																	else
																		_.first @props.files
																enableFullScreen: false
											div {className: styles.column},
												if (_.size @state.checkedScansIDs) > 1
													div {className: styles.infoHeader},
														ErrorIcon {size: 'small', primaryColor: '#8993a4'}
														if !@props.documentIDs?
															'Every selected scan will become a separate DMS document.'
														else
															'Every selected scan will be categorized separately.'
												div {className: styles.columnHeader},
													if @state.category.path?
														div {className: styles.code}, legalHelpers.getConjectPath @state.category.path
													else
														div {className: styles.code}, '-'
												div {	className: cnames styles.columnHeader, if changeInName then styles.modifiedText},
													legalHelpers.getName4Document @state, operations
													if changeInName
														div {className: styles.iconTooltip},
															Tooltip
																content: 'Document name will be changed due to updated properties'
															,
																Icon {icon: info, size: 9}

												div {className: styles.columnContent},
													div {className: styles.sectionContent}, @renderOriginStep operations.documentRegisterEnvironment.data
													div {className: styles.sectionContent}, @renderDestinationStep operations.documentRegisterEnvironment.data
													div {className: styles.sectionContent}, @renderCategoryStep operations.documentRegisterEnvironment.data
													div {className: styles.sectionContent}, @renderDetailsStep operations.documentRegisterEnvironment.data
