###
Add Project Modal
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import adopt from 'libs/adopt'
import moment from 'moment-mini'
import { convertToOptions } from 'libs/legal'

import { NotificationConsumer } from '../../../application/components/NotificationManager'
import { UserInfoConsumer } from 'libs/userInfo'

# Renderable
import { div, span, input, img } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import _Dropzone from 'react-dropzone'
Dropzone = React.createFactory _Dropzone

import _Spinner from '@bevy/spinner'
Spinner = React.createFactory _Spinner

import _BevyModal from '@bevy/modal'
BevyModal = React.createFactory _BevyModal

import { Field as _Field } from '@atlaskit/form'
Field = React.createFactory _Field

import _Select, {LoadableSelect as _LoadableSelect} from '@bevy/select'
Select = React.createFactory _Select
LoadableSelect = React.createFactory _LoadableSelect

import _FieldText from '@atlaskit/textfield'
FieldText = React.createFactory _FieldText

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import _Button from '@bevy/button'
Button = React.createFactory _Button

import _Tooltip from '@bevy/tooltip'
Tooltip = React.createFactory _Tooltip

import { DatePicker as _DatePicker } from '@atlaskit/datetime-picker'
DatePicker = React.createFactory _DatePicker

# Styles
import styles from './index.styl'
import { info } from 'react-icons-kit/feather'

# Data
import {
	AddProject
	EditProject
	GetOrganizations
	GetUsers
} from './data'

DataLayer = adopt
	queries: {}
	mutations:
		addProject: ({setNotification}) ->
			mutation: AddProject
			onCompleted: -> setNotification({content: 'Project added', appearance: 'success'})
			onError: -> setNotification ({content: 'Error on creating Project', appearance: 'error'})
		updateProject: ({setNotification, onUpdateComplete}) ->
			mutation: EditProject
			onCompleted: (data) ->
				onUpdateComplete(data)
				setNotification({content: 'Project edited', appearance: 'success'})

			onError: -> setNotification ({content: 'Error on editing Project', appearance: 'error'})

commonSelectProps =
	menuPortalTarget: document.body
	isSearchable: true
	hideSelectedOptions: true
	isClearable: false
	formatOptionLabel: (option) -> option.label
	getOptionLabel: (option) -> option.label
	getOptionValue: (option) -> option.value

export default class AddProjectModal extends React.Component
	@propTypes =
		onClose: PropTypes.func.isRequired

	constructor: (props) ->
		super props
		@fileInput = React.createRef()
		@state = {
			...@props.initialSetup
			projectID: if @props.projectID? then @props.projectID
			}

	# LOGIC
	handleImageUpload: (imageFile) =>
		fr = new FileReader()
		fr.onload = (e) =>
			@setState
				photoPreview: e.target.result
				photo: imageFile
		fr.readAsDataURL imageFile

	canProceed: =>
		mustHaveFields =
			if @props.initialSetup? #edit
				[ 'code', 'name', 'spvID', 'projectID']
			else
				[ 'code', 'name', 'spvID', 'projectLeaderID']
		everyRequiredFieldsFilled = _.every mustHaveFields, (field) => !_.isEmpty @state["#{field}"]
		madeAnyChange = if @props.initialSetup?
				_.some (_.omit @props.initialSetup, 'defaultValueSPV', 'defaultValueLeader'), (value, key) => value isnt @state["#{key}"]
			else
				true
		everyRequiredFieldsFilled and madeAnyChange

	getVariables4Mutation: =>
		variables = _.omit @state, ['hasMore', 'defaultValueSPV', 'photoPreview', 'dates', 'defaultValueLeader', 'projectLeaderIDHasMore', 'spvIDHasMore']
		variables = _.omitBy @state, (value, key) -> _.startsWith key, 'phase_'
		phasesObj = _.pickBy @state, (value, key) -> _.startsWith key, 'phase_'
		phases = []
		_.map phasesObj, (value, key) ->
			phases.push {
				id: _.trim key, 'phase_'
				name: value
			}
		if _.isString @state.photo
			variables = _.omit variables, ['photo','hasMore', 'defaultValueSPV', 'photoPreview', 'dates', 'defaultValueLeader', 'projectLeaderIDHasMore', 'spvIDHasMore']
			variables = _.omitBy variables, (value, key) -> _.startsWith key, 'phase_'
		if !_.isEmpty @state.dates
			variables.dates = _.map @state.dates, (date) -> {type: date.type, value: date.value}
		if !_.isEmpty phases
			variables.phases = phases
		if @props.projectID
			variables.id = @props.projectID
		variables

	onCloseProxy: (appropriateMutation) =>
		if _.isFunction appropriateMutation
			appropriateMutation
				variables: @getVariables4Mutation()
				refetchQueries: ['ProjectsList', 'ProjectDashboard']
		@props.onClose()

	# SUBCOMPONENTS
	renderLabel: ({label, tooltipContent, isRequired, grow}, children) =>
		div {className: cnames (if grow? then styles.fieldGrow) },
			Field
				label: span {className: styles.label},
					label
					if tooltipContent?
						div {className: cnames styles.iconTooltip, if isRequired then styles.moreMargin},
							Tooltip {content: tooltipContent}, Icon {icon: info, size: 9}
				name: label
				isRequired: isRequired
			, =>
				children

	renderTextField: ({label, slug, isRequired, tooltipContent, maxLength, grow, toUpper}) =>
		@renderLabel {label, tooltipContent, isRequired, grow},
			div {className: styles.formItemText},
				FieldText
					className: styles.formItem
					isLabelHidden: true
					onChange: (e) =>
						{ value } = e.target
						@setState ["#{slug}"]: if toUpper then _.toUpper value else  value
					value: @state["#{slug}"]
					# shouldFitContainer: true
					maxLength: maxLength

	renderDatePicker: ({label, slug, tooltipContent, isRequired, grow}) =>
		currDate = (_.find @state.dates, {type: slug})
		@renderLabel {label, tooltipContent, isRequired, grow},
			div {className: cnames styles.formItem, styles.datePicker},
				DatePicker
					className: styles.formItem
					id: slug
					dateFormat: 'DD/MM/YYYY'
					placeholder: ''
					parseInputValue: (value, dateFormat) -> moment(value, dateFormat).toDate()
					onChange: (value) =>
						newDate = {type: slug, value: moment.utc(value, 'YYYY-MM-DD').unix() }
						dates = _.clone @state.dates
						if currDate?
							dates = _.reject dates, (date) -> date.type is slug
						dates = [...dates, newDate]
						@setState {dates}
					value: if currDate? then moment.unix(currDate.value).format('YYYY-MM-DD')

	renderLoadableSelect: ({label, slug, isRequired, tooltipContent, isDisabled, defaultValue, handleDataUpdate, query, makeOptionsFromData}) =>
		@renderLabel {label, tooltipContent, isRequired},
			LoadableSelect {
				...commonSelectProps
				isDisabled
				values: [@state["#{slug}"]]
				onChange: (item) => @setState ["#{slug}"]: item.value
				hasMore: @state["#{slug}HasMore"]
				query
				makeOptionsFromData
				handleValueToDisplay: (currentValue, listOfPossibilities) =>
					if currentValue? and listOfPossibilities?
						itemToDisplay = _.find listOfPossibilities, (item) -> item.value is currentValue
						if itemToDisplay?
							label: itemToDisplay.label
							value: itemToDisplay.value
						else if defaultValue?
							defaultValue
				handleDataUpdate: (prev, fetchMoreResult) =>
					if _.isEmpty fetchMoreResult.organizations
						@setState "#{slug}HasMore": false
					handleDataUpdate prev, fetchMoreResult
			}

	renderPhasesSection: =>
		phases = _.pickBy @state, (value, key) -> _.startsWith key, 'phase_'
		div {className: styles.section},
			div {className: styles.sectionHeader}, 'Phases'
			_.map phases, (value, key) =>
				@renderLabel {
					label: 'Name',
					tooltipContent: 'Max. 100 characters'
					isRequired: true,
				},
					div {className: styles.formItemText},
						FieldText
							className: styles.formItem
							isLabelHidden: true
							onChange: (e) =>
								{ value } = e.target
								@setState [key]: value
							value: @state[key]

	# PARTS
	renderBasicsSection: =>
		div {className: styles.section},
			@renderTextField
				label: "Name"
				slug: 'name'
				isRequired: true
				tooltipContent: 'Max. 120 characters'
				maxLength: 120
			@renderTextField
				label: "Code"
				slug: 'code'
				tooltipContent: 'Max. 5 characters'
				maxLength: 5
				isRequired: true
				toUpper: true
			@renderLoadableSelect
				label: "SPV developer"
				slug: "spvID"
				isRequired: true
				tooltipContent: 'SPV developer company'
				query:
					query: GetOrganizations
					variables:
						filters: types: ["Internal"]
						pagination:
							limit: 30
				makeOptionsFromData: (data) =>
					if data?.organizations?
						convertToOptions data.organizations
					else
						[]
				handleDataUpdate: (prev, fetchMoreResult) ->
					{
						...prev
						organizations: [...prev.organizations, ...fetchMoreResult.organizations]
					}
				defaultValue: if @props.initialSetup? then @props.initialSetup.defaultValueSPV
			if !@props.initialSetup?
				@renderLoadableSelect
					label: "Project Leader"
					slug: "projectLeaderID"
					tooltipContent: 'Must be member of AD'
					isRequired: true
					query:
						query: GetUsers
						variables:
							pagination:
								limit: 30
					makeOptionsFromData: (data) =>
						if data?.users?
							convertToOptions _.reject data.users, __typename: 'Service'
						else
							[]
					handleDataUpdate: (prev, fetchMoreResult) ->
						{
							...prev
							users: [...prev.users, ...fetchMoreResult.users]
						}
					defaultValue: if @props.initialSetup? then @props.initialSetup.defaultValueLeader

	renderDatesSection: =>
		div {className: styles.section},
			div {className: styles.sectionHeader}, 'Dates'
			@renderDatePicker
				label: "Land Purchase Date"
				slug: 'landPurchaseDate'
			@renderDatePicker
				label: "Building Permit Date"
				slug: 'buildingPermitDate'
			@renderDatePicker
				label: "Building Construction Start Date"
				slug: 'buildingContructionStartDate'
			@renderDatePicker
				label: "Project End Date"
				slug: 'projectEndDate'

	renderPreviewSection: =>
		{ photo, photoPreview } = @state
		div {className: styles.section},
			div {className: styles.sectionHeader}, 'Preview'
			Tooltip {content: 'Drop image to upload'},
				Dropzone
					onDrop: (files) => @handleImageUpload  _.head files
					multiple: false
					accept: 'image/jpeg'
				, ({getRootProps, getInputProps, isDragActive, isDragReject}) =>
					div {...getRootProps()},
						input {
							...getInputProps()
							ref: @fileInput
							onChange: (event) => @handleImageUpload _.head event.target.files
						}
						div {
							className: cnames styles.previewDropzone, if isDragActive then styles.active
							style: if photo || photoPreview
								backgroundImage: "url(#{photoPreview || photo})"
								backgroundRepeat: 'no-repeat'
								backgroundSize: 'cover'
						},
							Button
								appearance: if photo? then 'primary'
								onClick: => @fileInput.current.click()
							, if photo? then 'Change preview image' else 'Upload preview image'

	renderAddressSection: =>
		div {className: styles.section},
			div {className: styles.sectionHeader}, 'Adress'
			div {className: styles.horizontal},
				@renderTextField
					label: "Street"
					slug: 'street'
					grow: true
				@renderTextField
					label: "Building number"
					slug: 'buildingNumber'
			div {className: styles.horizontal},
				@renderTextField
					label: "Postal Code"
					slug: 'postalCode'
				@renderTextField
					label: "City"
					slug: 'city'
					grow: true

	# CONTENT
	renderContent: =>
		div {className: styles.base},
			div {className: styles.column},
				@renderBasicsSection()
				@renderPhasesSection()
				if @props.advancedView
					Fragment {},
						@renderDatesSection()
			if @props.advancedView
				div {className: styles.column},
					Fragment {},
						@renderPreviewSection()
						@renderAddressSection()

	render: ->
		UserInfoConsumer {}, ({ability, me}) =>
			NotificationConsumer {}, ({setNotification}) =>
				DataLayer
					setNotification: setNotification
					onUpdateComplete: @props.onUpdateComplete
					, (operations) =>
						BevyModal
							onClose: @onCloseProxy
							header: if _.isEmpty @props.initialSetup then 'Add project' else 'Edit project'
							actions: [
								text: 'Save'
								isDisabled: !@canProceed()
								onClick: () => @onCloseProxy(
									if _.isEmpty @props.initialSetup
										operations.addProject.mutation
									else
										operations.updateProject.mutation
								)
							,
								text: 'Cancel'
								onClick: @onCloseProxy
							]
						,
							@renderContent()


