###
Add Group Member Modal
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import adopt from 'libs/adopt'
import { convertToOptions } from 'libs/legal'
import { NotificationConsumer } from '../../../application/components/NotificationManager'
import { UserInfoConsumer } from 'libs/userInfo'

# Renderable
import { div, span } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import _BevyModal from '@bevy/modal'
BevyModal = React.createFactory _BevyModal

import {
	LoadableSelectField as _LoadableSelectField,
} from 'components/FormItems'
LoadableSelectField = React.createFactory _LoadableSelectField

# Data
import {
	GetUsersNotInGroup
	AddUsersToGroup
} from './data'


REFETCH_QUERIES = [
	'GroupsList'
	'GroupDetail'
	]

DataLayer = adopt
	mutations:
		addUsersToGroup: ({id, setNotification}) ->
			mutation: AddUsersToGroup
			variables: { id }
			refetchQueries: REFETCH_QUERIES
			onCompleted: ->
				setNotification({content: 'Users successfully added to the group', appearance: 'success'})

export default class AddGroupMemberModal extends React.Component
	@propTypes =
		onClose: PropTypes.func.isRequired

	constructor: (props) ->
		super props
		@state = {}

	makeUserOptions: (arr) ->
		if !_.isEmpty arr
			_.map arr, (item) ->
				label: "#{item.name} (#{item.email})"
				value: item.id
		else
			[]

	onCloseProxy: (appropriateMutation) =>
		if _.isFunction appropriateMutation
			appropriateMutation
				variables:
					id: @props.groupID
					addedEntities: _.map @state.users, (user) ->
						id: user.value
		@props.onClose()

	render: ->
		UserInfoConsumer {}, ({ability, me}) =>
			NotificationConsumer {}, ({setNotification}) =>
				DataLayer
					setNotification: setNotification
					id: @props.groupID
					, (operations) =>
						BevyModal
							onClose: @onCloseProxy
							header: 'Add users to group'
							width: 'small'
							actions: [
								text: 'Save'
								isDisabled: _.isEmpty @state.users
								onClick: () => @onCloseProxy operations.addUsersToGroup.mutation
							,
								text: 'Cancel'
								onClick: @onCloseProxy
							]
						,
							LoadableSelectField
								label: 'Name'
								tooltipContent: 'To add new user please contact administrator.'
								isMulti: true
								query:
									query: GetUsersNotInGroup
									variables:
										id: @props.groupID
										pagination:
											limit: 20
								makeOptionsFromData: (data) =>
									if data?.userGroup?.notMembers
										@makeUserOptions data.userGroup.notMembers
									else
										[]
								handleValueToDisplay: (value, options) =>
									allPossibleOptions = _.uniqBy (_.concat options, @state.users), 'value'
									itemToDisplay = _.find allPossibleOptions, {value}
									if itemToDisplay?
										label: itemToDisplay.label
										value: itemToDisplay.value
								values: _.map @state.users, 'value'
								onChange: (items) =>
									@setState users: items
								hasMore: @state.hasMore
								handleDataUpdate: (prev, fetchMoreResult) =>
									if _.isEmpty fetchMoreResult.userGroup.notMembers
										@setState hasMore: false
									userGroup: {
										...prev.userGroup
										notMembers: [...prev.userGroup.notMembers, ...fetchMoreResult.userGroup.notMembers]
									}
