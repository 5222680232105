exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1gyJOrnSZ2E9uMNYCVQsqn {\n  display: flex;\n}\n._1gyJOrnSZ2E9uMNYCVQsqn._1gluaRvH3xYWTua6_CF4jP .uX6iloZ1ln8iefnE51Il1 {\n  padding: 1px 0;\n}\n.uX6iloZ1ln8iefnE51Il1 {\n  padding: 0 25px 0 0;\n  display: flex;\n  min-width: 100px;\n}\n._3XuPnY0l2-hgre_sVTAAsv {\n  color: #8993a4;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  padding-right: 10px;\n  padding-left: 0;\n  box-sizing: border-box;\n}\n._3XuPnY0l2-hgre_sVTAAsv.OvPGFuwfO-iRw_T_EH4kg {\n  grid-column: 1;\n}\n._2IuG4ynZFdU_FjlT58NrEn {\n  padding-left: 10px;\n}\n._2IuG4ynZFdU_FjlT58NrEn.OvPGFuwfO-iRw_T_EH4kg {\n  grid-column: 2;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "_1gyJOrnSZ2E9uMNYCVQsqn",
	"spacing-compact": "_1gluaRvH3xYWTua6_CF4jP",
	"cell": "uX6iloZ1ln8iefnE51Il1",
	"label": "_3XuPnY0l2-hgre_sVTAAsv",
	"column-1": "OvPGFuwfO-iRw_T_EH4kg",
	"value": "_2IuG4ynZFdU_FjlT58NrEn"
};