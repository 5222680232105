import gql from 'graphql-tag'

#QUERIES

export Shortcut = gql """
	query($projectID: ID!) {
		projectByID (id: $projectID){
			id
			navigationShortcuts {
				id
				name
				url
			}
		}
	}
"""


