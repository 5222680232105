###
Registering modal for confidential and private correspondence
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import adopt from 'libs/adopt'
import { getRefetchQueries, convertToOptions } from 'libs/legal'

import { UserInfoConsumer } from 'libs/userInfo'

# Renderable
import { div, p, table, tbody, thead, tr, th, td } from 'react-dom-factories'

Fragment = React.createFactory React.Fragment

import _Avatar, {AvatarItem as _AvatarItem} from '@atlaskit/avatar'
Avatar = React.createFactory _Avatar
AvatarItem = React.createFactory _AvatarItem

import _BevyModal from '@bevy/modal'
BevyModal = React.createFactory _BevyModal

import _Spinner from '@bevy/spinner'
Spinner = React.createFactory _Spinner

import _Select, {LoadableSelect as _LoadableSelect} from '@bevy/select'
Select = React.createFactory _Select
LoadableSelect = React.createFactory _LoadableSelect

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import _Tooltip from '@bevy/tooltip'
Tooltip = React.createFactory _Tooltip

import _ErrorDisplay from 'components/ErrorDisplay'
ErrorDisplay = React.createFactory _ErrorDisplay

import {FieldTextAreaStateless as _FieldTextArea} from '@atlaskit/field-text-area'
FieldTextArea = React.createFactory _FieldTextArea

import { Link as _Link } from 'react-router-dom'
Link = React.createFactory _Link

import { NotificationConsumer } from '../../application/components/NotificationManager'

# Styles
import styles from './index.styl'
import {
	x
	externalLink
} from 'react-icons-kit/feather'

# Data
import {
	GetProjectMembersAndGroupsAndACL
	UpdateACL
} from './data'
import trLegal from 'data/local/legal'

legalDocumentsCollectionQueries = [
	'ProjectLegalDocumentPreview'
	'DMS_Inbox_Accessory',
	'DMS_Registry_Accessory',
	'getACL'
]

DataLayer = adopt
	queries:
		getProjectMembersAndGroupsAndACL: ({ documentsIDs, projectSlug, setACL, calculateACLForMultipleDocuments }) ->
			query: GetProjectMembersAndGroupsAndACL
			fetchPolicy: 'network-only'
			variables:
				documentsIDs: documentsIDs
				projectSlug: projectSlug
				pagination:
					limit: 100
			onCompleted: (data) ->
				if data?.legalDocuments?
					setACL calculateACLForMultipleDocuments data
	mutations:
		updateACL: ({setNotification, documentID, projectSlug, app}) ->
			mutation: UpdateACL
			refetchQueries: getRefetchQueries legalDocumentsCollectionQueries, app
			onCompleted: (data) ->
				setNotification
					content: 'Document shared'
					appearance: 'success'
			onError: (error) ->
				setNotification
					content: trLegal.error.actionFailure
					appearance: 'error'

export default class DocumentShareModal extends React.Component
	@propTypes =
		onClose: PropTypes.func.isRequired

	constructor: (props) ->
		super props
		@state =
			acl: {}

	calculateChanges: (docID, { oldState, newState }) ->
		# Make new ACL for given document in comparabale form
		{users, projectGroups, groups } = newState
		makeComparableEntities = (arr, name) ->
			_.compact _.map arr, (entry) ->
				if !_.isEmpty entry.documents[docID]
					[name]: entry[name]
					roles: entry.documents[docID]

		comparabaleNewState = [
			...makeComparableEntities users, 'user'
			...makeComparableEntities projectGroups, 'projectGroup'
			...makeComparableEntities groups, 'group'
		]
		toMutationForm = (entry) ->
			entryID = _.get(entry, 'user.id') || _.get(entry, 'projectGroup.id') || _.get(entry, 'group.id')
			if !entryID?
				return null
			_.map entry.roles, (role) ->
				{
					role: role.name
					id: entryID
				}

		oldEntries = _.flattenDeep _.compact _.map oldState, toMutationForm
		newEntries = _.flattenDeep _.compact _.map comparabaleNewState, toMutationForm

		addedEntities = _.differenceWith newEntries, oldEntries, _.isEqual
		removedEntities = _.differenceWith oldEntries, newEntries, _.isEqual

		if _.isEmpty [...addedEntities, ...removedEntities]
			null
		else
			{
				id: docID
				addedEntities
				removedEntities
			}

	calculateACLForMultipleDocuments: (data) ->
		# List all users from all documents
		{
			users: usersList
			projectGroups: projectGroupsList
			groups: groupsList
		} = _.reduce data.legalDocuments, (acc, document) ->
			acc.users = [...acc.users, ..._.filter document.acl, (aclMember) -> aclMember.user?]
			acc.projectGroups = [...acc.projectGroups, ..._.filter document.acl, (aclMember) -> aclMember.projectGroup?]
			acc.groups = [...acc.groups, ..._.filter document.acl, (aclMember) -> aclMember.group?]
			acc
		, {
			users: []
			projectGroups: []
			groups: []
		}
		# Create single instance of each user / group
		usersList = _.map _.uniqBy(usersList, 'user.id'), (entry) ->
			{..._.omit(entry, 'roles'), roles: [], documents: {}}
		projectGroupsList = _.map _.uniqBy(projectGroupsList, 'projectGroup.id'), (entry) ->
			{..._.omit(entry, 'roles'), roles: [], documents: {}}
		groupsList = _.map _.uniqBy(groupsList, 'group.id'), (entry) ->
			{..._.omit(entry, 'roles'), roles: [], documents: {}}

		# Check users in every document possible
		for legalDocument in data.legalDocuments
			for entry in usersList
				roles = _.get _.find(legalDocument.acl, (aclMember) -> _.get(aclMember, 'user.id') is entry.user.id), 'roles', []
				entry.documents[legalDocument.id] = roles
				if _.isEmpty roles
					entry.roles.push null
				else
					entry.roles = [...entry.roles, ...roles]
			for entry in projectGroupsList
				roles = _.get _.find(legalDocument.acl, (aclMember) -> _.get(aclMember, 'projectGroup.id') is entry.projectGroup.id), 'roles', []
				entry.documents[legalDocument.id] = roles
				if _.isEmpty roles
					entry.roles.push null
				else
					entry.roles = [...entry.roles, ...roles]
			for entry in groupsList
				roles = _.get _.find(legalDocument.acl, (aclMember) -> _.get(aclMember, 'group.id') is entry.group.id), 'roles', []
				entry.documents[legalDocument.id] = roles
				if _.isEmpty roles
					entry.roles.push null
				else
					entry.roles = [...entry.roles, ...roles]

		makeListWithRoles = (arrayOfEntites) ->
			_.map arrayOfEntites, (entry) -> {
				...entry
				roles: _.uniqBy entry.roles, 'name'
			}

		{
			users: makeListWithRoles usersList
			projectGroups: makeListWithRoles projectGroupsList
			groups: makeListWithRoles groupsList
		}

	renderNotesSection: =>
		div {className: styles.section},
			table {className: styles.table},
				thead {},
					tr {className: styles.tableHeader},
						th {width: '10%', className: styles.header}
						th {width: '90%'}, 'Notes'
				tbody {},
					tr {},
						td {}
						td {className: styles.note},
							FieldTextArea
								isLabelHidden: true
								onChange: (e) => @setState { note: e.target.value }
								value: @state.note
								shouldFitContainer: true
								minimumRows: 3
								placeholder: 'Add message (optional)'

	renderMembers: (members, link) =>
		div {className: styles.membersSection},
			div {className: styles.members},
				_.map _.take(members, 4), (person) =>
					Avatar {...person, size: 'small'}
				if _.size(members) > 4 and link?
					div {className: styles.bubbleWrapper},
						Tooltip
							content: Fragment	{}, _.map _.drop(members, 4), (user) ->
								p {key: user.key}, user.name
						,
							div {className: styles.bubble},
								"+#{_.size(members) - 4}"
			if !_.isEmpty link
				Tooltip {content: 'Open group in new tab'},
					Link
						className: cnames styles.bubble, styles.clickable
						to: link
						target: "_blank"
					, Icon {icon: externalLink, size: 16}

	renderShareSection: ({label, availabeRoles, selectStyle, currentEntities, onACLChange, onRemoveEntity, onAddEntity, entitiesOptions, entityPlaceholder}) =>
		div {className: styles.section},
			table {className: styles.table},
				thead {},
					tr {className: styles.tableHeader},
						th {width: '10%', className: styles.header}, label
						th {width: '70%'}, 'Name'
						th {width: '13%'}, 'Permissions'
						th {width: '7%'}

				tbody {},
					_.map currentEntities, ({group, user, projectGroup, roles}) =>
						entity = group || user || projectGroup
						link =
							if group?
								"/groups/#{group.id}"
							else if projectGroup?
								"/projects/#{projectGroup.project.slug}/groups/#{projectGroup.id}"
						people = _.map (_.get entity, 'members'), ({user}) ->
							key: user.id
							name: user.name
							src: user.picture.mini
						tr {key: entity.id},
							td {className: styles.avatarColumn},
								AvatarItem
									avatar: Avatar {
										src: if entity.picture?.mini? then entity.picture.mini
										size: 'xsmall'
									}
							td {},
								div {className: styles.entityCell},
									div {}, entity.name
									@renderMembers people, link

							td {className: styles.selectColumn},
								do =>
									options = _.compact _.map availabeRoles, (role) ->
										if 'UserGroup' in role.accepts
											label: role.displayName
											value: role.name
									Select
										styles: selectStyle
										menuPortalTarget: document.body
										spacing: 'compact'
										options: options
										value: do ->
											level =
												if _.size(roles) is 1
													role = _.head roles
													label: role.displayName
													value: role.name
												else
													label: 'Various'
													value: 'Various'
										getOptionLabel: (option) -> option.label
										onChange: (value) -> onACLChange value, entity
							td {className: styles.cancelColumn},
								div
									className: styles.actionButton
									onClick: -> onRemoveEntity entity
								,
									Icon {icon: x, size: 16}
					tr {},
						td {className: styles.avatarWrapper}
						td {},
							Select
								styles: selectStyle
								menuPortalTarget: document.body
								spacing: 'compact'
								isSearchable: true
								options: entitiesOptions
								formatOptionLabel: (option) -> option.name
								getOptionLabel: (option) -> option.name
								getOptionValue: (option) -> option
								placeholder: entityPlaceholder
								onChange:onAddEntity
								value: null

	render: ->
		selectStyle =
			control:
				(base) ->
					{
						...base
						backgroundColor: 'transparent'
						borderColor: 'transparent'
						color: '#091e42'
					}
		NotificationConsumer {}, ({setNotification}) =>
			DataLayer
				projectSlug: @props.projectSlug
				documentsIDs: @props.documentsIDs
				setNotification: setNotification
				app: @props.app
				calculateACLForMultipleDocuments: @calculateACLForMultipleDocuments
				setACL: (acl) => @setState acl: acl
			, (operations) =>
				BevyModal
					'data-test-id': 'share-document-modal'
					width: 'medium'
					shouldCloseOnEscapePress: false
					shouldCloseOnOverlayClick: false
					onClose: @props.onClose
					autoFocus: false
					header: 'Shared with'
					actions: [
							text: 'Save'
							isDisabled:
								if operations.getProjectMembersAndGroupsAndACL.data? and @state.acl?
									data = operations.getProjectMembersAndGroupsAndACL.data
									changes = _.compact _.map data.legalDocuments, (legalDoc, index) =>
										change = @calculateChanges legalDoc.id, {oldState: legalDoc.acl, newState: @state.acl}
									_.isEmpty changes
								else
									true
							onClick: () =>
								if operations.getProjectMembersAndGroupsAndACL.data? and @state.acl?
									data = operations.getProjectMembersAndGroupsAndACL.data
									changes = _.compact _.map data.legalDocuments, (legalDoc, index) =>
										change = @calculateChanges legalDoc.id, {oldState: legalDoc.acl, newState: @state.acl}
									if !_.isEmpty changes
										operations.updateACL.mutation
											variables:
												input: changes
												note: @state.note
										@props.onClose()
						,
							text: 'Cancel'
							onClick: @props.onClose
						]
				,
					ErrorDisplay { query: operations.getProjectMembersAndGroupsAndACL }, () =>
						availabeRoles = _.get operations.getProjectMembersAndGroupsAndACL, 'data.permissionsModel.aclRoles', []
						legalDocumentsIDs = _.map _.get(operations.getProjectMembersAndGroupsAndACL, 'data.legalDocuments', []), 'id'
						Fragment {},


							@renderShareSection
								label: 'Users'
								availabeRoles: availabeRoles
								selectStyle: selectStyle
								entityPlaceholder: 'Add users'
								currentEntities: @state.acl.users
								entitiesOptions: do =>
									users = _.reject operations.getProjectMembersAndGroupsAndACL.data.project.members, (member) =>
										_.find(@state.acl.users, (aclRecord) -> aclRecord.user.id is member.user.id)?
									_.map users, 'user'
								onACLChange: (option, user) =>
									@setState acl: {
										...@state.acl
										users: _.map @state.acl.users, (stateRecord) ->
											if stateRecord.user.id is user.id
												stateRecord.roles = [_.find availabeRoles, name: option.value]
												stateRecord.documents = _.reduce legalDocumentsIDs, (acc, val) ->
													acc[val] = stateRecord.roles
													acc
												, {}
											stateRecord
									}
								onRemoveEntity: (user)=>
									@setState acl: {
										...@state.acl
										users: _.reject @state.acl.users, (stateRecord) ->
											stateRecord.user.id is user.id
									}
								onAddEntity: (value) =>
									defaultACLRoles = [_.find availabeRoles, displayName: 'Read']
									@setState acl: {
										...@state.acl
										users: [
											...@state.acl.users
											{
												user: value
												roles: defaultACLRoles
												documents: _.reduce legalDocumentsIDs, (acc, val) ->
													acc[val] = defaultACLRoles
													acc
												, {}
											}
										]
									}

							@renderShareSection
								label: 'Project Groups'
								availabeRoles: availabeRoles
								selectStyle: selectStyle
								entityPlaceholder: 'Add project group'
								currentEntities: @state.acl.projectGroups
								entitiesOptions: _.reject (_.sortBy operations.getProjectMembersAndGroupsAndACL.data.project.projectUserGroups, 'name'), (_projectGroup) =>
									_.find(@state.acl.projectGroups, (stateRecord) ->
										stateRecord.projectGroup.id is _projectGroup.id)?
								onACLChange: (option, projectGroup) =>
									@setState acl: {
										...@state.acl
										projectGroups: _.map @state.acl.projectGroups, (stateRecord) ->
											if stateRecord.projectGroup.id is projectGroup.id
												stateRecord.roles = [_.find availabeRoles, name: option.value]
												stateRecord.documents = _.reduce legalDocumentsIDs, (acc, val) ->
													acc[val] = stateRecord.roles
													acc
												, {}
											stateRecord
									}
								onRemoveEntity: (projectGroup )=>
									@setState acl: {
										...@state.acl
										projectGroups: _.reject @state.acl.projectGroups, (stateRecord) ->
											stateRecord.projectGroup.id is projectGroup.id
									}
								onAddEntity: (value) =>
									defaultACLRoles = [_.find availabeRoles, displayName: 'Read']
									@setState acl: {
										...@state.acl
										projectGroups: [
											...@state.acl.projectGroups
											{
												projectGroup: value
												roles: defaultACLRoles
												documents: _.reduce legalDocumentsIDs, (acc, val) ->
													acc[val] = defaultACLRoles
													acc
												, {}
											}
										]
									}

							@renderShareSection
								label: 'Global Groups'
								availabeRoles: availabeRoles
								selectStyle: selectStyle
								entityPlaceholder: 'Add group'
								currentEntities: @state.acl.groups
								entitiesOptions: _.reject (_.sortBy operations.getProjectMembersAndGroupsAndACL.data.userGroups, 'name'), (_group) =>
									_.find(@state.acl.groups, (stateRecord) -> stateRecord.group.id is _group.id)?
								onACLChange: (option, group) =>
									@setState acl: {
										...@state.acl
										groups: _.map @state.acl.groups, (stateRecord) ->
											if stateRecord.group.id is group.id
												stateRecord.roles = [_.find availabeRoles, name: option.value]
												stateRecord.documents = _.reduce legalDocumentsIDs, (acc, val) ->
													acc[val] = stateRecord.roles
													acc
												, {}
											stateRecord
									}
								onRemoveEntity: (group )=>
									@setState acl: {
										...@state.acl
										groups: _.reject @state.acl.groups, (stateRecord) -> stateRecord.group.id is group.id
									}
								onAddEntity: (value) =>
									defaultACLRoles = [_.find availabeRoles, displayName: 'Read']
									@setState acl: {
										...@state.acl
										groups: [
											...@state.acl.groups
											{
												group: value
												roles: defaultACLRoles
												documents: _.reduce legalDocumentsIDs, (acc, val) ->
													acc[val] = defaultACLRoles
													acc
												, {}
											}
										]
									}

							@renderNotesSection()

