import _ from 'lodash'
import React from 'react'
import moment from 'moment-mini'
import {FormatterConsumer} from 'libs/formatter'
import cnames from 'classnames'
import DataAttribute from '@bevy/data-attribute'

# Renderables
import { div, span, img, p } from 'react-dom-factories'

import { Link as _Link } from 'react-router-dom'
Link = React.createFactory _Link

# Data
import trLegal from 'data/local/legal'

import styles from './table.styl'


form =
	key: 'Form'
	label: trLegal.props.type
	minWidth: 60
	width: 120
	render: ({ item }) ->
		div {
			className: cnames styles.compactText
			'data-test-id': DataAttribute 'documents-list-form-cell'
		},
			if item.form? then _.capitalize(item.form.name)

topic =
	key: 'Topic'
	label: trLegal.props.topic
	width: 200
	render: ({ item }) ->
		div
			className: cnames styles.compactText
			onClick: (e) -> e.stopPropagation()
			'data-test-id': DataAttribute 'documents-list-topic-cell'
		, do ->
			Link {className: styles.link, to: item.href},
				if item.topic?
					_.capitalize(item.topic.name)

documentDate =
	key: 'DocumentDate'
	label: 'Document Date'
	width: 120
	maxWidth: 160
	render: ({ item }) ->
		date = _.find item.dates, type: 'CreationDate'
		FormatterConsumer {}, (format) ->
			span {className: cnames styles.compactText, if item.isFresh then styles.fresh},
				if date? then format.date date.value else '-'

registerNumber =
	key: 'registerNumber'
	label: 'Land & Mortgage register number'
	width: 200
	render: ({ item }) ->
		span {className: cnames styles.compactText},
			if item.identifiers? and (_.size (_.find item.identifiers, (identfier) -> identfier.type is 'LandRegisterNumber')) isnt 0
				_.get (_.find item.identifiers, (identfier) -> identfier.type is 'LandRegisterNumber'), 'value'
			else '-'

plotNumber =
	key: 'plotNumber'
	label: 'Parcel number'
	width: 240
	render: ({ item }) ->
		span {className: cnames styles.compactText},
			if item.identifiers? and (_.size (_.find item.identifiers, (identfier) -> identfier.type is 'ParcelNumber')) isnt 0
				_.get (_.find item.identifiers, (identfier) -> identfier.type is 'ParcelNumber'), 'value'
			else '-'

pricientNumber =
	key: 'pricientNumber'
	label: 'Precint Number'
	width: 120
	render: ({ item }) ->
		span {className: cnames styles.compactText},
			if item.identifiers? and (_.size (_.find item.identifiers, (identfier) -> identfier.type is 'PrecintNumber')) isnt 0
				_.get (_.find item.identifiers, (identfier) -> identfier.type is 'PrecintNumber'), 'value'
			else '-'

typeOwnership =
	key: 'typeOwnership'
	label: 'Type of ownership'
	width: 120
	render: ({ item }) ->
		span {className: cnames styles.compactText},
			if item.identifiers? and (_.size (_.find item.identifiers, (identfier) -> identfier.type is 'TypeOfOwnership')) isnt 0
				_.upperFirst _.toLower _.startCase _.get (_.find item.identifiers, (identfier) -> identfier.type is 'TypeOfOwnership'), 'value'
			else '-'

phases =
	key: 'Phases'
	label: 'Phases'
	width: 80
	isSortable: false
	render: ({ item }) ->
		span {className: cnames styles.compactText},
			if item.phases? and !_.isEmpty item.phases
				phases = _.map item.phases, 'name'
				_.join (_.sortBy phases), ', '
			else
				'-'

export DASHBOARD_COLUMNS = do ->
	columns =
		[
			{...form}
			{...topic}
			{ ...documentDate}
			{ ...registerNumber}
			{ ...plotNumber}
			{ ...pricientNumber}
			{ ...typeOwnership}
			{ ...phases}
		]

