###
Range Selector
###

# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import ReactDOM from 'react-dom'
import moment from 'moment-mini'

# Renderable
import { div } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment


# TODO: <NCU-0> After dependencies this feature needs to be re-checked
import {
	DropdownMenuStateless as _DropdownMenuStateless
} from '@atlaskit/dropdown-menu'
DropdownMenuStateless = React.createFactory _DropdownMenuStateless

import _Calendar from '@bevy/calendar'
Calendar = React.createFactory _Calendar

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import _Button from '@bevy/button'
Button = React.createFactory _Button

# Styles
import styles from './index.styl'
import {
	arrowRight
	calendar as calendarIcon
	xCircle
} from 'react-icons-kit/feather'

export default class RangeSelector extends React.Component
	@defaultProps = {}

	constructor: (props) ->
		super props
		@state =
			openDropList: null

	toggleDropList: (name) =>
		if @state.openDropList isnt name
			@setState openDropList: name
		else
			@setState openDropList: null

	handleChange: ({name, date, confirm}) =>
		calendars = {}
		if confirm  #confirm
			calendars[name] = date
			@props.onChange(calendars)
		else #clear
			calendars[name] = []
			@props.onChange(calendars)

	stepOneDayIfNecessary: (value, isNecessary, direction) ->
		unix = moment.unix(value)
		if isNecessary
			if direction is 'forward'
				unix.add(1, 'day')
			else if direction is 'backward'
				unix.subtract(1, 'day')
		else
			unix

	renderCalendarDropdown: ({calendar, isEndOfRange = false}) =>
		div {className: styles.datePicker},
			DropdownMenuStateless
				shouldFitContainer: true
				onOpenChange: (e) => @toggleDropList calendar.name
				isOpen: @state.openDropList is calendar.name
				position: 'top left'
				trigger:
					Button
						# spacing: 'compact'
						onClick: (e) => @toggleDropList calendar.name
						shouldFitContainer: true
						className: cnames [
								styles.atlasskitButton
								if @state.openDropList is calendar.name then styles.open
								else if calendar.value? then styles.selected
								if @props.calendars[0].value > @props.calendars[1].value then styles.error
								]
					,
						div {className: styles.buttonContent},
							if calendar.value? #set val
								@stepOneDayIfNecessary(calendar.value, isEndOfRange, 'backward').format('DD/MM/YYYY')
							else	#placeholder
								div {className: styles.placeholder}, if isEndOfRange then 'to' else 'from'

							div
								className: styles.icon
								onClick: (e) =>
									if calendar.value?
										e.stopPropagation()
										@handleChange({name: calendar.name})
							,
								Icon
									icon: if calendar.value? then xCircle else calendarIcon
									size: 9
				,
					Calendar
						date: if calendar.value?
							@stepOneDayIfNecessary(calendar.value, isEndOfRange, 'backward').unix()
						onClose: () => @setState openDropList: null
						onChange: (date) => @handleChange
							name: calendar.name
							date: @stepOneDayIfNecessary(date, isEndOfRange, 'forward').unix()
							isEndOfRange: isEndOfRange
							confirm: true
						onConfirm: (date) => @handleChange
							name: calendar.name
							date: @stepOneDayIfNecessary(date, isEndOfRange, 'forward').unix()
							confirm: true
							isEndOfRange: isEndOfRange
						onClear: () => @handleChange({name: calendar.name})
	render: ->
		Fragment {},
			@renderCalendarDropdown
				calendar: @props.calendars[0]
			div {className: styles.icon}, Icon {icon: arrowRight, size: 11}
			@renderCalendarDropdown
				calendar: @props.calendars[1]
				isEndOfRange: true

