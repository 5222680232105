###
PDF Viewer
###

# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import url from 'url'
import ReactDOM from 'react-dom'

# Renderable
import { div } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import { SizeMe as _SizeMe } from 'react-sizeme'
SizeMe = React.createFactory _SizeMe

import _Spinner from '@bevy/spinner'
Spinner = React.createFactory _Spinner

import { Document as _Document, Page as _Page } from 'react-pdf/dist/entry.webpack'
Document = React.createFactory _Document
Page = React.createFactory _Page

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import { ButtonGroup as _ButtonGroup } from '@atlaskit/button'
ButtonGroup = React.createFactory _ButtonGroup

import _Button from '@bevy/button'
Button = React.createFactory _Button

import _EmptyState from '@atlaskit/empty-state'
EmptyState = React.createFactory _EmptyState

# Styles
import styles from './index.styl'
import {
	rotateCcw
	rotateCw
	chevronsLeft
	chevronLeft
	chevronRight
	chevronsRight
	zoomIn
	zoomOut
	x
} from 'react-icons-kit/feather'

FileLoadError = ({invertColor}) ->
	EmptyState
		header: div {className: if invertColor? then styles.inverted}, 'Failed to load this file preview'
		imageUrl: require 'assets/empty-states/no-photo.svg'
		maxImageWidth: 300
		maxImageHeight: 300

export default class PDFPreview extends React.Component
	@defaultProps =
		enableRotating: true
		enableScaling: true
		enableFullScreen: true
		defaultFullScreen: false
		scale: 1
		pageNumber: 1
		renderError: FileLoadError

	constructor: (props) ->
		super props
		@file = null
		@state =
			numPages: null
			pageNumber: @props.pageNumber
			scale: @props.scale
			fullScreen: @props.defaultFullScreen

	onDocumentLoadSuccess: (pdfjs) =>
		@pdfjs = pdfjs
		@setState {
			numPages: pdfjs.numPages
			pagesRotationsConfig: []
		}

	handlePageChange: (pageNumber) =>
		@setState pageNumber: pageNumber

	handleRotateChange: (pageNumber, direction) =>
		currentPageRotation = @getCurrentPageRotationFromState pageNumber
		initialPageRotation = (await @pdfjs.getPage(pageNumber)).rotate
		rotationChange = switch direction
			when 'left'
				-90
			when 'right'
				90

		newRotationAbsolute = currentPageRotation + rotationChange
		if !currentPageRotation? # initial page rotation is only needed when there is no current page rotation in state
			newRotationAbsolute = newRotationAbsolute + initialPageRotation
		newRotationAbsolute = newRotationAbsolute % 360

		if direction is 'reset' or (newRotationAbsolute is initialPageRotation) # drop from state
			pagesRotationsConfig = _.reject @state.pagesRotationsConfig, {pageNumber}
		else # add to or modify in state
			pagesRotationsConfig = @state.pagesRotationsConfig
			if currentPageRotation?
				pagesRotationsConfig = _.reject @state.pagesRotationsConfig, {pageNumber}
			pageConfig =
				pageNumber: pageNumber
				rotation: newRotationAbsolute
				isSaved: false
			pagesRotationsConfig = _.concat pagesRotationsConfig, [pageConfig]
		@setState { pagesRotationsConfig }

	handleScaleChange: (scale) =>
		if scale > 1.5
			scale = 1.5
		else if scale < 0.5
			scale = 0.5
		@setState scale: scale

	getCurrentPageRotationFromState: (pageNumber) =>
		page = _.find @state.pagesRotationsConfig, {pageNumber}
		if page?
			page.rotation
		else
			null

	renderPageRotationButtonGroup: () =>
		ButtonGroup {},
			Button
				spacing: 'compact'
				appearance: 'subtle'
				isDisabled: _.isEmpty @state.pagesRotationsConfig
				onClick: () =>
					result = {
						config: @state.pagesRotationsConfig
						scan: @file
					}
			, div {className: styles.buttonText}, 'Save rotations'

			Button
				spacing: 'compact'
				appearance: 'subtle'
				onClick: () =>
					@handleRotateChange(@state.pageNumber, 'left')
				iconBefore: Icon {icon: rotateCcw, size: 14}

			Button
				spacing: 'compact'
				appearance: 'subtle'
				isDisabled: !@getCurrentPageRotationFromState(@state.pageNumber)?
				onClick: () => @handleRotateChange(@state.pageNumber, 'reset')
			, div {className: styles.buttonText}, 'Reset page rotation'

			Button
				spacing: 'compact'
				appearance: 'subtle'
				onClick: () =>
					@handleRotateChange(@state.pageNumber, 'right')
				iconBefore: Icon {icon: rotateCw, size: 14}

	renderPageChangeButtonGroup: () =>
		{ pageNumber, numPages } = @state
		div {className: styles.mainSection},
			ButtonGroup {},
				Button
					className: if pageNumber is 1 then styles.disabled
					spacing: 'compact'
					appearance: 'subtle'
					isDisabled: pageNumber is 1
					onClick: () => if pageNumber isnt 1 then @handlePageChange(1)
					iconBefore: Icon {icon: chevronsLeft, size: 16}

				Button
					className: if pageNumber is 1 then styles.disabled
					spacing: 'compact'
					appearance: 'subtle'
					isDisabled: pageNumber is 1
					onClick: () => if pageNumber isnt 1 then @handlePageChange(pageNumber - 1)
					iconBefore: Icon {icon: chevronLeft, size: 16}

				Button
					spacing: 'compact'
					appearance: 'subtle'
				, "#{pageNumber} of #{numPages}"

				Button
					className: if pageNumber is numPages then styles.disabled
					spacing: 'compact'
					appearance: 'subtle'
					isDisabled: pageNumber is numPages
					onClick: () => if pageNumber isnt numPages then @handlePageChange(pageNumber + 1)
					iconBefore: Icon {icon: chevronRight, size: 16}

				Button
					className: if pageNumber is numPages then styles.disabled
					spacing: 'compact'
					appearance: 'subtle'
					isDisabled: pageNumber is numPages
					onClick: () => if pageNumber isnt numPages then @handlePageChange(numPages)
					iconBefore: Icon {icon: chevronsRight, size: 16}

	renderPageScaleButtonGroup: () =>
		{ scale } = @state
		ButtonGroup {},
			Button
				className: if scale is 0.5 then styles.disabled
				spacing: 'compact'
				appearance: 'subtle'
				isDisabled: scale is 0.5
				onClick: () => if scale isnt 0.5 then @handleScaleChange(scale / 1.1 )
				iconBefore: Icon {icon: zoomOut, size: 16}

			Button
				className: if scale is @props.scale then styles.disabled
				spacing: 'compact'
				appearance: 'subtle'
				isDisabled: scale is @props.scale
				onClick: () => @handleScaleChange(@props.scale)
			, div {className: styles.buttonText}, 'Reset zoom'

			Button
				className: if scale is 1.5 then styles.disabled
				spacing: 'compact'
				appearance: 'subtle'
				isDisabled: scale is 1.5
				onClick: () => if scale isnt 1.5 then @handleScaleChange(scale * 1.1)
				iconBefore: Icon {icon: zoomIn, size: 16}

	renderToolbar: () ->
		div {className: styles.toolbar},
			if @props.enableRotating
				@renderPageRotationButtonGroup()
			@renderPageChangeButtonGroup()
			if @props.enableScaling
				@renderPageScaleButtonGroup()
			if @props.enableFullScreen and !(@state.fullScreen)
				ButtonGroup {},
					Button
						spacing: 'compact'
						appearance: 'subtle'
						onClick: () => @setState fullScreen: true
					, div {className: styles.buttonText}, 'Full screen'

	renderFullScreen: () =>
		{ pageNumber, numPages, scale } = @state
		fullScreen =
			div {className: cnames styles.blanket, styles.fullScreen},
				div {className: styles.topBar},
					div {className: styles.header},
						Button {
							className: cnames [styles.closeButton, styles.button]
							tooltip: 'Back'
							appearance: 'subtle'
							onClick: =>
								@setState fullScreen: false
								if @props.onCloseFullScreen? then @props.onCloseFullScreen()
							iconBefore:
								Icon
									icon: chevronLeft
									size: 24
							}
						div {className: styles.title}, if @props.title then @props.title else 'Document'
					@renderToolbar()
					div {className: styles.actions},
						if @props.fullScreenActions? then @props.fullScreenActions()
						Button {
							className: styles.closeButton
							tooltip: 'Close'
							appearance: 'subtle'
							onClick: =>
								@setState fullScreen: false
								if @props.onCloseFullScreen? then @props.onCloseFullScreen()
							iconBefore:
								Icon
									icon: x
									size: 24
						}
				div {className: styles.content},
					Document
						file: @file
						onLoadSuccess: @onDocumentLoadSuccess
						error: div {}, @props.renderError invertColor: true
						loading: Spinner {invertColor: true, size: 'large'}
						className: styles.base
					,
						SizeMe {monitorHeight: true}, ({size}) =>
							div {className: styles.sizeMePlaceholder},
								if size.width? and size.width > 0
									Page
										className: styles.pdfDocument
										pageNumber: pageNumber
										loading: div {className: styles.sizeMePlaceholder}
										renderAnnotationLayer: false
										rotate: @getCurrentPageRotationFromState pageNumber
										width: if _.isNumber and size.width > 0 then size.width/2
										scale: scale
								else
									null

		ReactDOM.createPortal(fullScreen, document.body)

	renderContent: () =>
		{ pageNumber, numPages, scale, pagesRotationsConfig } = @state
		Document
				file: @file
				onLoadSuccess: @onDocumentLoadSuccess
				loading: div {className: styles.spinner}, Spinner {isAbsolute: false, flex: false}
				error: => @props.renderError {}
				className: styles.base
			,
				Fragment {},
					@renderToolbar()
					SizeMe {monitorHeight: true}, ({size}) =>
						div
							className: styles.sizeMePlaceholder
							style: if (size.height?) and (size.height > 0) and (size.width?) and (size.width > 0)
								height: size.height
								width: size.width
						,
							if size.width? and size.width > 0
								Page
									className: styles.pdfDocument
									pageNumber: pageNumber
									loading: div {className: styles.sizeMePlaceholder}
									renderAnnotationLayer: false
									rotate: @getCurrentPageRotationFromState pageNumber
									width: if _.isNumber and size.width > 0 then size.width-30 #padding
									scale: scale
							else
								null

	render: ->
		{scan} = @props
		if !@file? or (scan? and @file? and (scan isnt @file)) or (scan? and (_.isString scan) and (!_.isEqual url.parse(scan).pathname, url.parse(@file).pathname))
			@file = scan
		if @state.fullScreen
			@renderFullScreen()
		else
			@renderContent()
