exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".pIRaOh0HrAAfD7DH-lXic {\n  display: flex;\n}\n._2zyclVMErEQqdjlSMSZ0Pt {\n  min-height: 200px;\n  flex-grow: 1;\n  flex-basis: 0;\n  padding-right: 20px;\n  border-right: solid 1px #f4f5f7;\n}\n._2zyclVMErEQqdjlSMSZ0Pt:last-of-type {\n  border: 0;\n  padding-left: 20px;\n  padding-right: 0;\n}\n._2zyclVMErEQqdjlSMSZ0Pt:first-of-type {\n  padding-left: 0;\n}\n.-gTpawt_xo1Ia_a1vkJmA {\n  margin-bottom: 20px;\n}\n._2u77vC8kChYzYVbs9Q0PHO {\n  flex-grow: 1;\n}\n._2u77vC8kChYzYVbs9Q0PHO div {\n  min-height: auto;\n  padding-top: 0;\n  padding-bottom: 0;\n}\n._3jj3giqWAA1YNWLvFvKlO6 div {\n  min-height: 28px;\n  padding-top: 0;\n  padding-bottom: 0;\n}\n._3jj3giqWAA1YNWLvFvKlO6 input {\n  font-size: 1rem;\n}\n._3qbF9vTx08k6qoFTtXvqTY {\n  cursor: pointer;\n  display: inline-block;\n  position: absolute;\n  transform: translate(4px, -1px);\n}\n._3qbF9vTx08k6qoFTtXvqTY.W0kNJNHOrLJ2Roul-Xv21 {\n  transform: translate(10px, -1px);\n}\n._2Vb3VbVS0-lYAvoMH7SZYc {\n  flex-grow: 1;\n}\n._4dsp_sO0VYR-C4BUpT_9K {\n  display: flex;\n}\n._4dsp_sO0VYR-C4BUpT_9K > div {\n  margin-right: 5px;\n}\n._4dsp_sO0VYR-C4BUpT_9K > div:last-of-type {\n  margin-right: 0;\n}\n._3Hz2HzRZ-adAK_wTKXfaZJ {\n  font-size: 1.18rem;\n  color: #6b778c;\n  padding: 4px 0;\n  border-bottom: 1px solid #f4f5f7;\n}\n._3JS1_1E4zRBjcwwRUdBUZ8 {\n  display: flex;\n  min-height: 100px;\n  justify-content: center;\n  align-items: center;\n  background-image: linear-gradient(45deg, #fff 25%, #f5f9ff 25%, #f5f9ff 50%, #fff 50%, #fff 75%, #f5f9ff 75%, #f5f9ff 100%);\n  background-size: 56.57px 56.57px;\n}\n._3JS1_1E4zRBjcwwRUdBUZ8._1u1PNsUgAqKe4gefWJ8Qu8 {\n  background-image: linear-gradient(45deg, #fff 25%, #e2edfc 25%, #e2edfc 50%, #fff 50%, #fff 75%, #e2edfc 75%, #e2edfc 100%);\n}\n", ""]);
// Exports
exports.locals = {
	"base": "pIRaOh0HrAAfD7DH-lXic",
	"column": "_2zyclVMErEQqdjlSMSZ0Pt",
	"section": "-gTpawt_xo1Ia_a1vkJmA",
	"formItem": "_2u77vC8kChYzYVbs9Q0PHO",
	"formItemText": "_3jj3giqWAA1YNWLvFvKlO6",
	"iconTooltip": "_3qbF9vTx08k6qoFTtXvqTY",
	"moreMargin": "W0kNJNHOrLJ2Roul-Xv21",
	"fieldGrow": "_2Vb3VbVS0-lYAvoMH7SZYc",
	"horizontal": "_4dsp_sO0VYR-C4BUpT_9K",
	"sectionHeader": "_3Hz2HzRZ-adAK_wTKXfaZJ",
	"previewDropzone": "_3JS1_1E4zRBjcwwRUdBUZ8",
	"active": "_1u1PNsUgAqKe4gefWJ8Qu8"
};