"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.scopes = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _helpers = require("../helpers");

var _GlobalHub = require("./GlobalHub");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// Generated by CoffeeScript 2.4.1
var LegalPathScopes;

/*
LegalPaths permissions model
*/
var scopes = (0, _helpers.makeDefaultSubject)('LegalPath', {
  Read: {
    actions: 'read'
  },
  Use: {
    actions: 'use'
  },
  ReadDefault: {
    actions: 'read',
    conditions: {
      projectID: {
        $exists: false
      },
      phaseIDs: {
        $exists: false
      }
    }
  },
  Update: {
    actions: 'update'
  }
});
exports.scopes = scopes;
LegalPathScopes = scopes;
var _default = {
  // Name of the GraphQL subject
  name: 'LegalPath',
  // Database collection
  collection: 'legalPaths',
  // Name used in making global id's
  globalIDName: 'legalPaths',
  // Available actions on root leve
  actions: ['read'],
  // Model fields with appropiate actions
  fields: {},
  // Available scopes with implicit subject as a model.name e.g. LegalPath
  scopes,
  // Additional fields requested when fetching items for attached and members roles
  projection: {},
  // Type of models that can become a member of this model without specified role
  accepts: [],
  // Entity based roles default and assignable
  roles: {
    // Roles granted to all users
    default: [],
    // Roles assignable to the entity that will be transfered to the User
    attached: [],
    // Roles assignable to the members (Users) of this entity
    members: []
  }
};
exports.default = _default;