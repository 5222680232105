import gql from 'graphql-tag'

export RootQuery = gql """
	query ProjectDashboard (
		$projectSlug: String!
		){
		permissionsModel(model: Project) {
			membersRoles {
				name
				displayName
			}
		}
		project(slug: $projectSlug) {
			id
			name
			slug
			city
			country
			street
			buildingNumber
			postalCode
			dates { type value }
			phases { id name }
			code
			photo {
				original
			}
			spv {
				id
				name
				abbreviation
				type
				website
				hedquarters {
					street
					buildingNumber
					postalCode
					city
				}
				nip
				regon
				krs
				description
				representationDescription
				representationType
				}
			photo {original}
			location {coordinates}
			management {
				phase {
					id
					name
				}
				type
				members {
					user {
						...on Person {
							id name picture {mini, original} email
							}
						}
					role
				}
			}
			buildingsAreas {
				id
				modifiedAt
				name
				pza
				nla
				gla
			}
			userGroups {
				id
				name
				slug
			}
		}
	}
"""
export DocumentsQuery = gql '''
	query ProjectDashboardDocuments ($projectSlug: String!){
		project(slug: $projectSlug) {
			id
			isMultiphase
			legalDocuments: legalDocumentsForLand {
				id
				topic { id name }
				form { id name }
				dates {type value}
				identifiers {type value}
				phases { id name }
				state
			}
		}
	}
'''

# MUTATIONS
export ProjectManagementUpdateMembers = gql """
	mutation( $projectID: ID!, $type: String! , $managementMembers: [ProjectManagementInput!]!, $phaseID: ID!) {
		projectManagementUpdateMembers(
			projectID: $projectID
			type: $type
			managementMembers: $managementMembers
			phaseID: $phaseID
		) {
			id
			buildingsAreas {
				id
				modifiedAt
				name
				pza
				nla
				gla
			}
		}
	}
"""

# MUTATIONS
export ProjectBuildingAreaReorder = gql """
	mutation( $projectID: ID!, $buildingsAreas: [BuildingAreaInput!]!) {
		projectBuildingAreaReorder(
			projectID: $projectID
			buildingsAreas: $buildingsAreas
		) {
			id
			buildingsAreas {
				id
				modifiedAt
				name
				pza
				nla
				gla
			}
		}
	}
"""
