import gql from 'graphql-tag'

export ProjectEditEnvironment = gql """
query ($projectID: ID!) {
		projectByID(id: $projectID){
			id
			name
			code
			street
			buildingNumber
			postalCode
			city
			phases { id name }
			dates { type value }
			photo { original	mini }
			spv { id name }
		}
	}
"""
