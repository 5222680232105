exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2zsA3djZwNqS9YLJQxPLKi {\n  justify-content: center;\n}\n._3nz__eJ-Q09vOrBZ0FMZiz {\n  background-color: #deebff;\n}\n.y39wVy28GzQhJuRW-JgGO {\n  border: solid 2px #4c9aff;\n  box-sizing: border-box;\n}\n._1B2CN5lu73AqG_TfqT4k3R canvas {\n  border: solid 2px #4c9aff;\n}\n._2A4rs_RIvg3x1IqMx-bHK6 canvas {\n  border: solid 1px rgba(220,220,220,0.8);\n  box-shadow: none;\n}\n._27wOpQWIe7boIGW1pkiV30 {\n  width: 14px;\n  height: 14px;\n  cursor: pointer;\n  background-color: #fff;\n  border-radius: 3px;\n  border: 1px solid #ebecf0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n._27wOpQWIe7boIGW1pkiV30:hover {\n  background-color: #ebecf0;\n  border-color: #ebecf0;\n}\n._27wOpQWIe7boIGW1pkiV30._2ITtSH3y_qfId9pa_t8Xns {\n  background-color: #0065ff;\n}\n._27wOpQWIe7boIGW1pkiV30._2ITtSH3y_qfId9pa_t8Xns:hover {\n  border-color: #0065ff;\n}\n", ""]);
// Exports
exports.locals = {
	"scanCard": "_2zsA3djZwNqS9YLJQxPLKi",
	"selected": "_3nz__eJ-Q09vOrBZ0FMZiz",
	"detail": "y39wVy28GzQhJuRW-JgGO",
	"pdfSelected": "_1B2CN5lu73AqG_TfqT4k3R",
	"pdfNotSelected": "_2A4rs_RIvg3x1IqMx-bHK6",
	"checkbox": "_27wOpQWIe7boIGW1pkiV30",
	"checked": "_2ITtSH3y_qfId9pa_t8Xns"
};