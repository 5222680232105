###
generate excel report modal
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import adopt from 'libs/adopt'
import { convertToOptions } from 'libs/legal'

# Renderable
Fragment = React.createFactory React.Fragment
import { div, span, input } from 'react-dom-factories'

import _BevyModal from '@bevy/modal'
BevyModal = React.createFactory _BevyModal

import {
	SelectField as _SelectField,
} from 'components/FormItems'
SelectField = React.createFactory _SelectField

import _Tooltip from '@bevy/tooltip'
Tooltip = React.createFactory _Tooltip


export default class GenerateReportModal extends React.Component
	@propTypes =
		onClose: PropTypes.func.isRequired

	constructor: (props) ->
		super props
		@state =
			documentsScope: null
			columnScope: null

	onConfirm: () =>
		{ allColumnConfig, currentColumnConfig, selectedDocumentIDs, currentPerspective, projectPerspective } = @props
		columns =
			if @state.columnScope is 'All available columns'
				allColumnConfig
			else
				currentColumnConfig
		columns = _.map columns, (column) -> _.pick column, ['key', 'query', 'label', 'width']

		config = { columns }

		if (!_.isEmpty selectedDocumentIDs) and @state.documentsScope is 'selected'
			config.documents = selectedDocumentIDs
		else if @state.documentsScope is  'all'
			config.completeReport = true
		else if @state.documentsScope is  'perspective-current'
			config.perspective = currentPerspective
		else if @state.documentsScope is  'perspective-project'
			config.perspective = projectPerspective
		config.choices =
			documents: _.find @getDocumentsOptions(), {value: @state.documentsScope}
			column: _.find @getColumnsOptions(), {value: @state.columnScope}
		@props.onConfirm config

	getDocumentsOptions: =>
		_.compact [
			if _.isEmpty @props.selectedDocumentIDs
				label:  div {style: color: 'grey'},'Selected documents'
				value: 'selected'
				isDisabled: true
		,
			if !_.isEmpty @props.selectedDocumentIDs
				label: 'Selected documents'
				value: 'selected'
		,
			label: 'Current perspective (folder + filters)'
			value: 'perspective-current'
		,
			label: 'All DMS documents (in this project)'
			value: 'perspective-project'
		,
			label: 'All DMS documents (across all projects)'
			value: 'all'
		]

	getColumnsOptions: =>
		convertToOptions ['Currently active columns', 'All available columns']

	render: ->
		BevyModal {
			'data-test-id': 'generate-report-modal'
			width: 'small'
			onClose: @props.onClose
			header: 'Generate Excel report'
			actions: [
				text: 'Confirm'
				isDisabled: _.some @state, (item) -> _.isEmpty item
				onClick: @onConfirm
			,
				text: 'Cancel'
				onClick: () => @props.onClose()
			]
		},
			Fragment {},
				SelectField
					label: "Documents scope"
					placeholder: 'Select which documents will be used to generate report...'
					value: @state.documentsScope
					onChange: (value) => @setState documentsScope: value
					options: @getDocumentsOptions()
				SelectField
					label: "Columns scope"
					placeholder: 'Select which columns will be used to generate report...'
					value: @state.columnScope
					onChange: (value) => @setState columnScope: value
					options: @getColumnsOptions()

