###
Add Project Building Area Modal
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import adopt from 'libs/adopt'
import { convertToOptions } from 'libs/legal'

import { NotificationConsumer } from '../../../application/components/NotificationManager'
import { UserInfoConsumer } from 'libs/userInfo'

# Renderable
import { div, span } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import _BevyModal from '@bevy/modal'
BevyModal = React.createFactory _BevyModal

import {
	TextField as _TextField,
	Inline as _Inline
} from 'components/FormItems'
Inline = React.createFactory _Inline
TextField = React.createFactory _TextField

import _ErrorDisplay from 'components/ErrorDisplay'
ErrorDisplay = React.createFactory _ErrorDisplay

# Data
import {
	ProjectBuildingAreaAdd
	ProjectBuildingAreaEdit
} from './data'

DataLayer = adopt
	queries: {}
	mutations:
		projectBuildingAreaAdd: ({setNotification}) ->
			mutation: ProjectBuildingAreaAdd
			onCompleted: -> setNotification({content: 'Added building area', appearance: 'success'})
			refetchQueries: ['ProjectDashboard']
			onError: -> setNotification ({content: 'Error on adding building area', appearance: 'error'})
		projectBuildingAreaEdit: ({setNotification}) ->
			mutation: ProjectBuildingAreaEdit
			refetchQueries: ['ProjectDashboard']
			onCompleted: -> setNotification({content: 'Edited building area', appearance: 'success'})
			onError: -> setNotification ({content: 'Error on editing building area', appearance: 'error'})


export default class AddProjectBuildingAreaModal extends React.Component
	@propTypes =
		onClose: PropTypes.func.isRequired

	constructor: (props) ->
		super props
		@state = {
			...@props.building
		}

	canProceed: =>
		{name, pza, nla, gla} = @state
		everyRequiredFieldsFilled =
			if name? and !_.isNaN(parseInt(pza)) and !_.isNaN(parseInt(nla)) and !_.isNaN(parseInt(gla))
				true
			else
				false
		madeAnyChange =
			if @props.building?
				_.some @state, (value) => value isnt @props.building[value]
			else
				true
		everyRequiredFieldsFilled and madeAnyChange

	getVariables4Mutation: =>
		variables = {
			projectID: @props.projectID
			name: @state.name
			..._.mapValues (_.omit @state, ['name', 'id']), (value, key) -> parseInt value
		}
		if @props.building?
			variables.id = @props.building.id
		variables


	onCloseProxy: (appropriateMutation) =>
		if _.isFunction appropriateMutation
			appropriateMutation
				variables: @getVariables4Mutation()
				refetchQueries: ['ProjectDashboard']
		@props.onClose()

	renderContent: =>
		Fragment {},
			Inline {padding: true},
				TextField
					# isDisabled: @props.building?
					label: 'Building Name'
					onChange: (value) => @setState name: value
					value: @state.name
				TextField
					label: 'PZA [sqm]'
					onChange: (value) => @setState pza: value
					value: @state.pza
				TextField
					label: 'NLA [sqm]'
					onChange: (value) => @setState nla: value
					value: @state.nla
				TextField
					label: 'GLA [sqm]'
					onChange: (value) => @setState gla: value
					value: @state.gla

	render: ->
		UserInfoConsumer {}, ({ability, me}) =>
			NotificationConsumer {}, ({setNotification}) =>
				DataLayer
					setNotification: setNotification
					, (operations) =>
						BevyModal
							onClose: @onCloseProxy
							header: if !@props.building? then 'Add building' else 'Edit building details'
							width: 'small'
							actions: [
								text: 'Save'
								isDisabled: !@canProceed()
								onClick: () => @onCloseProxy(
									if !@props.building?
										operations.projectBuildingAreaAdd.mutation
									else
										operations.projectBuildingAreaEdit.mutation
								)
							,
								text: 'Cancel'
								onClick: @onCloseProxy
							]
						,
							if @props.editQuery?
								ErrorDisplay {query: @props.editQuery}, @renderContent
							else
								@renderContent()


