###
List of Users for project
###

# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import DataAttribute from '@bevy/data-attribute'
import adopt from 'libs/adopt'

import { NotificationConsumer } from '../../application/components/NotificationManager'
import { UserInfoConsumer } from 'libs/userInfo'

# Renderable
import { div } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import _ContentController from '@bevy/content-controller'
ContentController = React.createFactory _ContentController

import _Spinner from '@atlaskit/spinner'
Spinner = React.createFactory _Spinner

import {InternalErrorState as _InternalErrorState} from 'components/ErrorDisplay'
InternalErrorState = React.createFactory _InternalErrorState

# Data
import {
	RootQuery
} from './data'

# Styles
import styles from './index.styl'

ITEMS_PER_PAGE = 100

DataLayer = adopt
	queries:
		rootQuery: ({projectSlug, sortKey, sortOrder, search, getInitialColumnsConfiguration, changeTableConfiguration}) ->
			query: RootQuery
			variables:
				projectSlug: projectSlug
				pagination:
					limit: ITEMS_PER_PAGE
				search: search
				sort:
					surname: 1
			notifyOnNetworkStatusChange: true
			onCompleted: (data) ->
				if !_.isEmpty data.project
					tableConfig = getInitialColumnsConfiguration data.project
					changeTableConfiguration tableConfig, data.project


# Content controller elements
import getToolbar from './elements/toolbar'
import getTable from './elements/table'
import getModals from './elements/modals'
import { USERS_COLUMNS } from './elements/columns'

export default class ProjectDocumentsView extends React.Component
	constructor: (props) ->
		super props
		@state =
			activeModal: null
			selected: []
			documentsTableColumns: @getInitialColumnsConfiguration()
			activeSearch: null
			activeDocuments: []
	searchUpdate: ({data, selected}) =>
		(value) =>
			@setState
				activeSearch: value
				selected: []
	getToolbar: getToolbar
	getTable: getTable
	getModals: getModals

	getInitialColumnsConfiguration: (project) =>
		savedConfig = JSON.parse localStorage.getItem("tableView-#{@props.match.params.project}-users")
		if savedConfig?
			_.compact _.map savedConfig, (column) ->
				columnTemplate = _.find USERS_COLUMNS(project), {key: column.key}
				if columnTemplate?
					{
						...columnTemplate
						selected: column.selected
					}
		else
			USERS_COLUMNS(project)

	changeTableConfiguration: (newTableConfig, project) =>
		if !newTableConfig? #restore default
			localStorage.removeItem "tableView-#{@props.match.params.project}-users"
			@setState documentsTableColumns: USERS_COLUMNS(project)
		else
			localStorage.setItem("tableView-#{@props.match.params.project}-users", JSON.stringify newTableConfig)
			@setState documentsTableColumns: newTableConfig

	handleItemsSelect: (ids) =>
		@setState
			selected: _.concat [], ids



	render: ->
		selected = @state.selected
		NotificationConsumer {}, ({ setNotification }) =>
			UserInfoConsumer {}, ({ ability, me, logAs }) =>
				DataLayer {
					setNotification
					projectSlug: @props.match.params.project
					search: @state.activeSearch
					getInitialColumnsConfiguration: @getInitialColumnsConfiguration
					changeTableConfiguration: @changeTableConfiguration
				}, (operations) =>
					if operations.rootQuery.error?
						InternalErrorState {}
					else
						members = if !operations.rootQuery.loading
								_.map operations.rootQuery.data.project.members, (member) ->
									{
										id: member.user.id
										...member
									}
							else
								[]
						projectID = if !operations.rootQuery.loading then operations.rootQuery.data.project.id

						Fragment {},
							ContentController
								pageId: "#{if operations.rootQuery.data?.project? then operations.rootQuery.data.project.name else 'project'} users"
								'data-test-id': 'users-content'
								className: cnames styles.page
								toolbar: @getToolbar {members, selected, ability,	me, logAs, projectID}
								contentClassName: styles.content
								paneClassName: styles.pane
								filters:
									search: @searchUpdate {data: operations, selected}
								views:
									table: @getTable {
										project: _.get operations, 'rootQuery.data.project'
										items: members
										loading: operations.rootQuery.loading
										fetchMore: operations.rootQuery.fetchMore
										itemsCount:  if !operations.rootQuery.loading then operations.rootQuery.data.project.membersCount #FIXME no more members count - maybe do it like in loadable select? until _.size fetchmore is 0
										columnsSetup: @state.documentsTableColumns
										changeTableConfiguration: @changeTableConfiguration
										selected
										onSelect: @handleItemsSelect
										getItemActions: (document) =>
											{secondary} = @getToolbar {users, selected, ability,	me,	projectID}
											secondary
									}

							@getModals {projectID, selected}

