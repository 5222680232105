import gql from 'graphql-tag'

export RootQuery = gql """
query projectUserGroupsList ($projectSlug: String!) {
	project(slug: $projectSlug) {
		id
		name
		projectUserGroups {
			id
			name
			slug
			picture { mini }
			members {
				... on ActorAccess{
					user {
						... on Person{
							id
						}
					}
				}
			}
		}
	}
}
"""

