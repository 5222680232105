import gql from 'graphql-tag'

#QUERIES

# MUTATIONS
export AddShortcut = gql """
	mutation addShortcut (
		$projectID: ID!
		$name: String!
		$url: String!
	) {
		addNavigationShortcut(projectID: $projectID, name: $name, url: $url){ 
			id
    		navigationShortcuts {
						id
        		name
        		url
      		}
		}
	}
"""
export UpdateShortcut = gql """
	mutation updateShortcut (
		$projectID: ID!
		$shortcutID: ID!
		$name: String!
		$url: String!
	) {
		updateNavigationShortcut(projectID: $projectID, shortcutID: $shortcutID, name: $name, url: $url){ 
			id
			navigationShortcuts {
					id
					name
					url
				}
		}
	}
"""
