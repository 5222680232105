"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.scopes = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _helpers = require("../helpers");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// Generated by CoffeeScript 2.4.1

/*
Organization permissions model
*/
var scopes = (0, _helpers.makeDefaultSubject)('Organization', {
  Manage: {
    actions: 'crud'
  },
  Create: {
    actions: 'create'
  },
  Read: {
    actions: 'read'
  },
  Update: {
    actions: 'update'
  },
  Delete: {
    actions: 'delete'
  }
});
exports.scopes = scopes;
var _default = {
  // Name of the GraphQL subject
  name: 'Organization',
  // Database collection
  collection: 'organizations',
  // Name used in making global id's
  globalIDName: 'organization',
  // Available actions on root leve
  actions: ['crud', 'create', 'read', 'update', 'delete'],
  // Alias for create, read, update, delete
  // Model fields with appropiate actions
  fields: {},
  // Available scopes with implicit subject as a model.name
  scopes,
  // Additional fields requested when fetching items for attached and members roles
  projection: {},
  // Type of models that can become a member of this model without specified role
  accepts: [],
  // Entity based roles default and assignable
  roles: {
    // Roles granted to all users
    default: [{
      name: 'Public organizations',
      description: 'Organizations can be read by all user of S360 platform',
      permissions: [{
        scopes: [scopes.Read]
      }]
    }],
    // Roles assignable to the entity that will be transfered to the User
    attached: [],
    // Roles assignable to the members (Users) of this entity
    members: []
  }
};
exports.default = _default;