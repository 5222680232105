import { runWithAdal } from 'react-adal'
import { AuthenticationContext, adalFetch, withAdalLogin, adalGetToken } from 'react-adal'
import _ from 'lodash'

adalConfig =
	redirectUri: do ->
		if window?.location?
			"#{window.location.protocol}//#{window.location.hostname}#{if !_.isEmpty(window.location.port) then ':'+window.location.port else ''}/"
		else
			'http://localhost:8080'
	tenant: '33dab507-5210-4075-805b-f2717d8cfa74'
	clientId: '9159db82-6a1e-49c3-861e-f4740230964b'
	endpoints:
		api: '4058d964-d8bc-4bcf-a72c-16178c98792e'
		graph: 'https://graph.microsoft.com'
	cacheLocation: 'localStorage'

export authContext = new AuthenticationContext(adalConfig)
export adalApiFetch = (fetch, url, options) -> adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options)
export withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api)
export getToken = (cb) -> authContext.acquireToken adalConfig.clientId, cb
export login = () -> authContext.login()
export logout = () ->
	localStorage.clear()
	setTimeout ->
		authContext.logOut()
	, 100
export getUser = (cb) -> authContext.getUser cb
export default (content) -> runWithAdal authContext, content, true
