import gql from 'graphql-tag'

#QUERIES
export GetOrganizations = gql """
	query GetOrganizations(
		$pagination: PaginationInput,
		$search: String,
		$filters: OrganizationsFilters
	) {
		organizations (
			pagination: $pagination,
			search: $search,
			filters: $filters
		){
			id
			name
		}
	}
"""

export GetUsers = gql """
	query GetUsers(
		$pagination: PaginationInput,
		$search: String,
	) {
		users (
			pagination: $pagination,
			search: $search,
		){
			...on Person { id name email}
		}
	}
"""

# MUTATIONS
export AddProject = gql """
	mutation AddProject (
		$name: String!,
		$code: String!,
		$spvID: ID,
		$projectLeaderID: ID,
		$street: String,
		$buildingNumber: String,
		$postalCode: String,
		$city: String,
		$dates: [ProjectDateInput!]
		$photo: ID
		$phases: [PhaseInput!]
	) {
		projectCreate(
			name: $name,
			code: $code,
			spvID: $spvID,
			projectLeaderID: $projectLeaderID,
			street: $street,
			buildingNumber: $buildingNumber,
			postalCode: $postalCode,
			city: $city
			dates: $dates
			photo: $photo
			phases: $phases
		) {
			id
			name
			slug
			code
			street
			buildingNumber
			postalCode
			city
			phases {
				id
				name
			}
			photo {
				mini
				original
			}
			spv {
				id
				name
			}
		}
	}
"""

export EditProject = gql """
	mutation EditProject(
		$id: ID!,
		$name: String,
		$code: String,
		$spvID: ID,
		$street: String,
		$buildingNumber: String,
		$postalCode: String,
		$city: String,
		$dates: [ProjectDateInput!]
		$photo: ID
		$phases: [PhaseInput!]
		) {
		projectUpdate(
			id: $id,
			name: $name
			code: $code
			spvID: $spvID
			street: $street,
			buildingNumber: $buildingNumber,
			postalCode: $postalCode,
			city: $city,
			dates: $dates
			photo: $photo
			phases: $phases
		) {
			id
			name
			slug
			code
			street
			buildingNumber
			postalCode
			city
			phases {
				id
				name
			}
			photo {
				mini
				original
			}
			spv {
				id
				name
			}
		}
	}
"""

