###
DndLsit
###

import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import PropTypes from 'prop-types'


# Renderables
import { div, span } from 'react-dom-factories'

Fragment = React.createFactory React.Fragment


import _Button from '@bevy/button'
Button = React.createFactory _Button

import { ButtonGroup as _ButtonGroup } from '@atlaskit/button'
ButtonGroup = React.createFactory _ButtonGroup

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

# Styles
import styles from './index.styl'
import { plus, x } from 'react-icons-kit/feather'


# React DnD
import HTML5Backend from 'react-dnd-html5-backend'
import { DndProvider as _DndProvider } from 'react-dnd'
DndProvider = React.createFactory _DndProvider
import { DragSource, DropTarget } from 'react-dnd'

ItemTypes = {
	LIST_ITEM: 'listItem'
}

class DraggableItem extends React.Component
	@defaultProps =
		itemHeight: 80
		itemGap: 16

	render: ->
		@props.connectDropTarget @props.connectDragSource div {
			className: cnames(
				styles.item,
				(if @props.isDragging then styles.isDragging),
				(if @props.isLast then styles.isLast)
				),
			style:
				if @props.isLast
					height: @props.itemHeight + 16
			# key: @props.index
		},
				if @props.isOver
					div {
						className: cnames(styles.dragDummy)
						style:
							height: @props.itemHeight
							marginBottom: @props.itemGap
					}

				if @props.isLast isnt true
					@props.connectDragPreview div {
						className: cnames(styles.draggableItem,(if @props.isDragging then styles.isDragging))
						style: minHeight: @props.itemHeight
					}
						, @props.children
				else if @props.children?
					@props.children

# Source
dragSource =
	beginDrag: (props) -> {
		itemIndex: props.itemIndex
	}
	endDrag: (props, monitor) ->
		if monitor.getDropResult()
			props.moveItemOnList(props.itemIndex, monitor.getDropResult().targetIndex)

dragSourceCollect = (connect, monitor) ->
	connectDragSource: connect.dragSource()
	connectDragPreview: connect.dragPreview()
	isDragging: monitor.isDragging()

# Target
dropTarget =
	drop: (props, monitor) ->
		targetIndex: props.itemIndex

dropTargetCollect = (connect, monitor) ->
	connectDropTarget: connect.dropTarget()
	isOver: monitor.isOver()
	canDrop: monitor.canDrop()

# Force refresh
options =
	arePropsEqual: (props, otherProps) -> false


# Export
DraggableItem = DragSource(ItemTypes.LIST_ITEM, dragSource, dragSourceCollect, options)(DraggableItem)
DraggableItem = DropTarget(ItemTypes.LIST_ITEM, dropTarget, dropTargetCollect, options)(DraggableItem)
DraggableItem = React.createFactory DraggableItem

export default class DnDList extends React.Component
	@defaultProps =
		itemHeight: 80
		itemGap: 16

	constructor: (props) ->
		super props
		@state =
			items: _.cloneDeep @props.items

	@getDerivedStateFromProps: (props, state) =>
		if (_.size(state.items) isnt _.size(props.items)) or !_.isEqual state.items, props.items
			console.log 'UPDATe'
			items: props.items
		else
			null

	moveItemOnList: (from, to) =>
		if from - to isnt -1
			if from < to
				to--
			items = _.cloneDeep @state.items
			items.splice(to, 0, items.splice(from, 1)[0])
			@setState items: items
			@props.onMoveEnd items

	render: ->
		ItemComponent = if @props.disableDrag then div else	DraggableItem
		DndProvider {backend: HTML5Backend},
			div {className: cnames styles.base},
				_.map @state.items, (item, index) =>
					Fragment {},
						ItemComponent
							key: index
							moveItemOnList: @moveItemOnList
							itemIndex: index
							itemHeight: @props.itemHeight
							itemGap: @props.itemGap
						,
							@props.renderItem {
								item
								index
								onDelete: @props.onDelete
								onEdit: @props.onEdit
								}
						if @props.renderSeparator?
							@props.renderSeparator(item, index)
				if !@props.disableDrag
					ItemComponent
						moveItemOnList: ->
						itemIndex: _.size @state.items
						isLast: true
						itemHeight: @props.itemHeight
					,
						if @props.onAdd?
							div {
								onClick: @props.onAdd, className: cnames styles.addItem, styles.draggableItem, if @props.isDragging then styles.isDragging
								style: minHeight: @props.itemHeight
							},
								if @props.addIcon?
									@props.addIcon
								else
									Icon {className: styles.arrow, icon: plus, size: 20}


