###
Edit user modal
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import adopt from 'libs/adopt'

# Renderable
import { div, span } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import _BevyModal from '@bevy/modal'
BevyModal = React.createFactory _BevyModal

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import _Avatar from '@atlaskit/avatar'
Avatar = React.createFactory _Avatar

import _Spinner from '@bevy/spinner'
Spinner = React.createFactory _Spinner

import styles from './index.styl'
import { convertToOptions } from 'libs/legal'

import _Select , {components} from '@bevy/select'
Select = React.createFactory _Select

import _Button from '@bevy/button'
Button = React.createFactory _Button

import {
	x
} from 'react-icons-kit/feather'

import {
	TextField as _TextField,
	SelectField as _SelectField,
	Inline as _Inline,
} from 'components/FormItems'

TextField = React.createFactory _TextField
SelectField = React.createFactory _SelectField
Inline = React.createFactory _Inline

# Styles
import { info } from 'react-icons-kit/feather'

# Data
import {
	GetGlobalRoles,
	GetGroups,
	GetProjects,
	GetProjectRoles,
	GetUser
	UserUpdateProjectMembership,
	UserUpdateRoles,
	UserUpdateUserGroupMembership
} from './data'

DataLayer = adopt
	queries: {
		getGlobalRoles: ->
			query: GetGlobalRoles
		getGroups: ->
			query: GetGroups
		getProjects: ->
			query: GetProjects
		getProjectRoles: ->
			query: GetProjectRoles
		getUser: ({id, setState, makeOptions}) ->
			query: GetUser
			variables:
				id: id
			onCompleted: (data) ->
				groups = convertToOptions _.map (_.filter data.user.memberships, 'group'), (group) ->
					id: group.group.id
					name: group.group.name
				project = _.filter data.user.memberships, 'project'
				setState
					globalRolesSelected: _.map data.user.roles, 'name'
					initialGlobalRoles: _.map data.user.roles, 'name'
					groups: _.map groups, 'value'
					groupsSelected: _.map groups, 'value'
					projectsInit: _.cloneDeep project
					projectsSelected: _.cloneDeep project
	}
	mutations:
		userUpdateProjectMembership: ->
			mutation: UserUpdateProjectMembership
		userUpdateRoles: ->
			mutation: UserUpdateRoles
		userUpdateUserGroupMembership: ->
			mutation: UserUpdateUserGroupMembership

export default class AddUserModal extends React.Component
	@propTypes =
		onClose: PropTypes.func.isRequired

	constructor: (props) ->
		super props
		@state =
			globalRolesSelected: []
			groupsSelected: []
			projectsSelected: []
			projectRolesSelected: []
			initialGlobalRoles: []
			groups: []
			projectRoles: []
			valueSelectRoles: []
			tmpProject: null

	makeUserLabel: (user) ->
		if user
			label: "#{if user.name? then user.name else '---'} (#{if user.email? then user.email else ''})"
			value: user.id

	makeRolesOptions: (arr) ->
		if !_.isEmpty arr
			_.map arr, (item) ->
				label: item.name
				value: item.slug
		else
			[]
	makeProjectRolesOptionsSelected: (arr, projectID) ->
		projects = _.filter arr, 'project'
		project = _.filter projects, (project) -> project.project.id is projectID
		if !_.isEmpty project
			if !_.isEmpty project[0].roles
				_.map project[0].roles, (item) ->
					label: item.name
					value: item.name
			else
				[]
		else
			[]
	makeOptions: (arr) ->
		if !_.isEmpty arr
			_.map arr, (item) ->
				label: item.displayName || item.name
				value: item.name
		else
			[]
	canProceed: =>
		if (!_.isEmpty _.difference @state.globalRolesSelected, @state.initialGlobalRoles) or
		(!_.isEmpty _.difference @state.initialGlobalRoles, @state.globalRolesSelected) or
		(!_.isEmpty _.difference @state.groupsSelected, @state.groups) or
		(!_.isEmpty _.difference @state.groups, @state.groupsSelected) or
		(_.size (_.get @getVariables4MutationProjectRoles(), 'addedProjects')) isnt 0 or
		(_.size (_.get @getVariables4MutationProjectRoles(), 'removedProjects')) isnt 0
			true
		else
			false

	getVariables4MutationRoles: =>
		id: @props.id
		addedRoles: _.difference @state.globalRolesSelected, @state.initialGlobalRoles
		removedRoles: _.difference @state.initialGlobalRoles, @state.globalRolesSelected

	getVariables4MutationGroups: =>
		id: @props.id
		addedUserGroups: _.map (_.difference @state.groupsSelected, @state.groups), (group) ->
			id: group
		removedUserGroups: _.map (_.difference @state.groups, @state.groupsSelected), (group) ->
			id: group

	getVariables4MutationProjectRoles: =>
		addedProjects = _.reduce @state.projectsSelected, (acc, project) =>
			thisProjectInit = _.find @state.projectsInit, (projectData) -> projectData.project.name is project.project.name
			if (_.size(thisProjectInit)) is 0
				_.map project.roles, (role) ->
					acc.push
						id: project.project.id
						role: role.name
			else
				_.map (_.differenceBy(project.roles, thisProjectInit.roles, 'name')), (role) ->
					acc.push
						id: project.project.id
						role: role.name
			acc
		, []
		removedProjects = _.reduce @state.projectsInit, (acc, project) =>
			thisProjectSelected = _.find @state.projectsSelected, (projectData) -> projectData.project.name is project.project.name
			if (_.size(thisProjectSelected)) is 0
				_.map project.roles, (role) ->
					acc.push
						id: project.project.id
			else
				_.map (_.differenceBy(project.roles, thisProjectSelected.roles, 'name')), (role) ->
					acc.push
						id: project.project.id
						role: role.name
			acc
		, []
		id: @props.id
		addedProjects: addedProjects
		removedProjects: removedProjects

	onCloseProxy: (operations) =>
		# TODO: waiting for userUpdateMemberships
		if operations
			if (!_.isEmpty _.difference @state.globalRolesSelected, @state.initialGlobalRoles) or
			(!_.isEmpty _.difference @state.initialGlobalRoles, @state.globalRolesSelected)
				operations.userUpdateRoles.mutation
					variables: @getVariables4MutationRoles()
			if (!_.isEmpty _.difference @state.groupsSelected, @state.groups) or
			(!_.isEmpty _.difference @state.groups, @state.groupsSelected)
				operations.userUpdateUserGroupMembership.mutation
					variables: @getVariables4MutationGroups()
			if (_.size (_.get @getVariables4MutationProjectRoles(), 'addedProjects')) isnt 0 or
			(_.size (_.get @getVariables4MutationProjectRoles(), 'removedProjects')) isnt 0
				operations.userUpdateProjectMembership.mutation
					variables: @getVariables4MutationProjectRoles()
		@props.onClose()

	render: =>
		DataLayer {
			id: @props.id
			setState: (state) =>
				@setState state
			makeOptions: @makeOptions
		}
		, (operations) =>
			BevyModal
				onClose: @onCloseProxy
				width: 'x-large'
				header: "Edit User Permissions"
				autoFocus: false
				actions: [
					text: 'Save'
					isDisabled: !@canProceed()
					onClick: () => @onCloseProxy operations
				,
					text: 'Cancel'
					onClick: @onCloseProxy
				]
			,
				if operations.getUser.loading
					Spinner {}
				else
					div {className: styles.modalHeader},
						div {}
							Avatar {
								name: operations.getUser.data.user.name
								src: operations.getUser.data.user.picture.mini
								size: 'small'
							}
						,
							div {className: styles.item}, operations.getUser.data.user.name
						,
							div {className: styles.item}, operations.getUser.data.user.email
				,
					div {className: styles.relative},
						Inline {},
							#roles
							div {key: 'roles', className: styles.roles},
								div {className: styles.tableItem}, 'Roles'
							,
								_.map @state.globalRolesSelected, (role) =>
									div {className: styles.tableItem, key: 'role' + role },
										Fragment {}, role
										div
											className: styles.removeAction
											onClick: =>
												@setState globalRolesSelected: _.reject @state.globalRolesSelected, (roleSelected) -> roleSelected is role
										,
											Icon {icon: x, size: 16}
							,
								div {className: styles.selectLast},
									Select
										slug: "globalRoles"
										isMulti: false
										menuPortalTarget: document.body
										spacing: 'compact'
										placeholder: "Add role"
										options: do =>
											if operations.getGlobalRoles?.data?
												globalRoles = _.filter operations.getGlobalRoles.data.permissionsModel.entityRoles, (role) => !(role.name in @state.globalRolesSelected)
												@makeOptions globalRoles
										onChange: (value) =>
											@setState
												globalRolesSelected: [...@state.globalRolesSelected, value.value]
										value: null
							div {key: 'groups', className: styles.roles},
								#groups
								div {className: styles.tableItem}, 'Groups'
							,
								_.map @state.groupsSelected, (group) =>
									div {className: styles.tableItem, key: 'group' + group },
										Fragment {}, _.get (_.find operations.getGroups.data.userGroups, (value) -> value.id is group), 'name'
										div
											className: styles.removeAction
											onClick: =>
												@setState groupsSelected: _.reject @state.groupsSelected, (groupSelected) -> groupSelected is group
										,
											Icon {icon: x, size: 16}
							,
								div {className: styles.tableItem},
									Select
										slug: "groups"
										isMulti: false
										menuPortalTarget: document.body
										spacing: 'compact'
										placeholder: "Add group"
										options: do =>
											if operations.getGroups?.data?
												groups = _.filter operations.getGroups.data.userGroups, (group) => !(group.id in @state.groupsSelected)
												convertToOptions groups
										onChange: (value) =>
											@setState
												groupsSelected: [...@state.groupsSelected, value.value]
										value: null
							div {key: 'projects', className: styles.roles},
								#projects
								div {className: styles.tableItem}, 'Projects'
							,
								_.map _.sortBy(@state.projectsSelected, (item) -> item.project.name), (project) =>
									Fragment {},
										div {className: styles.tableItem, key: 'project' + project.project.name },
											Fragment {}, project.project.name
											div
												className: styles.removeAction
												onClick: =>
													@setState projectsSelected: _.reject @state.projectsSelected, (projectToRemove) -> projectToRemove.project.name is project.project.name
											,
												Icon {icon: x, size: 16}

										_.map project.roles, (item, index) ->
											nameClass = if index is ((_.size(project.roles)) - 1) then styles.tableItemLastBorder else styles.tableItem
											div {className: nameClass , key: 'projectkey' + item.name }, ''
							,
								div {className: styles.selectLast},
									Select
										slug: "project"
										isMulti: false
										menuPortalTarget: document.body
										spacing: 'compact'
										placeholder: "Add project"
										options: do =>
											if operations.getProjects?.data?
												projects = _.map @state.projectsSelected, (item) ->
													item.project.name
												projects = _.filter operations.getProjects.data.projects , (project) -> !(project.name in projects)
												convertToOptions projects
										onChange: (value) =>
											@setState
												tmpProject: value
										value: @state.tmpProject
							div {key: 'projectsRoles', className: styles.roles},
								#projectRoles
								div {className: styles.tableItem}, 'Projects Roles'
							,
								_.map _.sortBy(@state.projectsSelected, (item) -> item.project.name), (project) =>
									Fragment {},
										_.map project.roles, (item) =>
											Fragment {},
												div {className: styles.tableItem, key: 'projectrole' + item.name },
													Fragment {}, item.displayName
													div
														className: styles.removeAction
														onClick: =>
															projectToRemoveRole = _.find @state.projectsSelected, (projectInArray) -> projectInArray.project.name is project.project.name
															projectToRemoveRole.roles.pop
																name: item.name
															@setState
																projectsSelected: [
																	..._.reject @state.projectsSelected, (projectTmp) -> projectTmp.project.name is project.project.name
																	projectToRemoveRole
															]
													,
														Icon {icon: x, size: 16}
										div {className: styles.selectItem},
											Select
												slug: 'projectSelection'
												isMulti: false
												menuPortalTarget: document.body
												spacing: 'compact'
												placeholder: 'Add Project Role'
												options: do =>
													if operations.getProjectRoles?.data?
														filteredProjectRoles = _.filter operations.getProjectRoles.data.permissionsModel.membersRoles, (role) ->
															!(role.name in _.map project.roles, 'name')
														filteredProjectRoles = _.filter filteredProjectRoles, (role) -> 'User' in role.models
														@makeOptions filteredProjectRoles
												onChange: (value) =>
													projectToAddRole = _.find @state.projectsSelected, (projectInArray) -> projectInArray.project.name is project.project.name
													projectToAddRole.roles.push
														name: value.value
														displayName: value.label
														__typename: 'Role'
													@setState
														projectsSelected: [
															..._.reject @state.projectsSelected, (projectTmp) -> projectTmp.project.name is project.project.name
															projectToAddRole
													]
												value: null
							,
								div {className: styles.selectLast},
									Select
										slug: 'projectRoles'
										isMulti: false
										menuPortalTarget: document.body
										spacing: 'compact'
										isDisabled: _.isEmpty @state.tmpProject
										placeholder: if _.isEmpty @state.tmpProject then 'First select project' else "Add project role to #{@state.tmpProject.label}"
										options: do =>
											if operations.getProjectRoles?.data?
												filteredProjectRoles = _.filter operations.getProjectRoles.data.permissionsModel.membersRoles, (role) -> 'User' in role.models
												@makeOptions filteredProjectRoles
										onChange: (value) =>
											@setState
												projectsSelected: [
													...@state.projectsSelected
													{
														project:
															id: @state.tmpProject.value
															name: @state.tmpProject.label
														roles: [
															name: value.value
															__typename: 'Role'
														]
													}
												]
												tmpProject: null
										value: null

