###
Add Project Group Member Modal
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import adopt from 'libs/adopt'
import { convertToOptions } from 'libs/legal'
import { NotificationConsumer } from '../../../application/components/NotificationManager'
import { UserInfoConsumer } from 'libs/userInfo'

# Renderable
Fragment = React.createFactory React.Fragment

import _BevyModal from '@bevy/modal'
BevyModal = React.createFactory _BevyModal

import {
	LoadableSelectField as _LoadableSelectField,
} from 'components/FormItems'
LoadableSelectField = React.createFactory _LoadableSelectField

# Data
import {
	GetProjectMembers
	AddUsersToProjectGroup
} from './data'

REFETCH_QUERIES = [
	'projectUserGroupDetail'
	'projectUserGroupsList'
	]


DataLayer = adopt
	mutations:
		addUsersToProjectGroup: ({id, setNotification}) ->
			mutation: AddUsersToProjectGroup
			variables: { id }
			refetchQueries: REFETCH_QUERIES
			onCompleted: ->
				setNotification({content: 'Users successfully added to the project group', appearance: 'success'})
			onError: (err) ->
				if err?.graphQLErrors? and (_.head err.graphQLErrors).message?
					setNotification ({content: (_.head err.graphQLErrors).message, appearance: 'error'})
				else
					setNotification ({content: 'Error adding user from Project Group', appearance: 'error'})

export default class AddProjectGroupMembersModal extends React.Component
	@propTypes =
		onClose: PropTypes.func.isRequired

	constructor: (props) ->
		super props
		@state = {}

	onCloseProxy: (appropriateMutation) =>
		if _.isFunction appropriateMutation
			appropriateMutation
				variables:
					id: @props.projectGroupID
					addedEntities: _.map @state.users, (user) ->
						id: user.value
		@props.onClose()

	render: ->
		UserInfoConsumer {}, ({ability, me}) =>
			NotificationConsumer {}, ({setNotification}) =>
				DataLayer
					setNotification: setNotification
					id: @props.projectGroupID
					, (operations) =>
						BevyModal
							onClose: @onCloseProxy
							header: 'Add users to project group'
							width: 'small'
							actions: [
								text: 'Save'
								isDisabled: _.isEmpty @state.users
								onClick: () => @onCloseProxy operations.addUsersToProjectGroup.mutation
							,
								text: 'Cancel'
								onClick: @onCloseProxy
							]
						,
							LoadableSelectField
								label: 'Name'
								tooltipContent: 'To add new user please contact administrator.'
								isMulti: true
								query:
									query: GetProjectMembers
									variables:
										id: @props.projectGroupID
										pagination:
											limit: 20
								makeOptionsFromData: (data) =>
									convertToOptions _.map (_.get data, 'projectUserGroup.project.members'), 'user'
								handleValueToDisplay: (value, options) =>
									allPossibleOptions = _.uniqBy (_.concat options, @state.users), 'value'
									itemToDisplay = _.find allPossibleOptions, {value}
									if itemToDisplay?
										label: itemToDisplay.label
										value: itemToDisplay.value
								values: _.map @state.users, 'value'
								onChange: (items) =>
									@setState users: items
								hasMore: @state.hasMore
								handleDataUpdate: (prev, fetchMoreResult) =>
									if _.isEmpty fetchMoreResult.projectUserGroup.project.members
										@setState hasMore: false
									projectUserGroup: {
										...prev.projectUserGroup
										project: {
												...prev.projectUserGroup.project
												members: [
													...prev.projectUserGroup.project.members,
													...fetchMoreResult.projectUserGroup.project.members
												]
										}
									}
