import _ from 'lodash'

export default ({data, format}) ->
	if (_.isEmpty data) or (_.isEmpty data.projectUserGroup) then {}
	else
		{projectUserGroup} = data
		items: _.compact [
			key: 'totalCount'
			description: 'Members'
			tooltip: 'Total group members count'
			value: _.size projectUserGroup.members
		]
