import _ from 'lodash'
import printJS from 'print-js'
import moment from 'moment-mini'
import filesize from 'filesize'
import diacritics from 'diacritics'

stage = if __STAGE__? then __STAGE__ else 'local'

export DOWNLOAD_TIMEOUT = 6000

export getConjectPath = (path, short = false) ->
	fullPath = []
	if path?
		_.map path.ancestors, (ancestor) ->
			ancestorCode = if ancestor.code < 10 then "0#{ancestor.code}" else ancestor.code
			if short then fullPath.push ancestorCode else fullPath.push("#{ancestorCode} #{ancestor.name}")
		pathCode = if path.code < 10 then "0#{path.code}" else path.code
		if short then fullPath.push pathCode else fullPath.push("#{pathCode} #{path.name}")
		_.toUpper _.join fullPath, ' / '
	else
		'-'

export getName4Document = (state, operation) ->
	{ files, basicInfo, category, details, temporaryOrganizations } = state
	project = _.get operation, 'documentRegisterEnvironment.data.project'
	parts = {}
	if project?
		parts.project = project.code
	if basicInfo.creationDate?
		parts.date = moment.unix(basicInfo.creationDate).format 'YYYYMMDD'
	else
		parts.date = moment.utc().format 'YYYYMMDD'
	if details.buildings? and (!_.isEmpty details.buildings) and project.buildingsSets?
		buildings = _.map details.buildings, (selectedBuilding) ->
			selectedBuilding = selectedBuilding.value
			_.find project.buildingsSets, {code: selectedBuilding}
		buildings = _.sortBy buildings, 'code'

		buildings = _.compact _.map buildings, (building) ->
			if !project.buildingsDownloadConfig?.buildings?
				building.name
			else
				buildingConfig = _.find project.buildingsDownloadConfig.buildings, {code: building.code}
				if buildingConfig.hideInCombination
					null
				else
					buildingConfig.downloadName
		if project.buildingsDownloadConfig?.prefix? and !_.isEmpty buildings
			buildings.unshift project.buildingsDownloadConfig.prefix

		parts.buildings = _.join buildings, ''
	if category?.form?.abbrev?
		parts.form = _.upperCase category.form.abbrev

	if category?.topic?.name?
		parts.topic = _.upperCase category.topic.name.substr 0, 40

	if !category?.form?.name? and !category?.topic?.name? and files? and (_.head files)?.name?
		parts.scan = (_.head files).name
	if details.freeText?
		parts.freeText = _.toUpper details.freeText.substr 0, 20
	numberPriorities = [
		'CaseNumber',
		'Number',
		'LandRegisterNumber',
		'NIPNumber',
		'REGONNumber',
		'KRSNumber',
		'ParcelNumber'
	]

	numbers = []
	_.map numberPriorities, (priorityName) ->
		if details.identifiers[priorityName]?
			numbers.push details.identifiers[priorityName].split(' ').join('')
	parts.number = _.join (_.compact numbers), '_'

	parts.correspondent =
		if basicInfo?.source?.abbreviation?
			_.upperCase basicInfo.source.abbreviation
		else if basicInfo?.source?.name?
			orgName = basicInfo.source.name
			phrasesToRemove = [
				/Sp\.?\s*z\s*o\s*\.?\s*o\s*\./i
				/Sp\.?\s*z\s*o\s*\.?\s*o\s*\./i
				/Sp(ó|o)(l|ł)ka z Ograniczon(ą|a) Odpowiedzialno(ś|s)ci(ą|a)/i
				/Sp\.\s*K/i
				/Spółka Komandytowa/i
				/Spółka Cywilna/i
				/Spółka Jawna/i
				/Spółka Partnerska/i
			]
			_.forEach phrasesToRemove, (phrase) -> orgName = _.replace orgName, phrase, ''
			_.trim _.upperCase orgName
	orderingList = project.legalDocumentNamePropertyOrder || [
			'project'
			'date'
			'buildings'
			'form'
			'topic'
			'scan'
			'freeText'
			'number'
			'correspondent'
		]
	correctlyOrderedParts = []
	_.map orderingList, (substring) ->
		correctlyOrderedParts.push parts["#{substring}"]
	name = _.join (_.compact correctlyOrderedParts), '_'
	name = _.replace name, /\/|\\|\?|\%|\*|\:|\|\"|\<|\>/g, '-'
	name = _.replace name, /\s/g, '_'
	name = name.substr 0, 250
	name = diacritics.remove(name)
	name = _.toUpper name
	name + '.pdf'

export convertToOptions = (items) ->
	if items?
		if _.isArray items
			items = _.map items, (item) ->
				label:
					if item.name? then item.name
					else if item.label? then item.label
					else if item.value? then item.value
					else item
				value:
					if item.key? then item.key
					else if item.code? then item.code
					else if	item.value? then item.value
					else if item.id? then item.id
					else if item.slug? then item.slug
					else if item.name? then item.name
					else item
		else if items.label? and items.value?
			items
		else
			label: items.name
			value: if items.id then items.id else items.name
	else
		[]

export isModified = (item) ->
	timeThreeMinutesAgo = moment().subtract(3, 'minute').unix()
	# LTTMA = LessThanThreeMinutesAgo
	createdLTTMA = item.createdAt > timeThreeMinutesAgo
	addedScanLTTMA = item.scan?.uploadedAt? and item.scan.uploadedAt > timeThreeMinutesAgo
	categorizedLTTMA = item.categorizedAt and item.categorizedAt > timeThreeMinutesAgo
	approvedLTTMA = item.approvedAt? and item.approvedAt > timeThreeMinutesAgo
	createdLTTMA or addedScanLTTMA or categorizedLTTMA or approvedLTTMA

export handleQRPrint = ({format, legalDocument, onQRPrintEnd}) ->
	document =
		id: legalDocument.id
		createdAt: legalDocument.createdAt
		createdBy: legalDocument.createdBy.name
		url: "#{window.location.origin}/legalDocuments/#{legalDocument.id}"
		project: legalDocument.project.name
		company: legalDocument.company.name
		source: legalDocument.correspondent.name
		date: _.find(legalDocument.dates, type: 'ReceivalDate').value
		form: legalDocument.form.name
		topic: legalDocument.topic.name
		fullPath: getConjectPath legalDocument.path
		shortPath: getConjectPath legalDocument.path, true

	#FIXME should use api proxy
	_stage = if stage is 'local' then 'development' else stage
	serviceEndpoints =
		development: 'https://jn2nmd634m.execute-api.eu-central-1.amazonaws.com/development'
		test: 'https://80a54u0kaj.execute-api.eu-central-1.amazonaws.com/test'
		production: 'https://7xk8wri3dl.execute-api.eu-central-1.amazonaws.com/production'

	url = serviceEndpoints[_stage] + "?format=#{format}&document=#{encodeURIComponent JSON.stringify document}"
	fetch url,
		mode: 'no-cors'
	.then (data) =>
		printJS
			printable: "https://s3.eu-central-1.amazonaws.com/s360-dms-qr-tags/#{_stage}/#{legalDocument.id}/#{format}.pdf"
			type: 'pdf'
			onLoadingStart: () -> # leave to display print window
			onLoadingEnd: () => onQRPrintEnd format
			onError: () => onQRPrintEnd format

export stringifyLoadedPDFsNames = (pdfs) ->
	MAX_FILES_VISIBLE = 4
	MAX_STRING_LENGTH = 15
	string = []
	_.map (_.take pdfs, MAX_FILES_VISIBLE), (file, index) =>
		fileName =
			if (_.size file.name) > MAX_STRING_LENGTH
				file.name.substring(0, MAX_STRING_LENGTH)
			else
				file.name
		fileSize = filesize file.size
		suffix = if pdfs[index+1]? then ', ' else ''
		string.push "#{fileName} (#{fileSize})#{suffix}"
	if (_.size pdfs) > MAX_FILES_VISIBLE
		string.push '...'
	_.join string, ''

export getRefetchQueries = (queries, docState) ->
	if (docState is 'Draft') or (docState is 'inbox')
		_.concat queries, 'DMS_Inbox_Table'
	else if (docState is 'Commited') or (docState is 'registry')
		_.concat queries, 'DMS_Registry_Table'
	else #if nothing provided then refetch bouth lists
		_.concat queries, ['DMS_Inbox_Table',  'DMS_Registry_Table']

export getTypeOfOwnership = () ->
	[
		value: 'PerpetualUsufruct'
		label: 'Perpetual usufruct'
	,
		value: 'Ownership'
		label: 'Ownership'
	]


