	import gql from 'graphql-tag'

	export GetGlobalRoles = gql """
	query{
		permissionsModel(model:User){
			entityRoles{
				name
			}
		}
	}
	"""

	export GetGroups = gql """
	query {
		userGroups {
			id
			name
		}
	}
	"""

	export GetProjects = gql """
	query {
			projects {
				id
				name
		}
	}
	"""
	export GetProjectRoles = gql """
	query{
		permissionsModel(model:Project){
			membersRoles{
				name
				models
				displayName
			}
		}
	}
	"""
	export GetUser = gql """
	query($id: ID!){
		user(id: $id){
			... on Service {
					id
			}
			... on Person {
				id
				name
				email
				picture {
					mini
				}
				memberships {
					...on UserGroupAccess {
						group {
							id
							name
						}
						roles {
								name
						}
					}
					...on ProjectAccess {
						project {
							id
							name
						}
						roles {
							name
							displayName
						}
					}
				}
				roles {
					name
				}
			}
		}
	}"""

	# MUTATIONS
	export UserUpdateProjectMembership = gql """
	mutation($id: ID!, $addedProjects: [MemberInputAdd!], $removedProjects: [MemberInputRemove!]) {
		userUpdateProjectMembership(
			id: $id
			addedProjects: $addedProjects
			removedProjects: $removedProjects
		) {
			...on Person {
				id
				memberships {
					...on UserGroupAccess {
						group {
							id
							name
						}
						roles {
								name
						}
					}
					...on ProjectAccess {
						project {
							id
							name
						}
						roles {
							name
						}
					}
				}
			}
		}
	}
	"""

	export UserUpdateUserGroupMembership = gql """
	mutation( $id: ID!, $addedUserGroups: [MemberInputAdd!], $removedUserGroups: [MemberInputRemove!]) {
		userUpdateUserGroupMembership(
			id: $id
			addedUserGroups: $addedUserGroups
			removedUserGroups: $removedUserGroups
		) {
			...on Person {
				id
				memberships {
					...on UserGroupAccess {
						group {
							id
							name
						}
						roles {
								name
						}
					}
					...on ProjectAccess {
						project {
							id
							name
						}
						roles {
							name
						}
					}
				}
			}
		}
	}
	"""

	export UserUpdateRoles = gql """
	mutation( $id: ID!, $addedRoles: [String!], $removedRoles: [String!]) {
		userUpdateRoles(
			id: $id
			addedRoles: $addedRoles
			removedRoles: $removedRoles
		) {
			... on Person {
				id
				roles{
					name
				}
			}
		}
	}
	"""
