import gql from 'graphql-tag'
import {
	ActorAccess
} from './query.graphql'

export GetProjectMembersAndGroupsAndACL = gql """
	query getACL ($projectSlug: String!, $documentsIDs: [ID!], $pagination: PaginationInput){
		project(slug: $projectSlug) {
			id
			slug
			projectUserGroups {
				id,
				name,
				picture { mini }
				project { id slug }
				members(pagination: {limit: 20}) {
					#{ActorAccess}
				}
			}
			members(pagination: $pagination) {
				#{ActorAccess}
			}
		}
		userGroups {
			id,
			name,
			picture { mini }
			members {
				#{ActorAccess}
			}
		}
		permissionsModel(model:LegalDocument) {
			aclRoles {
				name
				displayName
				accepts
			}
		}
		legalDocuments(filters: {ids: $documentsIDs}) {
			id
			acl {
				...on ActorAccess {
					roles {
						name
						displayName
					}
					user {
						...on Person { id, name, picture { mini } }
					}
				}
				...on UserGroupAccess {
					group { id, name, picture { mini } }
					roles {
						name
						displayName
					}
				}
				...on ProjectUserGroupAccess {
					projectGroup {
						id
						name
						picture {
							mini
						}
						project {
							id
							slug
						}
					}
					roles { name displayName }
				}
			}
		}
	}
"""

export UpdateACL = gql """
	mutation(
		$input: [DocumentACLInput!]!
		$note: String
		) {
		legalDocumentsUpdateACL(
			input: $input
			note: $note
			) {
			id
			acl {
				...on ActorAccess {
					roles {
						name
						displayName
					}
					user {
						...on Person { id, name, picture { mini } }
					}
				}
				...on UserGroupAccess {
					group { id, name, picture { mini } }
					roles {
						name
						displayName
					}
				}
				...on ProjectUserGroupAccess {
					projectGroup { id, name, picture { mini } }
					roles { name displayName }
				}
			}
		}
	}
"""
