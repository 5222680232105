import gql from 'graphql-tag'

export BlockedDocument = gql """
query($id: ID!, $projectID: ID!) {
  organization (id: $id) {
    id
    name
    documentsInUse (projectID: $projectID, companyID: $id) {
			total
			visible
			documents {
				id
				name
				state
				form { id, name }
				topic { id, name }
				scan { id, filename }
			}
    }
  }
}
"""

export ProjectLeader = gql """
query($projectID: ID!) {
  projectByID(id: $projectID) {
    id
		leader {
			id
			name
			picture {
				original
				mini
			}
		}
  }
}
"""

