export noPadding =
	padding: 0
	paddingTop: 0
	paddingBottom: 0
	paddingLeft: 0
	paddingRight: 0

export ellipsis =
	whiteSpace: 'nowrap'
	overflow: 'hidden'
	textOverflow: 'ellipsis'

export defaultColor = '#F4F5F7'
export defaultColorDark = '#8993A4'

export dangerColor = '#ff56301a'
export dangerColorDark = '#ff5630'
## standard
# export activeColor = '#B3D4FF'
# export activeColorDark = '#4C9AFF'

#revenue hak
export activeColor = '#E3FCEF'
export activeColorDark = '#36B37E'

export default customStyles = (props) =>
	control: (provided, state) =>
		{
			...provided
			backgroundColor:
				if props.appearance is 'danger'
					dangerColor
				else
					defaultColor
			# 	if state.selectProps.menuIsOpen
			# 		'transparent'
			# 	else if (!_.isEmpty state.selectProps.value) and (!props.disableColoring)
			# 		activeColor
			# 	else
			# 		defaultColor
			borderColor:
				if props.appearance is 'danger'
					dangerColorDark
				else
					defaultColor
			# 	if state.selectProps.menuIsOpen
			# 		activeColorDark
			# 	else if (!_.isEmpty state.selectProps.value) and (!props.disableColoring)
			# 		activeColor
			# 	else
			# 		defaultColor
			# ':hover':
			# 	backgroundColor: 'transparent'
			# 	borderColor: activeColorDark
			height: if !(props.isMulti?) then '26px' else ''  #26 button height
			borderWidth: '1px'
			minHeight: '26px'
			flexWrap: 'nowrap'
		}
	menu: (provided, state) ->
		{
			...provided
			zIndex: '9999'
		}
	menuPortal: (provided, state) ->
		{
			...provided
			zIndex: '9999'
		}
	placeholder: (provided, state) ->
		{
			...provided
			color: '#8993A4'
		}
	dropdownIndicator: (provided, state) ->
		{
			...provided
			...noPadding
			height: '12px'
			paddingRight: '4px'
		}
	clearIndicator: (provided, state) ->
		{
			...provided
			...noPadding
		}
	valueContainer: (provided, state) ->
		{
			...provided
			paddingTop: 0
			paddingBottom: 0
			paddingLeft: 4
			paddingRight: 4
			height: if !(props.isMulti?) then '26px' else ''# 26 button height
			minHeight: '26px'
		}
	badge: () ->
		{
			display: 'inline-block'
			marginLeft: 'auto'
			marginRight: '2px'
		}
