###
Common way of representing floor information on the left of the building
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
# Renderable
import { div } from 'react-dom-factories'

import _Toolbox from 'components/Toolbox'
Toolbox = React.createFactory _Toolbox

# Styles
import styles from './index.styl'



export default class HeaderBar extends React.Component
	@propTypes:
		# header: PropTypes.node.isRequired
		# descriptor: PropTypes.node
		header: PropTypes.node
		kpi: PropTypes.node
		toolbar: PropTypes.oneOfType [
			PropTypes.node
			PropTypes.object
		]
		filters: PropTypes.node
		inline: PropTypes.bool
	@defaultProps:
		document: false
		inline: false
	render: ->
		div {
			className: cnames [
				@props.className
				styles.base
				if @props.header? then styles.documentHeader else styles.pageHeader
				if @props.document then styles.embeddedHeader
				if @props.dms? then styles.dmsHeader
			]
		},
			if @props.header?
				div {
					className: cnames [
						styles.element,
						styles.header,
						(if @props.reversedColors? or @props.header.props.reversedColors?
							styles.reversed),
						@props.header.props.className,
						]
					style: if @props.headerColor? then {borderLeft: "4px solid #{@props.headerColor}"}
				},
					div {className: cnames [
						styles.elementContent
						if @props.inline and @props.kpi? then styles.inline
					]},
						@props.header
						if @props.inline and @props.kpi?
							div {className: cnames [styles.internalContent, if @props.dms? then styles.dmsInternalContent]}, @props.kpi
						if @props.inline and @props.acl?
							div {className: cnames [styles.internalContent, styles.acl, if @props.dms? then styles.dmsInternalContent]}, @props.acl
			if !@props.inline and @props.kpi?
				div {
					className: cnames [styles.element, styles.kpi]
					style: if @props.headerColor? then {borderLeft: "4px solid #{@props.headerColor}"}
				}, div {className: styles.elementContent}, @props.kpi
			if @props.toolbar? || @props.mode?
				div {className: cnames [
						if !@props.dms? then styles.element
						if !@props.dms? then styles.toolbar
						if @props.mode? then styles.withMode
						if @props.toolbarClassName? then @props.toolbarClassName
					]},
					div {className: styles.elementContent},
						if @props.mode? then @props.mode
						div {className: styles.toolbarContainer},
							@props.toolbar
			if @props.filters?
				div {className: cnames [styles.element, styles.filters]},
					div {className: styles.elementContent}, @props.filters
