import gql from 'graphql-tag'


export ProjectUserGroupQuery = gql """
	query projectUserGroupDetail (
		$id: ID!
		$pagination: PaginationInput
		) {
		projectUserGroup(id: $id) {
			id
			name
			slug
			description
			picture {
				mini
			}
			project {
				id
				name
				slug
				isMultiphase
				phases { id name }
			}
			roles {
				name
				info
			}
			members(pagination: $pagination) {
			... on ActorAccess{
				user {
					... on Person{
						id
						name
						email
						picture {
							mini
						}
					}
				}
			}
		}
	}
}

"""
