
###
S360 user panel
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import { logout } from '../../../../../wrapper'
# Renderable
import { h2, div, p } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment
import { UserInfoConsumer } from 'libs/userInfo'

import { Query as _Query } from 'react-apollo'
Query = React.createFactory _Query

import _Avatar from '@atlaskit/avatar'
Avatar = React.createFactory _Avatar

import _Spinner from '@bevy/spinner'
Spinner = React.createFactory _Spinner

import _Button from '@bevy/button'
Button = React.createFactory _Button

import _BevyModal, {BevyModalsWrapper as _BevyModalsWrapper} from '@bevy/modal'
BevyModalsWrapper = React.createFactory _BevyModalsWrapper
BevyModal = React.createFactory _BevyModal


# Styles
import styles from './index.styl'

# Data
import { UserPanelInfo } from './data'


export default class UserPanel extends React.Component
	constructor: (props) ->
		super props
		@state =
			isModalOpen: false

	render: ->
		UserInfoConsumer {}, ({ability, me}) =>
			Query
				query: UserPanelInfo
			, (queryResultat) =>
				if queryResultat.loading
					Spinner {}
				else if queryResultat.data.me?
					Fragment {},
						div {className: styles.userPanel},
							div {className: styles.avatar},
								Avatar
									size: 'xxlarge'
									src: queryResultat.data.me.picture.original
									component: ({children, className}) ->
										div {
											style:
												pointerEvents: 'none'
											className: className
										}, children
							p {className: styles.name}, queryResultat.data.me.name
							p {className: styles.email}, queryResultat.data.me.email
							Button {onClick: => @setState isModalOpen: true}, 'Logout'
							# Button {onClick: -> logout()}, 'Logout'
						BevyModalsWrapper {},
							if @state.isModalOpen
								BevyModal {
									'data-test-id': 'delete-document-modal'
									width: 'small'
									height: 150
									appearance: 'danger'
									header: 'Logout'
									actions: [
										text: 'Logout'
										onClick: -> logout()
									,
										text: 'Cancel'
										onClick: () => @setState isModalOpen: false
									]
								},
									'Are you sure you want to log out?'
				else null
