###
Calendar
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import moment from 'moment-mini'

# Renderable
import { div, span, input } from 'react-dom-factories'

Fragment = React.createFactory React.Fragment

import _Button from '@bevy/button'
Button = React.createFactory _Button

import _Calendar from '@atlaskit/calendar'
AtlasskitCalendar = React.createFactory _Calendar

import _Tooltip from '@atlaskit/tooltip'
Tooltip = React.createFactory _Tooltip

# Styles
import styles from './index.styl'

export default class Calendar extends React.Component
	@propTypes: {}
	@defaultProps: {}

	constructor: (props) ->
		super props
		@state =
			date: if @props.date? then moment.unix(@props.date).format('YYYY-MM-DD')

	handleConfirm: (date) =>
		@props.onConfirm if date? then moment(date).unix()
		@handleClose()

	handleClear: () =>
		@props.onClear()
		@setState
			date: null

	handleClose: () =>
		if @props.onClose? then @props.onClose()

	render: ->
		div {className: styles.base},
			AtlasskitCalendar
				defaultSelected: if @state.date? then [@state.date]
				onSelect: (value) =>
					@props.onConfirm moment(value.iso).unix()
					@handleClose()
					# @setState date: value.iso
			# div {className: styles.footer},
			# 	div {className: styles.button},
			# 		Tooltip content: "Apply filter with selected date",
			# 			Button
			# 				isDisabled: _.isEmpty @state.date
			# 				appearance: 'primary'
			# 				onClick: () => @handleConfirm @state.date
			# 			, 'Apply'
				# div {className: styles.button},
				# 	Tooltip content: "Clear date",
				# 		Button
				# 			appearance: 'subtle'
				# 			onClick: @handleClear
				# 		, 'Clear'
				# div {className: styles.button},
				# 	Tooltip content: "Close",
				# 		Button
				# 			appearance: 'subtle'
				# 			onClick: @handleClose
				# 		, 'Cancel'
