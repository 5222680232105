import gql from 'graphql-tag'


# QUERIES
export GetProjectInfo = gql """
	query getProjectInfo(
		$slug: String!
	) {
		project(slug: $slug) {
			id
			isMultiphase
			phases { id name }
		}
	}
"""

export GetRoles = gql """
	query getRoles {
		permissionsModel(model:ProjectUserGroup){
			entityRoles { name }
		}
	}
"""

export GetPhases = gql """
	query getPhases(
		$projectID: ID!
	) {
		projectByID(id: $projectID) {
			id
			isMultiphase
			phases { id name }
		}
	}
"""

# MUTATIONS
export AddProjectUserGroup = gql """
	mutation addGroup(
		$projectID: ID!,
		$name: String!,
		$description: String,
		$addedRoles: [ProjectUserGroupRoleInputAdd!]
		) {
		projectUserGroupCreate(
			name: $name
			description: $description
			addedRoles: $addedRoles
			projectID: $projectID
		) {
			id
			name
			project {
				id
			}
			description
			roles {
				name
				info
			}
		}
	}
"""

export EditProjectUserGroup = gql """
	mutation updateGroup (
		$id: ID!,
		$name: String,
		$description: String,
		$addedRoles: [ProjectUserGroupRoleInputAdd!]
		$removedRoles: [ProjectUserGroupRoleInputRemove!]
	) {
		projectUserGroupUpdate(
			id: $id
			name: $name
			description: $description
			addedRoles: $addedRoles
			removedRoles: $removedRoles
		) {
			id
			name
			description
			roles {
				name
				info
			}
		}
	}
"""
