###
Project building area delete modal
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import adopt from 'libs/adopt'

# Renderable
import { div } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import { NotificationConsumer } from '../../../application/components/NotificationManager'

import _BevyModal from '@bevy/modal'
BevyModal = React.createFactory _BevyModal

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

# Styles
import ErrorIcon from '@atlaskit/icon/glyph/error'

# Data
import {
	ProjectBuildingAreaDelete
} from './data'

DataLayer = adopt
	mutations:
		projectBuildingAreaDelete: ({setNotification, onConfirm}) ->
			mutation: ProjectBuildingAreaDelete
			refetchQueries: [
					'ProjectDashboard'
				]
			onCompleted: onConfirm

export default class ProjectManagementDeleteModal extends React.Component
	@propTypes =
		onClose: PropTypes.func.isRequired

	constructor: (props) ->
		super props

	render: ->
		NotificationConsumer {}, ({setNotification}) =>
			DataLayer {
				setNotification: setNotification
				onConfirm: @props.onConfirm
			}, (operations) =>
				BevyModal {
					onClose: @props.onClose
					header: 'Delete confirmation'
					headerIcon: ErrorIcon {size: 'medium', primaryColor: '#DE350B'}
					width: 'small'
					actions: [
						text: 'Delete'
						onClick: () =>
							operations.projectBuildingAreaDelete.mutation
								variables:
									projectID: @props.projectID
									id: @props.building.id
							@props.onClose()
					,
						text: 'Cancel'
						onClick: @props.onClose
					]
					note: 'Once deleted, it can be only restored by contacting your administrator.'
				},
					"Are you sure you want to remove #{@props.building.name} from buildings areas list?"

