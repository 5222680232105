import gql from 'graphql-tag'

# MUTATIONS
# export DeleteUser = gql """
# 	mutation($projectID: ID!, $userID: ID!) {
# 		projectPersonnelUpdateRoles(
# 			projectID: $projectID
# 			userID: $userID
# 			roles: []
# 		) {
# 			... on Person {
# 				id
# 			}
# 		}
# 	}
# """
export DeleteUser = gql '''
	mutation($userID: ID!, $removedProjects: [MemberInputRemove!]) {
		userUpdateProjectMembership(
			id: $userID
			removedProjects: $removedProjects
		)
	{
		... on Person {
			id
		}
	}
}
'''
