exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._3__k0CdipVF9I1Kih4vfuF {\n  height: 40px;\n  line-height: 40px;\n}\n._3__k0CdipVF9I1Kih4vfuF._2D8j7CXt4xhWhmblHZy8vf {\n  height: 32px;\n  line-height: 32px;\n}\n._3__k0CdipVF9I1Kih4vfuF.dD6o13sJihC8q5psPAwZK {\n  font-weight: bold;\n}\n._1lT5o765JyvzRhykRVd3qM {\n  display: flex;\n}\n.njUyFuEtuAUO0YMwUpTYB {\n  display: flex;\n  width: 100px;\n  margin-right: 8px;\n}\n.njUyFuEtuAUO0YMwUpTYB label {\n  text-transform: uppercase;\n}\n.njUyFuEtuAUO0YMwUpTYB label > div {\n  padding: 0 0 4px;\n}\n.njUyFuEtuAUO0YMwUpTYB ._1r6Q2mtBdNvL326tKVNSRV {\n  cursor: pointer;\n  margin: 30px 0 10px 3px;\n}\n.njUyFuEtuAUO0YMwUpTYB ._1r6Q2mtBdNvL326tKVNSRV:hover {\n  color: #ebecf0;\n}\n", ""]);
// Exports
exports.locals = {
	"button": "_3__k0CdipVF9I1Kih4vfuF",
	"compact": "_2D8j7CXt4xhWhmblHZy8vf",
	"filled": "dD6o13sJihC8q5psPAwZK",
	"dialogBase": "_1lT5o765JyvzRhykRVd3qM",
	"input": "njUyFuEtuAUO0YMwUpTYB",
	"icon": "_1r6Q2mtBdNvL326tKVNSRV"
};