import gql from 'graphql-tag'


# MUTATIONS
export ProjectBuildingAreaAdd = gql """
	mutation (
		$projectID: ID!
		$name: String!
		$pza: Int!
		$nla: Int!
		$gla: Int!
	) {
		projectBuildingAreaAdd(
			projectID: $projectID
			name: $name
			pza: $pza
			nla: $nla
			gla: $gla
		) {
			id
			buildingsAreas {
				id
				modifiedAt
				name
				pza
				nla
				gla
			}
		}
	}
"""

export ProjectBuildingAreaEdit = gql """
	mutation (
		$projectID: ID!
		$name: String!
		$pza: Int!
		$nla: Int!
		$gla: Int!
		$id: ID!
	) {
		projectBuildingAreaEdit(
			projectID: $projectID
			name: $name
			pza: $pza
			nla: $nla
			gla: $gla
			id: $id
		) {
			id
			buildingsAreas {
				id
				modifiedAt
				name
				pza
				nla
				gla
			}
		}
	}
"""

