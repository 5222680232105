###
Add Group Modal
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import adopt from 'libs/adopt'
import moment from 'moment-mini'
import { convertToOptions } from 'libs/legal'

import { NotificationConsumer } from '../../../application/components/NotificationManager'
import { UserInfoConsumer } from 'libs/userInfo'

# Renderable
import { div, span, input, img } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import _BevyModal from '@bevy/modal'
BevyModal = React.createFactory _BevyModal

import {
	TextField as _TextField,
	TextAreaField as _TextAreaField,
	LoadableSelectField as _LoadableSelectField
} from 'components/FormItems'
TextField = React.createFactory _TextField
TextAreaField = React.createFactory _TextAreaField
LoadableSelectField = React.createFactory _LoadableSelectField


# Data
import {
	GetRoles
	AddGroup
	EditGroup
} from './data'


REFETCH_QUERIES = [
	'GroupsList'
	]

DataLayer = adopt
	queries: {}
	mutations:
		addGroup: ({setNotification, onUpdateComplete}) ->
			mutation: AddGroup
			refetchQueries: REFETCH_QUERIES
			onCompleted: (data) ->
				# onUpdateComplete data
				setNotification({content: 'Group added', appearance: 'success'})
			onError: (err) ->
				setNotification ({content: 'Error on creating Group', appearance: 'error'})
		updateGroup: ({setNotification, onUpdateComplete}) ->
			mutation: EditGroup
			onCompleted: (data) ->
				setNotification({content: 'Group edited', appearance: 'success'})
			onError: -> setNotification ({content: 'Error on editing Group', appearance: 'error'})

export default class AddGroupModal extends React.Component
	@propTypes =
		onClose: PropTypes.func.isRequired

	constructor: (props) ->
		super props
		@state = {
			...@props.initialSetup
			groupID: if @props.groupID? then @props.groupID
			}

	canProceed: =>
		mustHaveFields = [ 'name']
		everyRequiredFieldsFilled = _.every mustHaveFields, (field) => !_.isEmpty @state["#{field}"]
		madeAnyChange = if @props.initialSetup?
				_.some @props.initialSetup, (value, key) => value isnt @state["#{key}"]
			else
				true
		everyRequiredFieldsFilled and madeAnyChange

	getVariables4Mutation: =>
		variables = @state
		if @props.groupID
			variables.id = @props.groupID
		variables

	onCloseProxy: (appropriateMutation) =>
		if _.isFunction appropriateMutation
			appropriateMutation
				variables: @getVariables4Mutation()
				refetchQueries: ['GroupsList']
		@props.onClose()

	render: ->
		UserInfoConsumer {}, ({ability, me}) =>
			NotificationConsumer {}, ({setNotification}) =>
				DataLayer
					setNotification: setNotification
					onUpdateComplete: @props.onUpdateComplete
					, (operations) =>
						BevyModal
							onClose: @onCloseProxy
							header: if _.isEmpty @props.initialSetup then 'Add group' else 'Edit group'
							actions: [
								text: 'Save'
								isDisabled: !@canProceed()
								onClick: () => @onCloseProxy(
									if _.isEmpty @props.initialSetup
										operations.addGroup.mutation
									else
										operations.updateGroup.mutation
								)
							,
								text: 'Cancel'
								onClick: @onCloseProxy
							]
						,
							Fragment {},
								TextField
									label: 'Name'
									isRequired: true
									onChange: (value) => @setState name: value
									value: @state.name
								LoadableSelectField
									label: 'Roles'
									values:	@state.roles
									isMulti: true
									onChange: (values) => @setState roles:  _.map values, 'value'
									query:
										query: GetRoles #FIXME: NOT GLOBAL ROLES <=================================
										variables:
											pagination:
												limit: 20
									makeOptionsFromData: (data) =>
										if data?.permissionsModel? then convertToOptions (_.map data.permissionsModel.entityRoles, 'name') else []
									hasMore: @state.hasMoreRoles
									handleDataUpdate: (prev, fetchMoreResult) =>
										if _.isEmpty fetchMoreResult then @setState hasMoreRoles: false
										{
											...prev
											...fetchMoreResult
										}
								TextAreaField
									label: 'Description'
									onChange: (value) => @setState description: value
									value: @state.description
									minimumRows: 1
