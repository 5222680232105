###
Panel over content which slides from one side of the screen a.k.a. flyout
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import ComponentProxy from '@bevy/component-proxy'
# Renderable
import { div } from 'react-dom-factories'

Fragment = React.createFactory React.Fragment

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import _Blanket from '@bevy/blanket'
Blanket = React.createFactory _Blanket

# Styles
import styles from './index.styl'
import {
	arrowRight
} from 'react-icons-kit/feather'


export default class SidePanel extends React.Component
	@propTypes:
		active: PropTypes.bool
		closeComponent: PropTypes.oneOfType [
			PropTypes.element
			PropTypes.func
		]
		renderCloseButton: PropTypes.bool
		withBlanket: PropTypes.bool
		positionFixed: PropTypes.bool

	@defaultProps:
		active: true
		alignLeft: false
		renderCloseButton: true
		withBlanket: true
		positionFixed: false

	constructor: (props) ->
		super props
	render: ->
		Fragment {},
			if @props.withBlanket and @props.active
				Blanket
					onClick: @props.onBlanketClick
					active: @props.active
					className: if @props.positionFixed then styles.fixed
			div {
				className: cnames [
						styles.sidePanel
						if @props.positionFixed then styles.fixed
						if @props.alignLeft then styles.leftSide else styles.rightSide
						if @props.active
							if @props.alignLeft then styles.leftSideActive else styles.rightSideActive
						@props.className
					]
				style: @props.style
				},
					div {className: cnames [styles.insideContent, 'bevy-side-panel-inner']},
						do => if @props.renderCloseButton
							div {className: styles.close},
								ComponentProxy @props.closeComponent, Icon {icon: arrowRight, size: 20}
						@props.children
