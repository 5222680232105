import gql from 'graphql-tag'


export DeleteProjectUserGroups = gql """
	mutation($ids: [ID!]!) {
		projectUserGroupsDelete(
			ids: $ids
		) {
			id
		}
	}
"""
