import gql from 'graphql-tag'

export RootQuery = gql """
	query ProjectCompaniesList (
		$projectSlug: String!,
		$pagination: PaginationInput,
		$sort: OrganizationsSort!,
		){
		project(slug: $projectSlug) {
			id
			name
			organizationsCount
			organizations(sort: $sort, pagination: $pagination){
				id
				name
				type
				inUse(projectSlug: $projectSlug)
				abbreviation
				website
				hedquarters {
					street
					buildingNumber
					floorNumber
					flatNumber
					district
					postalCode
					city
				}
			}
		}
	}
"""
