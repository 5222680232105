###
Revenue HUB for project
###

# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import Loadable from 'react-loadable'
import { UserInfoConsumer } from 'libs/userInfo'

# Renderable
import { div, p, ul, li, small, h1, h2, h3, span } from 'react-dom-factories'

import _RouterTabs from '@bevy/router-tabs'
RouterTabs = React.createFactory _RouterTabs

import { Box as _SkeletonBox } from '@bevy/skeleton'
SkeletonBox = React.createFactory _SkeletonBox

import _ApplicationHeader from 'components/ApplicationHeader'
ApplicationHeader = React.createFactory _ApplicationHeader

import _SideNavigation from 'components/SideNavigation'
SideNavigation = React.createFactory _SideNavigation

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import { Query as _Query } from 'react-apollo'
Query = React.createFactory _Query

import _Spinner from '@bevy/spinner'
Spinner = React.createFactory _Spinner

import _HubLoading from 'components/HubLoading'
HubLoading = React.createFactory _HubLoading

import {
	Route as _Route
	Switch as _Switch
	Link as _Link
	Redirect as _Redirect
} from 'react-router-dom'
Route = React.createFactory _Route
Switch = React.createFactory _Switch
Link = React.createFactory _Link
Redirect = React.createFactory _Redirect

import _SideNavFiltersProvider, { SideNavFiltersConsumer } from 'libs/filters'
SideNavFiltersProvider = React.createFactory _SideNavFiltersProvider

# Views
OpportunitiesView = Loadable
	loader: -> `import(/* webpackChunkName: "revenue-opportunities" */'../ProjectRevenueOpportunities')`
	loading: -> Spinner {}

OpportunitiesCompareView = Loadable
	loader: -> `import(/* webpackChunkName: "revenue-opportunity-compare" */'../ProjectRevenueOpportunitiesCompare')`
	loading: -> Spinner {}

OpportunityView = Loadable
	loader: -> `import(/* webpackChunkName: "revenue-opportunity-view" */'../ProjectRevenueOpportunity')`
	loading: -> Spinner {}

ScenariosView = Loadable
	loader: -> `import(/* webpackChunkName: "revenue-scenarios" */'../ProjectRevenueScenarios')`
	loading: -> Spinner {}

ScenarioView = Loadable
	loader: -> `import(/* webpackChunkName: "revenue-scenario" */'../ProjectRevenueScenario')`
	loading: -> Spinner {}

ScenarioManageView = Loadable
	loader: -> `import(/* webpackChunkName: "revenue-scenario-manage" */'../ProjectRevenueScenarioManageView')`
	loading: -> Spinner {}

# Sidenav
OpportunitiesSideNav = Loadable
	loader: -> `import(/* webpackChunkName: "revenue-opportunities-sidenav" */'../ProjectRevenueOpportunities/accessory')`
	loading: -> Spinner {}

ScenariosSideNav = Loadable
	loader: -> `import(/* webpackChunkName: "revenue-scenarios-sidenav" */'../ProjectRevenueScenarios/accessory')`
	loading: -> Spinner {}


# Data
import { RootQuery } from './data'
# Styles
import styles from './index.styl'
import {
	book
	gitBranch
	barChart
} from 'react-icons-kit/feather'


export default class ProjectRevenue extends React.Component
	render: ->
		div {
			className: cnames [
				@props.className
				styles.page
			]
		},
			Query
				query: RootQuery
				variables:
					projectSlug: @props.match.params.project
			, ({error, loading, data}) =>
				HubLoading {
					loading
					error
					data
					hubKey: 'Revenue'
				},
					SideNavFiltersProvider @props,
						SideNavFiltersConsumer {}, ({setActiveFolder}) =>
							SideNavigation
								hub: 'Revenue'
								hubIcon: require 'assets/revenueHubIcon.svg'
								hubClassName: styles.backgroundColor
								linkClassName: styles.textColor
								project: if !loading then data.project.name
								applications: [
									label: 'Opportunities'
									link: "/projects/#{@props.match.params.project}/revenue/opportunities"
									otherLinks: ["/projects/#{@props.match.params.project}/revenue/opportunity"]
									icon: Icon { icon: book, size: 12}
									# counter: data.project.inboxCount
									onClick: -> setActiveFolder {}
								,
									label: 'Scenarios'
									link: "/projects/#{@props.match.params.project}/revenue/scenarios"
									otherLinks: ["/projects/#{@props.match.params.project}/revenue/scenario"]
									icon: Icon { icon: gitBranch, size: 12}
									# counter: data.project.registryCount
									onClick: -> setActiveFolder {}
								]
								accessoryNavigation:
									Switch {},
										Route
											path: '/projects/:project/revenue/(opportunities|opportunity)'
											component: OpportunitiesSideNav
										Route
											path: "/projects/:project/revenue/(scenarios|scenario)"
											component: ScenariosSideNav
							,
								Switch {},
									Route
										path: '/projects/:project/revenue/opportunities/compare/:compareID'
										exact: true
										component: OpportunitiesCompareView
									Route
										path: '/projects/:project/revenue/opportunity/:id/:offerid?/:mode?'
										exact: false
										component: OpportunityView
									Route
										path: '/projects/:project/revenue/opportunities/:id?'
										exact: false
										component: OpportunitiesView
									Route
										path: '/projects/:project/revenue'
										exact: true
										render: (props) ->
											Redirect to: "/projects/#{props.match.params.project}/revenue/opportunities/"
									Route
										path: '/projects/:project/revenue/scenario/:id?/manage/:offerid?'
										exact: false
										component: ScenarioManageView
									Route
										path: '/projects/:project/revenue/scenarios/:id?'
										exact: false
										component: ScenariosView
									Route
										path: '/projects/:project/revenue/scenario/:id/:offerid?/:mode?'
										exact: false
										component: ScenarioView

