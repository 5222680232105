###
shortcut delete modal
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import adopt from 'libs/adopt'

# Renderable
import { div } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import _BevyModal from '@bevy/modal'
BevyModal = React.createFactory _BevyModal

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

# Styles
import ErrorIcon from '@atlaskit/icon/glyph/error'

# Data
import {
	DeleteShortcut
} from './data'

DataLayer = adopt
	mutations: 
		deleteShortcut:
			mutation: DeleteShortcut

export default class DeleteShortcutModal extends React.Component
	@propTypes =
		onClose: PropTypes.func.isRequired

	constructor: (props) ->
		super props

	render: ->
		DataLayer {}, (operations) =>
			BevyModal {
				width: 'small'
				height: 180
				appearance: 'danger'
				onClose: @props.onClose
				header: 'Delete confirmation'
				headerIcon: ErrorIcon {size: 'medium', primaryColor: '#DE350B'}
				actions: [
					text: 'Delete'
					onClick: () =>
						operations.deleteShortcut.mutation
							variables:
								projectID: @props.projectID
								shortcutID: @props.shortcutID
						@props.onClose()
				,
					text: 'Cancel'
					onClick: () => @props.onClose()
				]
			},
				'Are you sure you want to delete this shortcut?'

