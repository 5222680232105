exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._328oKFckcqC0omt8zbEytX svg {\n  fill: #ffc400;\n  stroke: transparent;\n}\n._6WRWupHdNQR24ZztNUAwb {\n  color: #8993a4;\n}\n.OJ7lxd83iBiMEkZxWM6sR svg,\n.ZFXHgrOkSUEXMrujz1jKx svg {\n  fill: #0065ff;\n  stroke: transparent;\n}\n._3vKrLRAdEz-K-MGMrCfIDr {\n  color: #0065ff;\n}\n._3GRioI6uAklOAZvSXNzMje svg {\n  fill: #ff991f;\n  stroke: transparent;\n}\n._33HE1kaBvvOCUjWxTCGtPl svg {\n  fill: #ff5630;\n  stroke: transparent;\n}\n", ""]);
// Exports
exports.locals = {
	"favorite": "_328oKFckcqC0omt8zbEytX",
	"attachment": "_6WRWupHdNQR24ZztNUAwb",
	"fresh": "OJ7lxd83iBiMEkZxWM6sR",
	"checkBox": "ZFXHgrOkSUEXMrujz1jKx",
	"unread": "_3vKrLRAdEz-K-MGMrCfIDr",
	"warning": "_3GRioI6uAklOAZvSXNzMje",
	"danger": "_33HE1kaBvvOCUjWxTCGtPl"
};