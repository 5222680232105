exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._3z7kGiliLUqmMSvLSnMa2r {\n  margin-left: 4px;\n  font-size: 120%;\n  font-weight: bold;\n}\n.Ya9QZgIDwbv4vKFeHiU7x {\n  align-self: center;\n  min-width: 102px;\n  border-bottom: 1px solid transparent;\n  position: relative;\n  padding: 4px 0 3px 0;\n}\n.Ya9QZgIDwbv4vKFeHiU7x.UqdV0fQBWASzsjfRpGtGa {\n  color: #8993a4;\n  font-style: italic;\n  border-bottom: 1px solid #ff991f;\n}\n.Ya9QZgIDwbv4vKFeHiU7x._3Ve-M04B781oRTAoLsJn8V:hover {\n  color: #0052cc;\n  cursor: pointer;\n}\n.Ya9QZgIDwbv4vKFeHiU7x._2xnd_xHhXu1tOpzcTve4Gz .i2hQ20j7F5rq-IEl3J_lC {\n  opacity: 0.2;\n}\n.Ya9QZgIDwbv4vKFeHiU7x._2xnd_xHhXu1tOpzcTve4Gz:hover {\n  color: inherit;\n  cursor: wait;\n}\n._2YdPhkjNDqmCGMm75GWJmU {\n  min-width: 40px;\n}\n._2YdPhkjNDqmCGMm75GWJmU {\n  align-self: center;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n  border-bottom: 1px solid #ff991f;\n  display: block !important;\n}\n._2YdPhkjNDqmCGMm75GWJmU ::placeholder {\n  color: #8993a4;\n  font-style: italic;\n}\n._2YdPhkjNDqmCGMm75GWJmU input {\n  padding-bottom: 2px;\n}\n", ""]);
// Exports
exports.locals = {
	"base": "_3z7kGiliLUqmMSvLSnMa2r",
	"EditableName": "Ya9QZgIDwbv4vKFeHiU7x",
	"placeholder": "UqdV0fQBWASzsjfRpGtGa",
	"active": "_3Ve-M04B781oRTAoLsJn8V",
	"loading": "_2xnd_xHhXu1tOpzcTve4Gz",
	"value": "i2hQ20j7F5rq-IEl3J_lC",
	"input": "_2YdPhkjNDqmCGMm75GWJmU"
};