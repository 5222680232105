###
First substep of basic step
###

# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'

# Renderable
import { div, p, ul, li, small, h1, span, h2, img } from 'react-dom-factories'

import { Query as _Query } from 'react-apollo'
Query = React.createFactory _Query
Fragment = React.createFactory React.Fragment

import {
	LoadableSelect as _LoadableSelect
	components
} from '@bevy/select'
LoadableSelect = React.createFactory _LoadableSelect

import _Tooltip from '@bevy/tooltip'
Tooltip = React.createFactory _Tooltip

import { Field as _Field } from '@atlaskit/form'
Field = React.createFactory _Field

import _FieldTextArea from '@atlaskit/textarea'
FieldTextArea = React.createFactory _FieldTextArea

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

# Data
import { getOrganizations } from '../../data'
import trLegal from 'data/local/legal'

# Styles
import styles from './index.styl'
import { alertTriangle, info } from 'react-icons-kit/feather'

import _Debouncer from 'components/Debouncer'
Debouncer = React.createFactory _Debouncer

export default class DestinationForm extends React.Component
	@defaultProps =
		lockedCompany: false
	constructor: (props) ->
		super props
		if @props.lockedCompany? and !@props.value.company?
			@props.onChange { ...@props.value, company: @props.lockedCompany }
		@state =
			receivalDateIsInFuture: false

	convertToOptions: (items) ->
		if _.isArray items
			_.map items, (item) ->
				if item?
					label: item.name
					value: if item.id then item.id else item.name
		else if items.label? and items.value?
			items
		else
			label: items.name
			value: if items.id then items.id else items.name

	Menu: (props) =>
		components.Menu {
			..._.omit props, 'children'
			children:
				Fragment {},
					div {className: styles.customMenu},
						div {className: styles.selectDivider}, "SPV of #{@props.projectName}"
						div
							className: cnames styles.defaultSelectOption, if props.getValue()[0]?.id is @props.lockedCompany.id then styles.selected
							onClick: (e) => props.setValue @convertToOptions @props.lockedCompany
						,
							@props.lockedCompany.name
					div {className: styles.selectDivider}, 'Other companies'
					div {className: styles.customRegularBody}, props.children
		}
			#

	render: ->
		Fragment {},
			div {className: styles.form},
				do =>
					isMissing = @props.highlightMissingValues and (_.isEmpty @props.value.company)
					Field
						label: div {className: cnames styles.label, if isMissing then styles.missingLabel},
								trLegal.props.spv
								div {className: styles.iconTooltip},
									Tooltip
										content: 'Skanska Group which should receive this document'
									,
										Icon {icon: info, size: 9}
								if isMissing
									div {className: styles.missingInfo},
										'Company field have to be selected to register document'
						name: trLegal.props.spv
					, =>
						LoadableSelect
							className: styles.formItem
							placeholder:
								div {className: styles.placeholderText},
									"Select #{_.toLower trLegal.props.spv}..."
							menuPortalTarget: document.body
							isSearchable: true
							isClearable: true
							appearance: if isMissing then 'danger'
							query:
								query: getOrganizations
								variables:
									projectSlug: @props.projectSlug
									pagination:
										limit: 20
									filters: types: ['Internal']
									sort:
										key: 'Name'
										order: 'Ascending'
							components: {@Menu}
							makeOptionsFromData: (data) =>
								if data?.project?
									organizations = _.orderBy _.reject(data.project.organizations, id: @props.lockedCompany.id), (x) -> _.upperFirst x.name
									@convertToOptions organizations
								else
									[]
							values: if @props.value.company? and !_.isEmpty @props.value.company then [@convertToOptions @props.value.company]
							handleValueToDisplay: (currentValue, listOfPossibilities) =>
								if currentValue? then currentValue
							onChange: (item) =>
								if item?
									company = {id: item.value, name: item.label, __typename: 'Organization' }
								else
									company = {}
								@props.onChange { ...@props.value, company }
							hasMore: @state.hasMore
							handleDataUpdate: (prev, fetchMoreResult) =>
								if _.isEmpty fetchMoreResult.project.organizations
									@setState hasMore: false
								{
									...prev
									project: {
										...prev.project
										organizations: [...prev.project.organizations, ...fetchMoreResult.project.organizations]
									}
								}

			Field
				label: span {className: styles.label}, trLegal.props.notes
				name: trLegal.props.notes
			, =>
				div {className: styles.fieldText},
					Debouncer
						wait: 250
						onChange: (value) =>
							@props.onChange { ...@props.value, description: value }
						value: @props.value.description
					, ({onChange, value}) ->
						FieldTextArea
							placeholder: 'Add text... (optional)'
							className: styles.formItem
							onChange: onChange
							value: value
							shouldFitContainer: true
							minimumRows: 3
