###
Upload wrapper to prevent rerendering of page
TODO: prevent rerendering of page
make more abstract -> receive mutation + query
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import DataAttribute from '@bevy/data-attribute'
# Renderable
import { div } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import { Query as _Query, Mutation as _Mutation } from 'react-apollo'
Query = React.createFactory _Query
Mutation = React.createFactory _Mutation

import { NotificationConsumer } from '../../application/components/NotificationManager'


import _ProgressTracker from '@bevy/progress-tracker'
ProgressTracker = React.createFactory _ProgressTracker

import {
	UploadScans
	GetScansStatus
} from './data'

# Styles
import styles from './index.styl'

export default class UploadWrapper extends React.Component
	@propTypes: {}
	@defaultProps: {}
	constructor: (props) ->
		super props
		@state =
			uploadFiles: []


	handleUploadFiles: ({files, variables, onCompleted, setNotification, updateNotification, uploadScans}) =>
		@onCompleted = onCompleted

		uploadFiles = _.map files, (file) ->
				name: file.name #TODO: uniqID
				status: 'Uploading...'
				progress: true

		_.map files, (file) =>
			uploadScans
				variables: {
					scans: [file]
					...variables
				}

		@setState
			uploadFiles: uploadFiles
		, =>
			@uploadNotificationKey = setNotification
				autoDismiss: false
				content: 'Uploading scan(s)'
				footer: @renderUploadNotificationFooter()


	renderUploadNotificationFooter: () =>
		_.map @state.uploadFiles, (file) ->
			div {
				['data-test-id']: DataAttribute 'upload-scans-notification'
				key: file.name
				className: styles.notificationFooterItem
			},
				ProgressTracker
					header: file.name
					status: file.status
					progress: if file.progress? then file.progress else true

	render: ->
		NotificationConsumer {}, ({setNotification, updateNotification}) =>
			someFileBeingProcessed = _.some @state.uploadFiles, (file) -> file.ticketID?
			Query
				query: GetScansStatus
				variables: ids: _.compact _.map @state.uploadFiles, 'ticketID'
				skip: !someFileBeingProcessed
				pollInterval: if someFileBeingProcessed then 1000 else 0
				notifyOnNetworkStatusChange: true
				onCompleted: (data) =>
					finishedTickets = _.filter data.systemTicketsByIDs, status: 'Done'
					if (!_.isEmpty finishedTickets) and (someFileBeingProcessed)
						uploadFiles = _.map @state.uploadFiles, (file) ->
							if (file.ticketID?) and (_.some finishedTickets, (finishedTicket) -> finishedTicket.id is file.ticketID)
								{...file, ticketID: undefined, progress: 100, status: 'Finished!'}
							else
								file
						@setState
							uploadFiles: uploadFiles
						, =>
							# update notification to show which files finished uploading/processing
							if !(_.every uploadFiles, (file) -> file.progress is 100)
								updateNotification
									key: @uploadNotificationKey
									footer: @renderUploadNotificationFooter()
							# finish
							else
								updateNotification
									key: @uploadNotificationKey
									content: 'Finished uploading scan(s)'
									footer: @renderUploadNotificationFooter()
									autoDismiss: true
							@onCompleted()
				, ({loading, data, error}) =>
					Mutation
						mutation: UploadScans
						onCompleted: (data) =>
							uploadFiles = _.map @state.uploadFiles, (file) ->
									if (file.name is data.legalDocumentCreateByScans[0].filename)
										{...file, ticketID: data.legalDocumentCreateByScans[0].id, status: 'Processing...'}
									else
										file
							@setState
								uploadFiles: uploadFiles
							, =>
								updateNotification
									key: @uploadNotificationKey
									footer: @renderUploadNotificationFooter()
					, (uploadScans) =>
						@props.children
							uploadFiles: (args) =>
								@handleUploadFiles {
									...args
									setNotification: setNotification
									updateNotification: updateNotification
									uploadScans: uploadScans
									}
