###
Filters bar is bar with possible filters
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import { UserInfoConsumer } from 'libs/userInfo'
import * as legalHelpers from 'libs/legal'

# Renderable
import { div, span, input } from 'react-dom-factories'

Fragment = React.createFactory React.Fragment

import _Select, {components, LoadableSelect as _LoadableSelect} from '@bevy/select'
Select = React.createFactory _Select
LoadableSelect = React.createFactory _LoadableSelect

import _Button from '@bevy/button'
Button = React.createFactory _Button

import _DatesRangeSelector from '@bevy/dates-range-selector'
DatesRangeSelector = React.createFactory _DatesRangeSelector

# Styles
import styles from './index.styl'

# Data
import trLegal from 'data/local/legal'
import {
 ListOptions
} from './data'

emptyQuickFiltersState =
	openDropList: null
	currentAgeValue: 'allAge'
	currentPendingValue: 'requireAction'
	expiresAfter: null
	expiresBefore: null
	receivedAfter: null
	receivedBefore: null
	createdAfter: null
	createdBefore: null
	paperCreatedAfter: null
	paperCreatedBefore: null

export default class FiltersBar extends React.Component
	@propTypes: {}
	@defaultProps: {}

	constructor: (props) ->
		super props
		initialState = {
			content: null
			searchValue: ''
			...emptyQuickFiltersState
		}
		if props.currentFilters?.hasReview?
			if props.currentFilters.hasReview
				initialState.currentPendingValue = 'awaitingApproval'
			else
				initialState.currentPendingValue = 'incomplete'
		@state = initialState

	handleValueToDisplay: (currentFilter, listOfPossibilities, me) ->
		itemToDisplay = _.find listOfPossibilities, (item) -> item.value is currentFilter
		if itemToDisplay?
			label: itemToDisplay.label
			value: itemToDisplay.value
		else if currentFilter is me.id
			label: me.name
			value: me.id

	BuildingMenu: (props) =>
		div {className: styles.customMenu},
			div {className: styles.highlightItem},
				Button
					shouldFitContainer: true
					spacing: 'compact'
					appearance: 'primary'
					onClick: (value) => props.setValue props.options
				, 'Select all'
			div {className: styles.customMenuContent},
				components.Menu props, props.children

	AssigneeMenu: (props, me) =>
		div {className: styles.customMenu},
			div {className: styles.highlightItem},
				Button
					shouldFitContainer: true
					spacing: 'compact'
					appearance: 'primary'
					onClick: (value) =>
							props.setValue [value: me.id, label: me.name]
				, 'Me'
			div {className: styles.customMenuContent},
				components.Menu props, props.children

	makeQuery4LoadableSelect: (property) =>
		query: ListOptions
		variables:
			projectSlug: @props.projectSlug
			property: property
			filters:
				states: ['Commited']
			pagination:
				limit: 50

	renderAdvanced: (me) =>
		rows = [
				header: 'Identifier'
				filters: [
						placeholder: trLegal.props.type
						query: 'Form'
						name: 'forms'
					,
						placeholder: trLegal.props.topic
						query: 'Topic'
						name: 'topics'
					,
						placeholder: trLegal.props.number
						query: 'Number'
						name: 'number'
					,
						placeholder: trLegal.props.caseNumber
						query: 'CaseNumber'
						name: 'caseNumber'
					,
					# 	placeholder: trLegal.props.NIPNumber
					# 	query: 'NIPNumber'
					# 	name: 'nipNumber'
					# ,
						placeholder: trLegal.props.mainContract
						query: 'MainContract'
						name: 'mainContract'
				]
			,
				header: 'Entities'
				filters: [
						placeholder: trLegal.props.source
						name: 'from'
						query: 'Correspondent'
					,
						placeholder: trLegal.props.assignee
						name: 'assignees'
						query: 'Assignee'
						components:
							Menu: (props) => @AssigneeMenu props, me
					,
						placeholder: trLegal.props.recipient
						name: 'recipient'
						query: 'Recipient'
					,
						placeholder: trLegal.props.entities
						name: 'participant'
						query: 'Participant'
				]
			,
				header: 'Project'
				filters: [
						placeholder: trLegal.props.building
						name: 'buildings'
						query: 'Building'
						components: { Menu: @BuildingMenu }
					,
						placeholder: trLegal.props.landRegisterNumber
						name: 'landRegisterNumber'
						query: 'LandRegisterNumber'
					,
						placeholder: trLegal.props.parcelNumber
						name: 'parcelNumber'
						query: 'ParcelNumber'
				]
			,
				header: 'Legal'
				filters: [
						placeholder: trLegal.props.KRSNumber
						name: 'KRSNumber'
						query: 'KRSNumber'
					,
						placeholder: trLegal.props.VatNumber
						name: 'vatNumber'
						query: 'VatNumber'
					,
						placeholder: trLegal.props.precinctNumber
						name: 'precintNumber'
						query: 'PrecintNumber'
					,
						placeholder: trLegal.props.REGONNumber
						name: 'REGONNumber'
						query: 'REGONNumber'
				]
		]

		datePickerRows = [
				header: 'Document date'
				filters: ['paperCreatedAfter', 'paperCreatedBefore']
			,
				header: 'Expiration date'
				filters: ['expiresAfter', 'expiresBefore']
			,
				header: 'Received on'
				filters: ['receivedAfter', 'receivedBefore']
			,
				header: 'Registered on'
				filters: ['createdAfter', 'createdBefore']
		]

		div {className: styles.advancedFilters},
			div {className: styles.section},
				_.map datePickerRows, (row) =>
					div	{className: styles.datePickersRow, key: row.header},
						div {className: styles.header}, row.header
						DatesRangeSelector
							calendars: _.map row.filters, (filter) =>
								name: filter
								value: @props.currentFilters[filter]
							onChange: (filters) => @props.onChange query: filters

			div {className: styles.separator}

			div {className: styles.section},
				_.map rows, (row) =>
					div
						className: styles.filterRow
						style: gridTemplateColumns: "55px repeat(#{_.size row.filters}, 148px)"
						key: row.header
					,
						div {className: styles.header}, row.header
						_.map row.filters, (filter, index) =>
							if !filter.isEmpty?
								commonProps =
									className: styles.input
									key: index
									placeholder: filter.placeholder
									components: filter.components
									isMulti: true
									hideSelectedOptions: false
									onChange: (values) =>
										filters = {}
										filters[filter.name] = _.map values, (item) -> item.value
										@props.onChange query: filters
								if filter.query?
									LoadableSelect {
										...commonProps
										query: @makeQuery4LoadableSelect filter.query
										values: @props.currentFilters[filter.name]
										handleValueToDisplay: (currentFilter, listOfPossibilities) =>
											@handleValueToDisplay currentFilter, listOfPossibilities, me
										hasMore: @state["hasMore_#{filter.name}"]
										makeOptionsFromData: (data) ->
											if data?.project?
												legalHelpers.convertToOptions data.project.legalDocumentsPropertyOptions
											else
												[]
										handleDataUpdate: (prev, fetchMoreResult) =>
											if _.isEmpty fetchMoreResult.project.legalDocumentsPropertyOptions
												@setState ["hasMore_#{filter.name}"]: false
											{
												...prev
												project: {
													...prev.project
													legalDocumentsPropertyOptions: [...prev.project.legalDocumentsPropertyOptions, ...fetchMoreResult.project.legalDocumentsPropertyOptions]
												}
											}
									}
								else
									Select {
										...commonProps
										options: filter.options
										value: _.map @props.currentFilters[filter.name], (currentFilter) =>
											@handleValueToDisplay(currentFilter, filter.options, me)
									}

							else
								div {key: index}

	render: ->
		Fragment {},
			div {className: styles.list},
				UserInfoConsumer {}, ({me}) =>
					@renderAdvanced(me)
			div {className: styles.clear},
				Button {
					# isDisabled: !@props.onClear?
					onClick: () => @props.onChange query: {}
					tooltip: 'Clear all filters and restore default'
				}, 'Clear filters'
