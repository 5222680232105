import _ from 'lodash'

import styles from '../index.styl'

export default ({data, selected, logAs, me, ability}) ->
	members = _.get data, 'projectUserGroup.members'
	selectedMembers = _.filter members, ({user}) -> _.some selected, (id) -> id is user.id
	if !_.isEmpty data
		className: styles.toolbar
		primary:
			isDisabled: !ability.can 'create', _.get(data, 'projectUserGroup'), 'member'
			content: 'Add group members'
			onClick: => @setState activeModal: 'add'
		secondary: _.compact [
			if !_.isEmpty(selected) and _.every selectedMembers, (member) -> ability.can 'delete', member.user
				key: 'delete'
				content: 'Delete group members'
				isDisabled: _.isEmpty selected
				onClick: => @setState activeModal: 'delete'
		,
			if _.size(selected) is 1 and (_.first(selected) isnt me.id) and ability.can 'logAs', 'User'
				key: 'logAs'
				content: 'Login as'
				onClick: => logAs _.pick _.head(selectedMembers).user, ['id', 'name']
				'data-test-id': 'toolbar-button-logAs-user'
		]
