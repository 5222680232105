exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._38ljhrr1P6MJ0BUEiF1v-7 {\n  flex-direction: column;\n  align-items: center;\n  position: relative;\n}\n._38ljhrr1P6MJ0BUEiF1v-7:hover {\n  cursor: pointer;\n}\n._38ljhrr1P6MJ0BUEiF1v-7 .AcV-r5vrxmHTw0BCFIEV4 {\n  display: flex;\n  justify-content: center;\n  width: 100%;\n  min-height: 30px;\n}\n._38ljhrr1P6MJ0BUEiF1v-7 ._2ainqODAkPoOXNx4v6FyvU {\n  position: absolute;\n  bottom: 10px;\n  left: 10px;\n  display: flex;\n  align-items: center;\n  padding: 5px 0;\n  background-color: rgba(220,220,220,0.8);\n  width: calc(100% - 20px);\n}\n._38ljhrr1P6MJ0BUEiF1v-7 ._2ainqODAkPoOXNx4v6FyvU .yA6xi6p3-4kzb5BkJMp3C {\n  margin: 0 5px;\n}\n._38ljhrr1P6MJ0BUEiF1v-7 ._2ainqODAkPoOXNx4v6FyvU ._2jONmIW6VJ_AYrt4oQY2A3 {\n  overflow: hidden;\n  margin: 0 5px;\n}\n._38ljhrr1P6MJ0BUEiF1v-7 ._2ainqODAkPoOXNx4v6FyvU .pdftSPRMrMTV-Lx2LVlAN {\n  font-size: 80%;\n}\n._38ljhrr1P6MJ0BUEiF1v-7 ._2ainqODAkPoOXNx4v6FyvU .hLME3AN-291O_a9FADBSF {\n  font-size: 75%;\n  color: #7a869a;\n}\n._38ljhrr1P6MJ0BUEiF1v-7 ._2ainqODAkPoOXNx4v6FyvU .pdftSPRMrMTV-Lx2LVlAN,\n._38ljhrr1P6MJ0BUEiF1v-7 ._2ainqODAkPoOXNx4v6FyvU .hLME3AN-291O_a9FADBSF {\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n._38ljhrr1P6MJ0BUEiF1v-7 ._2ainqODAkPoOXNx4v6FyvU .e25EXYrveZhbbvCX8Yt7c {\n  margin-left: auto;\n}\n._1oGuKGr8R7AEkvHIT5jIHi {\n  margin: 10px;\n  min-width: 100px;\n}\n._1oGuKGr8R7AEkvHIT5jIHi canvas {\n  box-shadow: rgba(9,30,66,0.25) 0px 4px 8px -2px, rgba(9,30,66,0.31) 0px 0px 1px;\n}\n._1oGuKGr8R7AEkvHIT5jIHi ._2UUKrV6L72lmEmVHsVqK-m {\n  display: none;\n}\n._3pNm2ZKkJVwYuEZ3kKq_JI {\n  display: flex;\n  align-items: center;\n}\n", ""]);
// Exports
exports.locals = {
	"base": "_38ljhrr1P6MJ0BUEiF1v-7",
	"preview": "AcV-r5vrxmHTw0BCFIEV4",
	"detail": "_2ainqODAkPoOXNx4v6FyvU",
	"icon": "yA6xi6p3-4kzb5BkJMp3C",
	"info": "_2jONmIW6VJ_AYrt4oQY2A3",
	"title": "pdftSPRMrMTV-Lx2LVlAN",
	"description": "hLME3AN-291O_a9FADBSF",
	"actions": "e25EXYrveZhbbvCX8Yt7c",
	"pdfDocument": "_1oGuKGr8R7AEkvHIT5jIHi",
	"react-pdf__Page__annotations": "_2UUKrV6L72lmEmVHsVqK-m",
	"pdfPreview": "_3pNm2ZKkJVwYuEZ3kKq_JI"
};