import _ from 'lodash'
import React from 'react'

# Renderable
import { div } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import {BevyModalsWrapper as _BevyModalsWrapper} from '@bevy/modal'
BevyModalsWrapper = React.createFactory _BevyModalsWrapper

import _AddGroupMembersModal from 'parts/Groups/AddGroupMembersModal'
AddGroupMembersModal = React.createFactory _AddGroupMembersModal

import _DeleteGroupMembersModal from 'parts/Groups/DeleteGroupMembersModal'
DeleteGroupMembersModal = React.createFactory _DeleteGroupMembersModal

export default getModals = ({selected}) ->
	BevyModalsWrapper {},
		switch @state.activeModal
			when 'add'
				AddGroupMembersModal
					groupID: @props.id
					onClose: => @setState activeModal: null
			when 'delete'
				DeleteGroupMembersModal
					groupID: @props.id
					usersIDs: @state.selected
					onClose: =>
						@setState
							activeModal: null
							selected: []
