###
Component (for notification) with "progressable" content (determinate or not)
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
# Renderable
import { div } from 'react-dom-factories'

Fragment = React.createFactory React.Fragment

# Styles
import styles from './index.styl'

export default class ProgressTracker extends React.Component
	@propTypes: {}
	@defaultProps: {}
	constructor: (props) ->
		super props

	# shouldComponentUpdate: (nextProps) ->
	# 	if @props.progress? and @props.progress is nextProps.progress
	# 		false
	# 	else
	# 		true

	render: ->
		div {className: styles.base},
			div {className: styles.headerBar},
				div {className: cnames if !@props.headerWrap then styles.header}, @props.header
				if  @props.status?
					div {className: styles.status},
						if _.isNumber @props.status
							"#{@props.status}%"
						else
							@props.status
			div {className: styles.progress},
				#TODO: progress bar component?
				div {className: styles.progressBar},
					if _.isNumber @props.progress
						div
							className: cnames styles.progressBarColor, styles.determinate
							style: width: "#{@props.progress}%"
					else
						div {className: cnames styles.progressBarColor, styles.indeterminate}
