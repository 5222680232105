import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'

export default class Debouncer extends React.Component
	@propTypes:
		wait: PropTypes.number
		options: PropTypes.object
		children: PropTypes.func.isRequired

	@defaultProps =
		wait: 500
		options: {}
	constructor: (props) ->
		super props
		@state =
			value: @props.value
		@debouncedOnChange = _.debounce	() =>
			@props.onChange @state.value
		, @props.wait, @props.options

	render: =>
		@props.children
			onChange: (e) =>
				@setState value: e.target.value
				@debouncedOnChange {}
			value: @state.value
