import gql from 'graphql-tag'

# MUTATIONS
export DeleteUsersFromGroups = gql """
	mutation($id: ID!, $addedEntities: [MemberInputAdd!], $removedEntities: [MemberInputRemove!]) {
		projectUserGroupUpdateMembers(id: $id, addedEntities: $addedEntities, removedEntities: $removedEntities) {
			id
		}
	}
"""
