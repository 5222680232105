# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import DataAttribute from '@bevy/data-attribute'
import PropTypes from 'prop-types'

import { div, p, ul, li, small, h1, h2, h3, span, img, button } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import _Lozenge from '@atlaskit/lozenge'
Lozenge = React.createFactory _Lozenge

import _Tooltip from '@atlaskit/tooltip'
Tooltip = React.createFactory _Tooltip

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import { Link as _Link } from 'react-router-dom'
Link = React.createFactory _Link

import styles from '../../index.styl'

import {
	emptySelection
	activeSelection
	chevronDown
	chevronUp
} from '@bevy/theme/icons'

export default class MasterDetailGroup extends React.Component
	@propTypes:
		title: PropTypes.oneOfType([
			PropTypes.node
			PropTypes.func
		]).isRequired
		subtitle: PropTypes.oneOfType([
			PropTypes.node
			PropTypes.func
		])
		href: PropTypes.string
		status: PropTypes.shape
			name: PropTypes.string.isRequired
			appearance: PropTypes.oneOf [
				'default'
				'inprogress'
				'moved'
				'new'
				'removed'
				'success'
			]
			isBold: PropTypes.bool
		indicator: PropTypes.shape
			name: PropTypes.node.isRequired
			icon: PropTypes.shape.isRequired
			className: PropTypes.string
	@defaultProps:
		isCollapsable: true
	constructor: (props) ->
		super props
		@state = {
			isCollapsed: false
		}
	render: ->
		Fragment {},
			div {className: cnames @props.className, styles.MasterDetailGroup},
				div {className: styles.topBar},
					div {className: styles.title}, @props.title
					if @props.accessory?
						div {className: styles.accessory}, @props.accessory
					if @props.status?
						div {className: styles.lozenge},
							Lozenge {
								..._.omit @props.status, 'name'
							}, @props.status.name
				div {className: styles.bottomBar},
					if @props.subtitle? then div {className: styles.subtitle}, @props.subtitle
					if @props.isCollapsable
						div {
							className: styles.chevron
							onClick: () => @setState isCollapsed: !@state.isCollapsed
						},
							if @state.isCollapsed then Icon {...chevronDown} else Icon {...chevronUp}
			if !@state.isCollapsed
				@props.children
