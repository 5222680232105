import _ from 'lodash'

import styles from '../index.styl'

export default ({data, selected, logAs, me, ability}) ->
	members = _.get data, 'detailsQuery.data.userGroup.members'
	firstSelectedMember = _.find members, ({user}) -> user.id is _.head selected
	if !_.isEmpty data.detailsQuery.data
		className: styles.toolbar
		primary:
			content: 'Add group members'
			isDisabled: !ability.can 'create', 'UserGroup', 'member'
			onClick: => @setState activeModal: 'add'
		secondary: _.compact [
			if !_.isEmpty(selected) and ability.can 'delete', 'UserGroup', 'member'
				key: 'delete'
				content: 'Delete group members'
				isDisabled: _.isEmpty selected
				onClick: => @setState activeModal: 'delete'
		,
			if _.size(selected) is 1 and (_.first(selected) isnt me.id) and ability.can 'logAs', 'User'
				key: 'logAs'
				content: 'Login as'
				onClick: => logAs _.pick firstSelectedMember.user, ['id', 'name']
				'data-test-id': 'toolbar-button-logAs-user'
		]
