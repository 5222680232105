# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import qs from 'qs'

import { div } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import _Button from '@bevy/button'
Button = React.createFactory _Button

import _MasterDetail from '@bevy/master-detail'
MasterDetail = React.createFactory _MasterDetail

import _EmptyState from '@atlaskit/empty-state'
EmptyState = React.createFactory _EmptyState

import _GroupDetail from 'parts/Groups/GroupDetail'
GroupDetail = React.createFactory _GroupDetail

import styles from '../index.styl'

export default ({data, selected}) ->
	skipList = @props.location.search? and qs.parse(@props.location.search.replace /^\?/i, '').full?
	matchedDocument = @props.match.params.id
	MasterDetail {
		loading: data.rootQuery.loading
		selected: selected
		onItemEvent:
			onClick: ({event, item}) =>
				if event.shiftKey
					previousState = selected
					nextState =
						if item.id in previousState
							_.without previousState, item.id
						else
							_.concat previousState, item.id
					nextState = _.uniq _.compact nextState
					if _.size(nextState) is 1
						@props.history.push "/groups/#{_.first nextState}#{@props.location.search}"
					else
						@props.history.push "/groups#{@props.location.search}"
					@setState selected: nextState
					event.preventDefault()
					event.stopPropagation()
					event.nativeEvent.stopImmediatePropagation()
				else
					@setState selected: []
		listHidden: skipList
		loadMore: ->
			console.log 'Fetching'
		hasMore: false
		# isListCollapsable: false
		groups:
			if data.rootQuery.loading or !data.rootQuery.data?.userGroups? then []
			else
				collections = _.partition data.rootQuery.data.userGroups, (group) => (_.size group.members) > 0
				[
					key: 'hasMembers'
					title: 'In use'
					accessory: "#{_.size collections[0]} groups"
					# subtitle: 'Groups in use'
				,
					key: 'empty'
					title: 'Empty'
					accessory: "#{_.size collections[1]} groups"
				]
		items: if !data.rootQuery.loading and data.rootQuery.data?.userGroups?
			userGroups = _.partition data.rootQuery.data.userGroups, (group) => (_.size group.members) > 0
			_.map (_.concat userGroups[0], userGroups[1]), (group) =>
				group: if !_.isEmpty group.members then 'hasMembers' else 'empty'
				id: group.id
				href: "/groups/#{group.id}#{@props.location.search}"
				uuid:
					"#{_.size group.members} Member#{if (_.size group.members) is 1 then '' else 's'}"
				title: group.name
				owner:
					src: group.picture.mini
				# teaser: 'group description'
	},
		if _.size(selected) > 1
			EmptyState
				header: 'Multiple groups selected'
				description: 'With mutliple groups select you can perform batch actions'
				imageUrl: require 'assets/empty-states/multiple.svg'
				maxImageWidth: 300
				maxImageHeight: 300
				# primaryAction: Button {appearance: 'primary'}, 'Compare'
				# secondaryAction: Button {isDisabled: true}, 'Archive'
		else if !matchedDocument?
			EmptyState
				header: 'Please select one or more groups'
				imageUrl: require 'assets/empty-states/box.png'
				maxImageWidth: 300
				maxImageHeight: 300
		else
			GroupDetail
				id: @props.match.params.id
				route: '/groups/:id'
