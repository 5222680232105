###
List of groups in project
###

# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import DataAttribute from '@bevy/data-attribute'
import adopt from 'libs/adopt'
import qs from 'qs'

import { NotificationConsumer } from '../../application/components/NotificationManager'
import { UserInfoConsumer } from 'libs/userInfo'

# Renderable
import { div } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import _ContentController from '@bevy/content-controller'
ContentController = React.createFactory _ContentController

import {InternalErrorState as _InternalErrorState} from 'components/ErrorDisplay'
InternalErrorState = React.createFactory _InternalErrorState

# Data
import {
	RootQuery
} from './data'
DataLayer = adopt
	queries:
		rootQuery: ({projectSlug}) ->
			query: RootQuery
			variables:
				projectSlug: projectSlug

# Styles
import styles from './index.styl'

# Content controller elements
import getToolbar from './elements/toolbar'
import getModals from './elements/modals'
import getMasterDetail from './elements/master-detail'

export default class GroupsView extends React.Component
	constructor: (props) ->
		super props
		@state =
			activeModal: null
			selected: []
			activeSearch: null

	getToolbar: getToolbar
	getModals: getModals
	getMasterDetail: getMasterDetail

	render: ->
		NotificationConsumer {}, ({ setNotification }) =>
			UserInfoConsumer {}, ({ ability, me, logAs }) =>
				skipList = @props.location.search? and qs.parse(@props.location.search.replace /^\?/i, '').full?
				matchedDocument = @props.match.params.id
				selected = _.compact _.uniq _.concat @state.selected, matchedDocument
				DataLayer {
					setNotification
					projectSlug: @props.match.params.project
					skip: skipList
					selected: selected
				}, (data) =>
					if data.rootQuery.error?
						InternalErrorState {}
					else
						projectName = _.get data, 'rootQuery.data.project.name'
						Fragment {},
							ContentController
								pageId: "#{if projectName? then projectName else 'project'} groups list"
								className: cnames styles.page
								toolbar: @getToolbar {data, selected, ability}
								contentClassName: styles.content
								paneClassName: styles.pane
								views:
									master: @getMasterDetail {data, selected, projectSlug: @props.match.params.project}
							@getModals {data, selected}


