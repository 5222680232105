import _ from 'lodash'
import React from 'react'

# Renderable
import { div } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import {BevyModalsWrapper as _BevyModalsWrapper} from '@bevy/modal'
BevyModalsWrapper = React.createFactory _BevyModalsWrapper

import _ModalEdit from './../../../parts/Users/GlobalEditUserModal'
ModalEdit = React.createFactory _ModalEdit

export default getModals = ({selected, members}) ->
	BevyModalsWrapper {},
		if @state.activeModal is 'edit'
			tmp = _.first @state.selected
			ModalEdit {
				id: _.first @state.selected
				onClose: =>
					@setState activeModal: null
			}
