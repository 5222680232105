###
document reassign modal
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import adopt from 'libs/adopt'
import { getRefetchQueries } from 'libs/legal'

import { UserInfoConsumer } from 'libs/userInfo'
import { NotificationConsumer } from '../../../application/components/NotificationManager'

# Renderable
import { div, span } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment
import DataAttribute from '@bevy/data-attribute'

import _BevyModal from '@bevy/modal'
BevyModal = React.createFactory _BevyModal

import _Button from '@bevy/button'
Button = React.createFactory _Button

import {Field as _Field} from '@atlaskit/form'
Field = React.createFactory _Field

import _Select, {LoadableSelect as _LoadableSelect} from '@bevy/select'
Select = React.createFactory _Select
LoadableSelect = React.createFactory _LoadableSelect

import _Avatar from '@atlaskit/avatar'
Avatar = React.createFactory _Avatar

import _ErrorDisplay from 'components/ErrorDisplay'
ErrorDisplay = React.createFactory _ErrorDisplay

# Data
import { ListUsersAndDocumentOwner, ReassignDocuments } from './data'
import trLegal from 'data/local/legal'

# Styles
import styles from './index.styl'

REFETCH_QUERIES = [
	'DMS_Inbox_Accessory',
	'DMS_Registry_Accessory',
	'ProjectLegalDocumentPreview'
	]

DataLayer = adopt
	queries:
		listUsers: ({projectSlug}) ->
			query: ListUsersAndDocumentOwner
			variables:
				projectSlug: projectSlug
	mutations:
		reassignDocuments: ({setNotification, app}) ->
			mutation: ReassignDocuments
			refetchQueries: getRefetchQueries REFETCH_QUERIES, app
			onCompleted: () ->
				setNotification({content: 'Document changed owner', appearance: 'success'})
			onError: (error) ->
				setNotification
					content: trLegal.error.actionFailure
					appearance: 'error'

export default class DocumentReassignModal extends React.Component
	@propTypes =
		onClose: PropTypes.func.isRequired

	constructor: (props) ->
		super props
		@state =
			currentAssignee: null

	handleAssigneeChange: (assignee) =>
		@setState
			currentAssignee: assignee

	MenuList: (props) =>
		Fragment {},
			if !props.selectProps.value? or (props.selectProps.me.id isnt props.selectProps.value.id)
				Button
					shouldFitContainer: true
					onClick: () =>
						props.setValue()
						@handleAssigneeChange props.selectProps.me
				, "Me (#{props.selectProps.me.name})"
			components.MenuList {...props},
				props.children

	render: ->
		UserInfoConsumer {}, ({ability, me}) =>
			NotificationConsumer {}, ({setNotification}) =>
				DataLayer
					projectSlug: @props.projectSlug
					setNotification: setNotification
					app: @props.app
				, (operations) =>
					BevyModal
						'data-test-id': 'reassign-document-modal'
						width: 'small'
						height: 200
						onClose: @props.onClose
						header: 'Change owner'
						actions: [
							text: 'Reassign'
							isDisabled: _.isEmpty @state.currentAssignee
							onClick: () =>
								operations.reassignDocuments.mutation
									variables:
										documentIDs: @props.documentIDs
										assignee:
											type: @state.currentAssignee.__typename
											id: @state.currentAssignee.id
								@props.onClose()
						,
							text: 'Cancel'
							onClick: @props.onClose
						]
					,
						ErrorDisplay { query: operations.listUsers }, () =>
							availableOptions = if operations.listUsers.data? then _.map operations.listUsers.data.project.members, (member) -> member.user
							if @state.currentAssignee?
								availableOptions = _.reject availableOptions,
							Field {label: 'Assignee', name: 'assignee'}, =>
								LoadableSelect
									menuPortalTarget: document.body
									isSearchable: true
									spacing: 'compact'
									placeholder: 'Select assignee'
									me: me
									options: _.orderBy availableOptions, 'name'
									formatOptionLabel: (option) ->
										div {className: styles.option},
											div {className: styles.avatar},
												Avatar
													src: option.picture.mini
													size: 'xsmall'
											span {}, option.name
									getOptionLabel: (option) -> option.name
									getOptionValue: (option) -> option
									onChange: (value) => @handleAssigneeChange value
									values: if @state.currentAssignee? then [@state.currentAssignee] else null
									handleValueToDisplay: (currentValue, listOfPossibilities) ->
										if currentValue? then currentValue
									query:
										query: ListUsersAndDocumentOwner
										variables:
											projectSlug: @props.projectSlug
									makeOptionsFromData: (data) =>
										options = _.orderBy _.map(_.get(data, 'project.members', []), (member) -> member.user), 'name'
										options = _.reject options, (option) => option.id is @state.currentAssignee
										_.uniqBy options, 'id'
									hasMore: @state.hasMore
									handleDataUpdate: (prev, fetchMoreResult) =>
										if _.isEmpty fetchMoreResult.project.members
											@setState hasMore: false
										{
											...prev
											project: {
												...prev.project
												members: [...prev.project.members, ...fetchMoreResult.project.members]
											}
										}
