import gql from 'graphql-tag'

# QUERIES
export GetOptions = gql """
	query GetUserOptions($userID: ID!, $projectsID: [ID!], $projectID: ID!) {
		projectPermissions: permissionsModel(model: Project) {
			membersRoles {
				name
				models
				displayName
			}
		}
		projectByID(id: $projectID) {
			id
			isMultiphase
			phases { id name }
		}
		user(id: $userID) {
			...on Person {
				id
				name
				email
				memberships(types: [Project], ids: $projectsID) {
					...on ProjectAccess {
						project {
							id
							slug
							name
						}
						roles {
							displayName
							name
							info
						}
					}
				}
			}
		}
	}
"""

# MUTATIONS
export UpdateUser = gql '''
	mutation($userID: ID!, $addedProjects: [MemberInputAdd!], $removedProjects: [MemberInputRemove!], $projectsID: [ID!]) {
		userUpdateProjectMembership(
			id: $userID
			addedProjects: $addedProjects
			removedProjects: $removedProjects
		)
	{
		... on Person {
			id
			memberships(types: [Project], ids: $projectsID) {
				...on ProjectAccess {
					project {
						id
						slug
						name
					}
					roles {
						name
						info
					}
				}
			}
		}
	}
}
'''
