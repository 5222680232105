import _ from 'lodash'
import React from 'react'

import { div } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import {
	add
} from '@bevy/theme/icons'
import styles from '../index.styl'

export default ({organizations, selected, ability, me, projectID}) ->
	selectedOrganizations = _.filter organizations, (organization) -> _.some selected, (selectedID) -> selectedID is organization.id
	selectedOrganizations = _.map selectedOrganizations, (organization) -> {...organization, projectID: projectID}
	primary = if ability.can 'create', 'Organization'
		key: 'add'
		content: 'New'
		iconBefore: Icon add
		onClick: => @setState activeModal: 'add'
		'data-test-id': 'toolbar-button-add-new-company'
	secondary = [
		if ((_.size selected) is 1) and !_.isEmpty(selectedOrganizations) and (ability.can 'update', selectedOrganizations[0])
			key: 'edit'
			content: 'Edit'
			onClick: => @setState activeModal: 'edit'
			'data-test-id': 'toolbar-button-edit-company'
	,
		if (!_.isEmpty selected) and _.every selectedOrganizations, (org) -> ability.can('delete', org) and
		(_.every selectedOrganizations , (org) -> org.inUse is false or ((_.size selected) is 1))
			key: 'delete'
			content: 'Delete'
			onClick: => if _.every selectedOrganizations , (org) -> org.inUse is true then @setState activeModal: 'listToDelete' else @setState activeModal: 'delete'
			'data-test-id': 'toolbar-button-delete-company'
	]
	primary: primary
	secondary: _.compact secondary

