###
First substep of basic step
###

# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import moment from 'moment-mini'
import { convertToOptions } from 'libs/legal'

# Renderable
import { div, p, ul, li, small, h1, span, h2, img } from 'react-dom-factories'

import { Query as _Query } from 'react-apollo'
Query = React.createFactory _Query
Fragment = React.createFactory React.Fragment

import _Select, {components} from '@bevy/select'
Select = React.createFactory _Select

import {
	# LoadableCreatableSelect as _LoadableCreatableSelect
	LoadableSelect as _LoadableSelect
	NoOptionsMessage
} from '@bevy/select'
# LoadableCreatableSelect = React.createFactory _LoadableCreatableSelect
LoadableSelect = React.createFactory _LoadableSelect

import _Tooltip from '@bevy/tooltip'
Tooltip = React.createFactory _Tooltip

import { Field as _Field } from '@atlaskit/form'
Field = React.createFactory _Field

import { DatePicker as _DatePicker } from '@atlaskit/datetime-picker'
DatePicker = React.createFactory _DatePicker

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

# Data
import {
	getOrganizations
	getBuildingsSets
} from '../../data'
import trLegal from 'data/local/legal'

# Styles
import styles from './index.styl'
import { alertTriangle, info } from 'react-icons-kit/feather'


export default class OriginForm extends React.Component
	constructor: (props) ->
		super props
		@state =
			receivalDateIsInFuture: false

	render: ->
		div {},
			div { className: cnames styles.sectionHorizontal },
				Field
					label: span {className: styles.label}, trLegal.props.receivalDate
					name: trLegal.props.receivalDate
				, =>
					div {className: cnames styles.formItem, styles.datePicker},
						DatePicker
							className: styles.formItem
							id: 'ReceivedDataPicker'
							isInvalid: @state.receivalDateIsInFuture
							dateFormat: 'DD/MM/YYYY'
							parseInputValue: (value, dateFormat) -> moment(value, dateFormat).toDate()
							onChange: (value) =>
								@props.onChange {...@props.value, receivalDate: moment.utc(value, 'YYYY-MM-DD').unix() }
								@setState receivalDateIsInFuture: !moment.utc(value, 'YYYY-MM-DD').isBefore(moment.utc())
							value: if @props.value.receivalDate? then moment.unix(@props.value.receivalDate).format('YYYY-MM-DD')
				Field
					label: span {className: styles.label}, trLegal.props.creationDate
					name: trLegal.props.creationDate
				, =>
					div {className: cnames styles.formItem, styles.datePicker},
						DatePicker
							className: styles.formItem
							id: 'CreationDataPicker'
							dateFormat: 'DD/MM/YYYY'
							parseInputValue: (value, dateFormat) -> moment(value, dateFormat).toDate()
							onChange: (value) =>
								@props.onChange {...@props.value, creationDate: moment.utc(value, 'YYYY-MM-DD').unix() }
							value: if @props.value.creationDate? then moment.unix(@props.value.creationDate).format('YYYY-MM-DD')
			if @state.receivalDateIsInFuture
				div {className: styles.alert},
					Icon {className: styles.icon, icon: alertTriangle, size: 13}
					'Selected receival date is in the future'

			do =>
				isMissingSource = @props.highlightMissingValues and (_.isEmpty @props.value.source)
				isMissingPhases = @props.highlightMissingValues and (_.isEmpty @props.value.phases)
				Fragment {},
					if @props.isMultiphase
						Query
							query: getBuildingsSets
							variables:
								projectSlug: @props.projectSlug
						, ({ loading, data }) =>
							options = convertToOptions _.get data, 'project.phases'
							isDisabled = @props.isMultiphase and _.size(options) is 1
							isMissingPhases = @props.highlightMissingValues and (_.isEmpty @props.value.phases) and _.size(options) isnt 1
							Field
								label:
									div {className: cnames styles.label, if isMissingPhases then styles.missingLabel},
										'Phase'
										if isDisabled
											div {className: styles.iconTooltip},
												Tooltip
													content: 'You have access to only one project phase'
												,
													Icon {icon: info, size: 9}
										if isMissingPhases
											div {className: styles.missingInfo},
												'Phase(s) have to be selected to register document'
								name: 'phases'
							, =>
								Select
									isMulti: true
									hideSelectedOptions: false
									resetMultiFormatting: true
									value: @props.value.phases
									isDisabled: isDisabled
									appearance: if isMissingPhases then 'danger'
									placeholder:
										div {className: styles.placeholderText},
											if isDisabled
												_.head(options).label
											else
												'Select phases...'
									onChange: (value) =>
										@props.onChange { ...@props.value, phases: value }
									options: options
					Field
						label:
							div {className: cnames styles.label, if isMissingSource then styles.missingLabel},
								trLegal.props.source
								div {className: styles.iconTooltip},
									Tooltip
										content: 'Office or company from which document was received or send'
									,
										Icon {icon: info, size: 9}
								if isMissingSource
									div {className: styles.missingInfo},
										'Contractors field have to be selected to register document'

						name: 'Source'
					, =>
						LoadableSelect
							menuPortalTarget: document.body
							isSearchable: true
							isClearable: true
							placeholder:
								div {className: styles.placeholderText},
									"Select #{_.toLower trLegal.props.source}..."
							appearance: if isMissingSource then 'danger'
							query:
								query: getOrganizations
								variables:
									projectSlug: @props.projectSlug
									pagination:
										limit: 20
									sort:
										key: 'Name'
										order: 'Ascending'
							makeOptionsFromData: (data) =>
								if data?.project?
									organizations = _.orderBy data.project.organizations, (x) -> _.upperFirst x.name
									options = convertToOptions organizations
									if !_.isEmpty @props.temporaryOrganizations
										options = _.concat options, @convertToOptions @props.temporaryOrganizations
									_.map options, (option) ->
										{
											...option
											abbreviation: _.get (_.find organizations, (organization) -> option.value is organization.id), 'abbreviation'
										}
								else
									[]
							values: if !_.isEmpty @props.value.source then convertToOptions([@props.value.source])
							handleValueToDisplay: (currentValue, listOfPossibilities) =>
								if currentValue? then currentValue
							onChange: (item) =>
								if item?
									newSource = {id: item.value, name: item.label, __typename: 'Organization', abbreviation: item.abbreviation, fromContractor: true}
								else
									@setState createdExternalCompany: {}
									newSource = {}
								@props.onChange { ...@props.value, source: newSource, deleteContractor: true}
							hasMore: @state.hasMore
							handleDataUpdate: (prev, fetchMoreResult) =>
								if _.isEmpty fetchMoreResult.project.organizations
									@setState hasMore: false
								{
									...prev
									project: {
										...prev.project
										organizations: [...prev.project.organizations, ...fetchMoreResult.project.organizations]
									}
								}
							noOptionsMessage: () =>
								if @props.projectLeader?
									NoOptionsMessage
										fieldName: trLegal.props.source
										contactPerson: {...@props.projectLeader, role: 'Project Leader'}
							onCreateOption: (value) =>
								newSource = {name: value}
								@props.onChange { ...@props.value, source: newSource}
								@props.onTemporaryCreate newSource
			if (!_.isEmpty @state.createdExternalCompany) and (!_.isEmpty @props.value.source) and (@props.value.source is @state.createdExternalCompany)
				div {className: styles.alert},
					Icon {className: styles.icon, icon: alertTriangle, size: 13}
					'New organization will be created'
