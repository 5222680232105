"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.scopes = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _helpers = require("../helpers");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// Generated by CoffeeScript 2.4.1

/*
Platform permissions model
*/
var scopes = (0, _helpers.makeDefaultSubject)('Platform', {
  Production: {
    actions: 'read',
    fields: 'production'
  },
  Staging: {
    actions: 'read',
    fields: 'staging'
  },
  Test: {
    actions: 'read',
    fields: 'test'
  },
  Development: {
    actions: 'read',
    fields: 'development'
  },
  Local: {
    actions: 'read',
    fields: 'local'
  }
});
exports.scopes = scopes;
var _default = {
  name: 'Platform',
  actions: ['crud', 'create', 'read', 'update', 'delete'],
  // Alias for create, read, update, delete
  scopes,
  // Additional fields requested when fetching items for attached and members roles
  projection: {},
  // Type of models that can become a member of this model without specified role
  accepts: [],
  // Entity based roles default and assignable
  roles: {
    // Roles granted to all users
    default: [{
      name: 'S360 platform user',
      description: 'All basic permissions to use S360 platform',
      permissions: [{
        scopes: [scopes.Production]
      }]
    }],
    // Roles assignable to the entity that will be transfered to the User
    attached: [],
    // Roles assignable to the members (Users) of this entity
    members: []
  }
};
exports.default = _default;