###
Modal wrapper for atlaskit modal
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import DataAttribute from '@bevy/data-attribute'

# Renderable
import { div, span } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import _Modal, {
	ModalHeader as _ModalHeader
	ModalFooter as _ModalFooter
	ModalTransition as _ModalTransition
} from '@atlaskit/modal-dialog'
Modal = React.createFactory _Modal
ModalHeader = React.createFactory _ModalHeader
ModalFooter = React.createFactory _ModalFooter

# Styles
import styles from './index.styl'
import {
	alertCircle
} from 'react-icons-kit/feather'

export BevyModalsWrapper = _ModalTransition
export default class BevyModal extends React.Component
	@propTypes =
		onClose: PropTypes.func

	constructor: (props) ->
		super props

	componentDidMount: ->
		document.addEventListener 'keydown', @handleCloseByEsc
	componentWillUnmount: ->
		document.removeEventListener 'keydown', @handleCloseByEsc

	handleCloseByEsc: (event) =>
		if event.key is 'Escape' then @props.onClose()

	renderHeader: () =>
		ModalHeader {},
			div {},
				div {className: styles.modalHeader},
					if @props.headerIcon?
						div {className: cnames styles.headerIcon}, @props.headerIcon
					@props.header
				if @props.headerSub?
					div {className: styles.headerSub}, @props.headerSub

	render: ->
		Modal {
			shouldCloseOnEscapePress: false
			shouldCloseOnOverlayClick: false
			..._.omit @props, ['header', 'note']
			header:
				if _.isString(@props.header) or _.isString(@props.headerSub)
					@renderHeader
				else
					@props.header
		},
			div {
				'data-test-id': DataAttribute @props['data-test-id']
				className: styles.modalContent},
				@props.children
				if @props.note?
					div {className: styles.modalNote},
						if _.isString @props.note
							Fragment {},
								Icon {icon: alertCircle, size: 12}
								div {className: styles.text},
									"Note: #{@props.note}"
						else @props.note
