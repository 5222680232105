exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._19D2CaCIATeOzHtyTWfSOF {\n  margin: 0;\n}\n._19D2CaCIATeOzHtyTWfSOF ._35MHr8AX3AqYNdBXWnIQjX {\n  background: #fff;\n  padding: 0 32px;\n}\n._19D2CaCIATeOzHtyTWfSOF ._35MHr8AX3AqYNdBXWnIQjX ._5IVgdq3WRwad5HN9Xyp-9 {\n  padding: 8px;\n  border-bottom: 1px solid #f4f5f7;\n}\n._19D2CaCIATeOzHtyTWfSOF ._35MHr8AX3AqYNdBXWnIQjX.EJvk9vHDFGdFZaWcfUp0b ._5IVgdq3WRwad5HN9Xyp-9 {\n  padding: 4px 8px;\n}\n._19D2CaCIATeOzHtyTWfSOF._2bgByZTwQiDa-S0GvVVfbT .EJvk9vHDFGdFZaWcfUp0b {\n  padding: 0;\n  border-radius: 5px 5px 0 0;\n  margin-left: 32px;\n  margin-right: 32px;\n}\n._19D2CaCIATeOzHtyTWfSOF._2bgByZTwQiDa-S0GvVVfbT .EJvk9vHDFGdFZaWcfUp0b ._5IVgdq3WRwad5HN9Xyp-9 {\n  padding: 8px;\n}\n._19D2CaCIATeOzHtyTWfSOF.KiNBaSKOPplPJBzjvyYFq .J_DZEMRN_5OyfhSEJ6mvS {\n  padding: 0;\n  border-radius: 5px 5px 0 0;\n  margin-left: 32px;\n  margin-right: 32px;\n}\n._19D2CaCIATeOzHtyTWfSOF.KiNBaSKOPplPJBzjvyYFq .J_DZEMRN_5OyfhSEJ6mvS ._5IVgdq3WRwad5HN9Xyp-9 {\n  padding: 8px;\n}\n._19D2CaCIATeOzHtyTWfSOF.KiNBaSKOPplPJBzjvyYFq.Qs2V9QlbByOVl2TnuIAa1 .J_DZEMRN_5OyfhSEJ6mvS {\n  margin-left: 0;\n  margin-right: 0;\n}\n._19D2CaCIATeOzHtyTWfSOF.KiNBaSKOPplPJBzjvyYFq.Qs2V9QlbByOVl2TnuIAa1 ._35MHr8AX3AqYNdBXWnIQjX {\n  padding-left: 0;\n  padding-right: 0;\n}\n._19D2CaCIATeOzHtyTWfSOF._2hQ0a1WUqTPe8-HEBRy91I ._5IVgdq3WRwad5HN9Xyp-9 {\n  padding: 2px;\n}\n._19D2CaCIATeOzHtyTWfSOF._2hQ0a1WUqTPe8-HEBRy91I.EJvk9vHDFGdFZaWcfUp0b ._5IVgdq3WRwad5HN9Xyp-9 {\n  padding: 1px 2px;\n}\n._19D2CaCIATeOzHtyTWfSOF._2hQ0a1WUqTPe8-HEBRy91I._2bgByZTwQiDa-S0GvVVfbT .EJvk9vHDFGdFZaWcfUp0b ._5IVgdq3WRwad5HN9Xyp-9 {\n  padding: 2px;\n}\n._19D2CaCIATeOzHtyTWfSOF._2hQ0a1WUqTPe8-HEBRy91I.KiNBaSKOPplPJBzjvyYFq .J_DZEMRN_5OyfhSEJ6mvS ._5IVgdq3WRwad5HN9Xyp-9 {\n  padding: 2px;\n}\n", ""]);
// Exports
exports.locals = {
	"base": "_19D2CaCIATeOzHtyTWfSOF",
	"element": "_35MHr8AX3AqYNdBXWnIQjX",
	"elementContent": "_5IVgdq3WRwad5HN9Xyp-9",
	"toolbar": "EJvk9vHDFGdFZaWcfUp0b",
	"pageHeader": "_2bgByZTwQiDa-S0GvVVfbT",
	"documentHeader": "KiNBaSKOPplPJBzjvyYFq",
	"header": "J_DZEMRN_5OyfhSEJ6mvS",
	"embeddedHeader": "Qs2V9QlbByOVl2TnuIAa1",
	"compact": "_2hQ0a1WUqTPe8-HEBRy91I"
};