import gql from 'graphql-tag'

export ProjectBuildingAreaDelete = gql """
	mutation(
		$projectID: ID!
		$id: ID!
		) {
		projectBuildingAreaDelete(
			projectID: $projectID
			id: $id
		) {
			id
			buildingsAreas {
				id
				modifiedAt
				name
				pza
				nla
				gla
			}
		}
	}
"""
