exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._3Wg4Vhhe0HTh85zjpOROQQ {\n  display: flex;\n  flex-direction: column;\n  box-sizing: border-box;\n}\n._13CfIJH1VNaE0pnlEF8nSA {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.FzbZO3g3PXrw67hCLJ54h._3V47sK6xCjnI2kamOQaBPT {\n  padding-bottom: 0;\n}\n.FzbZO3g3PXrw67hCLJ54h ._11XACZ6wgc6c3Gjli_OSh2 {\n  background-color: #fafafb;\n}\n.FzbZO3g3PXrw67hCLJ54h ._11XACZ6wgc6c3Gjli_OSh2:hover {\n  background-color: #f4f5f7;\n  border-radius: 4px;\n  cursor: pointer;\n}\n.FzbZO3g3PXrw67hCLJ54h ._11XACZ6wgc6c3Gjli_OSh2._3V47sK6xCjnI2kamOQaBPT {\n  display: none;\n  cursor: grabbing;\n}\n._3dUX74_DVzM81CRndTKGsX {\n  margin: 15px;\n}\n._3zqCWwi7i9JGrDgSLsnzfo {\n  border-radius: 3px;\n  background: #f2f2f2;\n}\n", ""]);
// Exports
exports.locals = {
	"base": "_3Wg4Vhhe0HTh85zjpOROQQ",
	"addItem": "_13CfIJH1VNaE0pnlEF8nSA",
	"item": "FzbZO3g3PXrw67hCLJ54h",
	"isDragging": "_3V47sK6xCjnI2kamOQaBPT",
	"draggableItem": "_11XACZ6wgc6c3Gjli_OSh2",
	"arrow": "_3dUX74_DVzM81CRndTKGsX",
	"dragDummy": "_3zqCWwi7i9JGrDgSLsnzfo"
};