exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2zXMyKO45mL-F28XNYWp0t {\n  background-color: #f4f5f7;\n  display: flex;\n  height: 100vh;\n}\n._2zXMyKO45mL-F28XNYWp0t ._1WyqyffcWUZPSxc6hGvrm5 {\n  background-image: url(\"https://source.unsplash.com/1600x900/?building,skyscrapper\");\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: cover;\n  flex: 61.8%;\n}\n._2zXMyKO45mL-F28XNYWp0t ._2UZ3DN_jQdq-4D6bM2oA_X {\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  flex: 38.2%;\n  padding: 30px 80px;\n  box-sizing: border-box;\n  background-color: #fff;\n}\n._2zXMyKO45mL-F28XNYWp0t ._2UZ3DN_jQdq-4D6bM2oA_X ._3MOxD11-kgKzS-sfUXVjOm {\n  font-size: 1.3em;\n  height: auto;\n}\n._2zXMyKO45mL-F28XNYWp0t ._2UZ3DN_jQdq-4D6bM2oA_X ._3MOxD11-kgKzS-sfUXVjOm > span > span {\n  padding-top: 15px;\n  padding-bottom: 15px;\n}\n._2zXMyKO45mL-F28XNYWp0t ._2UZ3DN_jQdq-4D6bM2oA_X ._2CZNtqfrDupqf5_69T8iOD {\n  margin-bottom: 20px;\n}\n._2zXMyKO45mL-F28XNYWp0t ._2UZ3DN_jQdq-4D6bM2oA_X ._2CZNtqfrDupqf5_69T8iOD h1 {\n  font-size: 2em;\n  font-weight: 500;\n  margin-bottom: 0;\n}\n._2zXMyKO45mL-F28XNYWp0t ._2UZ3DN_jQdq-4D6bM2oA_X ._2CZNtqfrDupqf5_69T8iOD p {\n  font-size: 1.3em;\n  font-weight: 100;\n}\n@media screen and (max-width: 565px) {\n  ._2zXMyKO45mL-F28XNYWp0t {\n    flex-direction: column;\n  }\n  ._2zXMyKO45mL-F28XNYWp0t ._1WyqyffcWUZPSxc6hGvrm5 {\n    flex: 25%;\n  }\n  ._2zXMyKO45mL-F28XNYWp0t ._2UZ3DN_jQdq-4D6bM2oA_X {\n    flex: 75%;\n    justify-content: flex-start;\n    padding: 60px 20px;\n    font-size: 1.5rem;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"page": "_2zXMyKO45mL-F28XNYWp0t",
	"background": "_1WyqyffcWUZPSxc6hGvrm5",
	"loginWindow": "_2UZ3DN_jQdq-4D6bM2oA_X",
	"button": "_3MOxD11-kgKzS-sfUXVjOm",
	"welcomeMessage": "_2CZNtqfrDupqf5_69T8iOD"
};