import _ from 'lodash'
import React from 'react'

import { div } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import {
	add
} from '@bevy/theme/icons'
import styles from '../index.styl'

export default ({selected, ability, data}) ->
	userGroups = _.get data, 'rootQuery.data.userGroups'
	firstSelectedUserGroup = _.find userGroups, {id: _.head selected}
	primary =
		key: 'add'
		content: 'Add group'
		iconBefore: Icon add
		onClick: => @setState activeModal: 'add'
		'data-test-id': 'toolbar-button-add-new-group'
	secondary = _.compact [
		if _.size(selected) is 1 and firstSelectedUserGroup? and ability.can 'update', firstSelectedUserGroup
			key: 'edit'
			content: 'Edit'
			onClick: => @setState activeModal: 'edit'
			'data-test-id': 'toolbar-button-edit-group'
	,
		if _.size(selected) is 1 and firstSelectedUserGroup? and ability.can 'delete', firstSelectedUserGroup
			key: 'delete'
			content: 'Delete'
			onClick: => @setState activeModal: 'delete'
			'data-test-id': 'toolbar-button-delete-group'
	]
	{ primary, secondary }

