"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.scopes = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _helpers = require("../helpers");

var _LegalDocument = require("./LegalDocument");

var _LegalPath = require("./LegalPath");

var _Platform = require("./Platform");

var _Project = require("./Project");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

// Generated by CoffeeScript 2.4.1

/*
Helpers
*/
var fetchAllPaths, getPermissions4Paths;
fetchAllPaths = _lodash.default.once(function (db, cache) {
  return new Promise(async function (resolve, reject) {
    var collection;
    collection = await db.collection('legalPaths');
    return resolve((await collection.find({}, {
      projection: {
        permissions: 1
      }
    }).toArray()));
  });
});

getPermissions4Paths = function (name, scope4LegalDocuments, skipPaths) {
  return async function ({
    user,
    parent,
    membership,
    resolved,
    db,
    cache
  }) {
    /*
    TODO: Should be editablePaths = _.filter paths, isEditableContainer: true
    */
    var accessiblePaths, accessiblePathsIDs, editablePaths, pathCondition, paths;
    paths = await fetchAllPaths(db, cache);
    editablePaths = ['5c699b2c446b4586a759e44b']; // Tenants

    accessiblePaths = _lodash.default.filter(paths, function (path) {
      return path.permissions[name];
    });
    accessiblePathsIDs = _lodash.default.map(_lodash.default.filter(paths, function (path) {
      return path.permissions[name];
    }), '_id');
    pathCondition = skipPaths ? {} : {
      pathID: {
        $in: accessiblePathsIDs
      }
    };
    return _lodash.default.compact([{
      // ProjectScopes.ReadLegal
      // ProjectScopes.ReadPersonnel
      scopes: [_LegalDocument.scopes.Create]
    }, {
      scopes: [scope4LegalDocuments],
      conditions: _objectSpread({}, pathCondition)
    }, {
      // ,
      // 	...(
      // 		_.map _.intersection(editablePaths, accessiblePathsIDs), (editablePathID) ->
      // 			scopes: [
      // 				LegalPathScopes.Read
      // 			]
      // 			conditions:
      // 				ancestors: editablePathID
      // 	)
      scopes: [_LegalPath.scopes.Read, _LegalPath.scopes.Use],
      conditions: {
        $and: [skipPaths ? {} : {
          _id: {
            $in: _lodash.default.map(_lodash.default.filter(paths, function (path) {
              return path.permissions[name];
            }), '_id')
          }
        }]
      }
    }]);
  };
};
/*
UserGroup permissions model
*/


var scopes = (0, _helpers.makeDefaultSubject)('UserGroup', {
  Manage: {
    actions: 'crud'
  },
  Read: {
    actions: 'read'
  },
  Destroy: {
    actions: 'destroy'
  }
});
exports.scopes = scopes;
var _default = {
  // Name of the GraphQL subject
  name: 'UserGroup',
  // Database collection
  collection: 'userGroups',
  // Name used in making global id's
  globalIDName: 'userGroups',
  // Name used in GraphQL as AccessXXX field name
  accessProperty: 'group',
  // Available actions on root leve
  actions: ['crud', 'create', 'read', 'update', 'delete'],
  // Alias for create, read, update, delete
  // Model fields with appropiate actions
  fields: {},
  // Available scopes with implicit subject as a model.name
  scopes,
  // Additional fields requested when fetching items for attached and members roles
  projection: {},
  // Type of models that can become a member of this model without specified role
  accepts: ['User'],
  // Entity based roles default and assignable
  roles: {
    // Roles granted to all users
    default: [],
    // Roles assignable to the entity that will be transfered to the User
    attached: [{
      name: 'Skanska CDE Paths',
      permissions: getPermissions4Paths('', _LegalDocument.scopes.ReadCommited, true)
    }, {
      name: 'Legal Paths',
      permissions: getPermissions4Paths('legal', _LegalDocument.scopes.Read)
    }, {
      name: 'Controlling Paths',
      permissions: getPermissions4Paths('controlling', _LegalDocument.scopes.ReadCommited)
    }, {
      name: 'Accounting Paths',
      permissions: getPermissions4Paths('accounting', _LegalDocument.scopes.ReadCommited)
    }, {
      name: 'Taxes Paths',
      permissions: getPermissions4Paths('taxes', _LegalDocument.scopes.ReadCommited)
    }, {
      name: 'Facility Paths',
      permissions: getPermissions4Paths('facility', _LegalDocument.scopes.ReadCommited)
    }, {
      name: 'Marketing Paths',
      permissions: getPermissions4Paths('marketing', _LegalDocument.scopes.ReadCommited)
    }, {
      name: 'TQT Paths',
      permissions: getPermissions4Paths('tqt', _LegalDocument.scopes.ReadCommited)
    }, {
      name: 'Leasing Paths',
      permissions: getPermissions4Paths('leasing', _LegalDocument.scopes.ReadCommited)
    }, {
      name: 'Asset Management Paths',
      permissions: getPermissions4Paths('assetManagement', _LegalDocument.scopes.ReadCommited)
    }, {
      name: 'Skanska SA Paths',
      permissions: getPermissions4Paths('skanskaSA', _LegalDocument.scopes.ReadCommited)
    }, {
      name: 'Architects Paths',
      permissions: getPermissions4Paths('architects', _LegalDocument.scopes.ReadCommited)
    }, {
      name: 'Consultant Paths',
      permissions: getPermissions4Paths('consultants', _LegalDocument.scopes.ReadCommited)
    }, {
      name: 'Staging environment',
      description: 'Role with access to platform in <Staging> environment',
      permissions: function ({
        user,
        parent,
        membership,
        resolved,
        db,
        cache
      }) {
        return [{
          scopes: [_Platform.scopes.Staging]
        }];
      }
    }, {
      name: 'Test environment',
      description: 'Role with access to platform in <Test> environment',
      permissions: function ({
        user,
        parent,
        membership,
        resolved,
        db,
        cache
      }) {
        return [{
          scopes: [_Platform.scopes.Test]
        }];
      }
    }, {
      name: 'Development environment',
      description: 'Role with access to platform in <Development> environment',
      permissions: function ({
        user,
        parent,
        membership,
        resolved,
        db,
        cache
      }) {
        return [{
          scopes: [_Platform.scopes.Development]
        }];
      }
    }],
    // Roles assignable to the members (Users) of this entity
    members: []
  }
};
exports.default = _default;