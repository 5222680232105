###
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import moment from 'moment-mini'

# Renderable
import { div, span, input } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import { Field as _Field } from '@atlaskit/form'
Field = React.createFactory _Field

import _Select , {
	LoadableSelect as _LoadableSelect
	} from '@bevy/select'
Select = React.createFactory _Select
LoadableSelect = React.createFactory _LoadableSelect

import _FieldText from '@atlaskit/textfield'
FieldText = React.createFactory _FieldText

import _FieldTextArea from '@atlaskit/textarea'
FieldTextArea = React.createFactory _FieldTextArea

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import _Tooltip from '@bevy/tooltip'
Tooltip = React.createFactory _Tooltip

import { DatePicker as _DatePicker } from '@atlaskit/datetime-picker'
DatePicker = React.createFactory _DatePicker

import _Dropzone from 'react-dropzone'
Dropzone = React.createFactory _Dropzone

import _Button from '@bevy/button'
Button = React.createFactory _Button


# Styles
import styles from './index.styl'
import { info } from 'react-icons-kit/feather'

export Header = ({text}) ->
	div {className: styles.header}, text

export Separator = ({spacing}) ->
	div {className: cnames styles.separator, if spacing then styles.spacing}

export Inline = ({children, border, padding, proportions}) ->
	div {className: cnames styles.inline},
		_.map children, (child, key) ->
			div {
				style:
					flex: if proportions?[key] then proportions[key] else 1
				className: cnames styles.inlineElement, (if border then styles.border), (if padding then styles.padding)
			},
				child

export InfoBox = ({text, icon, className, button}) ->
	div {className: cnames styles.textBox, className},
		if icon?
			Icon
				className: styles.icon
				icon: icon,
				size: 16
		text
		if button?
			Button {
				className: styles.link
				href: button.link
				target: if button.target? then button.target else if button.link? then '_blank'
			}, button.text

export Label = ({label, tooltipContent, isRequired, flex}, children) ->
	if !label?
		div {}, children
	else
		Field
			label: span {className: styles.label},
				label
				if label is ' ' then div {style: minHeight: '9px'}
				if tooltipContent?
					div {className: cnames styles.iconTooltip, if isRequired then styles.moreMargin},
						Tooltip {content: tooltipContent}, Icon {icon: info, size: 9}
			name: label
			isRequired: isRequired
		, =>
			children


export CustomField = ({label, isRequired, tooltipContent, children }) ->
	Label {label, tooltipContent, isRequired},
		children

export TextField = ({label, isRequired, tooltipContent, onChange, value, isInvalid, errorMsg, flex, ...otherProps}) ->
	Label {label, tooltipContent, isRequired},
		Fragment {},
			div {className: cnames styles.fieldTextWrapper, styles.inlineAdditionalStyle, if isInvalid then styles.isInvalid },
				FieldText {
					value
					onChange: (e) => onChange e.target.value
					className: styles.fieldText
					isLabelHidden: true
					shouldFitContainer: true
					isInvalid: isInvalid
					...otherProps
				}
			if isInvalid and errorMsg
				div {className: styles.errorMsg}, errorMsg



export TextAreaField = ({label, isRequired, tooltipContent, onChange, value, isInvalid, ...otherProps}) ->
	Label {label, tooltipContent, isRequired},
		div {className: cnames  styles.fieldTextWrapper, (if !onChange? then styles.nonEditable), if isInvalid then styles.isInvalid },
			FieldTextArea {
				value
				onChange: (e) -> if onChange? then onChange e.target.value
				className: cnames styles.fieldText, if isInvalid then styles.warning
				shouldFitContainer: true
				minimumRows: 3
				isInvalid
				...otherProps
			}

export SelectField = ({label, isRequired, tooltipContent, options, onChange, value, isDisabled, isEditable = true, isMulti, ...otherProps}) =>
	Label {label, tooltipContent, isRequired},
		Select {
			options
			isMulti
			value:
				if isMulti
					_.map value, (val) -> _.find options, {value: val}
				else
					_.find options, (item) => item.value is value
			onChange: (item) =>
				if _.isArray item
					onChange _.map item, 'value'
				else if item?
					onChange item.value
				else if item is null
					onChange []
			isDisabled: isDisabled or !isEditable
			menuPortalTarget: document.body
			isSearchable: true
			hideSelectedOptions: false
			isClearable: true
			formatOptionLabel: (option) -> option.label
			getOptionLabel: (option) -> option.label
			getOptionValue: (option) -> option.value
			resetMultiFormatting: true
			...otherProps
		}

export LoadableSelectField = ({label, isRequired, tooltipContent, onChange, values, isMulti, ...otherProps}) =>
	Label {label, tooltipContent, isRequired},
		LoadableSelect {
			values
			onChange
			isMulti
			menuPortalTarget: document.body
			isSearchable: true
			resetMultiFormatting: if isMulti then true
			...otherProps
		}

export DatePickerField = ({label, isRequired, tooltipContent, onChange, value, defaultValue, ...otherProps}) =>
	Label {label, tooltipContent, isRequired},
		div {className: styles.datePicker},
			DatePicker {
				value: if value? then moment.unix(value).format('YYYY-MM-DD')
				# defaultValue: if defaultValue? then moment.unix(defaultValue).format('YYYY-MM-DD')
				onChange: (value) => onChange moment.utc(value, 'YYYY-MM-DD').unix(), 'seconds'
				placeholder: 'eg. 20/02/2020'
				dateFormat: 'DD/MM/YYYY'
				parseInputValue: (value, dateFormat) -> moment(value, dateFormat).toDate()
				...otherProps
			}

export FileField = ({label, isRequired, tooltipContent, ...props, onFile, fileType, multiple, children}) ->
	Label {label, tooltipContent, isRequired},
		Dropzone
			onDrop: onFile
			multiple: if multiple? then multiple else false
			accept: if fileType? then fileType
		, ({getRootProps, getInputProps, isDragActive, isDragReject}) =>
			triggerWrapper = (kids) ->
				div {..._.pick getRootProps(), ['onClick', 'onFocus']},
					input {
						onChange: (event) => onFile event.target.files
						...getInputProps()
					}
					kids
			div {..._.omit getRootProps(), ['onClick', 'onFocus', 'tabIndex']},
				children {isDragActive, isDragReject, triggerWrapper}
