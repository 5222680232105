import gql from 'graphql-tag'

export RootQuery = gql """
query ProjectUsersList(
	$projectSlug: String!
	$pagination: PaginationInput
	$search: String
	$sort: JSONObject
) {
	project(slug: $projectSlug) {
		id
		name
		isMultiphase
		phases { id name }
		members(pagination: $pagination, types: [User], search: $search, sort: $sort) {
			...on ActorAccess {
				user {
					... on Person {
						id
						name
						email
					}
				}
				roles {
					name
					info
					displayName
				}
			}
		}
	}
}
"""

