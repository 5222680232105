import * as feather from 'react-icons-kit/feather'
import * as oct from 'react-icons-kit/oct'
import {
	ic_fiber_manual_record
	ic_sort_by_alpha
	ic_lock
	ic_assignment_ind
	ic_place
	ic_attach_file
	ic_warning
	ic_error
	ic_check_box
} from 'react-icons-kit/md'

import {
	table as tableIcon
} from 'react-icons-kit/fa'

import {
	browser_layout_2
} from 'react-icons-kit/ikons'

import {
	horizontalMiddle
	left
	right
} from 'react-icons-kit/entypo'

import styles from './index.styl'

export emptySelection =
	icon: feather.circle
	size: 14
export activeSelection =
	icon: feather.checkCircle
	size: 14
export fresh =
	icon: feather.circle
	size: 12
	className: styles.fresh
export unread =
	icon: feather.circle
	size: 12
	className: styles.unread
export favorite =
	icon: feather.star
	size: 16
	className: styles.favorite

export key =
	icon: feather.award
	size: 16
export search =
	icon: feather.search
	size: 16
export filter =
	icon: feather.filter
	size: 16
export quickFilters =
	icon: feather.zap
	size: 16


export more =
	icon: feather.moreHorizontal
	size: 16

export sort =
	icon: ic_sort_by_alpha
	size: 16

export download =
	icon: feather.download
	size: 16
export print =
	icon: feather.printer
	size: 16
export refresh =
	icon: feather.refreshCw
	size: 16
export settings =
	icon: feather.settings
	size: 16

export alert =
	icon: feather.alertTriangle
	size: 16

export chevronDown =
	icon: feather.chevronDown
	size: 16
export chevronUp =
	icon: feather.chevronUp
	size: 16

export goBack =
	icon: feather.chevronLeft
	size: 24

export close =
	icon: feather.x
	size: 24

export add =
	icon: feather.plus
	size: 16

export	layers =
	icon: feather.layers
	size: 16

export viewSettings =
	icon: feather.layout
	size: 16

# export masterDetail =
# 	icon: feather.sidebar
# 	size: 16
# export table =
# 	icon: tableIcon
# 	size: 16

export accessory =
	icon: feather.sidebar
	size: 16
export data =
	icon: feather.grid
	size: 16


export viewBoth =
	icon: feather.sidebar
export viewLeft =
	icon: left
export viewRight =
	icon: right


export compareExclude =
	icon: feather.eyeOff
	size: 14
export compareOpen =
	icon: feather.externalLink
	size: 14
export compareSetAsBaseline =
	icon: feather.barChart
	size: 14


export editorBold =
	icon: feather.bold
	size: 14
export editorItalic =
	icon: feather.italic
	size: 14
export editorUnderline =
	icon: feather.underline
	size: 14
export editorList =
	icon: feather.list
	size: 14


export visibleItem =
	icon: feather.eye
	size: 14
export invisibleItem =
	icon: feather.eyeOff
	size: 14

export attachedItem =
	icon: feather.zap
	size: 14
export detachedItem =
	icon: feather.zapOff
	size: 14

export locked =
	icon: ic_lock
	size: 14

export checked =
	icon: feather.checkSquare
	size: 14
export unchecked =
	icon: feather.square
	size: 14

export anchor =
	icon: ic_assignment_ind
	size: 16

export local =
	icon: ic_place
	size: 16

export attachment =
	icon: ic_attach_file
	size: 16
	className: styles.attachment

export warning =
	icon: ic_error
	size: 16
	className: styles.warning
export danger =
	icon: ic_warning
	size: 16
	className: styles.danger

export checkBox =
	icon: ic_check_box
	size: 16
	className: styles.checkBox
