import gql from 'graphql-tag'

export MuteDocuments = gql """
	mutation($documentIDs: [ID!]!, $isMuted: Boolean!) {
		legalDocumentsUpdateMute(documentIDs: $documentIDs, value: $isMuted) {
			id
			isMuted
			isUrgent
			isFresh
			project {
				id
				slug
			}
			state
		}
	}
"""

export CacheUpdateQuery = gql """
	query($projectSlug: String!, $filters: LegalDocumentsFilters){
		project(slug: $projectSlug) {
			id
			legalDocumentsCount(filters: $filters)
		}
	}
"""
