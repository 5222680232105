exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._3pB-hEar4PDhiEUv8C_Ovu {\n  border-bottom: 1px solid #808080;\n  padding: 12px 16px;\n}\n._3H_7BqlEQ5HFOQuTluBvVX {\n  display: inline-block;\n}\n.DBRSBGKmWCKIFVeRz_OC- {\n  vertical-align: top;\n}\n._1kwKB93cclvmGJ9AGRUfVV {\n  width: 150px;\n}\n.On1r3X2QnOGJaJcW9ioS1 {\n  float: right;\n}\n._3SxksEa_z3L5-kDD721bXL {\n  font-weight: 900;\n  padding-top: 30px;\n  font-size: 1.45rem;\n  padding-bottom: 20px;\n}\n._2G05UlLR_r3lF-u6gRGCg4 {\n  background-color: #f4f5f7;\n  padding: 10px 0 10px 10px;\n  width: 160px;\n  border-radius: 4px;\n}\n._1pOiGXH4YRjVkUPRIBZ0qK {\n  font-weight: 600;\n  font-size: 1.18rem;\n}\n._3nBVVJV71h3sTG_7v6MPGT {\n  font-weight: 300;\n  font-size: 1rem;\n  color: #505f79;\n  margin-top: 2px;\n}\n._2aOAxW2kD6Q8rbm4tlPGm4 {\n  margin-right: 10px;\n}\n._2y87MU5w_BETgarF7X5QDd {\n  padding-top: 7px;\n  color: #808080;\n}\n", ""]);
// Exports
exports.locals = {
	"padding": "_3pB-hEar4PDhiEUv8C_Ovu",
	"inlineBlock": "_3H_7BqlEQ5HFOQuTluBvVX",
	"alignTop": "DBRSBGKmWCKIFVeRz_OC-",
	"tableWidth": "_1kwKB93cclvmGJ9AGRUfVV",
	"floatRight": "On1r3X2QnOGJaJcW9ioS1",
	"info": "_3SxksEa_z3L5-kDD721bXL",
	"greyBackground": "_2G05UlLR_r3lF-u6gRGCg4",
	"leaderName": "_1pOiGXH4YRjVkUPRIBZ0qK",
	"projectLeader": "_3nBVVJV71h3sTG_7v6MPGT",
	"marginRight": "_2aOAxW2kD6Q8rbm4tlPGm4",
	"more": "_2y87MU5w_BETgarF7X5QDd"
};