import gql from 'graphql-tag'

export ListOptions = gql """
	query SelectOptions (
		$projectSlug: String!,
		$property: LegalDocumentsSortKey!,
		$filters: LegalDocumentsFilters,
		$pagination: PaginationInput,
		$search: String
	){
		project(slug: $projectSlug) {
			id
			legalDocumentsPropertyOptions(filters: $filters, property: $property, pagination: $pagination, search: $search)
		}
	}
"""
