###
Project entrypoint view
TODO: Replace image with real content
###

# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import moment from 'moment-mini'
import {ILITEAPI} from 'libs/geoportal.js'
import { UserInfoConsumer } from 'libs/userInfo'

# Renderable
import { div, p, ul, li, img, span, iframe, a } from 'react-dom-factories'

Fragment = React.createFactory React.Fragment

import { Query as _Query, Mutation as _Mutation} from 'react-apollo'
Query = React.createFactory _Query
Mutation = React.createFactory _Mutation

import _Button from '@bevy/button'
Button = React.createFactory _Button

import _ErrorDisplay from 'components/ErrorDisplay'
ErrorDisplay = React.createFactory _ErrorDisplay

import _EmptyState from '@atlaskit/empty-state'
EmptyState = React.createFactory _EmptyState

import _Spinner from '@bevy/spinner'
Spinner = React.createFactory _Spinner

import _Tooltip from '@atlaskit/tooltip'
Tooltip = React.createFactory _Tooltip

import _DnDList from '@bevy/dnd-list'
DnDList = React.createFactory _DnDList

import _FieldText from '@atlaskit/textfield'
FieldText = React.createFactory _FieldText

import _EditableItem from '@bevy/editable-item'
EditableItem = React.createFactory _EditableItem

import { ButtonGroup as _ButtonGroup } from '@atlaskit/button'
ButtonGroup = React.createFactory _ButtonGroup

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon
import { NotificationConsumer } from '../../application/components/NotificationManager'

import _RouterTabs from '@bevy/router-tabs'
RouterTabs = React.createFactory _RouterTabs

import _Tabs from '@atlaskit/tabs'
Tabs = React.createFactory _Tabs

import {
	Map as _Map
	Marker as _Marker
	GoogleApiWrapper
	} from 'google-maps-react'
Map = React.createFactory _Map
Marker = React.createFactory _Marker

# Data
import {
	RootQuery,
	DocumentsQuery,
	ProjectManagementUpdateMembers
	ProjectBuildingAreaReorder
} from './data'
import trLegal from 'data/local/legal'

# Styles
import styles from './index.styl'
import { plus, x, edit2 } from 'react-icons-kit/feather'

# Elements
import getModals from './elements/modals'
import getTable from './elements/table'

GOOGLE_MAPS_API_KEY = 'AIzaSyCqSxJpQ3CTy2RlTtoU-oYf2Z9Fxltd1ZI'
ITEMS_PER_PAGE = 100

export class ProjectView extends React.Component
	constructor: (props) ->
		super props
		@state =
			activeModal: null

	getModals: getModals
	getTable: getTable

	# LOGIC
	getMembersOfManagmentType: (members, acceptableRoles) ->
		result = []
		for acceptableRole in acceptableRoles
			for member in members
				if _.some member.roles, (role) -> role.name is acceptableRole
					result.push member
		_.uniqBy result, 'user.id'

	# SUBCOMPONENTS
	renderColumn: ({header, content, className}) ->
		div {className: cnames styles.section, className},
			header
			content

	renderTable: ({headers, buildingsAreas, className, mutateBuildingTable, project, ability}) =>
		div {className: cnames className},
			div { className: cnames styles.grid, styles.table},
				_.map headers, (columnHeader, index) ->
					span {key: index, className: styles.title}, columnHeader
				if ability.can 'update', project
					Button
						className: styles.editBuildingButton
						appearance: if @state.editBuildings then 'primary' else'subtle'
						spacing: 'none'
						onClick: (e) =>
							@setState editBuildings: !@state.editBuildings
					,
						if @state.editBuildings then 'Save' else 'Edit'
			@renderSeparator {subtle: true}

			DnDList
				disableDrag: !@state.editBuildings
				items: buildingsAreas
				renderSeparator: () => @renderSeparator {subtle: true}
				addIcon: Icon {className: styles.arrow, icon: plus, size: 14}
				itemHeight: 14
				renderItem: ({item, index, onDelete, onEdit}) =>
					div { key: item.name, className: cnames styles.grid, styles.table},
						span {}, item.name
						span {}, "#{item.pza} sqm"
						span {}, "#{item.nla} sqm"
						span {},"#{item.gla} sqm"
						if @state.editBuildings
							div {className: styles.buildingEditButtons},
								if onEdit?
									Button
										className: styles.button
										appearance: 'subtle'
										spacing: 'none'
										onClick: (e) => onEdit item, e
									, Icon {icon: edit2, size: 12}
								if onDelete?
									Button
										className: styles.button
										appearance: 'subtle'
										spacing: 'none'
										onClick: (e) => onDelete item, e
									, Icon {icon: x, size: 12}
						else
							div {className: styles.buildingModified},
								moment.unix(item.modifiedAt).format('DD/MM/YYYY')
				onMoveEnd: (items) ->
					mutateBuildingTable
						variables:
							projectID: project.id
							buildingsAreas: _.map items, (item) -> _.omit item, '__typename'
				onAdd: =>
					@setState
						activeModal: 'addBuildingArea'
				onEdit: (item) =>
					@setState
						activeModal: 'editBuildingArea'
						selectedBuilding: item
				onDelete: (item) =>
					@setState
						activeModal: 'deleteBuildingArea'
						selectedBuilding: item
			div { className: cnames styles.grid, styles.table},
				span {}, 'SUM:'
				span {}, "#{_.sum _.map buildingsAreas, 'pza'} sqm"
				span {}, "#{_.sum _.map buildingsAreas, 'nla'} sqm"
				span {}, "#{_.sum _.map buildingsAreas, 'gla'} sqm"


	renderHeadlessTable: ({rows}) =>
		Fragment {},
			_.map rows, (row, index) =>
				{ header, content } = row
				Fragment {},
					div { key: index, className: cnames styles.grid, styles.headerlessTable},
						span {key: index, className: styles.title}, header
						span {}, if content? then content else '-'
					if index isnt ((_.size rows) - 1 )
						@renderSeparator {subtle: true}

	renderItem: ({value, label, className}) ->
		div {className: cnames styles.item, className},
			div {className: styles.label}, label
			div {className: styles.value}, value

	renderPersonItem: ({item, index, onDelete, onEdit}) =>
		{user, role} = item
		div {className: cnames styles.personItem},
			div
				className: styles.picture
				style:
					backgroundImage: "url(#{_.get user, 'picture.original'})"
			div {className: styles.informations},
				div {className: styles.name},
					_.get user, 'name'
				div {className: styles.position},
					div
						className: cnames styles.readView, (if !role? then styles.placeholder)
					, if role? then role else 'no role specified'
				a {href: "mailto:#{_.get user, 'email'}", className: styles.email },
					_.get user, 'email'
				div {className: styles.phone},
					if user?.telephone? then user.telephone
			if @state.editManagement
				ButtonGroup {},
					if onEdit?
						Button
							className: styles.actionButton
							appearance: 'subtle'
							spacing: 'compact'
							onClick: (e) => onEdit item, e
						, Icon {icon: edit2, size: 12}
					if onDelete?
						Button
							className: styles.actionButton
							appearance: 'subtle'
							spacing: 'compact'
							onClick: (e) => onDelete item, e
						, Icon {icon: x, size: 12}

	renderSeparator: (props = {}) ->
		div {className: cnames styles.separator, if props?.subtle? then styles.subtle}

	renderHeader: (args) ->
		if _.isObject args
			{header, onClick, isDisabled, tooltip, appearance, buttonText} = args
		else
			header = args
		Tooltip
			className: styles.tooltip
			content: if tooltip? then tooltip
			showTimeout: 200
			hideTimeout: 5000
		,
			div {className: cnames styles.header},
				div {className: cnames if tooltip? then styles.hoverable}, header
				if onClick? and !isDisabled
					Button {
						className: styles.editButton
						content: buttonText || 'Edit'
						appearance: if appearance? then appearance else'subtle'
						onClick
					}, buttonText || 'Edit'

	renderEmptyState: (text) -> div {className: styles.emptyState}, text

	renderGeoportalMap: (geolocation) ->
		ILITEAPI.init
			divId: 'geoportalMap'
			width: '100%'
			height: '100%'
			activeGpMapId: 'gp4'
			scale: 5000
			marker:
				x: _.get geolocation, 'lng'
				y: _.get geolocation, 'lat'
				sr: 4326
				scale: 5000
				show: false

	renderManagementSection: ({project}, mutateManagement) =>
		if project.management?
			content = (management, project, phase) =>
				div {className: cnames styles.managmentSections},
					_.map management, ({phase, type, members}) =>
						div {key: "#{phase.id}_#{type}"},
							div {className: styles.managementHeader}, type
							DnDList
								disableDrag: !@state.editManagement
								items: members
								renderItem: @renderPersonItem
								onMoveEnd: (items) ->
									mutateManagement
										variables:
											projectID: project.id
											phaseID: phase.id
											type: type
											managementMembers: _.map items, (item) ->
												id: item.user.id
												role: item.role
								onAdd: =>
									@setState
										activeModal: 'addProjectMember'
										selectedManagementType: type
										selectedManagmentPhaseID: phase.id
								onEdit: (item) =>
									@setState
										activeModal: 'editProjectMember'
										selectedManagementType: type
										selectedManagementUserID: item.user.id
										selectedManagementRole: item.role
										selectedManagmentPhaseID: phase.id
								onDelete: (item) =>
									@setState
										activeModal: 'deleteProjectMember'
										selectedManagementType: type
										selectedManagementUserID: item.user.id
										selectedManagmentPhaseID: phase.id
			if _.size(project.phases) is 1
				management = project.management
				phase = _.head project.phases
				content management, project, phase
			else
				Tabs
					location: @props.location
					onSelect: (item, index) => @setState selectedManagementPhase: index
					selected: @state.selectedManagementPhase
					tabs: _.map project.phases, (phase) =>
						management = _.filter project.management, (item) -> item.phase.id is phase.id
						id: phase.id
						label: phase.name
						content: content management, project, phase


	render: ->
		UserInfoConsumer {}, ({ability, me}) =>
			div {className: cnames @props.className },
				Query
					query: RootQuery
					fetchPolicy: 'network-only'
					variables:
						projectSlug: @props.match.params.project
						pagination:
							limit: ITEMS_PER_PAGE
					onCompleted: (data) =>
						if !_.isEmpty data.project
							@setState
								originalSlug: data.project.slug
				, ({data, loading, error, refetch}) =>
					if loading then  Spinner {}
					else
						NotificationConsumer {}, ({setNotification}) =>
							Mutation
								mutation: ProjectManagementUpdateMembers
								onCompleted: ->
									setNotification
										content: 'Successfully changed order of project management members. '
										appearance: 'success'
							, (mutateManagement, {loading}) =>
								Mutation
									mutation: ProjectBuildingAreaReorder
									onCompleted: ->
										setNotification
											content: 'Successfully changed order of buildings.'
											appearance: 'success'
								, (mutateBuildingTable, {loading}) =>
										ErrorDisplay {
											error
											refetch
											withImage: true
										}, () =>
											{project, permissionsModel} = data
											if _.isEmpty project
												EmptyState
													header: trLegal.error.invalidProject
													imageUrl: require 'assets/empty-states/empty-result.svg'
													maxImageWidth: 300
													maxImageHeight: 300
													primaryAction:
														Button
															href: "/projects"
														, "Go to Projects List"
											else
												geoLocationData = if project?.location?.coordinates?
													coords = project.location.coordinates
													{lat: coords[0], lng: coords[1]}
												div {className: styles.base},
													Fragment {},
														@renderItem
															label: project.name
															value: 'Project Summary'
															className: styles.headerItem
														div {className: styles.content},
															img
																src: require('assets/skanska_seal.png')
																className: styles.logo

															div {className: cnames styles.grid, styles.generalSection},
																@renderColumn
																	header: @renderHeader
																		header: 'Info'
																		isDisabled: ability.cannot 'update', project
																		onClick: () => @setState activeModal: 'editProject'
																	content: Fragment {},
																		@renderItem
																			label: 'Project Name'
																			value: project.name
																		@renderItem
																			label: 'Abbrevation'
																			value: project.code
																		@renderItem
																			label: 'Address'
																			value: do ->
																				{street, buildingNumber, postalCode, city} = project
																				if street? and city?
																					"#{street} #{if buildingNumber? then buildingNumber else ''}, #{if postalCode? then postalCode else ''} #{city}"
																				else '-'
																		# @renderItem
																		# 	label: 'Region'
																		# 	value: if project.region? then project.region else '-'
																		@renderItem
																			label: 'SPV Developer / owner'
																			value: if project.spv?.name? then project.spv.name
																		if project.dates?
																			_.map project.dates, (date) =>
																				@renderItem
																					label: _.capitalize _.upperCase date.type
																					value: moment.unix(date.value).format('DD/MM/YYYY')
																		if project.buildingsAreas?
																			@renderTable
																				ability: ability
																				className: styles.darkTable
																				headers: ['Stage', 'PZA', 'NLA', 'GLA']
																				project: project
																				buildingsAreas: project.buildingsAreas
																				mutateBuildingTable: mutateBuildingTable

																@renderColumn
																	# header: @renderHeader 'Preview'
																	content:
																		if !project.photo?
																			@renderEmptyState 'Add project preview to display photo'
																		else
																			img {src: project.photo.original, className: styles.imgResponsive}
																@renderColumn
																	# header: @renderHeader 'Location'
																	content: Fragment {},
																		if _.isEmpty geoLocationData
																			@renderEmptyState 'Set project location to display map'
																		else
																			div {key: (geoLocationData.lat*geoLocationData.lng), className: styles.map},
																				Map
																					google: @props.google
																					zoom: 16
																					initialCenter: geoLocationData
																					gestureHandling: "cooperative"
																				, Marker {}
															@renderSeparator()

															div {className: cnames styles.grid, styles.landSection},
																Query {
																	query: DocumentsQuery
																	skip: ability.cannot 'read', project, 'Legal'
																	fetchPolicy: 'network-only'
																	variables:
																		projectSlug: @props.match.params.project
																		documentsFilters:
																			typeOfOwnership: ['PerpetualUsufruct', 'Ownership']
																}, ({data, loading, error, refetch}) =>
																	@renderColumn
																		header: @renderHeader 'Land Specification'
																		content: Fragment {},
																			@getTable {
																				items: _.get data, 'project.legalDocuments'
																				isMultiphase: _.get data, 'project.isMultiphase'
																				loading
																			}
																@renderColumn
																	header: @renderHeader 'Map'
																	content:
																		div {className: styles.map},
																		if !geoLocationData?
																			@renderEmptyState 'Set project location to display map'
																		else
																			do =>
																				if __STAGE__? and (__STAGE__ isnt 'local')
																					if ILITEAPI? then @renderGeoportalMap geoLocationData
																			div id: 'geoportalMap'
															@renderSeparator()

															div {className: cnames styles.grid, styles.companySection},
																@renderColumn
																	header: @renderHeader
																		header: 'Company data'
																		isDisabled: ability.cannot 'update', project
																		onClick: () => @setState activeModal: 'editCompany'
																	content:
																		if !project.spv?
																			div {}, '–'
																		else
																			@renderHeadlessTable
																				rows: [
																					header: 'SPV Developer / subtitute developer'
																					content: project.spv.name
																				,
																					header: 'KRS'
																					content: project.spv.krs
																				,
																					header: 'NIP'
																					content: project.spv.nip
																				,
																					header: 'REGON'
																					content: project.spv.regon
																				,
																					header: 'Headquarters'
																					content: if project.spv.hedquarters?
																						{street, buildingNumber, postalCode, city} = project.spv.hedquarters
																						if street? and buildingNumber? and postalCode? and city?
																							"#{street} #{buildingNumber}, #{postalCode} #{city}"
																				,
																					header: 'Introduction'
																					content: project.spv.description
																				,
																					header: 'Representation'
																					content: project.spv.representationDescription
																				,
																					header: 'Representation type'
																					content: project.spv.representationType
																				]
															@renderSeparator()

															@renderColumn
																header: @renderHeader
																	header: 'Project management'
																	isDisabled: ability.cannot 'update', project
																	appearance: if @state.editManagement then 'primary' else'subtle'
																	buttonText: if @state.editManagement then 'Save' else 'Edit'
																	onClick: () =>
																		@setState editManagement: !@state.editManagement
																content: do =>
																	if !project.management? and _.isEmpty project.management
																		@renderEmptyState 'Please contact administrator to configure project management roles for this project'
																	else
																		@renderManagementSection data, mutateManagement


														@getModals {project}

export default GoogleApiWrapper({apiKey: GOOGLE_MAPS_API_KEY})(ProjectView)
