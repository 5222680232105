###
Login screen
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
# Renderable
import { div, img, p } from 'react-dom-factories'

import _Lottie from 'react-lottie'
Lottie = React.createFactory _Lottie

import { CSSTransition as _CSSTransition } from 'react-transition-group'
CSSTransition = React.createFactory _CSSTransition

import * as animationData from './world-locations.json'

Fragment = React.createFactory React.Fragment

# Styles
import styles from './index.styl'



export default class PlatformSpinner extends React.Component
	@defaultProps:
		visible: true
	constructor: (props) ->
		super props
		@state =
			word: @selectdWord()
	selectdWord: ->
		_.sample ['platform', 'assets', 'data', 'profile', 'informations']
	componentDidMount: ->
		@interval = setInterval =>
			@setState word: @selectdWord()
		, 1500
	render: ->
		CSSTransition {
			in: @props.visible
			classNames:
				enterDone: styles.animation
				exit: styles.animationOut
			timeout: 800
			unmountOnExit: true
			onExited: =>
				clearInterval @interval
		},
			div {
				className: styles.main
			},
				Fragment {},
					Lottie {
						options:
							loop: true
							autoplay: true
							animationData: animationData.default
						height: 300
						widht: 300
					}
				div {className: styles.label}, "Loading #{@state.word}..."
