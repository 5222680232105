###
Add Shortcut modal
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import adopt from 'libs/adopt'
import validator from 'validator'

# Renderable
import { div, span, input, img } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import _BevyModal from '@bevy/modal'
BevyModal = React.createFactory _BevyModal

import {
	TextField as _TextField
} from 'components/FormItems'
TextField = React.createFactory _TextField

import {
	AddShortcut
	UpdateShortcut
} from './data'

DataLayer = adopt
	queries: {}
	mutations:
		addShortcut:
			mutation: AddShortcut
		updateShortcut:
			mutation: UpdateShortcut

export default class AddShortcutModal extends React.Component
	constructor: (props) ->
		super props
		@state =
			name: if @props.shortcutName then @props.shortcutName else ""
			url: if @props.shortcutUrl then @props.shortcutUrl else ""
			isInvalid: false
	getVariables4Mutation: =>
		url = @state.url
		pattern = ///
			http
		///
		if _.isEmpty @state.url.match pattern
			url = 'http://' + @state.url
		variables = {
			name: @state.name
			url: url
			projectID: @props.projectID
		}
		if (!_.isEmpty @props.shortcutID)
			variables.shortcutID = @props.shortcutID
		variables

	onCloseProxy: (appropriateMutation) =>
		if _.isFunction appropriateMutation
			appropriateMutation
				variables: @getVariables4Mutation()
		@props.onClose()

	canProceed: =>
		(!_.isEmpty @state.name) and (!_.isEmpty @state.url)

	render: =>
		DataLayer {}, (operations) =>
			BevyModal
				onClose: () => @onCloseProxy()
				width: "small"
				header: if @props.shortcutID then "Edit shortcut" else "Add shortcut"
				actions: [
					text: 'Save'
					isDisabled: !@canProceed()
					onClick: () =>
						if validator.isURL @state.url
							@onCloseProxy(
								if _.isEmpty @props.shortcutID
									operations.addShortcut.mutation
								else
									operations.updateShortcut.mutation
							)
						else
							@setState isInvalid: true
				,
					text: 'Cancel'
					onClick: () => @onCloseProxy()
				]
			,
				Fragment {},
					TextField {
						label: "Name"
						onChange: (value) =>
							@setState name:value
						value: @state.name
					}
					Fragment {},
						TextField {
							label: "Website address"
							placeholder: "e.g. http://google.com"
							onChange: (value) => @setState url: value
							value: @state.url
							isInvalid: @state.isInvalid
							errorMsg: if @state.isInvalid then 'This is invalid address'
						}
