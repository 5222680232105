import gql from 'graphql-tag'


export ListDocuments = (queryFields, search) -> gql """
	query DMS_Inbox_Table(
		$projectSlug: String!,
		$filters: LegalDocumentsFilters,
		$sort: LegalDocumentsSort!,
		$pagination: PaginationInput,
		$searchText: String
	) {
	project(slug: $projectSlug) {
		id
		name
		isMultiphase
		legalDocumentsCount(filters: $filters, text: $searchText)
		legalDocuments(filters: $filters, sort: $sort, pagination: $pagination, text: $searchText)
		{
			id
			projectID
			createdAt
			#{queryFields}
			isUrgent
			isFresh
			isMuted
			hasScan
			hasCategory
			hasReview
			hasDetails
			isToxic
			scan {
				id
				filename
				secured {
					original
				}
			}
		}
	}
}
"""

export GenerateReport = gql """
	query(
		$documents: [ID!]
		$completeReport: Boolean
		$perspective: JSONObject
		$columns: [JSONObject]
		){
		legalDocumentGenerateReport(
			documents: $documents
			completeReport: $completeReport
			perspective: $perspective
			columns: $columns
			) {
				id
				url
				status
			}
	}
"""

export UpdateReviewed = gql """
	mutation($documentIDs: [ID!]!, $reviewed: Boolean!){
		legalDocumentUpdateReviewed(documentIDs: $documentIDs, value: $reviewed) {
			id
			status
			modifiedAt
			modifiedBy {
				... on Person { id, name }
				... on Service { id, name }
			}
			hasReview
		}
	}
"""

export UpdateApproved = gql """
	mutation($documentIDs: [ID!]!, $approved: Boolean!){
		legalDocumentUpdateApproval(documentIDs: $documentIDs, value: $approved) {
			id
			status
			state
			modifiedAt
			modifiedBy {
				... on Person { id, name }
				... on Service { id, name }
			}
			hasApproval
			approvedAt
			approvedBy { id, name }
		}
	}
"""

export ListDocumentsSummaryCounts = gql """
	query DMS_Inbox_Summary(
		$projectSlug: String!,
		$allDocumentsFilters: LegalDocumentsFilters,
		$filteredDocumentsFilters: LegalDocumentsFilters,
	){
		project(slug: $projectSlug) {
			id
			allDocumentsCount: legalDocumentsCount(filters: $allDocumentsFilters)
			filteredDocumentsCount: legalDocumentsCount(filters: $filteredDocumentsFilters)
			legalDocuments (filters: $allDocumentsFilters) {
				id
				scan { id, secured { size } }
			}
		}
	}
"""

export ListDocumentsCounts = gql """
	query ProjectLegalDocumentsCounts (
		$projectSlug: String!,
		$urgentFilters: LegalDocumentsFilters,
		$freshFilters: LegalDocumentsFilters,
		$mutedFilters: LegalDocumentsFilters,
		$allAgeFilters: LegalDocumentsFilters,
		$lastDayFilters: LegalDocumentsFilters,
		$lastThreeDaysFilters: LegalDocumentsFilters,
		$lastWeekFilters: LegalDocumentsFilters,
		$assignedToMeFilters: LegalDocumentsFilters,
		$myDraftsFilters: LegalDocumentsFilters,
		$incompleteFilters: LegalDocumentsFilters,
		$requireActionFilters: LegalDocumentsFilters,
		$awaitingApprovalFilters: LegalDocumentsFilters,
		$unpairedScansFilters: LegalDocumentsFilters
	) {
		project(slug: $projectSlug) {
			id
			urgentCount: legalDocumentsCount(filters: $urgentFilters)
			freshCount: legalDocumentsCount(filters: $freshFilters)
			mutedCount: legalDocumentsCount(filters: $mutedFilters)
			allAgeCount: legalDocumentsCount(filters: $allAgeFilters)
			lastDayCount: legalDocumentsCount(filters: $lastDayFilters)
			lastThreeDaysCount: legalDocumentsCount(filters: $lastThreeDaysFilters)
			lastWeekCount: legalDocumentsCount(filters: $lastWeekFilters)
			assignedToMeCount: legalDocumentsCount(filters: $assignedToMeFilters)
			myDraftsCount: legalDocumentsCount(filters: $myDraftsFilters)
			incompleteCount: legalDocumentsCount(filters: $incompleteFilters)
			requireActionCount: legalDocumentsCount(filters: $requireActionFilters)
			awaitingApprovalCount: legalDocumentsCount(filters: $awaitingApprovalFilters)
			unpairedScansCount: legalDocumentsCount(filters: $unpairedScansFilters)
		}
	}
"""

export GetScansStatus = gql """
	query($ids: [ID!]!) {
		systemTicketsByIDs(ids: $ids) {
			id
			status
		}
	}
"""

export DownloadDocuments = gql """
	query($documentIDs: [ID!]!){
		legalDocumentDownload(documents: $documentIDs) {
			id
			url
		}
	}
"""

export MuteDocuments = gql """
	mutation($documentIDs: [ID!]!, $isMuted: Boolean!) {
		legalDocumentsUpdateMute(documentIDs: $documentIDs, value: $isMuted) {
			id
			isMuted
			isUrgent
			isFresh
			project {
				id
				slug
			}
			state
		}
	}
"""


# accessory nav
export FoldersQuery = gql """
	query DMS_Inbox_Accessory(
		$projectSlug: String!,
		$all: LegalDocumentsFilters,
		$alert: LegalDocumentsFilters,
		$new: LegalDocumentsFilters,
		$muted: LegalDocumentsFilters,
		$owner: LegalDocumentsFilters,
		$recent: LegalDocumentsFilters,
		$awaitingApproval: LegalDocumentsFilters,
		$awaitingMyApproval: LegalDocumentsFilters,
		$unpairedScans: LegalDocumentsFilters
	) {
		project(slug: $projectSlug) {
			id
			all: legalDocumentsCount(filters: $all)
			alert: legalDocumentsCount(filters: $alert)
			new: legalDocumentsCount(filters: $new)
			muted: legalDocumentsCount(filters: $muted)
			owner: legalDocumentsCount(filters: $owner)
			recent: legalDocumentsCount(filters: $recent)
			awaitingApproval: legalDocumentsCount(filters: $awaitingApproval)
			awaitingMyApproval: legalDocumentsCount(filters: $awaitingMyApproval)
			unpairedScans: legalDocumentsCount(filters: $unpairedScans)
		}
	}
"""


export CountersCacheUpdateQuery = gql """
	query($projectSlug: String!, $filters: LegalDocumentsFilters){
		project(slug: $projectSlug) {
			id
			legalDocumentsCount(filters: $filters)
		}
	}
"""
