###
Project entrypoint view
TODO: Replace image with real content
###

# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'

# Renderable
import { div, img } from 'react-dom-factories'

Fragment = React.createFactory React.Fragment

import { Query as _Query } from 'react-apollo'
Query = React.createFactory _Query

import {Redirect as _Redirect} from 'react-router-dom'
Redirect = React.createFactory _Redirect

import _HubLoading from 'components/HubLoading'
HubLoading = React.createFactory _HubLoading

# Data
import { RootQuery } from './data'

# Styles
import styles from './index.styl'

export default class ProjectView extends React.Component

	render: ->
		div {className: cnames @props.className },
			Query
				query: RootQuery
				variables:
					projectSlug: @props.match.params.project
			, ({data, loading, error}) =>
				HubLoading {
					loading
					error
					data
				},
					div {className: styles.base},
						switch @props.match.params.project
							when 'p180'
								img {src: require('assets/p180-card.jpg'), className: styles.imgResponsive}
							when 'centrum-poludnie'
								img {src: require('assets/centrum-poludnie-3-card.jpg'), className: styles.imgResponsive}
							when 'centrum-poludnie-3'
								img {src: require('assets/centrum-poludnie-3-card.jpg'), className: styles.imgResponsive}
							when 'centrum-poludnie-5'
								img {src: require('assets/centrum-poludnie-5-card.jpg'), className: styles.imgResponsive}
							else
								img {src: require('assets/aleja-pokoju-card.jpg'), className: styles.imgResponsive}
