import 'fullscreen-api-polyfill'
import * as Sentry from '@sentry/browser'
import { SentrySettings } from 'libs/logger'

if __STAGE__ isnt 'local'
	Sentry.init {
		release: if __VERSION__? then "S360@#{__VERSION__}#{if __COMMIT__? then '(#'+__COMMIT__+')' else ''}"
		environment: __STAGE__
		dsn: 'https://89a0aa6cd7a2495bbc65c1e2542cfa18@sentry.io/1540031'
		...SentrySettings
	}
import S360Application from './application'
export default S360Application
