"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.scopes = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _helpers = require("../helpers");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// Generated by CoffeeScript 2.4.1

/*
Scopes from other models
*/
// import { scopes as SafetyVisitScopes } from './SafetyVisit'

/*
SafetyVisitMember permissions model
*/
var scopes = (0, _helpers.makeDefaultSubject)('SafetyVisitMember', {
  Self: (0, _helpers.makeDefaultFields)('self', {
    Accept: {
      actions: 'accept'
    },
    Reject: {
      actions: 'reject'
    },
    Remove: {
      actions: 'remove'
    }
  })
});
exports.scopes = scopes;
var _default = {
  // Name of the GraphQL subject
  name: 'SafetyVisitMember',
  // Available actions on root leve
  actions: [],
  // Model fields with appropiate actions
  fields: {},
  // Available scopes with implicit subject as a model.name
  scopes,
  // Additional fields requested when fetching items for attached and members roles
  projection: {},
  // Type of models that can become a member of this model without specified role
  accepts: [],
  // Entity based roles default and assignable
  roles: {
    // Roles granted to all users
    default: [],
    // Roles assignable to the entity that will be transfered to the User
    attached: [],
    // Roles assigned to each member
    membersDefault: [],
    // Roles assignable to the members (Users) of this entity
    members: []
  }
};
exports.default = _default;