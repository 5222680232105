###
Add Company Modal
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import adopt from 'libs/adopt'

import { NotificationConsumer } from '../../../application/components/NotificationManager'
import { UserInfoConsumer } from 'libs/userInfo'

# Renderable
import { div, span } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import _Spinner from '@bevy/spinner'
Spinner = React.createFactory _Spinner

import _BevyModal from '@bevy/modal'
BevyModal = React.createFactory _BevyModal

import {
	TextField as _TextField,
	SelectField as _SelectField
	TextAreaField as _TextAreaField,
	Inline as _Inline
} from 'components/FormItems'
Inline = React.createFactory _Inline
TextField = React.createFactory _TextField
SelectField = React.createFactory _SelectField
TextAreaField = React.createFactory _TextAreaField

import _ErrorDisplay from 'components/ErrorDisplay'
ErrorDisplay = React.createFactory _ErrorDisplay

# Styles
import styles from './index.styl'

# Data
import {
	AddOrganization
	EditOrganization
} from './data'

DataLayer = adopt
	queries: {}
	mutations:
		addOrganization: ({setNotification}) ->
			mutation: AddOrganization
			onCompleted: -> setNotification({content: 'Organization added', appearance: 'success'})
			onError: -> setNotification ({content: 'Error on creating organization', appearance: 'error'})
		updateOrganization: ({setNotification}) ->
			mutation: EditOrganization
			onCompleted: -> setNotification({content: 'Organization edited', appearance: 'success'})
			onError: -> setNotification ({content: 'Error on editing organization', appearance: 'error'})

emptyState =
	name: ''
	abbreviation: ''
	type: 'External'

commonSelectProps =
	menuPortalTarget: document.body
	isSearchable: true
	hideSelectedOptions: true
	isClearable: false
	formatOptionLabel: (option) -> option.label
	getOptionLabel: (option) -> option.label
	getOptionValue: (option) -> option.value

export default class AddOrganizationModal extends React.Component
	@propTypes =
		onClose: PropTypes.func.isRequired

	constructor: (props) ->
		super props
		@state = {
			...emptyState
			...@props.initialSetup
			projectsIDs: if @props.projectID? then [@props.projectID]
			}

	# LOGIC
	makeOptions: (arr) ->
		_.map arr, (item) ->
			label: item
			value: item

	canProceed: =>
		everyRequiredFieldsFilled =
			if !_.isEmpty @state.name
				true
			else
				false
		madeAnyChange = if @props.initialSetup?
				_.some @props.initialSetup, (value, key) => value isnt @state["#{key}"]
			else
				true
		everyRequiredFieldsFilled and madeAnyChange

	getVariables4Mutation: =>
		variables =
			if !@props.advancedView?
				_.pick @state, ['name', 'abbreviation', 'type']
			else
				@state
		if @props.organizationID
			variables.id = @props.organizationID
		if @props.projectID?
			variables.projectsIDs = [@props.projectID]
		variables

	onCloseProxy: (appropriateMutation) =>
		if _.isFunction appropriateMutation
			appropriateMutation
				variables: @getVariables4Mutation()
				refetchQueries: ['ProjectCompaniesList']

		# @setState {
		# 	...emptyState
		# 	...@props.initialSetup
		# 	projectsIDs: if @props.projectID? then [@props.projectID]
		# 	}
		@props.onClose()

	# SUBCOMPONENTS
	renderTextField: (args) =>
		TextField {
			...args
			onChange: (value) => @setState ["#{args.slug}"]: value
			value: @state["#{args.slug}"]
		}

	renderSelect: (args) =>
		SelectField {
			...args
			onChange: (value) => @setState ["#{args.slug}"]: value
			value: @state["#{args.slug}"]
			defaultValue: args.options[0].value
		}
	renderTextarea: (args) =>
		TextAreaField {
			...args
			onChange: (value) => @setState ["#{args.slug}"]: value
			value: @state["#{args.slug}"]
		}
	# PARTS
	renderBasicsSection: =>
		div {className: styles.section},
			@renderTextField
				label: "Name"
				slug: 'name'
				isRequired: true
				tooltipContent: 'Max. 120 characters'
				maxLength: 120
			@renderTextField
				label: "Abbreviation"
				slug: 'abbreviation'
				tooltipContent: 'Max. 15 characters'
				maxLength: 15
			@renderSelect
				label: "Type"
				slug: "type"
				options: @makeOptions ['External', 'Internal']
				tooltipContent: 'To change company type contact Project Manager'
				isDisabled: true

	renderRepresentationSection: =>
		div {className: styles.section},
			div {className: styles.sectionHeader}, 'Representation'
			@renderTextarea
				label: "Description"
				slug: 'representationDescription'
			@renderTextField
				label: "Type"
				slug: 'representationType'

	renderIdentifiersSection: =>
		div {className: styles.section},
			div {className: styles.sectionHeader}, 'Identifiers'
			@renderTextField
				label: "NIP"
				slug: 'nip'
			@renderTextField
				label: "REGON"
				slug: 'regon'
			@renderTextField
				label: "KRS"
				slug: 'krs'

	renderDetailsSection: =>
		div {className: styles.section},
			div {className: styles.sectionHeader}, 'Details'
			@renderTextField
				label: "Website"
				slug: 'website'
			@renderTextarea
				label: "Introduction"
				slug: 'description'

	renderAddressSection: =>
		div {className: styles.section},
			div {className: styles.sectionHeader}, 'Adress'
			Inline { padding: true, proportions: [3, 1] },
					@renderTextField
						label: "Street"
						slug: 'street'
					@renderTextField
						label: "Building number"
						slug: 'buildingNumber'
			Inline { padding: true, proportions: [1, 3]},
					@renderTextField
						label: "Postal Code"
						slug: 'postalCode'
					@renderTextField
						label: "City"
						slug: 'city'


	# CONTENT
	renderContent: =>
		div {className: styles.base},
			div {className: styles.column},
				@renderBasicsSection()
				if @props.advancedView
					Fragment {},
						@renderDetailsSection()
						@renderAddressSection()
			if @props.advancedView
				div {className: styles.column},
					Fragment {},
						@renderIdentifiersSection()
						@renderRepresentationSection()

	render: ->
		UserInfoConsumer {}, ({ability, me}) =>
			NotificationConsumer {}, ({setNotification}) =>
				DataLayer
					setNotification: setNotification
					, (operations) =>
						BevyModal
							onClose: @onCloseProxy
							header: if _.isEmpty @props.initialSetup then 'Add organization' else 'Edit organization'
							width: if @props.advancedView then 'large'
							actions: [
								text: 'Save'
								isDisabled: !@canProceed()
								onClick: () => @onCloseProxy(
									if _.isEmpty @props.initialSetup
										operations.addOrganization.mutation
									else
										operations.updateOrganization.mutation
								)
							,
								text: 'Cancel'
								onClick: @onCloseProxy
							]
						,
							if @props.editQuery?
								ErrorDisplay {query: @props.editQuery}, () =>
									@renderContent()
							else
								@renderContent()


