exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._10dk9rhWFmtRLWPijNiryN {\n  width: 100%;\n  height: 100%;\n}\n.YfVecWkpb_wxa5DvrG1n7 {\n  width: 100%;\n}\n._1pt0t6GzLXN3TGWM9C0KPx {\n  display: flex;\n  flex-wrap: wrap;\n}\n._1pt0t6GzLXN3TGWM9C0KPx > * {\n  width: 50%;\n  box-sizing: border-box;\n}\n._1pt0t6GzLXN3TGWM9C0KPx > *:not(:nth-child(2n)) {\n  padding-right: 10px;\n}\n._2GerdTSMlL9l6OMbNN-rN div {\n  min-height: auto;\n  padding-top: 0;\n  padding-bottom: 0;\n}\n.qyfynxfewCr9wbjKIb3Gu {\n  display: flex;\n}\n.qyfynxfewCr9wbjKIb3Gu ._38Bd46EPXiJ720-mC31oFt {\n  padding: 4px 0;\n  min-height: 26px;\n  border-radius: 0;\n  color: #000;\n  border: 1px solid #fff;\n}\n._2BkI-Ph01e5QoBP-a6zt1t div {\n  padding-top: 8px;\n  padding-bottom: 8px;\n}\n._3Y2Ok7TuJp9PP6MYWprFAy div {\n  min-height: 28px;\n  padding-top: 0;\n  padding-bottom: 0;\n  border: 0;\n  background-color: #f4f5f7;\n}\n._3Y2Ok7TuJp9PP6MYWprFAy input {\n  font-size: 1rem;\n  padding: 7px 6px;\n}\n.ORDYAQQ_O3XALtVYp7cT4 {\n  display: flex;\n  flex-direction: column;\n}\n._1dGDpUoJbtNXvseCvuZ-nJ {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  min-height: 120px;\n}\n._2zy7_2F3jGBZrG8ONwbSbS {\n  font-size: 1rem;\n  position: relative;\n}\n.OYSowd7tRXTbvLW3r9QGq {\n  cursor: pointer;\n  display: inline-block;\n  position: absolute;\n  transform: translate(4px, -1px);\n}\n._1-ea2N0EeCJDLoY7SoD7ab td div {\n  font-size: 1rem;\n}\n._1-ea2N0EeCJDLoY7SoD7ab thead th {\n  font-size: 1rem;\n}\n.DKYk_HxIkhpt7qY5x1YT {\n  display: inline;\n  padding-left: 6px;\n  font-weight: 400;\n  font-size: 0.8rem;\n}\n.DKYk_HxIkhpt7qY5x1YT._1Hyg9sEE5qPF-a7s9qp0bp {\n  padding-left: 15px;\n}\n._20chSPJpVg7UUPWsIJG42v {\n  color: #8993a4;\n}\n._2HG_c0L34Ode5WgjHlydoT {\n  min-height: 28px;\n  padding-top: 0;\n  padding-bottom: 0;\n}\n", ""]);
// Exports
exports.locals = {
	"stepContainer": "_10dk9rhWFmtRLWPijNiryN",
	"sectionWrapper": "YfVecWkpb_wxa5DvrG1n7",
	"sectionHorizontal": "_1pt0t6GzLXN3TGWM9C0KPx",
	"formItem": "_2GerdTSMlL9l6OMbNN-rN",
	"buildingCombinations": "qyfynxfewCr9wbjKIb3Gu",
	"buildingCombination": "_38Bd46EPXiJ720-mC31oFt",
	"buildingList": "_2BkI-Ph01e5QoBP-a6zt1t",
	"formItemText": "_3Y2Ok7TuJp9PP6MYWprFAy",
	"emptyContainer": "ORDYAQQ_O3XALtVYp7cT4",
	"placeholder": "_1dGDpUoJbtNXvseCvuZ-nJ",
	"label": "_2zy7_2F3jGBZrG8ONwbSbS",
	"iconTooltip": "OYSowd7tRXTbvLW3r9QGq",
	"datePicker": "_1-ea2N0EeCJDLoY7SoD7ab",
	"missingInfo": "DKYk_HxIkhpt7qY5x1YT",
	"withIcon": "_1Hyg9sEE5qPF-a7s9qp0bp",
	"placeholderText": "_20chSPJpVg7UUPWsIJG42v",
	"selectOwnership": "_2HG_c0L34Ode5WgjHlydoT"
};