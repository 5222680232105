import _ from 'lodash'
import React from 'react'

# Renderable
import { div } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import {BevyModalsWrapper as _BevyModalsWrapper} from '@bevy/modal'
BevyModalsWrapper = React.createFactory _BevyModalsWrapper

import _AddGroupModal from 'parts/Groups/AddGroupModal'
AddGroupModal = React.createFactory _AddGroupModal

import _EditGroupModal from 'parts/Groups/EditGroupModal'
EditGroupModal = React.createFactory _EditGroupModal

import _DeleteGroupsModal from 'parts/Groups/DeleteGroupsModal'
DeleteGroupsModal = React.createFactory _DeleteGroupsModal

export default getModals = ({selected}) ->
	BevyModalsWrapper {},
		switch @state.activeModal
			when 'add'
				AddGroupModal
					onClose: => @setState activeModal: null
			when 'edit'
				EditGroupModal
					groupID: _.first selected
					onClose: => @setState activeModal: null
			when 'delete'
				DeleteGroupsModal {
					...@props
					groupsIDs: selected
					onClose: =>
						@setState
							activeModal: null
							selected: []
				}
