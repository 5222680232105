import gql from 'graphql-tag'

# QUERIES
export GetProjectMembers = gql """
query getProjectMembers (
		$id: ID!
		$search: String
		$pagination: PaginationInput
		) {
		projectUserGroup(id: $id) {
			id
			project {
				id
				members(pagination: $pagination, search: $search) {
					...on ActorAccess {
						user {
							... on Person{
								id
								name
								email
							}
						}
					}
				}
			}
		}
}
"""

# MUTATIONS
export AddUsersToProjectGroup = gql """
	mutation(
		$id: ID!,
		$addedEntities: [MemberInputAdd!],
		$removedEntities: [MemberInputRemove!]
	) {
		projectUserGroupUpdateMembers(
			id: $id,
			addedEntities: $addedEntities,
			removedEntities: $removedEntities) {
			id
		}
	}
"""
