# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import DataAttribute from '@bevy/data-attribute'

# Renderable
import { div } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import _Spinner from '@bevy/spinner'
Spinner = React.createFactory _Spinner

import _ListEditor from '@bevy/list-editor'
ListEditor = React.createFactory _ListEditor

import _Table from '@bevy/table'
Table = React.createFactory _Table

import _Button from '@bevy/button'
Button = React.createFactory _Button

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import _DropdownMenu from '@bevy/dropdown-menu'
DropdownMenu = React.createFactory _DropdownMenu

import _Tooltip from '@bevy/tooltip'
Tooltip = React.createFactory _Tooltip

import _SelectionToolbar from '../parts/SelectionToolbar'
SelectionToolbar = React.createFactory _SelectionToolbar

# Styles
import styles from './table.styl'
import {
	check
	settings
	messageSquare
	bellOff
	moreHorizontal
	paperclip
} from 'react-icons-kit/feather'
import ErrorIcon from '@atlaskit/icon/glyph/error'
import PresenceActiveIcon from '@atlaskit/icon/glyph/presence-active'

getRowClassName = (item, index) ->
	cnames [
			if index % 2 then styles.odd else styles.even
	]

export default ({ items, itemsCount, loading, fetchMore, columnsSetup, changeTableConfiguration, selected, onSelect, onSortChange, sortKey, sortOrder, getItemActions}) ->
	ListEditor
		items: _.reject columnsSetup, isFixed: true
		onApply: (presets) -> changeTableConfiguration presets
		onRestore: (presets) -> changeTableConfiguration null
	, (openTableEditor) =>
		Fragment {},
			Table
				loading: loading
				rowHeight: 24
				columns: _.filter(columnsSetup, selected: true)
				onSortChange: onSortChange
				sortKey: sortKey
				sortOrder: sortOrder
				prefix:
					width: 48
					render: ({ index, item, isSelected }) =>
						isSelected = _.includes selected, item.id
						div {className: styles.prefix},
							div {
								className: cnames [
									styles.checkbox
									if isSelected then styles.checked
								]
								onClick: (event) =>
									event.stopPropagation()
									previousState = selected
									nextState =
										if previousState? and (item.id in previousState)
											_.without previousState, item.id
										else
											_.concat previousState, item.id
									nextState = _.uniq _.compact nextState
									onSelect nextState
							}, Icon {icon: check, size: 12, style: color: 'white'}
				suffix:
					width: 32
					renderHeader: ->
						Button
							appearance: 'subtle'
							onClick: () => openTableEditor()
							iconBefore: Icon {icon: settings, size: 13}
					render: ({ index, item }) ->
						# actions = _.flatten getItemActions item
						# # actions = []
						# DropdownMenu
						# 	closeOnSelect: true
						# 	trigger:
						# 		# div {className: if !isHovered then styles.hidden},
						# 		Icon
						# 			icon: moreHorizontal
						# 			size: 11
						# 			className: styles.more
						# 	options: _.map actions, (option) -> {
						# 		label: option.content
						# 		tooltip: option.description
						# 		onClick: option.onClick
						# 	}
				items: items
				itemsCount: itemsCount
				rowClassName: (item, index) -> if item? then getRowClassName item, index
				loadMore: ({ skip, limit }) =>
					new Promise (resolve, reject) =>
						if loading
							resolve()
						else
							@setState isLoadingPage: true
							fetchMore
								variables:
									filters: {...@state.filters, states: ['Draft']}
									pagination:
										limit: limit
										skip: skip
									sort:
										key: @state.sortKey
										order: if @state.sortOrder is 'asc' then 'Ascending' else 'Descending'
								updateQuery: (prev, { fetchMoreResult }) =>
									@setState isLoadingPage: false
									if !fetchMoreResult
										console.warn 'fetchMoreResult empty'
									else
										resolve()
										{
											...prev
											project: {
												...prev.project
												organizations: [...prev.project.organizations, ...fetchMoreResult.project.organizations]
											}
										}
				selected: selected
				onItemEvent:
					onClick: ({event, item}) =>
						if !item?
							return
						previousState = selected
						if event.metaKey or event.ctrlKey
							nextState =
								if previousState? and (item.id in previousState)
									_.without previousState, item.id
								else
									_.concat previousState, item.id
							nextState = _.uniq _.compact nextState
						else
							if previousState? and (item.id in previousState)
								nextState = []
							else
								nextState = [item.id]
						@setState selected: nextState
						event.preventDefault()
						event.stopPropagation()
						event.nativeEvent.stopImmediatePropagation()

			SelectionToolbar {
				allDocumentsCount: itemsCount
				selectedDocuments: selected
				clearSelection: => onSelect []
			}

