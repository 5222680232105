import _ from 'lodash'
import React from 'react'
import moment from 'moment-mini'
import {FormatterConsumer} from 'libs/formatter'
import cnames from 'classnames'
import DataAttribute from '@bevy/data-attribute'

# Renderables
import { div, span, img, p } from 'react-dom-factories'

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import { Link as _Link } from 'react-router-dom'
Link = React.createFactory _Link

import _Tooltip from '@bevy/tooltip'
Tooltip = React.createFactory _Tooltip

# Data
import trLegal from 'data/local/legal'

# Styles
import styles from './columns.styl'
import {
	userCheck
} from 'react-icons-kit/feather'

projectLeader =
	key: 'projectLeader'
	label: 'Project Leader'
	width: 80
	constWidth: true
	isNotResizable: true
	isSortable: false
	render: ({ item }) ->
		div {className: cnames styles.compactText, styles.centered},
			if (!_.isEmpty item.roles) and _.some item.roles, (role) -> role.name is 'Project Leader'
				Icon {icon: userCheck, size: 11}

name =
	key: 'name'
	label: 'Name'
	sortKey: 'Name'
	width: 200
	isSortable: false
	render: ({ item }) ->
		span {className: cnames styles.compactText},
			if item.user.name? then item.user.name else '-'

email =
	key: 'email'
	label: 'E-mail'
	sortKey: 'Email'
	width: 200
	isSortable: false
	render: ({ item }) ->
		span {className: cnames styles.compactText},
			if item.user.email? then item.user.email else '-'

phaseRolesColumn = (phase, isProjectMultiphase) ->
	key: if isProjectMultiphase then "#{phase.name}-roles" else "roles"
	label: if isProjectMultiphase then "#{phase.name} Roles" else "Roles"
	width: 500
	render: ({ item }) ->
		roles = _.get item, 'roles'
		roles4Phase = _.filter roles, (role) ->
			_.includes role.info.phaseID, phase.id
		roles4Phase = _.sortBy roles4Phase, 'name'
		span {className: cnames styles.compactText},
			if _.isEmpty(roles) or _.isEmpty(roles4Phase)
				'-'
			else
				_.join (_.map roles4Phase, 'displayName'), ', '

export USERS_COLUMNS = (project) ->
	columns = [
			projectLeader
		,
			name
		,
			email
	]
	isProjectMultiphase = _.get project, 'isMultiphase'
	phases = _.get project, 'phases'
	phasesColumns = _.map phases, (phase) ->
		phaseRolesColumn(phase, isProjectMultiphase)
	if !_.isEmpty phases
		columns = _.concat columns, phasesColumns
	_.map columns, (column) ->
		{
			...column,
			selected: true
			selectedByDefault: true
		}
