# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import PropTypes from 'prop-types'
import DataAttribute from '@bevy/data-attribute'

import qs from 'qs'


# Renderables
import { div, p, ul, li, small, h1, h2, h3, span, img, button, title } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import {Helmet as _Helmet} from "react-helmet"
Helmet = React.createFactory _Helmet

import _KPIToolbar from '@bevy/kpi-toolbar'
KPIToolbar = React.createFactory _KPIToolbar

import _Button from '@bevy/button'
Button = React.createFactory _Button

import _Toolbar from '../components/Toolbar'
ToolbarElement = React.createFactory _Toolbar

import _FiltersBar from '../components/FiltersBar'
FiltersBar = React.createFactory _FiltersBar

import _HeaderBar from '../components/HeaderBar'
HeaderBar = React.createFactory _HeaderBar

import _DocumentHeader from '@bevy/document-header'
DocumentHeader = React.createFactory _DocumentHeader

import _DMSDocumentHeader from '@bevy/document-header-dms'
DMSDocumentHeader = React.createFactory _DMSDocumentHeader

import _ModeIndicator from '../components/ModeIndicator'
ModeIndicator = React.createFactory _ModeIndicator

import _ViewConfigItem from '../components/ViewConfigItem'
ViewConfigItem = React.createFactory _ViewConfigItem

import _DropdownMenu from '@bevy/dropdown-menu'
DropdownMenu = React.createFactory _DropdownMenu

import {
	list
} from 'react-icons-kit/feather'

import {
	# masterDetail
	# table
	viewSettings
	accessory
	data

	filter as filterIcon
	search as searchIcon
	quickFilters as quickFiltersIcon

	viewBoth
	viewLeft
	viewRight

} from '@bevy/theme/icons'

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

qsSettings =
	strictNullHandling: true
	encodeValuesOnly: true
	arrayFormat: 'comma'
	encode: false

export default class ContentExplorer extends React.Component
	@propTypes:
		pageId: PropTypes.string.isRequired
		setPageTitle: PropTypes.bool
		filters: PropTypes.shape
			onChange: PropTypes.func
			quick: PropTypes.arrayOf PropTypes.oneOfType [
					PropTypes.shape
						slug: PropTypes.string.isRequired
						query: PropTypes.object.isRequired
				,
					PropTypes.shape
						slug: PropTypes.string.isRequired
						query: PropTypes.func.isRequired
				,
					PropTypes.shape
						slug: PropTypes.string.isRequired
						query: PropTypes.func.isRequired
						options: PropTypes.arrayOf(
							PropTypes.oneOfType([
									PropTypes.shape
										slug: PropTypes.string
										value: PropTypes.oneOfType([
											PropTypes.string
											PropTypes.number
										]).isRequired
								,
									PropTypes.shape
										slug: PropTypes.string.isRequired
										value: PropTypes.object.isRequired
							])
						).isRequired
				]
		mode: PropTypes.shape
			title: PropTypes.node
			exitButton: PropTypes.shape(
				href: PropTypes.string
				onClick: PropTypes.func
			).isRequired

	@defaultProps:
		setPageTitle: true

	@CalculateActiveFilters: (filters) ->
		if filters?
			_.reduce qs.parse(window.location.search.replace(/^\?/, ''), qsSettings), (acc, value, slug) ->
				filter = _.find(filters.quick, {slug})
				if !filter?
					return acc
				if filter.options? and filter.type is 'checklist'
					acc[slug] = value.split ','
				else if filter.options? and filter.type is 'radio'
					acc[slug] = value
				else if filter.type is 'date'
					acc[slug] = value
				else if filter.type is 'toggle'
					acc[slug] = true
				else
					filter.query
				acc
			, {}
		else
			{}
	@CalculateDataQuery: (filters, activeFilters) ->
		activeFilters = if activeFilters? then activeFilters else @CalculateActiveFilters filters
		_.reduce activeFilters, (acc, value, slug) =>
				filter = _.find(filters.quick, {slug})
				if filter.options? and filter.type is 'checklist'
					acc.push _.find(filters.quick, {slug}).query value
				else if filter.options? and filter.type is 'radio'
					if !_.isEmpty value
						acc.push value
				else if filter.type is 'date'
					acc.push _.find(filters.quick, {slug}).query value
				else
					acc.push value
				acc
			, []
	@CalculateQueryString: (filters, activeFilters) ->
		qs.stringify {
			..._.omit qs.parse(window.location.search.replace(/^\?/, '')), _.map filters.quick, 'slug'
			...(
				_.reduce activeFilters, (acc, value, slug) =>
					filter = _.find(filters.quick, {slug})
					if filter.options? and filter.type is 'checklist'
						acc[slug] = value
					else if filter.options? and filter.type is 'radio'
						if !_.isEmpty value
							acc[slug] = value
					else if filter.type is 'date'
						acc[slug] = value
					else
						acc[slug] = null
					acc
				, {}
			)
		}, qsSettings

	constructor: (props) ->
		super props
		# Restore state from localStorage
		currentConfig = {}
		configs = _.map @props.configs, (conf, key) -> {...conf, key: key}

		if window.localStorage? and props.pageId?
			currentConfig = JSON.parse window.localStorage.getItem "#{props.pageId}"
		if !currentConfig? and configs? and !_.isEmpty configs
			currentConfig = _.first _.map configs
		@state =
			viewSettingsOpen: false
			viewConfig: @alignConfig currentConfig, props
			activeFilters: {}
		# @setState filters: {...filtersFromQuery, statuses: ['Approved']}
	getAvailability: (props) ->
		availableViews = _.keys(props.views)
		availableAccessories = _.keys(props.accessories)
		{
			views: availableViews
			accessories: availableAccessories
			layouts: _.keys(props.layouts)
			visibilities: do ->
				visibilities = []
				if !_.isEmpty(availableViews) and !_.isEmpty(availableAccessories)
					visibilities.push 'both'
				if !_.isEmpty(availableViews)
					visibilities.push 'view'
				if !_.isEmpty(availableAccessories)
					visibilities.push 'accessory'
				visibilities

			filtering: _.compact [
				'quick' if props.filters?.quick? and !_.isEmpty props.filters.quick
				'search' if props.filters?.search?
				'filters' if props.filters?.filters? and !_.isEmpty props.filters.filters
			]
		}
	alignConfig: (config = {}, props) ->
		availability = @getAvailability props

		key: if config?.key? then config.key
		view: if config?.view? and config.view in availability.views then config.view else _.first availability.views
		accessory: if config?.accessory? and config.accessory in availability.accessories then config.accessory else _.first availability.accessories
		layout: if config?.layout? and config.layout in availability.layouts then config.layout else _.first availability.layouts
		visibility: if config?.layout? and config.layout in availability.visibilities then config.layout else _.first availability.visibilities

		filtering: if config?.filtering? and config.filtering in availability.filtering then config.filtering else null
	# storageUpdate: (event) ->
	# 	console.log event
	componentDidMount: ->
		window.addEventListener 'storage', @storageUpdate
		@setState activeFilters: @constructor.CalculateActiveFilters @props.filters
	componentDidUpdate: (props, state) ->
		if !_.isEqual(props.filters, @props.filters)
			@setState activeFilters: @constructor.CalculateActiveFilters props.filters
	componentWillUnmount: ->
		window.removeEventListener 'storage', @storageUpdate
	setConfig: (config, cb) =>
		@setState viewConfig: {...@state.viewConfig, ...config}, =>
			if window.localStorage? and @props.pageId?
				window.localStorage.setItem "#{@props.pageId}", JSON.stringify @state.viewConfig
	# Small internal helpers
	getFilteringButton: (filteringType) =>
		isSelected: @state.viewConfig.filtering is filteringType
		isDisabled: !_.isEmpty(@state.activeFilters) and @state.viewConfig.filtering isnt filteringType
		key: filteringType
		onClick: =>
			# QUICKFIX for search clear
			if (@state.viewConfig.filtering is 'search') and @props.filters?.search?
				@props.filters.search null
			if @state.viewConfig.filtering is filteringType
				@setState activeFilters: {}
				, =>
					@onQueryChangeProxy()
					@setConfig filtering: null
			else if _.isEmpty(@state.activeFilters)
				@setConfig filtering: filteringType
		content: switch filteringType
			when 'filters' then filterIcon
			when 'quick' then quickFiltersIcon
			when 'search' then searchIcon
	getVisibalityButton: (visibilityType) =>

		isSelected: @state.viewConfig.visibility is visibilityType
		key: visibilityType
		onClick: =>
			if @state.viewConfig.visibility is visibilityType
				@setConfig visibility: null
			else
				@setConfig visibility: visibilityType
		content: switch visibilityType
			when 'both' then viewBoth
			when 'view' then viewLeft
			when 'accessory' then viewRight

	onQueryChangeProxy: =>
		if @props.filters?.onChange?
			query = @constructor.CalculateDataQuery @props.filters, @state.activeFilters
			querystring = @constructor.CalculateQueryString @props.filters, @state.activeFilters
			@props.filters.onChange { query, querystring }

	setFilter: ({event, filter, item}) =>
		nextState = null
		if filter.options? and filter.type is 'checklist'
			currentValues = []
			if @state.activeFilters[filter.slug]?
				currentValues = @state.activeFilters[filter.slug]
			if (item.slug || item.value) in currentValues
				currentValues = _.without currentValues, (item.slug || item.value)
			else
				currentValues = _.concat currentValues, (item.slug || item.value)

			if _.isEmpty currentValues
				nextState = _.omit @state.activeFilters, filter.slug
			else
				nextState = {...@state.activeFilters, "#{filter.slug}": currentValues}
		else if filter.options? and filter.type is 'radio'
			nextState =
				if @state.activeFilters[filter.slug]? and (@state.activeFilters[filter.slug] is (item.slug || item.value)) or _.isEmpty item.value
					_.omit @state.activeFilters, filter.slug
				else
					{...@state.activeFilters, "#{filter.slug}": (item.slug || item.value)}
		else if filter.type is 'date'
			nextState =
				if @state.activeFilters[filter.slug]? and (@state.activeFilters[filter.slug] is (item.slug || item.value)) or !item.value?
					_.omit @state.activeFilters, filter.slug
				else
					{...@state.activeFilters, "#{filter.slug}": (item.slug || item.value)}
		else if _.isEmpty filter.options
			nextState =
				if @state.activeFilters[filter.slug]?
					_.omit @state.activeFilters, filter.slug
				else
					{...@state.activeFilters, "#{filter.slug}": filter.query}
		else
			throw new Error "Unkown filter type #{filter}"
		@setState {activeFilters: nextState}, @onQueryChangeProxy
	mapQuickFilters: (quickFilters) =>
		_.map quickFilters, (filter) =>
			additionalProps = {}
			if filter.options? and filter.type is 'checklist'
				additionalProps.options = _.map filter.options, (option) =>
					if @state.activeFilters[filter.slug]? and (option.slug || option.value) in @state.activeFilters[filter.slug]
						{
							...option
							isActive: true
						}
					else
						option
			else if filter.options? and filter.type is 'radio'
				additionalProps.options = _.map filter.options, (option) =>
					if @state.activeFilters[filter.slug]? and (option.slug || option.value) is @state.activeFilters[filter.slug]
						{
							...option
							isActive: true
						}
					else
						option
				additionalProps.options.unshift {
					label: filter.name
					value: ''
				}
			else if filter.type is 'date'
				additionalProps.date = if @state.activeFilters[filter.slug]? then @state.activeFilters[filter.slug]
			{
				...filter
				key: if !filter.key? then filter.slug
				...additionalProps
				isActive: @state.activeFilters[filter.slug]?
				onSelect: @setFilter
			}
	onClear: =>
		@setState activeFilters: {}, @onQueryChangeProxy

	filtersBar: () =>
		if @props.forceShowQuickFilters then FiltersBar  {
			...@props.filters
			quick: if @props.filters.quick? then @mapQuickFilters @props.filters.quick
			onClear: if !_.isEmpty(@state.activeFilters) then @onClear
			mode: 'quick'
		}
		else if @props.filters? and @state.viewConfig.filtering? then FiltersBar {
			...@props.filters
			quick: if @props.filters.quick? then @mapQuickFilters @props.filters.quick
			onClear: if !_.isEmpty(@state.activeFilters) then @onClear
			mode: @state.viewConfig.filtering
		}

	render: ->
		availability = @getAvailability @props
		currentRenderProps = {
			..._.pick @props, ['items', 'openItem', 'fetchMore', 'settings']
		}
		if @props.hideFilters
			currentRenderProps = {...currentRenderProps, filtersBar: @filtersBar}
		div {
			className: cnames @props.className
			['data-test-id']: DataAttribute if @props['data-test-id']? then @props['data-test-id']
		},
			if @props.setPageTitle then Helmet {}, title {}, _.startCase @props.pageId #change page title based on pageId
			# Header display with conditions
			HeaderBar {
				..._.pick @props, ['header', 'inline']
				header: if @props.header? and _.isPlainObject @props.header
					if @props.dms?
						DMSDocumentHeader {...@props.header}
					else
						DocumentHeader {...@props.header, ...@props.header.props}
				headerColor: if @props.header?.color? then @props.header.color
				reversedColors: @props.header?.reversedColors
				mode: if @props.mode? then ModeIndicator @props.mode
				toolbar: do =>
					toolbarProps = {...@props.toolbar}
					# if _.size(@props.configs) > 1
					# 	if !toolbarProps.standard?
					# 		toolbarProps.standard = []
					# 	toolbarProps.standard.push
					# 		isSelected: @state.viewSettingsOpen
					# 		key: 'viewSettings'
					# 		onClick: => @setState viewSettingsOpen: !@state.viewSettingsOpen
					# 		content: viewSettings

					# if _.size(availability.visibilities) > 1
					# 	if !toolbarProps.standard?
					# 		toolbarProps.standard = []
					# 	toolbarProps.standard.unshift _.map availability.visibilities, @getVisibalityButton
					if (_.size(availability.filtering) > 0) and !@props.forceShowQuickFilters?
						if !toolbarProps.standard?
							toolbarProps.standard = []
						if _.size(availability.filtering) > 1
							# For multiple buttons add them as a group
							toolbarProps.standard.unshift _.map availability.filtering, @getFilteringButton
						else
							availableFiltering = _.first availability.filtering
							# Add single "toggle" button
							toolbarProps.standard.unshift @getFilteringButton availableFiltering
						# console.log availability.filtering
					if !_.isEmpty toolbarProps
						ToolbarElement toolbarProps,
							do =>
								if @props.configs? and _.size(@props.configs) > 1
									configs = _.map @props.configs, (conf, key) => {...conf, key: key, isActive: @state.viewConfig.key is key}
									activeConfig = _.find configs, 'isActive'
									otherConfigs = _.reject configs, 'isActive'
									DropdownMenu
										trigger: Button {appearance: 'subtle', isSelected: @state.viewSettingsOpen, iconBefore: if activeConfig? then activeConfig.icon else Icon viewSettings}
										onOpenChange: (isOpen) => @setState viewSettingsOpen: isOpen
										options: _.map otherConfigs, (conf) =>
											# label: conf.name
											label: ViewConfigItem icon: conf.icon, label: conf.name
											onClick: => @setState viewConfig: @alignConfig conf, @props

				filters: if !@props.hideFilters then @filtersBar()
					# TODO: Parse and recognize queries
					# TODO: slug = key?
				kpi: if @props.kpi? then KPIToolbar @props.kpi
				dms: @props.dms
				acl: @props.acl
			}
			div {className: cnames @props.contentClassName},
				if @state.viewConfig.visibility in ['both', 'view']
					div {
						className: cnames @props.paneClassName
						style: do =>
							layout = if @props.forcedLayout? then @props.forcedLayout else @state.viewConfig.layout
							if @state.viewConfig.visibility is 'both'
								@props.layouts[layout].view
					},
						if _.isFunction @props.views[@state.viewConfig.view]
							@props.views[@state.viewConfig.view] currentRenderProps
						else
							@props.views[@state.viewConfig.view]
				if @state.viewConfig.visibility in ['both', 'accessory']
					div {
						className: cnames [@props.paneClassName, @props.accessoryClassName]
						style: do =>
							layout = if @props.forcedLayout? then @props.forcedLayout else @state.viewConfig.layout
							if @state.viewConfig.visibility is 'both'
								@props.layouts[layout].accessory
					},
						if _.isFunction @props.accessories[@state.viewConfig.accessory]
							@props.accessories[@state.viewConfig.accessory] currentRenderProps
						else
							@props.accessories[@state.viewConfig.accessory]
