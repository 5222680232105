###
Selection summary presents aggregated values based on current selection
###
# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import {FormatterConsumer} from 'libs/formatter'

# Renderable
import { div, img, span } from 'react-dom-factories'

import _PropertiesList from '@bevy/properties-list'
PropertiesList = React.createFactory _PropertiesList

import _Button from '@bevy/button'
Button = React.createFactory _Button

# Styles
import styles from './index.styl'
import {
} from 'react-icons-kit/feather'

export default class SelectionToolbar extends React.Component
	@propTypes: {}
	@defaultProps: {}
	constructor: (props) ->
		super props


	render: ->
		div {className: cnames [styles.base], if !_.isEmpty @props.selected then styles.highlighted},
			Button
				className: cnames styles.clearButton, if !_.isEmpty @props.selected then styles.selected
				spacing: 'compact'
				appearance: 'subtle'
				onClick: @props.clearSelection
			, 'Clear selection'
			PropertiesList
				className: styles.propsList
				columns: 4
				spacing: 'none'
				items: [
						label: 'All group members'
						content: if @props.itemsCount? then @props.itemsCount else '-'
					,
						label: 'Selected members'
						content: if !_.isEmpty @props.selected then _.size @props.selected else '-'
				]
