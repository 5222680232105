###
Edit Group
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import adopt from 'libs/adopt'

# Renderable
import _Spinner from '@bevy/spinner'
Spinner = React.createFactory _Spinner

import _AddGroupModal from '../AddGroupModal'
AddGroupModal = React.createFactory _AddGroupModal

# Data
import {
	GroupEditEnvironment
} from './data'

DataLayer = adopt
	queries:
		groupEditEnvironment: ({ groupID}) ->
			query: GroupEditEnvironment
			variables:
				groupID: groupID

export default class GroupEditModal extends React.Component
	@propTypes =
		onClose: PropTypes.func.isRequired

	getInitialData: (data) =>
		group = _.get data, 'userGroup'
		if !_.isEmpty group
			{
				..._.pick group, ['name', 'description']
				roles: _.map group.roles, (item) -> item.name
				picture: group.picture.original
			}

	render: ->
		DataLayer {groupID: @props.groupID}, (operations) =>
			activeQuery = operations.groupEditEnvironment
			if activeQuery.loading then Spinner {}
			else
				AddGroupModal {
					...@props
					initialSetup: if activeQuery.data? then @getInitialData activeQuery.data
					editQuery: activeQuery
				}

