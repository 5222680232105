import gql from 'graphql-tag'


# QUERIES
export GetMembers = gql """
query getMembers (
		$id: ID!
		$pagination: PaginationInput
		$search: String
		) {
		projectByID(id: $id) {
			id
			members(pagination: $pagination, search: $search) {
				...on ActorAccess {
					user {
						... on Person{
							id
							name
							email
						}
					}
				}
			}
		}
}
"""

# MUTATIONS
export ProjectManagementAdd = gql """
	mutation (
		$projectID: ID!
		$type: String!
		$id: ID!
		$role: String!
		$phaseID: ID!
	) {
		projectManagementAdd(
			projectID: $projectID
			type: $type
			id: $id
			role: $role
			phaseID: $phaseID
		) {
			id
			management {
				type
				phase { id name}
				members {
					user {
						...on Person {
							id name picture {mini}
							}
						}
					role
				}
			}
		}
	}
"""


export ProjectManagementEdit = gql """
	mutation (
		$projectID: ID!
		$type: String!
		$id: ID!
		$role: String!
		$phaseID: ID!
	) {
		projectManagementEdit(
			projectID: $projectID
			type: $type
			id: $id
			role: $role
			phaseID: $phaseID
		) {
			id
			management {
				type
				phase { id name}
				members {
					user {
						...on Person {
							id name picture {mini}
							}
						}
					role
				}
			}
		}
	}
"""

