# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import DataAttribute from '@bevy/data-attribute'
import PropTypes from 'prop-types'

import { div, p, ul, li, small, h1, h2, h3, span, img, button } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import _Avatar from '@atlaskit/avatar'
Avatar = React.createFactory _Avatar

import _Lozenge from '@atlaskit/lozenge'
Lozenge = React.createFactory _Lozenge

import _Tooltip from '@bevy/tooltip'
Tooltip = React.createFactory _Tooltip

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import { Link as _Link } from 'react-router-dom'
Link = React.createFactory _Link

import styles from './index.styl'

import {
	emptySelection
	activeSelection
	favorite
	locked
	attachment
	fresh
	unread
	danger
	warning
	checkBox
} from '@bevy/theme/icons'

export default class MasterDetailItem extends React.Component
	@propTypes:
		href: PropTypes.string
		id: PropTypes.string
		uuid: PropTypes.string
		title: PropTypes.node.isRequired
		group: PropTypes.string
		owner: PropTypes.shape
			name: PropTypes.string
			src: PropTypes.string.isRequired
		teaser: PropTypes.node
		flagLeft: PropTypes.shape
			icon: PropTypes.node
			tooltip: PropTypes.string
		flagRight: PropTypes.shape
			icon: PropTypes.node
			tooltip: PropTypes.string
		specialState: PropTypes.oneOfType [
			PropTypes.oneOf [
				'warning'
				'danger'
			]
			PropTypes.shape
				tooltip: PropTypes.string
				icon: PropTypes.node
				type: PropTypes.oneOf [
					'warning'
					'danger'
				]
		]
		identifier: PropTypes.oneOfType [
			PropTypes.shape
				value: PropTypes.node
				tooltip: PropTypes.string
				danger: PropTypes.bool
				warning: PropTypes.bool
			PropTypes.node
		]
		selected: PropTypes.bool
		isLocked: PropTypes.bool
		isArchived: PropTypes.bool
		isPrimary: PropTypes.bool
		isNew: PropTypes.bool
		isUnread: PropTypes.bool
		hasAttachment: PropTypes.bool


	constructor: (props) ->
		super props
		@state = {}
	render: ->
		content = Fragment {},

		# Left side
			div {
				key: 'selection'
				className: cnames styles.selectionIndicator
			},
				div { className: styles.indicatorElement },
					if @props.selected
						Icon {...checkBox, size: 16}
					else if @props.isFresh
						Icon {...fresh, size: 16}
					else if @props.isUnread
						Icon {...unread, size: 16}
			div {
				key: 'primary'
				className: cnames [styles.indicatorElement, styles.primaryIndicator]
			},
				if @props.isPrimary || @props.primaryIndicator
					if @props.primaryIndicator then @props.primaryIndicator
					else Icon {...favorite}
			div {
				key: 'flagLeft'
				className: [styles.indicatorElement, styles.flagLeft]
			},
				if @props.flagLeft?
					Tooltip {
						content: @props.flagLeft.tooltip
					},
						Icon {...@props.flagLeft.icon}


			# Center
			div {className: cnames(styles.uuid, if @props.indentItem then styles.indent)},
				@props.uuid || @props.id
			div {className: cnames(styles.title, if @props.indentItem then styles.indent)},
				@props.title || span {className: styles.placeholder}, 'Untitled'
			div {
				key: 'flagRight'
				className: cnames styles.flagRight
			},
				if @props.flagRight?
					Tooltip {
						content: @props.flagRight.tooltip
					},
						Icon {...@props.flagRight.icon, size: 16}

			# Right side
			div {className: cnames styles.identifier},
				if @props.specialState?
					if _.isString @props.specialState
						div {className: styles.specialStateIcon},
							if @props.specialState is 'danger'
								Icon {...danger, size: 12}
							else if @props.specialState is 'warning'
								Icon {...warning, size: 12}
					else if _.isPlainObject @props.specialState
						Tooltip {
							content: @props.specialState.tooltip
							error: (@props.specialState.type is 'danger')
						},
							div {className: styles.specialStateIcon},
								if @props.specialState.icon?
									Icon {...@props.specialState.icon, size: 12}
								else
									if @props.specialState.type is 'danger'
										Icon {...danger, size: 12}
									else if @props.specialState.type is 'warning'
										Icon {...warning, size: 12}
					else @props.specialState

				else if @props.isLocked
					div {className: styles.specialStateIcon},
						Tooltip {
							content: @props.lockedDescription || 'Document is non-editable'
						},
							Icon {...locked, size: 12}
				if @props.owner?
					div {
						className: cnames styles.owner
					},
						if @props.owner.name?
							div {className: cnames styles.ownerName}, @props.owner.name
						Avatar {
							...@props.owner
							size: 'xsmall'
						}
				else if @props.identifier?
					if _.isPlainObject @props.identifier
						Tooltip {
							content: @props.identifier.tooltip
						},
							div {className: styles.identifierWrapper},
								div {className: styles.specialStateIcon},
									if @props.identifier.danger then Icon {...danger, size: 12}
									else if @props.identifier.warning then Icon {...warning, size: 12}
								div {
									className: cnames [
										styles.identifierContent
										if @props.identifier.danger then styles.danger
										else if @props.identifier.warning then styles.warning
									]
								}, @props.identifier.value
					else
						div {className: styles.identifierContent}, @props.identifier
			div {className: cnames(styles.teaser, if @props.indentItem then styles.indent)},
				if @props.teaser? then @props.teaser
			div {className: cnames styles.attachment},
				if @props.hasAttachment then Icon {...attachment, size: 16}


		do =>
			itemProps = {
				className: cnames [
					styles.listItem
					@props.className
					'bevy-masterDetail-item'
					if @props.selected then styles.selected
					if @props.isLocked then styles.lockedItem
					else if @props.isArchived then styles.archivedItem
				]
				style: if @props.color? then {borderLeft: "4px solid #{@props.color}"}
				..._.pick @props, [
						'onClick'
						'onDoubleClick'
						'onMouseEnter'
						'onMouseLeave'
					]
			}

			if @props.href?
				Link {
					to: @props.href
					...itemProps
				}, content
			else
				div {
					...itemProps
				}, content
