"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.scopes = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _helpers = require("../helpers");

var _LegalDocument = require("./LegalDocument");

var _LegalPath = require("./LegalPath");

var _Platform = require("./Platform");

var _Project = require("./Project");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

// Generated by CoffeeScript 2.4.1

/*
Helpers
*/
var fetchAllPaths, getPermissions4PathsGroup;
fetchAllPaths = _lodash.default.once(function (db, cache) {
  return new Promise(async function (resolve, reject) {
    var collection;
    collection = await db.collection('legalPaths');
    return resolve((await collection.find({}, {
      projection: {
        permissions: 1
      }
    }).toArray()));
  });
});

getPermissions4PathsGroup = function (name, scope4LegalDocuments, skipPaths) {
  return async function ({
    user,
    parent: projectUserGroups,
    membership,
    resolved,
    db,
    cache
  }) {
    var pathCondition, paths, phasesOfProjectUserGroup;
    paths = await fetchAllPaths(db, cache);
    phasesOfProjectUserGroup = _lodash.default.map(projectUserGroups.roles, function (role) {
      return {
        phaseIDs: role.info.phaseID
      };
    });
    pathCondition = skipPaths ? {} : {
      pathID: {
        $in: _lodash.default.map(_lodash.default.filter(paths, function (path) {
          return path.permissions[name];
        }), '_id')
      }
    };
    return [{
      scopes: [_Project.scopes.ReadLegal, _Project.scopes.ReadPersonnel, _LegalDocument.scopes.Create],
      conditions: _objectSpread({}, (0, _helpers.identityCondition)(resolved, 'project', projectUserGroups.projectID))
    }, {
      scopes: [scope4LegalDocuments],
      conditions: _objectSpread({}, pathCondition, {
        projectID: projectUserGroups.projectID,
        $or: phasesOfProjectUserGroup
      })
    }, {
      scopes: [_LegalPath.scopes.Read, _LegalPath.scopes.Use],
      conditions: {
        $and: [skipPaths ? {} : {
          _id: {
            $in: _lodash.default.map(_lodash.default.filter(paths, function (path) {
              return path.permissions[name];
            }), '_id')
          }
        }, (0, _helpers.projectAssociationCondition)(resolved, {
          _id: projectUserGroups.projectID
        }), {
          $or: [{
            phaseIDs: {
              $exists: false
            }
          }, ...phasesOfProjectUserGroup]
        }]
      }
    }];
  };
};
/*
UserGroup permissions model
*/


var scopes = (0, _helpers.makeDefaultSubject)('ProjectUserGroup', {
  Manage: {
    actions: 'crud'
  },
  Read: {
    actions: 'read'
  },
  Destroy: {
    actions: 'destroy'
  }
});
exports.scopes = scopes;
var _default = {
  // Name of the GraphQL subject
  name: 'ProjectUserGroup',
  // Database collection
  collection: 'projectUserGroups',
  // Name used in making global id's
  globalIDName: 'projectUserGroups',
  // Name used in GraphQL as AccessXXX field name
  accessProperty: 'projectGroup',
  // Available actions on root leve
  actions: ['crud', 'create', 'read', 'update', 'delete'],
  // Alias for create, read, update, delete
  // Model fields with appropiate actions
  fields: {},
  // Available scopes with implicit subject as a model.name
  scopes,
  // Additional fields requested when fetching items for attached and members roles
  projection: {
    projectID: 1
  },
  // Type of models that can become a member of this model without specified role
  accepts: ['User'],
  // Entity based roles default and assignable
  roles: {
    // Roles granted to all users
    default: [],
    // Roles assignable to the entity that will be transfered to the User
    attached: [{
      name: 'Project Skanska CDE Paths',
      permissions: getPermissions4PathsGroup('legal', _LegalDocument.scopes.ReadCommited, true)
    }, {
      name: 'Project Legal Paths',
      permissions: getPermissions4PathsGroup('legal', _LegalDocument.scopes.Read)
    }, {
      name: 'Project Controlling Paths',
      permissions: getPermissions4PathsGroup('controlling', _LegalDocument.scopes.ReadCommited)
    }, {
      name: 'Project Accounting Paths',
      permissions: getPermissions4PathsGroup('accounting', _LegalDocument.scopes.ReadCommited)
    }, {
      name: 'Project Taxes Paths',
      permissions: getPermissions4PathsGroup('taxes', _LegalDocument.scopes.ReadCommited)
    }, {
      name: 'Project Facility Paths',
      permissions: getPermissions4PathsGroup('facility', _LegalDocument.scopes.ReadCommited)
    }, {
      name: 'Project Marketing Paths',
      permissions: getPermissions4PathsGroup('marketing', _LegalDocument.scopes.ReadCommited)
    }, {
      name: 'Project TQT Paths',
      permissions: getPermissions4PathsGroup('tqt', _LegalDocument.scopes.ReadCommited)
    }, {
      name: 'Project Leasing Paths',
      permissions: getPermissions4PathsGroup('leasing', _LegalDocument.scopes.ReadCommited)
    }, {
      name: 'Project Asset Management Paths',
      permissions: getPermissions4PathsGroup('assetManagement', _LegalDocument.scopes.ReadCommited)
    }, {
      name: 'Project Skanska SA Paths',
      permissions: getPermissions4PathsGroup('skanskaSA', _LegalDocument.scopes.ReadCommited)
    }, {
      name: 'Project Architects Paths',
      permissions: getPermissions4PathsGroup('architects', _LegalDocument.scopes.ReadCommited)
    }, {
      name: 'Project Consultant Paths',
      permissions: getPermissions4PathsGroup('consultants', _LegalDocument.scopes.ReadCommited)
    }, {
      name: 'Project Staging environment',
      description: 'Role with access to platform in <Staging> environment',
      permissions: function ({
        user,
        parent: projectUserGroups,
        membership,
        resolved,
        db,
        cache
      }) {
        return [{
          scopes: [_Platform.scopes.Staging],
          conditions: {
            projectID: projectUserGroups.projectID
          }
        }];
      }
    }, {
      name: 'Project Test environment',
      description: 'Role with access to platform in <Test> environment',
      permissions: function ({
        user,
        parent: projectUserGroups,
        membership,
        resolved,
        db,
        cache
      }) {
        return [{
          scopes: [_Platform.scopes.Test],
          conditions: {
            projectID: projectUserGroups.projectID
          }
        }];
      }
    }, {
      name: 'Project Development environment',
      description: 'Role with access to platform in <Development> environment',
      permissions: function ({
        user,
        parent: projectUserGroups,
        membership,
        resolved,
        db,
        cache
      }) {
        return [{
          scopes: [_Platform.scopes.Development],
          conditions: {
            projectID: projectUserGroups.projectID
          }
        }];
      }
    }],
    // Roles assignable to the members (Users) of this entity
    members: []
  }
};
exports.default = _default;