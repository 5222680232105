###
Third substep of basic step
###

# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import moment from 'moment-mini'

# Renderable
import { div, p, ul, li, small, h1, span, h2, img } from 'react-dom-factories'

Fragment = React.createFactory React.Fragment
import { Query as _Query } from 'react-apollo'
Query = React.createFactory _Query

import _Select, {components} from '@bevy/select'
Select = React.createFactory _Select

import {
	LoadableSelect as _LoadableSelect
	NoOptionsMessage
} from '@bevy/select'
LoadableSelect = React.createFactory _LoadableSelect

import { Can } from 'libs/userInfo'
import { getTypeOfOwnership } from 'libs/legal'

import { Checkbox as _Checkbox } from '@atlaskit/checkbox'
Checkbox = React.createFactory _Checkbox

import _FieldText from '@atlaskit/textfield'
FieldText = React.createFactory _FieldText
import _FieldTextArea from '@atlaskit/textarea'
FieldTextArea = React.createFactory _FieldTextArea

import { Field as _Field } from '@atlaskit/form'
Field = React.createFactory _Field

import { DatePicker as _DatePicker } from '@atlaskit/datetime-picker'
DatePicker = React.createFactory _DatePicker

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import _Button from '@bevy/button'
Button = React.createFactory _Button

import _Tooltip from '@bevy/tooltip'
Tooltip = React.createFactory _Tooltip

import _Debouncer from 'components/Debouncer'
Debouncer = React.createFactory _Debouncer

# Styles
import styles from './index.styl'
import { info } from 'react-icons-kit/feather'

# Data
import {
	getProjectMembers
	getOrganizations
	getBuildingsSets
} from '../../data'
import trLegal from 'data/local/legal'

datesLabels = {
	'CreationDate': trLegal.props.creationDate
	'ValidationDate': trLegal.props.validationDate
	'ExpirationDate': trLegal.props.expirationDate
}
entitiesLabels = {
	'Participants': trLegal.props.participants
	'Recipient': trLegal.props.recipient
}
identifiersLabels = {
	'Number': trLegal.props.number
	'CaseNumber': trLegal.props.caseNumber
	'PrecintNumber': trLegal.props.precinctNumber
	'KRSNumber': trLegal.props.KRDNumber
	'NIPNumber': trLegal.props.NIPNumber
	'VatNumber': trLegal.props.VatNumber
	'REGONNumber': trLegal.props.REGONNumber
	'LandRegisterNumber': trLegal.props.landRegisterNumber
	'ParcelNumber': trLegal.props.parcelNumber
	'TypeOfOwnership': trLegal.props.typeOfOwnership
}

export default class DetailsForm extends React.Component
	constructor: (props) ->
		super props
		@state = {}
	convertToOptions: (items) ->
		if _.isArray items
			_.map items, (item) ->
				if item?
					label: item.name
					value: if item.id then item.id else item.name
		else if items.label? and items.value?
			items
		else
			label: items.name
			value: if items.id then items.id else items.name

	BuildingMenuList: (props) =>
		Fragment {},
			if !_.isEmpty props.selectProps.buildingCombinations
				div {className: styles.buildingCombinations},
					_.map props.selectProps.buildingCombinations, (combination) ->
						Button
							className: styles.buildingCombination
							shouldFitContainer: true
							spacing: 'compact'
							onClick: =>
								props.setValue _.filter props.options, (option) ->
									_.some combination.codes, (value) ->
										value is option.value
							, combination.name
			div {className: styles.buildingList},
				components.MenuList props, props.children

	MultiValueRemove: (props) =>
		if @props.contractor? and @props.contractor.id is props.data.value
			null
		else
			React.createElement components.MultiValueRemove, props

	render: ->
		div { className: cnames styles.stepContainer, if !@props.active then styles.emptyContainer},
			if @props.multipleScans
				div {className: styles.placeholder}, 'You cannot add detailed informations for documents in batch registration mode.'
			else if @props.active
				{dates, identifiers, entities} = @props.value
				{ combination, phases } = @props
				div {},
					div { className: styles.sectionWrapper },
						# building
						if combination.building?
							Query
								query: getBuildingsSets
								variables:
									projectSlug: @props.project.slug
							, ({ loading, data }) =>
								options = _.map (if data?.project?.buildingsSets? then data.project.buildingsSets else []), (item) ->
									label: item.name
									value: item.code
								possiblePhaseIDs = _.map (_.get data, 'project.phases'), 'id'
								selectedPhaseIDs = _.map phases, 'value'
								if _.isEmpty selectedPhaseIDs
									selectedPhaseIDs = possiblePhaseIDs
								buildingCombinations = _.get data, 'project.buildingsCombinations', []
								buildingCombinations = _.map buildingCombinations, (combination) ->
									{...combination, codeKey: _.join combination.codes.sort(), '.'}
								buildingCombinations = _.filter buildingCombinations, (buildingCombination) => # get combinations from selected phases
									_.some selectedPhaseIDs, (documentPhaseID) -> buildingCombination.phaseID is documentPhaseID
								buildingCombinations = _.sortBy (_.uniqBy buildingCombinations, 'codeKey'), 'codeKey' # remove duplicates and sort
								Field
									label:
										div {className: styles.label},
											trLegal.props.buildings
											div {className: styles.missingInfo},
												'Required for approval'
									name: 'buildings'
								, =>
									Fragment {},
										Select
											menuPortalTarget: document.body
											isMulti: true
											hideSelectedOptions: false
											resetMultiFormatting: true
											# className: styles.formItem
											value: @props.value.buildings
											placeholder:
												div {className: styles.placeholderText},
													'Select buildings...'
											onChange: (value) =>
												@props.onChange { ...@props.value, buildings: value }
											options: options
											components: {MenuList: @BuildingMenuList}
											buildingCombinations: buildingCombinations

						Field
							label: div {className: styles.label},
								'Free text'
								div {className: styles.iconTooltip},
									Tooltip
										content: 'Max. 30 characters added to file name when downloaded'
									,
										Icon {icon: info, size: 9}
							name: 'Free text'
						, =>
							div {className: styles.formItemText},
								Debouncer
									wait: 250
									onChange: (value) =>
										@props.onChange { ...@props.value, freeText: value }
									value: @props.value.freeText
								, ({onChange, value}) ->
									FieldText
										isLabelHidden: true
										className: styles.formItem
										maxLength: 30
										placeholder: 'Add text... (optional)'
										onChange: onChange
										value: value
										shouldFitContainer: true

						# dates
						do =>
							datesFields = _.reject combination.dates, (item) -> _.includes ['CreationDate', 'ReceivalDate'], item.type
							if !_.isEmpty datesFields
								div { className: cnames styles.sectionWrapper, styles.sectionHorizontal},
									_.map datesFields, (field) =>
										Field
											label: span {className: styles.label}, datesLabels[field.type]
											key: field.type
											name: field.type
										, =>
											div {className: cnames styles.formItem, styles.datePicker},
												DatePicker
													id: field.type
													formatDisplayLabel: (value, dateFormat) -> value
													dateFormat: 'DD/MM/YYYY'
													parseInputValue: (value, dateFormat) -> moment(value, dateFormat).toDate()
													placeholder: "e.g. #{moment().format('DD/MM/YYYY')}"
													onChange: (value) =>
														dates = {...dates, [field.type]: moment.utc(value, 'YYYY-MM-DD').unix()}
														@props.onChange { ...@props.value, dates }
													value: if dates[field.type]? then moment.unix(dates[field.type]).format('DD/MM/YYYY')

						# identifiers
						do => if !_.isEmpty combination.identifiers
							div { className: cnames styles.sectionWrapper, styles.sectionHorizontal },
								_.map combination.identifiers, (field) =>
									Field
										label:
											div {className: styles.label},
												identifiersLabels[field.type]
												if field.isRequired
													div {className: styles.missingInfo},
														'Required for approval'
										key: field.type
										name: field.type
									, =>
										if field.type is 'TypeOfOwnership'
											options =  getTypeOfOwnership()
											Select
												isMulti: false
												hideSelectedOptions: false
												resetMultiFormatting: true
												className: cnames [styles.selectOwnership]
												value: if identifiers[field.type]? then {label: _.upperFirst _.toLower _.startCase identifiers[field.type], value: identifiers[field.type]}
												placeholder: 'Select type of ownership'
												onChange: (value) =>
													identifiers = {...identifiers, [field.type]: value.value}
													@props.onChange { ...@props.value, identifiers}
												options: options
												components: {MenuList: @BuildingMenuList}
												menuPortalTarget: document.body
										else
											div {className: styles.formItemText},
											Debouncer
												wait: 250
												onChange: (value) =>
													identifiers = {...identifiers, [field.type]: value}
													@props.onChange { ...@props.value, identifiers }
												value: if identifiers[field.type]? then identifiers[field.type]
											, ({onChange, value}) ->
												FieldText
													placeholder: "Add document #{_.toLower identifiersLabels[field.type]}..."
													isLabelHidden: true
													className: styles.formItem
													onChange: onChange
													value: value
													shouldFitContainer: true

						# External ID
						Can {do: 'external', of: {__typename: 'LegalDocument', projectID: @props.project.id}},
							div { className: cnames styles.sectionWrapper, styles.sectionHorizontal },
								Field
									label:
										div {className: styles.label},
											'External ID'
											div {className: styles.iconTooltip},
												Tooltip
													content: 'Signature or number from the system used in your organization (not Skanska)'
												,
													Icon {icon: info, size: 9}

									key: 'externalID'
									name: 'External ID'
								, =>
									div {className: styles.formItemText},
										Debouncer
											wait: 250
											onChange: (value) =>
												identifiers = {...identifiers, ExternalID: value}
												@props.onChange { ...@props.value, identifiers }
											value: if identifiers.ExternalID? then identifiers.ExternalID else ''
										, ({onChange, value}) ->
											FieldText
												placeholder: 'Add external ID...'
												isLabelHidden: true
												className: styles.formItem
												onChange: onChange
												value: value
												shouldFitContainer: true

						# entities
						do =>
							if !_.isEmpty combination.entities
								div { className: styles.sectionWrapper },
									_.map combination.entities, (field, index) =>
										if field.type is 'Recipient' #FIXME: not working recipinent in mutation
											Query
												key: field.type
												query: getProjectMembers
												variables:
													projectSlug: @props.project.slug
											, ({ loading, data }) =>
												if loading
													null
												else
													options = if data.project.members then _.map data.project.members, (member) -> member.user
													options = _.orderBy options, 'name'
													options = @convertToOptions options
													Field
														label:
															div {className: styles.label},
																entitiesLabels[field.type]
																div {className: styles.iconTooltip},
																	Tooltip
																		content: 'Recipient of a given document'
																	,
																		Icon {icon: info, size: 9}
																if field.isRequired
																	div {className: cnames styles.missingInfo, styles.withIcon},
																		'Required for approval'
														key: field.type
														name: field.type
													, =>
														Select
															# className: styles.formItem
															menuPortalTarget: document.body
															isSearchable: true
															isMulti: false
															options: options
															onChange: (value) =>
																newEntities = [{id: value.value, name: value.label}]
																entities = {...entities, [field.type]: newEntities }
																@props.onChange { ...@props.value, entities }
															value: if entities[field.type]? then @convertToOptions entities[field.type]
										else
											Field
												label:
													div {className: styles.label},
														'Involved entities'
														div {className: styles.iconTooltip},
															Tooltip
																content: 'Entities related to a given document'
															,
																Icon {icon: info, size: 9}
														if field.isRequired
															div {className: cnames styles.missingInfo, styles.withIcon},
																'Required for approval'
												key: field.type
												name: field.type
											, =>
												values = entities[field.type]
												if @props.contractor?.id?
													contratorItem = _.find values, (item) => item.id is @props.contractor.id
													values = _.reject values, (item) => item.id is @props.contractor.id
													values = _.compact _.concat contratorItem, values
												LoadableSelect
													# className: styles.formItem
													menuPortalTarget: document.body
													isSearchable: true
													isClearable: true
													isMulti: true
													resetMultiFormatting: true
													hideSelectedOptions: false
													placeholder:
														div {className: styles.placeholderText},
															'Select involved entities... (multiselect)'
													query:
														query: getOrganizations
														variables:
															projectSlug: @props.projectSlug
															pagination:
																limit: 20
															sort:
																key: 'Name'
																order: 'Ascending'
													makeOptionsFromData: (data) =>
														if data?.project?
															organizations = _.orderBy data.project.organizations, (x) -> _.upperFirst x.name
															options = @convertToOptions organizations
															if !_.isEmpty @props.temporaryOrganizations
																options = _.concat options, @convertToOptions @props.temporaryOrganizations
															options
														else
															[]
													values: if entities[field.type]? then @convertToOptions values
													handleValueToDisplay: (currentValue, listOfPossibilities) =>
														if currentValue? then currentValue
													onChange: (value) =>
														if value?
															newEntities = _.map value, (item) ->
																{id: item.value, name: item.label, __typename: 'Organization', fromContractor: false}
															entities = {...entities, [field.type]: newEntities }
														else
															@setState createdExternalCompany: {}
															entities = {}
														@props.onChange { ...@props.value, entities }
													hasMore: @state.hasMore
													handleDataUpdate: (prev, fetchMoreResult) =>
														if _.isEmpty fetchMoreResult.project.organizations
															@setState hasMore: false
														{
															...prev
															project: {
																...prev.project
																organizations: [...prev.project.organizations, ...fetchMoreResult.project.organizations]
															}
														}
													components: {
														@MultiValueRemove
													}
													noOptionsMessage: () =>
														if @props.projectLeader?
															NoOptionsMessage
																fieldName: trLegal.props.source
																contactPerson: {...@props.projectLeader, role: 'Project Leader'}
													# onCreateOption: (value) =>
													# 	newEntity = {name: value}
													# 	entitiesOfType = _.concat @props.value.entities[field.type], newEntity
													# 	entities = {...@props.value.entities, [field.type]: entitiesOfType }
													# 	@props.onChange { ...@props.value, entities}
													# 	@props.onTemporaryCreate newEntity


			else
				div {className: styles.placeholder}, 'Please fill all required fields to see detailed document information'
