###
Item on Dropdown to pick / set master detail view
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'

# Renderable
import { div, span } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

# Styles
import styles from './index.styl'

import {
	close
} from '@bevy/theme/icons'


export default class HeaderBar extends React.Component
	@propTypes: {}
	@defaultProps: {}
	render: ->
		div {className: cnames @props.className, styles.base},
			do => if @props.icon?
				@props.icon
			span {className: styles.label}, @props.label
