###
Opportunity details presents crucial informations about opportunity and all leasing offers in that opportunity
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import {FormatterConsumer} from 'libs/formatter'
import moment from 'moment-mini'

# Renderable
import { div, h2 } from 'react-dom-factories'

import _DocumentHeader from '@bevy/document-header'
DocumentHeader = React.createFactory _DocumentHeader

import { ButtonGroup as _ButtonGroup } from '@atlaskit/button'
ButtonGroup = React.createFactory _ButtonGroup

import _Button from '@bevy/button'
Button = React.createFactory _Button

import _Toolbox from 'components/Toolbox'
Toolbox = React.createFactory _Toolbox

import _HeaderBar from 'components/HeaderBar'
HeaderBar = React.createFactory _HeaderBar

import _KPIToolbar from '@bevy/kpi-toolbar'
KPIToolbar = React.createFactory _KPIToolbar

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import _Avatar, {AvatarItem as _AvatarItem} from '@atlaskit/avatar'
Avatar = React.createFactory _Avatar
AvatarItem = React.createFactory _AvatarItem


# Styles
import styles from './index.styl'
import {
	paperclip
	messageSquare
	share2
	download
	externalLink
	arrowLeft
	arrowUpLeft
	chevronLeft
	clipboard
} from 'react-icons-kit/feather'

export default class ObservationHeader extends React.Component
	renderHeader: =>
		DocumentHeader
			type: @props.code
			title: "#{@props.createdBy.name}, #{moment.unix(@props.createdAt).format 'DD/MM/YYYY HH:MM'}"
			status:
				name: switch @props.status
					when 'New' then 'New'
					when 'PendingCheck' then 'Pending Check'
					when 'Checked' then 'Checked'
					when 'OpenIssue' then 'Synced with BIM360'
					when 'OverdueIssue' then 'Overdue issue'
					when 'Resolved' then 'Resolved'
				appearance: switch @props.status
					when 'New', 'PendingCheck' then 'default'
					when 'Checked' then 'inprogress'
					when 'OpenIssue' then 'moved'
					when 'OverdueIssue' then 'removed'
					when 'Resolved' then 'success'
			description: @props.description
			author:
				name: @props.modifiedBy.name
			lastUpdate: @props.modifiedAt
			actions:
				ButtonGroup {},
					Button {
						tooltip: 'Attachments'
						appearance: 'subtle-link'
						iconBefore:
							Icon
								icon: paperclip
								size: 16
					}
					Button {
						tooltip: 'Comments'
						appearance: 'subtle-link'
						iconBefore:
							Icon
								icon: messageSquare
								size: 16
					}
					Button {
						tooltip: 'Share'
						appearance: 'subtle-link'
						iconBefore:
							Icon
								icon: share2
								size: 16
					}
	renderKPI: =>
		FormatterConsumer {}, (format) =>
			KPIToolbar
				items: [
						key: 'reporter'
						description: 'Reporter'
						tooltip: 'Person who reported the observation'
						value:
							div {className: styles.avatarWrapper},
								AvatarItem
									avatar: Avatar {
										src: @props.modifiedBy.picture.mini
										size: 'small'
									}
									primaryText:
										@props.modifiedBy.name

					,
						key: 'reporting-time'
						description: 'Reporting time'
						tooltip: 'Time of the record of the observation'
						value: moment.unix(@props.createdAt).format('DD/MM/YYYY HH:MM')
					,
						key: 'bim-status'
						description: 'BIM status'
						tooltip: 'BIM status'
						value: if @props.bimIssue? then @props.bimIssue.status else '-'
				]
	renderToolbar: =>
		if @props.inReview
			Toolbox
				borderless: true
				actions:
					switch @props.status
						when 'New'
							ButtonGroup {},
								Button {appearance: 'subtle', onClick: @props.delete}, 'Delete'
						when 'PendingCheck'
							[
								# Button {appearance: 'subtle', onClick: @props.syncToBim}, 'Sync to BIM360'
								Button {appearance: 'subtle', onClick: @props.checked}, 'Mark as checked'
								Button {appearance: 'subtle', onClick: @props.delete}, 'Delete'
							]
						when 'Checked'
							[
								# Button {appearance: 'subtle', onClick: @props.syncToBim}, 'Sync to BIM360'
								Button {appearance: 'subtle', onClick: @props.pending}, 'Mark as pending'
								Button {appearance: 'subtle', onClick: @props.delete}, 'Delete'
							]
						else
							null
				custom: []
				external: []

	render: ->
		div {
			className: cnames [
					styles.base
					@props.className
				]
			..._.pickBy @props, (val, key) -> /^data-/.test key
			},
				HeaderBar {
					className: styles.header
					document: true
					compact: true
					header: @renderHeader()
					descriptor: @renderKPI()
					toolbar: @renderToolbar()
				}
