import _ from 'lodash'
import React from 'react'
import moment from 'moment-mini'
import {FormatterConsumer} from 'libs/formatter'
import cnames from 'classnames'
import DataAttribute from '@bevy/data-attribute'

# Renderables
import { div, span, img, p } from 'react-dom-factories'

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import { Link as _Link } from 'react-router-dom'
Link = React.createFactory _Link

import _Tooltip from '@bevy/tooltip'
Tooltip = React.createFactory _Tooltip

# Data
import trLegal from 'data/local/legal'

import styles from './columns.styl'
import {
	messageSquare
	paperclip
	fileText
} from 'react-icons-kit/feather'


hasScan =
	key: 'HasScan'
	query: 'hasScan'
	label: 'Scan'
	width: 35
	isNotResizable: true
	sortKey: 'HasScan'
	render: ({ item }) ->
		div {className: cnames styles.compactText, if item.isFresh then styles.fresh},
			if item.hasScan then Icon {icon: paperclip, size: 11}

description =
	key: 'description'
	query: 'description'
	label: 'Note'
	width: 35
	isNotResizable: true
	sortKey: 'description'
	render: ({ item }) -> div {className: cnames styles.compactText, if item.isFresh then styles.fresh},
		if !_.isEmpty item.description then Icon {icon: messageSquare, size: 11}

age =
	key: 'Age'
	query: 'createdAt'
	label: trLegal.props.age
	sortKey: 'CreatedAt'
	width: 60
	isSortable: true
	render: ({ item }) ->
		FormatterConsumer {}, (format) ->
			span {className: cnames styles.compactText, if item.isFresh then styles.fresh},
				do =>
					diff = moment().diff(moment.unix(item.createdAt), 'days')
					if diff > 1 then  "#{diff} days"
					else if diff is 1 then "#{diff} day"
					else 'today'

form =
	key: 'Form'
	query: 'form {id name}'
	label: trLegal.props.type
	sortKey: 'Form'
	isSortable: true
	minWidth: 60
	width: 100
	render: ({ item }) ->
		div {
			className: cnames styles.compactText, if item.isFresh then styles.fresh
			'data-test-id': DataAttribute 'documents-list-form-cell'
		},
			if item.form? then item.form.name
			else span {className: styles.textSpecial}, 'Scan'

topic =
	key: 'Topic'
	query: 'topic {id name}'
	label: trLegal.props.topic
	sortKey: 'Topic'
	isSortable: true
	width: 200
	render: ({ item }) ->
		div
			className: cnames styles.compactText, if item.isFresh then styles.fresh
			onClick: (e) -> e.stopPropagation()
			'data-test-id': DataAttribute 'documents-list-topic-cell'
		, do ->
			Link {className: styles.link, to: item.href},
				if item.topic?
					item.topic.name
				else if item.scan?.filename?
					item.scan.filename

correspondent =
	key: 'Correspondent'
	query: 'correspondent {id name}'
	label: trLegal.props.source
	sortKey: 'Correspondent'
	width: 200
	isSortable: true
	render: ({ item }) ->
		div {className: cnames styles.compactText, if item.isFresh then styles.fresh},
			if item.correspondent? then item.correspondent.name
			else if item.scan?.source? then item.scan.source
			else '-'

name =
	key: 'name'
	query: 'name'
	label: 'File name'
	sortKey: 'FileName'
	width: 300
	maxWidth: 800
	isSortable: false
	render: ({ item }) ->
		Tooltip
			className: styles.tooltip
			content: item.name
			showTimeout: 200
			hideTimeout: 5000
		,
			div {className: cnames styles.compactText, if item.isFresh then styles.fresh},
				item.name

status =
	key: 'status'
	query: 'status'
	label: 'Status'
	sortKey: 'Status'
	width: 80
	isSortable: true
	render: ({ item }) ->
		span {className: cnames styles.compactText, if item.isFresh then styles.fresh},
			if item.hasReview and item.hasDetails and item.hasCategory and item.hasScan
				'Awaiting approval'
			else if item.hasDetails and item.hasCategory and item.hasScan
				'To review'
			else if item.hasCategory and item.hasScan
				'To specify'
			else if item.hasScan
				'To categorize'
			else if item.hasCategory
				'Scan required'
			else
				'-'

mainContract =
	key: 'MainContract'
	query: 'parentDocument {id identifiers {type value}}'
	label: trLegal.props.mainContract
	sortKey: 'MainContract'
	width: 120
	isSortable: true
	render: ({item, }) ->
		if item.parentDocument?
			numberIdentifier = _.find(item.parentDocument.identifiers, {type: 'Number'})
			Link
				className: cnames styles.compactText, styles.link, if item.isFresh then styles.fresh
				to: _.get item, 'parentDocument.href'
			,
				Icon {icon: fileText, size: 11}
				if numberIdentifier? then numberIdentifier.value

		else div {className: cnames styles.compactText, styles.centered}, '-'

# originalDocument =
# 	key: 'OriginalDocument'
# 	query: 'originalDocument {id}'
# 	selectedByDefault: false
# 	header:
# 		label: trLegal.props.originalDocument
# 		sortKey: 'OriginalDocument'
# 		width: min: 50
# 		isSortable: true
# 	cell: (doc, displayDocument) ->
# 		if item.originalDocument?
# 				div
# 					className: cnames styles.compactText, styles.centered, styles.link
# 					onClick: -> displayDocument item.originalDocument
# 				, Icon {icon: copy, size: 12}
# 			else div {className: cnames styles.compactText, styles.centered},

# Dates
documentDate =
	key: 'DocumentDate'
	query: 'dates {type value}'
	label: trLegal.props.creationDate
	sortKey: 'CreationDate'
	width: 80
	maxWidth: 160
	isSortable: true
	render: ({ item }) ->
		date = _.find item.dates, type: 'CreationDate'
		FormatterConsumer {}, (format) ->
			span {className: cnames styles.compactText, if item.isFresh then styles.fresh},
				if date? then format.date date.value else '-'

receivalDate =
	key: 'ReceivalDate'
	query: 'dates {type value}'
	label: trLegal.props.receivalDate
	sortKey: 'ReceivalDate'
	width: 80
	maxWidth: 160
	isSortable: true
	render: ({ item }) ->
		FormatterConsumer {}, (format) ->
			span {className: cnames styles.compactText, if item.isFresh then styles.fresh},
				format.date _.find(item.dates, type: 'ReceivalDate').value

validationDate =
	key: 'ValidationDate'
	query: 'dates {type value}'
	label: 'Validity'
	sortKey: 'ValidationDate'
	width: 80
	maxWidth: 160
	isSortable: true
	render: ({ item }) ->
		FormatterConsumer {}, (format) ->
			date = _.find item.dates, type: 'ValidationDate'
			span {className: cnames styles.compactText, if item.isFresh then styles.fresh},
				if date? then format.date date.value else '-'

expirationDate =
	key: 'ExpirationDate'
	query: 'dates {type value}'
	label: 'Expiration'
	sortKey: 'ExpirationDate'
	width: 80
	maxWidth: 160
	isNotResizable: true
	isSortable: true
	render: ({ item }) ->
		FormatterConsumer {}, (format) ->
			date = _.find item.dates, type: 'ExpirationDate'
			span {className: cnames styles.compactText, if item.isFresh then styles.fresh},
				if date? then format.date date.value else '-'

registeredAt =
	key: 'RegisteredAt'
	query: 'createdAt'
	label: trLegal.props.createdAt
	sortKey: 'CreatedAt'
	width: 80
	maxWidth: 160
	isSortable: true
	render: ({ item }) ->
		FormatterConsumer {}, (format) ->
			span {className: cnames styles.compactText, if item.isFresh then styles.fresh}, format.date item.createdAt

modifiedAt =
	key: 'ModifiedAt'
	query: 'modifiedAt'
	label: trLegal.props.modifiedAt
	sortKey: 'ModifiedAt'
	width: 80
	maxWidth: 160
	isSortable: true
	render: ({ item }) ->
		FormatterConsumer {}, (format) ->
			span {className: cnames styles.compactText, if item.isFresh then styles.fresh},
				if item.modifiedAt? then format.date item.modifiedAt else '-'


# Entities
asignee =
	key: 'Assignee'
	query: 'asignee { ... on Person { id, name } ... on Service { id, name } }'
	label: trLegal.props.assignee
	isSortable: true
	sortKey: 'Assignee'
	width: 100
	render: ({ item }) -> div {className: cnames styles.compactText, if item.isFresh then styles.fresh}, item.asignee.name

registeredBy =
	key: 'RegisteredBy'
	query: 'createdBy {
		... on Person { id name }
		...on Service { name, id }
	}'
	label: trLegal.props.createdBy
	sortKey: 'CreatedBy'
	isSortable: true
	width: 100
	render: ({ item }) -> div {className: cnames styles.compactText, if item.isFresh then styles.fresh}, item.createdBy.name

recipient =
	key: 'Recipient'
	query: 'entities { recipient { ... on Person { id name } } }'
	label: trLegal.props.recipient
	# sortKey: 'CreatedBy'
	# isSortable: true
	width: 100
	render: ({ item }) -> div {className: cnames styles.compactText, if item.isFresh then styles.fresh},
		if item.recipient? then item.recipient.name else '-'

project =
	key: 'project'
	label: 'Project'
	sortKey: 'Project'
	width: 80
	minWidth: 50
	maxWidth: 80
	isSortable: true
	render: ({ item }) -> div {className: cnames styles.compactText, if item.isFresh then styles.fresh},
		if item.project? then item.project else '-'


# Project
company =
	key: 'Company'
	query: 'company {id name}'
	label: trLegal.props.company
	sortKey: 'Company'
	width: 100
	isSortable: false
	render: ({ item }) -> div {className: cnames styles.compactText, if item.isFresh then styles.fresh},
		if item.company? then item.company.name

buildings =
	key: 'Buildings'
	query: 'buildings'
	label: trLegal.props.buildings
	sortKey: 'Buildings'
	width: 45
	isSortable: false
	render: ({ item }) -> div {className: cnames styles.compactText, if item.isFresh then styles.fresh},
		if item.buildings? and !_.isEmpty item.buildings then _.join (_.sortBy item.buildings), ', ' else '-'

phases =
	key: 'Phases'
	query: 'phases {id name}'
	label: 'Phases'
	sortKey: 'Phases'
	width: 45
	isSortable: false
	render: ({ item }) ->
		div {className: cnames styles.compactText, if item.isFresh then styles.fresh},
			if item.phases? and !_.isEmpty item.phases
				phases = _.map item.phases, 'name'
				_.join (_.sortBy phases), ', '
			else
				'-'

freeText =
	key: 'FreeText'
	query: 'freeText'
	label: 'Free text'
	sortKey: 'FreeText'
	width: 80
	isSortable: false
	render: ({ item }) -> div {className: cnames styles.compactText, if item.isFresh then styles.fresh},
		if item.freeText? then item.freeText else '-'

typeOwnership =
	key: 'TypeOfOwnership'
	query: 'identifiers {type value}'
	label: 'Type of ownership'
	sortKey: 'TypeOfOwnership'
	width: 80
	isSortable: false
	render: ({ item }) ->
		span {className: cnames styles.compactText},
			if item.identifiers? and (_.size (_.find item.identifiers, (identfier) -> identfier.type is 'TypeOfOwnership')) isnt 0
				_.upperFirst _.toLower _.startCase _.get (_.find item.identifiers, (identfier) -> identfier.type is 'TypeOfOwnership'), 'value'
			else '-'
# Identifiers
identifiers = [
	key: 'Number'
	label: trLegal.props.number
,
	key: 'CaseNumber'
	label: trLegal.props.caseNumber
,
	key: 'LandRegisterNumber'
	label: trLegal.props.landRegisterNumber
	width: 160
,
	key: 'NIPNumber'
	label: trLegal.props.NIPNumber
,
	key: 'KRSNumber'
	label: trLegal.props.KRSNumber
,
	key: 'VatNumber'
	label: trLegal.props.VatNumber
,
	key: 'REGONNumber'
	label: trLegal.props.REGONNumber
,
	key: 'ExternalID'
	label: trLegal.props.ExternalID
,
	key: 'ParcelNumber'
	label: trLegal.props.parcelNumber
,
	key: 'PercintNumber'
	label: trLegal.props.precinctNumber
	width: 160
]

identifiersColumns = _.map identifiers, (identifier) ->
	{
		key: identifier.key
		query: 'identifiers {type value}'
		selectedByDefault: false
		label: identifier.label
		isSortable: true
		sortKey: identifier.key
		width: if identifier.width? then identifier.width else 120
		render: ({ item }) ->
			number = _.find item.identifiers, {type: identifier.key}
			div {className: cnames styles.compactText, if item.isFresh then styles.fresh},
			if number? then number.value else '-'
	}

export INBOX_COLUMNS = do ->
	columns =
		[
			# default columns
			{ ...hasScan, selectedByDefault: true }
			{ ...description, selectedByDefault: true }
			{ ...age, selectedByDefault: true }
			{ ...form, selectedByDefault: true }
			{ ...topic, selectedByDefault: true }
			{ ...correspondent, selectedByDefault: true }
			{ ...asignee, selectedByDefault: true }
			{ ...receivalDate, selectedByDefault: true }
			{ ...status, selectedByDefault: true }
			{ ...name, selectedByDefault: true }

			# dates
			{ ...registeredAt, selectedByDefault: false }
			{ ...modifiedAt, selectedByDefault: false }
			{ ...documentDate, selectedByDefault: false }
			{ ...validationDate, selectedByDefault: false }
			{ ...expirationDate, selectedByDefault: false }
			# entities
			# { ...recipient, selectedByDefault: false }
			{ ...registeredBy, selectedByDefault: false }
			# project
			{ ...buildings, selectedByDefault: false }
			{ ...phases, selectedByDefault: false }
			{ ...freeText, selectedByDefault: false }
			{ ...company, selectedByDefault: false }
			{ ...typeOwnership, selectedByDefault: false}
			# identifiers
			# { ...mainContract, selectedByDefault: false }
			...identifiersColumns
		]
	_.map columns, (column) -> {...column, selected: column.selectedByDefault}

export REGISTRY_COLUMNS = do ->
	columns =
		[
			# default columns
			{ ...form, selectedByDefault: true }
			{ ...topic, selectedByDefault: true }
			{ ...correspondent, selectedByDefault: true }
			{ ...buildings, selectedByDefault: true }
			{ ...phases, selectedByDefault: false }
			{ ...freeText, selectedByDefault: true }
			{ ...documentDate, selectedByDefault: true }
			{ ...receivalDate, selectedByDefault: true}
			{ ...mainContract, selectedByDefault: true }
			{ ...validationDate, selectedByDefault: true }
			{ ...expirationDate, selectedByDefault: true }
			# dates
			{ ...registeredAt, selectedByDefault: false }
			{ ...modifiedAt, selectedByDefault: false}
			# entities
			{ ...asignee, selectedByDefault: false }
			{ ...registeredBy, selectedByDefault: false}
			{ ...recipient, selectedByDefault: false}
			# project
			{ ...company, selectedByDefault: false}
			# identifiers
			...identifiersColumns
			{ ...name, selectedByDefault: false }
			{ ...typeOwnership, selectedByDefault: false}
		]
	_.map columns, (column) -> {...column, selected: column.selectedByDefault}

export LEGAL_COLUMNS = do ->
	columns =
		[
			# default columns
			{ ...project, selectedByDefault: true }
			{ ...name, selectedByDefault: true }
			{ ...form, selectedByDefault: true }
			{ ...topic, selectedByDefault: true }
			{ ...correspondent, selectedByDefault: true }
			{ ...buildings, selectedByDefault: true }
			{ ...freeText, selectedByDefault: true }
			{ ...documentDate, selectedByDefault: true }
			{ ...receivalDate, selectedByDefault: true}
			{ ...mainContract, selectedByDefault: true }
			{ ...validationDate, selectedByDefault: true }
			{ ...expirationDate, selectedByDefault: true }
			# dates
			{ ...registeredAt, selectedByDefault: false }
			{ ...modifiedAt, selectedByDefault: false}
			# entities
			{ ...asignee, selectedByDefault: false }
			{ ...registeredBy, selectedByDefault: false}
			{ ...recipient, selectedByDefault: false}
			# project
			{ ...company, selectedByDefault: false}
			# identifiers
			...identifiersColumns
		]
	_.map columns, (column) -> {...column, selected: column.selectedByDefault}
