###
S360 application / platform layout and navigation
###

# Libs
import _ from 'lodash'
import React from 'react'
import PageTitle from 'components/PageTitle'

# Renderable
import { div, p, ul, li, img, span, iframe } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import {
	Route as _Route
	Switch as _Switch
} from 'react-router-dom'
Route = React.createFactory _Route
Switch = React.createFactory _Switch

import {InvalidHubState as _InvalidHubState} from 'components/ErrorDisplay'
InvalidHubState = React.createFactory _InvalidHubState

import AccountsHub from '../pages/Accounts'
import Dashboard from '../pages/Dashboard'
import Groups from '../pages/Groups'
import ProjectsHub from '../pages/Projects'
import ProjectDashboardOld from '../pages/ProjectDashboardOld'
import ProjectDashboard from '../pages/ProjectDashboard'
import ProjectRevenue from '../pages/ProjectRevenue'
import ProjectCosts from '../pages/ProjectCosts'
import ProjectLegal from '../pages/ProjectLegal'
import ProjectUsers from '../pages/ProjectUsers'
import ProjectGroups from '../pages/ProjectGroups'
import ProjectCompanies from '../pages/ProjectCompanies'
import ProjectScenarios from '../pages/ProjectScenarios'
import ProjectSafety from '../pages/ProjectSafety'
import Users from '../pages/Users'
import ProjectLegalEmail from '../pages/ProjectLegalEmail'

import { Query as _Query} from 'react-apollo'
Query = React.createFactory _Query

import _Spinner from '@bevy/spinner'
Spinner = React.createFactory _Spinner

import gql from 'graphql-tag'

# Styles
import styles from './index.styl'

export default class MainRouter extends React.Component
	render: ->
		pageProps = @props
		Fragment {},
			PageTitle 'Skanska 360'
			Switch {},
				Route
					exact: true
					path: '/'
					render: (props) ->
						React.createElement Dashboard, {...props, ...pageProps}
				Route
					exact: true
					path: '/accounts'
					render: (props) ->
						React.createElement AccountsHub, {...props, ...pageProps}
				Route
					path: '/groups/:id?'
					render: (props) ->
						React.createElement Groups, {...props, ...pageProps}
				Route
					path: '/users'
					render: (props) ->
						React.createElement Users, {...props, ...pageProps}
				Route
					exact: true
					path: '/projects'
					render: (props) ->
						React.createElement ProjectsHub, {...props, ...pageProps}
				Route
					exact: true
					path: '/projects/legal/:sendTime'
					render: (props) ->
						React.createElement ProjectLegalEmail, {...props, ...pageProps}
				Route
					exact: true
					path: '/projects/:project/new'
					render: (props) ->
						div {className: styles.dashboard},
							React.createElement ProjectDashboard, {...props, ...pageProps}
				Route
					exact: true
					path: '/projects/:project'
					render: (props) ->
						div {className: styles.dashboard},
							React.createElement ProjectDashboard, {...props, ...pageProps}
						# projectSlug = _.get(props, 'match.params.project')
						# newProjectsSlugs = ['p180', 'centrum-poludnie', 'centrum-poludnie-3', 'centrum-poludnie-5', 'aleja-pokoju', 'centrum-poludnie-3-4', 'centrum-poludnie-3and4']
						# if projectSlug?
						# 	Query {
						# 		query: gql('query($projectSlug: String!){project(slug: $projectSlug){id, hasProjectCard}}')
						# 		variables:
						# 			projectSlug: projectSlug
						# 	}, ({data, loading, error}) =>
						# 		if loading
						# 			Spinner {}
						# 		else if data.project.hasProjectCard || projectSlug in newProjectsSlugs
						# 			div {className: styles.dashboard},
						# 				React.createElement ProjectDashboard, {...props, ...pageProps}
						# 		else
						# 			React.createElement ProjectDashboardOld, {...props, ...pageProps}
						# else
						# 	React.createElement ProjectDashboardOld, {...props, ...pageProps}
				Route
					exact: true
					path: '/projects/:project/old'
					render: (props) ->
						React.createElement ProjectDashboardOld, {...props, ...pageProps}
				Route
					path: '/projects/:project/revenue'
					render: (props) ->
						React.createElement ProjectRevenue, {...props, ...pageProps}
				Route
					path: '/projects/:project/costs'
					render: (props) ->
						React.createElement ProjectCosts, {...props, ...pageProps}
				Route
					path: '/projects/:project/legal'
					render: (props) ->
						React.createElement ProjectLegal, {...props, ...pageProps}
				Route
					path: '/projects/:project/users'
					render: (props) ->
						React.createElement ProjectUsers, {...props, ...pageProps}
				Route
					path: '/projects/:project/groups/:id?'
					render: (props) ->
						React.createElement ProjectGroups, {...props, ...pageProps}
				Route
					path: '/projects/:project/companies'
					render: (props) ->
						React.createElement ProjectCompanies, {...props, ...pageProps}
				Route
					path: '/projects/:project/scenarios'
					render: (props) ->
						React.createElement ProjectScenarios, {...props, ...pageProps}
				Route
					path: '/projects/:project/safety'
					render: (props) ->
						React.createElement ProjectSafety, {...props, ...pageProps}
				Route
					path: '/projects/:project/'
					exact: false
					render: (props) ->
						InvalidHubState redirectLink: "/projects/#{props.match.params.project}"
				Route
					path: '/'
					exact: false
					render: (props) ->
						InvalidHubState redirectLink: "/"

