###
document reassign modal
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import adopt from 'libs/adopt'
import DataAttribute from '@bevy/data-attribute'
import { getRefetchQueries } from 'libs/legal'

# Renderable
import { div } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import { Query as _Query, Mutation as _Mutation } from 'react-apollo'
Query = React.createFactory _Query
Mutation = React.createFactory _Mutation

import { NotificationConsumer } from '../../../application/components/NotificationManager'

import _BevyModal from '@bevy/modal'
BevyModal = React.createFactory _BevyModal

# Data
import {
	MuteDocuments
	CacheUpdateQuery
} from './data'
import trLegal from 'data/local/legal'

import { FoldersQuery } from '../../../pages/ProjectLegalInbox/data'

REFETCH_QUERIES = [
	'DMS_Hub_counts',
	'DMS_Inbox_Accessory',
	'DMS_Registry_Accessory'
	]

DataLayer = adopt
	mutations:
		muteDocuments: ({setNotification, app}) ->
			mutation: MuteDocuments
			refetchQueries: getRefetchQueries REFETCH_QUERIES, app
			onCompleted: (data) ->
				setNotification
					appearance: 'success'
					content:
						if data.legalDocumentsUpdateMute[0].isMuted
							'Alerts turned off'
						else
							'Alerts turned on'
			onError: (error) ->
				setNotification
					content: trLegal.error.actionFailure
					appearance: 'error'
			update: (store, { data: { legalDocumentsUpdateMute } }) =>
				query =
					query: CacheUpdateQuery
					variables:
						projectSlug: legalDocumentsUpdateMute[0].project.slug
						filters:
							isMuted: true
							states: [legalDocumentsUpdateMute[0].state]
				data = store.readQuery query
				data.project.legalDocumentsCount = data.project.legalDocumentsCount + _.size legalDocumentsUpdateMute
				store.writeQuery {
					...query
					data: data
				}

export default class DocumentMuteModal extends React.Component
	@propTypes =
		onClose: PropTypes.func.isRequired
	constructor: (props) ->
		super props

	render: ->
		NotificationConsumer {}, ({setNotification}) =>
			DataLayer {
				setNotification: setNotification
				app: @props.app
			}, (operations) =>
				BevyModal {
					'data-test-id': DataAttribute 'turn-off-alerts-modal'
					width: 'small'
					height: 180
					onClose: @props.onClose
					header: 'Turn off alerts'
					actions: [
						text: 'Confirm'
						onClick: () =>
							operations.muteDocuments.mutation
								variables:
									documentIDs:
										if @props.documentIDs?
											@props.documentIDs
										else if @props.documentID?
											@props.documentID
									isMuted: true
							@props.onClose()
					,
						text: 'Cancel'
						onClick: () => @props.onClose()
					]
					note: 'Once turned off, alerts can be turned on.'
				},
					'Are you sure you want tu turn off alerts for this document?'
