import gql from 'graphql-tag'

export DeleteShortcut = gql """
	mutation updateShortcut (
		$projectID: ID!
		$shortcutID: ID!
	) {
		deleteNavigationShortcut(projectID: $projectID, shortcutID: $shortcutID){ 
			id
			navigationShortcuts {
					id
					name
					url
				}
		}
	}
"""