###
Key performance indicator visualisation with support for delta changes and comparision with different value
###

# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import PropTypes from 'prop-types'
import moment from 'moment-mini'
# Renderable
import { div, p, ul, li, small, h1, span } from 'react-dom-factories'

Fragment = React.createFactory React.Fragment

import _Tooltip from '@bevy/tooltip'
Tooltip = React.createFactory _Tooltip

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import _EditableItem from '@bevy/editable-item'
EditableItem = React.createFactory _EditableItem

import {DatePicker as _DatePicker } from '@atlaskit/datetime-picker'
DatePicker = React.createFactory _DatePicker

import _AutosizeInput from 'react-input-autosize'
AutosizeInput = React.createFactory _AutosizeInput

# Styles
import styles from './index.styl'
import {
	arrowUp
	arrowDown
	minus
	edit2
} from 'react-icons-kit/feather'
import {
	ic_new_releases as edited
	ic_warning as danger
	ic_error as warning
	ic_error_outline as info
} from 'react-icons-kit/md'

defaultEditProps = (onChange, forceEdit, toggleForceEdit) ->
	renderEdit: ({tempValue, onTempChange, onBlur}) =>
		AutosizeInput {
			autoFocus: true
			value: tempValue
			className: styles.editInput
			onChange: (event) -> onTempChange event.target.value
			minWidth: 40
			placeholderIsMinWidth: true
			onBlur
		}
	onChange: (value) ->
		if forceEdit
			toggleForceEdit()
		onChange value

dateEditProps = (onChange, forceEdit, toggleForceEdit) ->
	renderEdit: ({tempValue, onTempChange, onBlur}) ->
		div {className: cnames styles.editableDate},
			DatePicker
				value: tempValue
				onChange: (e) -> onTempChange e
				icon: null
				dateFormat: 'DD/MM/YYYY'
				defaultIsOpen: true
	onChange: (value) =>
		if forceEdit
			toggleForceEdit()
		onChange moment.utc(value, 'YYYY-MM-DD').unix()


export default class KPI extends React.Component
	@propTypes:
		className: PropTypes.string
		status: PropTypes.string
		children: PropTypes.node
		description: PropTypes.string
		unit: PropTypes.string
		after: PropTypes.string
		tooltip: PropTypes.node
		difference: PropTypes.shape
			value: PropTypes.node
			direction: PropTypes.oneOf ['up','down']
			positive: PropTypes.bool

	constructor: (props) ->
		super props
		@state =
			forceEdit: false

	getColoringClassName: (difference) ->
		if difference.positive?
			if difference.positive
				styles.positive
			else
				styles.negative

	toggleForceEdit: () =>
		@setState forceEdit: !@state.forceEdit


	renderStatus: () =>
		# if @props.tooltip? and !@props.status?
		# 	Tooltip {content: if @props.tooltip? then @props.tooltip},
		# 		div {className: cnames [styles.status, styles.info]}, Icon size: 12, icon: info
		# else if @props.status?

		if @props.status?
			Tooltip {content: if @props.tooltip? then @props.tooltip},
				div {className: cnames [
					if @props.tooltip? then styles.status
					switch @props.status
							when 'danger' then styles.danger
							when 'warning' then styles.warning
							when 'edited' then styles.edited
							when 'info' then styles.info
				]},
					Icon size: 12, icon:
						switch @props.status
							when 'danger' then danger
							when 'warning' then warning
							when 'edited' then edited
							when 'info' then info

	renderDescription: () =>
		div {className: cnames [styles.description]},
			@props.description

	renderDifferenceIcon: () =>
		if @props.difference?.direction?
			div {className: cnames [
				styles.differenceIcon
				@getColoringClassName @props.difference
			]},
					Icon size: 12, icon: do =>
						switch @props.difference.direction
							when 'up' then arrowUp
							when 'down' then arrowDown

	renderValue: () =>
		div {className: cnames [styles.value, (if @props.editable then styles.editable), (if @props.mini then styles.mini)]},
			if @props.editable
				props = switch @props.editFormat
					when 'date' then dateEditProps(@props.onEdit, @state.forceEdit, @toggleForceEdit)
					else defaultEditProps(@props.onEdit, @state.forceEdit, @toggleForceEdit)
				EditableItem {
					...props
					showButtonsAlways: true
					enableEnterPress: true
					isRequired: true
					hideButtonWhenNoChange: false
					standardSize: false
					forceEdit: @state.forceEdit or @props.forceEdit
					initialEditValue:
						if @props.editFormat is 'date'
							date = moment.utc(@props.children, 'DD/MM/YYYY').unix()
							if _.isNaN(date) then date = moment().unix()
							moment.unix(date).format('YYYY-MM-DD')
						else @props.children
					renderDisplay: ({onClick}) =>
						span {
							className: styles.editableDisplayValue
							onClick
						},
							@props.children
							if @props.after? then span {className: styles.after}, @props.after
				}
			else if @props.children?
				@props.children
			else
				'–'


	renderDifferenceValue: () =>
		div {className: cnames [
			styles.differenceValue
			@getColoringClassName @props.difference
		]},
			@props.difference.value

	render: ->
		Tooltip {content: if @props.tooltip? and (!@props.status) then @props.tooltip},
			div {className: cnames [
				styles.base
				if !@props.status? and !@props.difference?.direction? then styles.oneColumn
				@props.className
				]},
				if @props.status? and @props.difference?.direction?
					div {className: styles.status}, @renderStatus()
				div {className: cnames [styles.descriptionRow, if @props.reversedColors then styles.reversedColors]},
					@renderDescription()
					if @props.unit? then  div {className: cnames [styles.unit]}, "[#{@props.unit}]"
				if @props.status? and @props.difference?.direction?
					div {className: styles.differenceIcon}, @renderDifferenceIcon()
				div {className: cnames [styles.valueRow]},
					@renderValue()
					if @props.difference?.value? then @renderDifferenceValue()

				# if @props.editable?
				# 	div
				# 		className: styles.editTrigger
				# 		onClick: @toggleForceEdit
				# 	,
				# 		Icon size: 12, icon: edit2
