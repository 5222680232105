###
Scans gallery
###

# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import moment from 'moment-mini'

# Renderable
import { div, p, ul, li, small, h1, span, h2, img } from 'react-dom-factories'

import _Table from '@bevy/table'
Table = React.createFactory _Table


import _ScanCard from '@bevy/scan-card'
ScanCard = React.createFactory _ScanCard

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

# Data
import trLegal from 'data/local/legal'

# Styles
import styles from './index.styl'
import {
	check
} from 'react-icons-kit/feather'

export default class ScansGallery extends React.Component
	constructor: (props) ->
		super props

	renderCheckbox: (item, isSelected) =>
		div {
			className: cnames [
				styles.checkbox
				if isSelected then styles.checked
			]
			onClick: (event) =>
				event.stopPropagation()
				@props.onCheckChange item
		}, Icon {icon: check, size: 12, style: color: 'white'}

	render: ->
		Table
			items: @props.scans
			selected: [@props.selectedScanID]
			rowHeight: 232
			columns: [
				key: 'scanCards'
				render: ({item, index}) =>
					isSelected = _.some @props.checkedScansIDs, (checkedID) -> checkedID is item.id
					ScanCard
						className: cnames styles.scanCard
						detailClassName: cnames if isSelected then styles.detail
						pdfClassName: cnames if isSelected then styles.pdfSelected else styles.pdfNotSelected
						key: index
						preview: item.scan
						title: item.scan.name
						description: moment(item.scan.lastModified).fromNow()
						icon: @renderCheckbox item, isSelected
			]
			itemsCount: _.size @props.scans
			rowClassName: (item, index) ->
				cnames [
					styles.scanCard
				,
					if item.isSelected then styles.selected
				]
			onItemEvent:
				onClick: ({event, item, index}) =>
					@props.onScanPreviewChange item, index
					event.preventDefault()
					event.stopPropagation()
					event.nativeEvent.stopImmediatePropagation()
