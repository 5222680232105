# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import DataAttribute from '@bevy/data-attribute'

# Renderable
import { div, img, p, input} from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import { Query as _Query } from 'react-apollo'
Query = React.createFactory _Query

import _Table from '@bevy/table'
Table = React.createFactory _Table

import _Button from '@bevy/button'
Button = React.createFactory _Button

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import _Spinner from '@atlaskit/spinner'
Spinner = React.createFactory _Spinner

import _SelectionToolbar from '../parts/SelectionToolbar'
SelectionToolbar = React.createFactory _SelectionToolbar

import _DropdownMenu from '@bevy/dropdown-menu'
DropdownMenu = React.createFactory _DropdownMenu

import _ListEditor from '@bevy/list-editor'
ListEditor = React.createFactory _ListEditor

import _ErrorDisplay from 'components/ErrorDisplay'
ErrorDisplay = React.createFactory _ErrorDisplay

import _EmptyState from '@atlaskit/empty-state'
EmptyState = React.createFactory _EmptyState

import { UserInfoConsumer } from 'libs/userInfo'

import {
	ListDocumentsSummaryCounts
} from '../data'
import {REGISTRY_PATH} from 'data/paths'
import { isModified } from 'libs/legal'

# Styles
import styles from './table.styl'
import {
	check
	settings
	messageSquare
	bellOff
	moreHorizontal
	paperclip
} from 'react-icons-kit/feather'
import ErrorIcon from '@atlaskit/icon/glyph/error'
import PresenceActiveIcon from '@atlaskit/icon/glyph/presence-active'

getRowClassName = (item, index) ->
	cnames [
			if index % 2 then styles.odd else styles.even
		,
			if isModified item then styles.modified
		,
			if item.isUrgent then styles.urgent
	]

export default ({ data, columnsSetup, changeTableConfiguration, selectedDocuments, onSelect, onSortChange, sortKey, sortOrder, getItemActions, currentFilters, uploadFiles }) ->
	ErrorDisplay {
		error: data.documentsList.error
		refetch: data.documentsList.refetch
		withImage: true
	}, () =>
			if data.documentsList.data?.legalDocumentsCreatedCounterToday?.projects?
				items = data.documentsList.data.legalDocumentsCreatedCounterToday.projects
				items = _.reduce items, (acc, value) ->
					_.map value.legalDocuments, (document) ->
						acc.push	{
							...document
							project: value.projectName
							href:"/projects/#{value.slug}/legal/#{REGISTRY_PATH}/#{document.id}"
						}
					acc
				, []
			else
				items = []
			ListEditor
				items: _.reject columnsSetup, isFixed: true
				onApply: (presets) -> changeTableConfiguration presets
				onRestore: (presets) -> changeTableConfiguration null
			, (openTableEditor) =>
				Fragment {},
					Table
						loading: data.documentsList.loading
						rowHeight: 24
						columns: _.filter(columnsSetup, selected: true)
						columnsSetup: columnsSetup
						changeTableConfiguration: changeTableConfiguration
						emptyState: () ->
							EmptyState
								header: 'No documents'
								description: 'In this day no one registred document'
								imageUrl: require 'assets/empty-states/empty-result.svg'
								maxImageWidth: 300
								maxImageHeight: 300
						onSortChange: onSortChange
						sortKey: sortKey
						sortOrder: sortOrder
						suffix:
							width: 32
							renderHeader: ->
								Button
									appearance: 'subtle'
									onClick: () => openTableEditor()
									iconBefore: Icon {icon: settings, size: 13}
							render: ({ index, item }) ->
								Fragment {}, ''
								# DropdownMenu
								# 	closeOnSelect: true
								# 	trigger:
								# 		# div {className: if !isHovered then styles.hidden},
								# 		Icon
								# 			icon: moreHorizontal
								# 			size: 11
								# 			className: styles.more
						items: items
						itemsCount: if data.documentsList?.data?.legalDocumentsCreatedCounterToday?.counterAll? then data.documentsList.data.legalDocumentsCreatedCounterToday.counterAll else 100
						rowClassName: (item, index) -> if item? then getRowClassName item, index
						loadMore: ({ skip, limit }) =>
							console.debug 'Fetching', "skip: #{skip}", "limit: #{limit}"
							new Promise (resolve, reject) =>
								if data.documentsList.loading or @state.isLoadingPage
									resolve()
								else
									@setState isLoadingPage: false
									data.documentsList.fetchMore
										variables:
											pagination:
												limit: limit
												skip: skip
										updateQuery: (prev, { fetchMoreResult }) =>
											@setState
												isLoadingPage: false
											if !fetchMoreResult
												console.warn 'fetchMoreResult empty'
												newData
											else
												resolve()
												legalDocumentsCreatedCounterToday: {
													...prev.legalDocumentsCreatedCounterToday
													...projects: _.map prev.legalDocumentsCreatedCounterToday.projects, (project, key) ->
														{
															...project
															legalDocuments: [...project.legalDocuments, ...fetchMoreResult.legalDocumentsCreatedCounterToday.projects[key].legalDocuments]
														}
												}
						selected: selectedDocuments
						onItemEvent:
							# onClick: ({event, item}) =>
							# 	previousState = selectedDocuments
							# 	if event.metaKey or event.ctrlKey
							# 		nextState =
							# 			if item.id in previousState
							# 				_.without previousState, item.id
							# 			else
							# 				_.concat previousState, item.id
							# 		nextState = _.uniq _.compact nextState
							# 	else
							# 		if item.id in previousState
							# 			nextState = []
							# 		else
							# 			nextState = [item.id]

							# 	@setState selectedDocuments: nextState
							# 	event.preventDefault()
							# 	event.stopPropagation()
							# 	event.nativeEvent.stopImmediatePropagation()
							onClick: ({ event, item }) =>
								@props.history.push item.href

					# Footer
					# div {className: styles.footer},
					# 	SelectionToolbar {
					# 		...data.project
					# 		selectedDocuments: @state.selectedDocuments
					# 		clearSelection: => @setState selectedDocuments: []
					# 	}
