	import trLegal from 'data/local/legal'
	import moment from 'moment-mini'

	export default emptyProcess =
		step: 'basic'
		shouldRegisterAnother: false
		qrType: 'no'
		parentRequired: false
		combination: null
		someValueChanged: false
		highlightMissingValues: false
		files: []
		basicInfo:
			source: null
			receivalDate: moment.utc().startOf('day').unix()
			creationDate: moment.utc().startOf('day').unix()
			company: null
			description: ''
		category:
			form: null
			topic: null
			path: null
			parentDocument: null
		details:
			buildings: []
			freeText: ''
			dates: {}
			identifiers: {}
			entities: {}
		steps: [
			key: 'basic'
			name: trLegal.registration.basic
			advanced: true # if visible in advanced mode
			done: false # true if related fields are filled
		,
			key: 'category'
			name: trLegal.registration.category
			advanced: true
			done: false
		,
			key: 'details'
			name: trLegal.registration.details
			advanced: true
			done: false
		,
			key: 'review'
			name: trLegal.registration.review
			advanced: false
			done: true
		]
