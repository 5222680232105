import _ from 'lodash'
import React from 'react'

import _DocumentHeader from '@bevy/document-header'
DocumentHeader = React.createFactory _DocumentHeader

import { DatePicker as _DatePicker } from '@atlaskit/datetime-picker'
DatePicker = React.createFactory _DatePicker

import cnames from 'classnames'

Fragment = React.createFactory React.Fragment

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import { div } from 'react-dom-factories'

import styles from '../index.styl'

import moment from 'moment-mini'

export default () ->
	type:
		Fragment {},
			div {className: styles.label},
				'Daily report'
			div {className: styles.datePicker},
				DatePicker
					id: 'sendTime'
					dateFormat: 'DD/MM/YYYY'
					onChange: (value) => @props.history.push '/projects/legal/' + value
					value: @props.match.params.sendTime
	className: cnames styles.header
