###
Component to display document header
###

# Libs
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import moment from 'moment-mini'
import DataAttribute from '@bevy/data-attribute'
import _ from 'lodash'

# Components
import { div, button, span, p } from 'react-dom-factories'

Fragment = React.createFactory React.Fragment

import _Lozenge from '@atlaskit/lozenge'
Lozenge = React.createFactory _Lozenge

import _Breadcrumbs, { BreadcrumbsItem as _BreadcrumbsItem } from '@atlaskit/breadcrumbs'
Breadcrumbs = React.createFactory _Breadcrumbs
BreadcrumbsItem = React.createFactory _BreadcrumbsItem

import { Link as _Link } from 'react-router-dom'
Link = React.createFactory _Link

import _Tooltip from '@bevy/tooltip'
Tooltip = React.createFactory _Tooltip

import _InlineEdit from '@bevy/inline-edit'
InlineEdit = React.createFactory _InlineEdit

import _Button from '@bevy/button'
Button = React.createFactory _Button

# import _Button from '@bevy/button'
# Button = React.createFactory _Button

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

#Styles
import styles from './index.styl'
import {
	refreshCw
	check
	alertCircle
	# arrowLeft
} from 'react-icons-kit/feather'
import {
	ic_lock
} from 'react-icons-kit/md'

class BreadcrumbLink extends React.Component
	render: ->
		{
			children
			className
			href
			onMouseEnter
			onMouseLeave
		} = @props
		Link {
			..._.pick @props, [
					'className'
					'onMouseEnter'
					'onMouseLeave'
				]
			to: href
		},
			children

export default class DocumentHeaderDMS extends React.Component
	@propTypes =
		compact: PropTypes.bool
		mini: PropTypes.bool
		title: PropTypes.node.isRequired
		description: PropTypes.string
		author: PropTypes.shape
			name: PropTypes.string
			image: PropTypes.string
		lastUpdate: PropTypes.number
		lastUpdateShort: PropTypes.bool
		status: PropTypes.oneOfType [
			PropTypes.string
			PropTypes.shape
				icon: PropTypes.node.isRequired
				tooltip: PropTypes.string
			]
		state: PropTypes.node
		sync: PropTypes.oneOf [
				'Saved'
				'Syncing'
				'Error'
			]
		actions: PropTypes.node
		# TODO: Add types for this props
		# action
		# owner
		# people
		# peopleAction
		# ownerAction
		# subtitle
		type: PropTypes.node
		uuid: PropTypes.node
		breadcrumbs: PropTypes.arrayOf PropTypes.shape
			href: PropTypes.string
			text: PropTypes.node.isRequired
		isLoading: PropTypes.bool
	@defaultProps:
		compact: false
		mini: false
		lastUpdateShort: false
		# edit: false
		# backArrow: true

	render: ->
		# console.log @props.owner, @props.people
		div {className: cnames [
			'bevy-document-header'
			if @props.compact then styles.compact
			if @props.mini then styles.mini
			@props.className
			styles.base
			if @props.edit then styles.edit
		]},
			div {className: cnames [
				styles.main
			]},
				div {className: cnames styles.documentInfo, 'bevy-document-header-document-info'},
					do => if @props.action?
						Button {
							...@props.action
							className: cnames @props.action.className, styles.action
						}
					do =>
						Fragment {},
							if @props.status?
								Tooltip { content: @props.status.tooltip },
									div {className: styles.statusIcon}, @props.status.icon
							else if @props.locked
								Tooltip {
									content: @props.lockedDescription || 'Document is non-editable'
								},
									div {className: styles.statusIcon}, Icon icon: ic_lock, size: 18
					div {
						className: styles.titleContainer
						style: if @props.titleAtTheTop? then flexDirection: 'column-reverse'
					},
						if @props.subtitle? then span {className: cnames styles.subtitle, 'bevy-document-header-document-info-subtitle'}, @props.subtitle
						div {},
							if !_.isEmpty @props.breadcrumbs
								Breadcrumbs {},
									_.map @props.breadcrumbs, (breadcrumb) ->
										BreadcrumbsItem {...breadcrumb, component: BreadcrumbLink}
									if @props.uuid?
										BreadcrumbsItem {
											text: "#{@props.type} [#{@props.uuid}]"
											component: BreadcrumbLink
										}
							# else
						Fragment {},
							if @props.type? then span {className: cnames styles.type, 'bevy-document-header-document-info-type'}, @props.type
							if @props.uuid? then span {className: cnames styles.uuid, 'bevy-document-header-document-info-uuid'}, @props.uuid
						Tooltip {content: @props.title},
							span {className: cnames styles.title, 'bevy-document-header-document-info-title'}, @props.title

						if @props.subtitle? then span {className: cnames styles.subtitle, 'bevy-document-header-document-info-subtitle'}, @props.subtitle
					div {className: cnames(styles.sidebar, 'bevy-document-header-sidebar')},
						div {className: styles.status},
							do =>
								if @props.note?
									div {className: styles.note}, @props.note

							if @props.lastUpdate?
								Fragment {},
									div {className: cnames styles.modificationStatus, 'bevy-document-header-modificationStatus'},
										span {}, do =>
											if @props.lastUpdateShort is true
												weeks = moment().diff(moment.unix(@props.lastUpdate), 'weeks', true)
												days = moment().diff(moment.unix(@props.lastUpdate), 'days', true)
												if days < 1
													'today'
												else if weeks < 1
													"#{_.floor(days)} d. ago"
												else
													"#{_.floor(weeks)} w. ago"
											else
												"Last modified #{moment.unix(@props.lastUpdate).fromNow()}"
										do =>
											if @props.author?.name?
												Fragment {},
													span {}, ' by '
													span {className: styles.author}, @props.author.name
						do =>
							if @props.actions?
								div {className: cnames [ styles.actions, 'document-header-actions' ]},
									@props.actions
