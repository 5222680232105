export default
	error:
		internalError:
			header: 'Ups, something went wrong'
			description: ''
		networkError:
			header: 'Connection error'
			description: 'Try to refresh the page'
		invalidProject:
			header: 'This project does not exist'
		invalidHub:
			header: 'This hub does not exist'
		noPermission:
			header: 'You have no access to this content'
			description: 'Ask Project Leader for access'
		noPermissionForHub:
			header: 'You have no access to this hub'
			description: 'Ask Project Leader for access'
		noPermissionForProject:
			header: 'You have no access to this project'
			description: 'Ask Project Leader for access'
		fileLoad:
			header: 'Failed to load this file preview'
			description: 'Try to refresh the page'
		noPlatformPermission:
			header: 'You don\'t have access to current environment'
			description: 'Go to production environment'
