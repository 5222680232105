import _ from 'lodash'
import React from 'react'

# Renderable
import { div } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import {BevyModalsWrapper as _BevyModalsWrapper} from '@bevy/modal'
BevyModalsWrapper = React.createFactory _BevyModalsWrapper

import _AddUserModal from 'parts/Users/AddUserModal'
AddUserModal = React.createFactory _AddUserModal

import _EditUserModal from 'parts/Users/EditUserModal'
EditUserModal = React.createFactory _EditUserModal

import _DeleteUserModal from 'parts/Users/DeleteUserModal'
DeleteUserModal = React.createFactory _DeleteUserModal

export default getModals = ({projectID, selected}) ->
	BevyModalsWrapper {},
		switch @state.activeModal
			when 'add'
				AddUserModal {
					projectID
					onClose: =>
						@setState activeModal: null
				}
			when 'edit'
				EditUserModal {
					userID: _.first @state.selected
					projectID
					onClose: =>
						@setState activeModal: null
				}
			when 'delete'
				DeleteUserModal {
					userID: _.first @state.selected
					projectID
					onClose: =>
						@setState
							activeModal: null
							selected: []
				}
