import gql from 'graphql-tag'

export RootQuery = gql '''
	query ProjectNavigation(
		$projectSlug: String!,
		$inboxUrgentFilters: LegalDocumentsFilters,
		$inboxFreshFilters: LegalDocumentsFilters,
		$registryUrgentFilters: LegalDocumentsFilters,
		$registryFreshFilters: LegalDocumentsFilters
		){
		project(slug: $projectSlug) {
			id
			slug
			branch
			navigationShortcuts {
				id
				name
				url
			}	
			inboxUrgentCount: legalDocumentsCount(filters: $inboxUrgentFilters)
			inboxFreshCount: legalDocumentsCount(filters: $inboxFreshFilters)
			registryUrgentCount: legalDocumentsCount(filters: $registryUrgentFilters)
			registryFreshCount: legalDocumentsCount(filters: $registryFreshFilters)
		}
		projects {
			id
			name
			city
			country
			slug
			photo {
				mini
			}
		}
	}
'''
