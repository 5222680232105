import gql from 'graphql-tag'

export ListUsersAndDocumentOwner = gql """
	query($projectSlug: String!, $pagination: PaginationInput, $search: String) {
		project(slug: $projectSlug) {
			id
			members(pagination: $pagination, search: $search) {
				... on ActorAccess{
					user {
						... on Person {
							id
							name
							picture {mini}
						}
						... on Service {
							id
							name
							picture {mini}
						}
					}
				}
			}
		}
	}
"""

export ReassignDocuments = gql """
	mutation($documentIDs: [ID!]!, $assignee: LegalDocumentAssigneeInput!) {
		legalDocumentsUpdateAssignee(documentIDs: $documentIDs, value: $assignee) {
			id
			asignee {
				... on Person { id, name }
				... on Service { id, name }
			}
		}
	}
"""
