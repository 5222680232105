exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".dv8sDKDneBdEHNeut2wHf {\n  max-width: calc(100% - 20px);\n  padding: 5px;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  align-self: stretch;\n  text-transform: none;\n  font-size: 90%;\n}\n._2xZgLePerh8hkmAHvvdzFg {\n  color: #8993a4;\n  line-height: 1;\n  padding-top: 1px;\n}\n._2xZgLePerh8hkmAHvvdzFg:hover {\n  color: #42526e;\n}\n._2__kxxOBJr5yICfYYenREf {\n  display: flex;\n  align-items: center;\n  line-height: 1;\n  padding: 2px 0;\n}\n._3tbAcHxjeeT6CLqVy25v_5 {\n  margin-right: 5px;\n  color: #c1c7d0;\n}\n._3tbAcHxjeeT6CLqVy25v_5._28tVhzT6IP0x3jsl0vK0ce {\n  color: #0065ff;\n}\n._2oOqozKLGYs_TeSWnTSs26 {\n  text-overflow: ellipsis;\n  overflow: hidden;\n  flex-grow: 1;\n  white-space: nowrap;\n}\n", ""]);
// Exports
exports.locals = {
	"values": "dv8sDKDneBdEHNeut2wHf",
	"clearIndicator": "_2xZgLePerh8hkmAHvvdzFg",
	"checkboxWrapper": "_2__kxxOBJr5yICfYYenREf",
	"checkboxWrapperIcon": "_3tbAcHxjeeT6CLqVy25v_5",
	"checkboxWrapperIconSelected": "_28tVhzT6IP0x3jsl0vK0ce",
	"checkboxWrapperText": "_2oOqozKLGYs_TeSWnTSs26"
};