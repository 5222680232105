###
Component to chage page title

usage:
import PageTitle from 'components/PageTitle'
in render:
	PageTitle 'new page title'

###

# Libs
import React from 'react'
# Renderable
import { title } from 'react-dom-factories'
import {Helmet as _Helmet} from "react-helmet"
Helmet = React.createFactory _Helmet

export default PageTitle = (string) -> Helmet {}, title {}, string
