exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2ViA6X8NY5uETTFvJt1fx9 {\n  padding: 20px;\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  width: 100%;\n  box-sizing: border-box;\n}\n._1LtamjoyQ05GKUqmEBbBbW {\n  display: flex;\n  justify-content: space-between;\n}\n._1uC_5TLRrjrl1nhH6QMJIG {\n  font-weight: 600;\n  color: #091e42;\n  font-size: 1.6rem;\n}\n._1dSFPFmdI0bWe7qMCt8uTJ {\n  overflow-y: scroll;\n  margin: 16px 0;\n  padding-right: 12px;\n  flex: 1;\n}\n._1vB7HLymJ1yOmUhDC3-td- {\n  display: flex;\n  align-items: center;\n  min-height: 32px;\n}\n._1vB7HLymJ1yOmUhDC3-td-:hover .mV8sAX_5GC9cofSnErgwH {\n  display: inline;\n}\n._1vB7HLymJ1yOmUhDC3-td- .mV8sAX_5GC9cofSnErgwH {\n  display: none;\n  margin-right: 5px;\n  margin-left: auto;\n}\n._3OSkvgQuUWh7_fAe5kP3-f {\n  padding-bottom: 5px;\n}\n._3OSkvgQuUWh7_fAe5kP3-f._2CvAv98QyFX1knPGpxgn7 {\n  padding-bottom: 0;\n}\n._3OSkvgQuUWh7_fAe5kP3-f.QDK7oPUtx1c8pGOAB-NZE {\n  height: 42px;\n}\n._3OSkvgQuUWh7_fAe5kP3-f ._32ksg7mZ9HuW5wV1TTa0c5 {\n  min-height: 32px;\n  background-color: #fafafb;\n}\n._3OSkvgQuUWh7_fAe5kP3-f ._32ksg7mZ9HuW5wV1TTa0c5:hover {\n  background-color: #f4f5f7;\n  border-radius: 4px;\n  cursor: pointer;\n}\n._3OSkvgQuUWh7_fAe5kP3-f ._32ksg7mZ9HuW5wV1TTa0c5._2CvAv98QyFX1knPGpxgn7 {\n  display: none;\n  cursor: grabbing;\n}\n._1-aEQPTC0rctC0emriZSES {\n  height: 42px;\n  border-radius: 3px;\n  background: #f2f2f2;\n  margin-bottom: 5px;\n}\n._3LJa-cYRHXq_mwhbwuGFLs {\n  display: flex;\n}\n._1JifIEXyf1NeNm9tNNim9s {\n  display: flex;\n  max-width: 100%;\n  background-color: #fafbfc;\n  z-index: 4;\n  width: 330px;\n  box-shadow: -5px 0px 11px 2px rgba(9,30,66,0.32);\n}\n._1JifIEXyf1NeNm9tNNim9s .nV60BK0Ukd-ZopjUWmGTL {\n  cursor: pointer;\n}\n", ""]);
// Exports
exports.locals = {
	"base": "_2ViA6X8NY5uETTFvJt1fx9",
	"headerBar": "_1LtamjoyQ05GKUqmEBbBbW",
	"header": "_1uC_5TLRrjrl1nhH6QMJIG",
	"body": "_1dSFPFmdI0bWe7qMCt8uTJ",
	"itemContent": "_1vB7HLymJ1yOmUhDC3-td-",
	"actions": "mV8sAX_5GC9cofSnErgwH",
	"item": "_3OSkvgQuUWh7_fAe5kP3-f",
	"isDragging": "_2CvAv98QyFX1knPGpxgn7",
	"isLast": "QDK7oPUtx1c8pGOAB-NZE",
	"draggableItem": "_32ksg7mZ9HuW5wV1TTa0c5",
	"dragDummy": "_1-aEQPTC0rctC0emriZSES",
	"footer": "_3LJa-cYRHXq_mwhbwuGFLs",
	"sidePanel": "_1JifIEXyf1NeNm9tNNim9s",
	"closeButton": "nV60BK0Ukd-ZopjUWmGTL"
};