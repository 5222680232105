###
Edit document modal
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import adopt from 'libs/adopt'
import { convertToOptions } from 'libs/legal'

# Renderable
import { div, p } from 'react-dom-factories'
import DataAttribute from '@bevy/data-attribute'
import { NotificationConsumer } from '../../../application/components/NotificationManager'

Fragment = React.createFactory React.Fragment

import _Spinner from '@bevy/spinner'
Spinner = React.createFactory _Spinner

import _RegisterDocument from 'parts/Legal/RegisterDocumentModal'
RegisterDocument = React.createFactory _RegisterDocument

# Data
import {
	DocumentEditEnvironment
} from './data'
import trLegal from 'data/local/legal'


DataLayer = adopt
	queries:
		documentEditEnvironment: ({documentID}) ->
			query: DocumentEditEnvironment
			variables:
				documentID: documentID

export default class DocumentEditModal extends React.Component
	@propTypes =
		onClose: PropTypes.func.isRequired

	getInitialData: (data) =>
		{ legalDocument } = data
		phases = convertToOptions legalDocument.phases
		buildings = convertToOptions legalDocument.project.buildingsSets
		details =
			dates: _.reduce legalDocument.dates, ((acc, {type, value}) -> acc[type] = value; acc), {}
			identifiers: _.reduce legalDocument.identifiers, ((acc, {type, value}) -> acc[type] = value; acc), {}
			entities: {}
			freeText: ''
		if legalDocument.entities.participants?
			details.entities.Participants = legalDocument.entities.participants
		if legalDocument.entities.recipient?
			details.entities.Recipient = legalDocument.entities.recipient
		if legalDocument.buildings?
			details.buildings = _.map legalDocument.buildings, (building) ->
				_.find buildings, (item) -> item.value is building
		if legalDocument.freeText? and !_.isEmpty legalDocument.freeText
			details.freeText = legalDocument.freeText

		return
			step: 'review'
			shouldRegisterAnother: false
			qrType: 'no'
			parentRequired: false
			combination: legalDocument.combination
			file: if legalDocument.scan?.secured? then legalDocument.scan.secured.original else null
			state: if legalDocument.state? then legalDocument.state else null
			name: if legalDocument.name? then "#{legalDocument.name}.pdf" else null
			basicInfo:
				source: legalDocument.correspondent
				receivalDate: _.get (_.find legalDocument.dates, (date) -> date.type is 'ReceivalDate'), 'value'
				creationDate: do ->
					# TODO: Temporary fix for bad scan processing
					cd = _.get (_.find legalDocument.dates, (date) -> date.type is 'CreationDate'), 'value'
					rd = _.get (_.find legalDocument.dates, (date) -> date.type is 'ReceivalDate'), 'value'
					cd || rd
				phases: phases
				company: legalDocument.company
				description: legalDocument.description
			category:
				form: legalDocument.form
				topic: legalDocument.topic
				path: legalDocument.path
				parentDocument: if legalDocument.parentDocument? then legalDocument.parentDocument else null
			details: details
			steps: [
				key: 'basic'
				name: trLegal.registration.basic
				advanced: true # if visible in advanced mode
				done: true # true if related fields are filled
			,
				key: 'category'
				name: trLegal.registration.category
				advanced: true
				done: true
			,
				key: 'details'
				name: trLegal.registration.details
				advanced: true
				done: false
			,
				key: 'review'
				name: trLegal.registration.review
				advanced: false
				done: true
			]

	render: ->
		NotificationConsumer {}, ({setNotification}) =>
			DataLayer
				documentID: @props.documentID
				, (operations) =>
					RegisterDocument {
						isEdition: true
						app: @props.app
						loading: operations.documentEditEnvironment.loading
						error: operations.documentEditEnvironment.error
						initialSetup: if operations.documentEditEnvironment.data?.legalDocument? then @getInitialData operations.documentEditEnvironment.data
						documentIDs: [@props.documentID]
						enableRecategorize: true
						showPreview: false
						fileChangeEnabled: false
						printQRCodeEnabled: false
						registerAnotherEnabled: false
						onDone: @props.onDone
						...@props
					}
