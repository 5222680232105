"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.scopes = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _helpers = require("../helpers");

var _Project = require("./Project");

var _LegalDocument = require("./LegalDocument");

var _Organization = require("./Organization");

var _User = require("./User");

var _SafetyVisit = require("./SafetyVisit");

var _SafetyObservation = require("./SafetyObservation");

var _LegalPath = require("./LegalPath");

var _SafetyVisitMember = require("./SafetyVisitMember");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

// Generated by CoffeeScript 2.4.1

/*
Roles
*/
var SafetyUserPermissions, emptyRoles, permissionsToReadLegalPathsInProject, permissionsToReadLegalPersonnelOrganizationsInProject;
emptyRoles = _lodash.default.map(['Regional Director', 'Senior Regional Asset Coordinator', 'HSE Manager', 'Controlling Manager', 'Leasing Negotiator', 'Leasing Manager', 'Marketing and Communication Manager', 'Asset Manager', 'Facility Manager', "Executive Vice President", "Junior Project Leader", "Business Controler", "Finance Director", "Lawyer", "HSE Director", "HSE Coordinator", "Regional Asset Coordinator", "Marketing and Communication Coordinator", "Marketing and Communication Specialist", "Document Specialist", "Project Support", "Administration", "Project Director", "Construction Manager", "Works Manager", "Construction Engineer", "Project Engineer", "BIM Coordinator", "Design Coordinator", "HSE Specialist", "Accounting Specialist", "Production Specialist", "Main Designer", "Designer", "Consultant", "Legal Advisor"], function (item) {
  return {
    name: item
  };
});
/*
Project permissions model
*/

var scopes = (0, _helpers.makeDefaultSubject)('Project', {
  Manage: {
    actions: 'crud'
  },
  Create: {
    actions: 'create'
  },
  Update: {
    actions: 'update'
  },
  Delete: {
    actions: 'delete'
  },
  Read: {
    actions: 'read',
    conditions: _objectSpread({}, _helpers.isSelfDeletedCondition)
  },
  ReadRevenue: {
    actions: 'read',
    fields: 'Revenue',
    conditions: _objectSpread({}, _helpers.isSelfDeletedCondition)
  },
  ReadCosts: {
    actions: 'read',
    fields: 'Costs',
    conditions: _objectSpread({}, _helpers.isSelfDeletedCondition)
  },
  ReadWhatIf: {
    actions: 'read',
    fields: 'WhatIf',
    conditions: _objectSpread({}, _helpers.isSelfDeletedCondition)
  },
  ReadSafety: {
    actions: 'read',
    fields: 'Safety',
    conditions: _objectSpread({}, _helpers.isSelfDeletedCondition)
  },
  ReadSafetyRD: {
    actions: 'read',
    fields: 'Safety',
    conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {
      branch: 'RD'
    })
  },
  ReadSafetyCDE: {
    actions: 'read',
    fields: 'Safety',
    conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {
      branch: 'CDE'
    })
  },
  ReadTQT: {
    actions: 'read',
    fields: 'TQT',
    conditions: _objectSpread({}, _helpers.isSelfDeletedCondition)
  },
  ReadLegal: {
    actions: 'read',
    fields: 'Legal',
    conditions: _objectSpread({}, _helpers.isSelfDeletedCondition)
  },
  ReadOrganizations: {
    actions: 'read',
    fields: 'Organizations',
    conditions: _objectSpread({}, _helpers.isSelfDeletedCondition)
  },
  ReadPersonnel: {
    actions: 'read',
    fields: 'Personnel',
    conditions: _objectSpread({}, _helpers.isSelfDeletedCondition)
  },
  ReadAllHubs: [{
    actions: 'read',
    fields: 'Personnel',
    conditions: _objectSpread({}, _helpers.isSelfDeletedCondition)
  }, {
    actions: 'read',
    fields: 'Revenue',
    conditions: _objectSpread({}, _helpers.isSelfDeletedCondition)
  }, {
    actions: 'read',
    fields: 'Costs',
    conditions: _objectSpread({}, _helpers.isSelfDeletedCondition)
  }, {
    actions: 'read',
    fields: 'WhatIf',
    conditions: _objectSpread({}, _helpers.isSelfDeletedCondition)
  }, {
    actions: 'read',
    fields: 'Safety',
    conditions: _objectSpread({}, _helpers.isSelfDeletedCondition)
  }, {
    actions: 'read',
    fields: 'TQT',
    conditions: _objectSpread({}, _helpers.isSelfDeletedCondition)
  }, {
    actions: 'read',
    fields: 'Legal',
    conditions: _objectSpread({}, _helpers.isSelfDeletedCondition)
  }, {
    actions: 'read',
    fields: 'Organizations',
    conditions: _objectSpread({}, _helpers.isSelfDeletedCondition)
  }, {
    actions: 'read',
    fields: 'Personnel',
    conditions: _objectSpread({}, _helpers.isSelfDeletedCondition)
  }]
});
exports.scopes = scopes;

SafetyUserPermissions = function ({
  user,
  parent: project,
  membership,
  resolved,
  db,
  cache
}) {
  return _lodash.default.compact([{
    scopes: [_Project.scopes.ReadSafety],
    conditions: _objectSpread({}, (0, _helpers.identityCondition)(resolved, 'project', project))
  }, {
    scopes: [_SafetyVisit.scopes.Self.Read, _SafetyVisit.scopes.Members.Join],
    conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {}, (0, _helpers.projectAssociationCondition)(resolved, project), {
      status: {
        $in: ['InLobby']
      }
    })
  }, {
    scopes: [_SafetyVisit.scopes.Self.Read],
    conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {}, (0, _helpers.projectAssociationCondition)(resolved, project), {
      status: {
        $in: ['InProgress']
      }
    })
  }, project.branch !== 'CDE' ? {
    scopes: [_SafetyObservation.scopes.Self.Create],
    conditions: _objectSpread({}, (0, _helpers.projectAssociationCondition)(resolved, project), {
      $or: [{
        visitID: {
          $exists: false
        }
      }, {
        visitID: null
      }]
    })
  } : void 0]);
}; // all the paths from all the phases in project
// TODO: Add time based conditions
// timestamp:
// 	$lte: Date.now()
// 	$gte: Date.now() - 1day


permissionsToReadLegalPathsInProject = function (meta, project, resolved) {
  var ref;
  return {
    scopes: [_LegalPath.scopes.Read, _LegalPath.scopes.Use, _LegalPath.scopes.Update],
    conditions: {
      $and: [(0, _helpers.projectAssociationCondition)(resolved, project), {
        $or: _lodash.default.compact([{
          phaseIDs: {
            $exists: false
          }
        }, (meta != null ? (ref = meta.info) != null ? ref.phaseID : void 0 : void 0) != null ? {
          phaseIDs: meta.info.phaseID
        } : void 0])
      }]
    }
  };
};

permissionsToReadLegalPersonnelOrganizationsInProject = function (project) {
  return {
    scopes: [_Project.scopes.ReadPersonnel, _Project.scopes.ReadLegal, _Project.scopes.ReadOrganizations],
    conditions: {
      $or: [{
        id: (0, _helpers.makeGlobalID)('project', project._id)
      }, {
        _id: project._id
      }]
    }
  };
};

var _default = {
  // Name of the GraphQL subject
  name: 'Project',
  // Database collection
  collection: 'projects',
  // Name used in making global id's
  globalIDName: 'project',
  // Available actions on root leve
  actions: ['crud', 'create', 'read', 'update', 'delete'],
  // Alias for create, read, update, delete
  // Model fields with appropiate actions
  fields: {},
  // Available scopes with implicit subject as a model.name
  scopes,
  // Additional fields requested when fetching items for attached and members roles
  projection: {
    branch: 1,
    phases: 1
  },
  // Type of models that can become a member of this model without specified role
  accepts: [],
  // Entity based roles default and assignable
  roles: {
    // Roles granted to all users
    default: [],
    // Roles assignable to the entity that will be transfered to the User
    attached: [],
    // Roles assignable to the members (Users) of this entity
    members: [...(0, _helpers.makeSetOfAccepts)(['User'], [...emptyRoles, {
      name: 'Project Leader',
      singular: true,
      permissions: function ({
        user,
        parent: project,
        membership,
        resolved,
        db,
        cache,
        meta
      }) {
        return [{
          scopes: [_LegalDocument.scopes.Manage, _Organization.scopes.Manage, _User.scopes.Read],
          conditions: {
            $or: [{
              projectID: (0, _helpers.makeGlobalID)('project', project._id)
            }, {
              projectID: project._id
            }]
          }
        }, permissionsToReadLegalPersonnelOrganizationsInProject(project), permissionsToReadLegalPathsInProject(meta, project, resolved), {
          scopes: [_Project.scopes.Update],
          conditions: resolved ? {
            id: (0, _helpers.makeGlobalID)('project', project._id)
          } : {
            _id: project._id
          }
        }];
      }
    }, {
      name: 'Project Manager',
      permissions: function ({
        user,
        parent: project,
        membership,
        resolved,
        db,
        cache,
        meta
      }) {
        return [{
          scopes: [_LegalDocument.scopes.Manage, _Organization.scopes.Manage, _User.scopes.Read],
          conditions: {
            $or: [{
              projectID: (0, _helpers.makeGlobalID)('project', project._id)
            }, {
              projectID: project._id
            }]
          }
        }, permissionsToReadLegalPersonnelOrganizationsInProject(project), permissionsToReadLegalPathsInProject(meta, project, resolved), {
          scopes: [_Project.scopes.Update],
          conditions: resolved ? {
            id: (0, _helpers.makeGlobalID)('project', project._id)
          } : {
            _id: project._id
          }
        }];
      }
    }, {
      name: 'Document Manager',
      permissions: function ({
        user,
        parent: project,
        membership,
        resolved,
        db,
        cache,
        meta
      }) {
        return [{
          scopes: [_LegalDocument.scopes.Manage, _Organization.scopes.Manage, _User.scopes.Read],
          conditions: {
            $or: [{
              projectID: (0, _helpers.makeGlobalID)('project', project._id)
            }, {
              projectID: project._id
            }]
          }
        }, permissionsToReadLegalPersonnelOrganizationsInProject(project), permissionsToReadLegalPathsInProject(meta, project, resolved)];
      }
    }, {
      name: 'Legal User',
      permissions: function ({
        user,
        parent: project,
        membership,
        resolved,
        db,
        cache,
        meta
      }) {
        return [{
          scopes: [_LegalDocument.scopes.Create, _User.scopes.Read],
          conditions: {
            $or: [{
              projectID: (0, _helpers.makeGlobalID)('project', project._id)
            }, {
              projectID: project._id
            }]
          }
        }, permissionsToReadLegalPersonnelOrganizationsInProject(project)];
      }
    }, {
      name: 'Spectator',
      permissions: function ({
        user,
        parent: project,
        membership,
        resolved,
        db,
        cache,
        meta
      }) {
        return [{
          scopes: [_User.scopes.Read],
          conditions: {
            $or: [{
              projectID: (0, _helpers.makeGlobalID)('project', project._id)
            }, {
              projectID: project._id
            }]
          }
        }, permissionsToReadLegalPersonnelOrganizationsInProject(project)];
      }
    }, {
      name: 'Finance Tester',
      permissions: function ({
        user,
        parent: project,
        membership,
        resolved,
        db,
        cache
      }) {
        return [{
          scopes: [_Project.scopes.ReadRevenue, _User.scopes.Read],
          conditions: {
            $or: [{
              projectID: (0, _helpers.makeGlobalID)('project', project._id)
            }, {
              projectID: project._id
            }]
          }
        }, {
          scopes: [_Project.scopes.ReadPersonnel, _Project.scopes.ReadOrganizations],
          conditions: {
            $or: [{
              id: (0, _helpers.makeGlobalID)('project', project._id)
            }, {
              _id: project._id
            }]
          }
        }];
      }
    }, {
      name: 'Safety User',
      displayName: 'Safety Guest',
      description: 'Everyone in project with basic access to safety aspect of the project with permissions to:\n	* create non-visit observations\n	* join visits',
      permissions: SafetyUserPermissions
    }, {
      name: 'Safety Inspector',
      displayName: 'Safety Tour Leader',
      description: 'Person responsible for safety aspact of the project with main permissions for:\n	* create safety visits\n	* becoming default visit guide',
      permissions: async function ({
        user,
        parent: project,
        membership,
        resolved,
        db,
        cache
      }) {
        var permissions;
        permissions = _lodash.default.compact([// Allow RDE inspectors to record training
        project.branch === 'RD' ? {
          scopes: [_SafetyVisit.scopes.Self.RecordTraining],
          conditions: resolved ? {
            'project.id': {
              $exists: false
            }
          } : {
            projectID: {
              $exists: false
            }
          }
        } : void 0, {
          scopes: [_SafetyVisit.scopes.Self.Create],
          conditions: _objectSpread({}, (0, _helpers.projectAssociationCondition)(resolved, project), {}, project.branch === 'CDE' ? {
            recordType: 'Onsite'
          } : {})
        }, {
          scopes: [_SafetyVisit.scopes.Self.Read],
          conditions: _objectSpread({}, (0, _helpers.projectAssociationCondition)(resolved, project), {}, _helpers.isSelfDeletedCondition)
        }, {
          scopes: [_SafetyObservation.scopes.Self.Read, _SafetyObservation.scopes.Details.Update, _SafetyObservation.scopes.Ratings.Update, _SafetyObservation.scopes.Attachments.Manage],
          conditions: _objectSpread({}, (0, _helpers.projectAssociationCondition)(resolved, project), {}, _helpers.isSelfDeletedCondition)
        }, {
          scopes: [_SafetyObservation.scopes.Review.Check],
          conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {}, (0, _helpers.projectAssociationCondition)(resolved, project), {
            // rating: $ne: 'Positive'
            status: 'PendingCheck'
          })
        }, {
          scopes: [_SafetyObservation.scopes.Review.Uncheck],
          conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {}, (0, _helpers.projectAssociationCondition)(resolved, project), {
            rating: {
              $ne: 'Positive'
            },
            status: 'Checked'
          })
        }]);
        return [// ,
        // 	scopes: [
        // 		SafetyVisitScopes.Self.Share
        // 	]
        // 	conditions: {
        // 		...projectAssociationCondition resolved, project
        // 		...isSelfDeletedCondition
        // 		TODO: rolesToAdd: 'SafetyVisitReader'
        // 	}
        // ,
        // 	scopes: [
        // 		SafetyObservationScopes.Self.Share
        // 	]
        // 	conditions: {
        // 		...projectAssociationCondition resolved, project
        // 		...isSelfDeletedCondition
        // 		TODO: rolesToAdd: 'SafetyObservationReader'
        // 	}
        ...permissions, ...(await SafetyUserPermissions({
          user,
          parent: project,
          membership,
          resolved,
          db,
          cache
        }))];
      }
    }, {
      name: 'Safety Project Manager',
      displayName: 'Safety Admin',
      description: '⛑Manager of all safety properties in project',
      permissions: function ({
        user,
        parent: project,
        membership,
        resolved,
        db,
        cache
      }) {
        return [{
          scopes: [_Project.scopes.ReadSafety],
          conditions: _objectSpread({}, (0, _helpers.projectAssociationCondition)(resolved, project))
        }, {
          scopes: [_SafetyVisit.scopes.Self.Read],
          conditions: _objectSpread({}, (0, _helpers.projectAssociationCondition)(resolved, project))
        }, {
          scopes: [_SafetyVisit.scopes.Self.Delete, _SafetyVisit.scopes.Self.Sync, _SafetyVisit.scopes.Members.Add],
          conditions: _objectSpread({}, (0, _helpers.projectAssociationCondition)(resolved, project), {}, _helpers.isSelfDeletedCondition)
        }, {
          scopes: [_SafetyVisitMember.scopes.Self.Accept, _SafetyVisitMember.scopes.Self.Reject],
          conditions: {
            role: 'Pending Member'
          }
        }, {
          scopes: [_SafetyVisitMember.scopes.Self.Remove],
          conditions: {
            role: 'Member'
          }
        }, {
          scopes: [_SafetyVisit.scopes.Members.Invite],
          conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {}, (0, _helpers.projectAssociationCondition)(resolved, project), {
            status: {
              $in: ['InLobby']
            }
          })
        }, {
          scopes: [_SafetyVisit.scopes.Attachments.Manage],
          conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {}, (0, _helpers.projectAssociationCondition)(resolved, project), {
            status: {
              $nin: ['Resolved']
            }
          })
        }, {
          // ,
          // 	scopes: [
          // 		SafetyVisitScopes.Review.Start
          // 	]
          // 	conditions: {
          // 		...isSelfDeletedCondition
          // 		...projectAssociationCondition resolved, project
          // 		status: $in: ['PendingReview']
          // 	}
          // ,
          // 	scopes: [
          // 		SafetyVisitScopes.Review.Cancel
          // 	]
          // 	conditions: {
          // 		...isSelfDeletedCondition
          // 		...projectAssociationCondition resolved, project
          // 		status: $in: ['InReview']
          // 	}
          // ,
          // 	scopes: [
          // 		SafetyVisitScopes.Review.Finish
          // 	]
          // 	conditions: {
          // 		...isSelfDeletedCondition
          // 		...projectAssociationCondition resolved, project
          // 		status: $in: ['InReview']
          // 	}
          scopes: [_SafetyVisit.scopes.Type.Update, _SafetyVisit.scopes.Owner.Update],
          conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {}, (0, _helpers.projectAssociationCondition)(resolved, project), {
            status: {
              $in: ['InLobby', 'InProgress', 'PendingReview', 'InReview']
            }
          })
        }, {
          scopes: [_SafetyVisit.scopes.Progress.Start],
          conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {}, (0, _helpers.projectAssociationCondition)(resolved, project), {
            status: {
              $in: ['InLobby']
            },
            type: {
              $exists: true
            }
          })
        }, {
          scopes: [_SafetyVisit.scopes.Progress.End],
          conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {}, (0, _helpers.projectAssociationCondition)(resolved, project), {
            status: {
              $in: ['InProgress']
            }
          })
        }, {
          scopes: [_SafetyVisit.scopes.Comments.Create],
          conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {}, (0, _helpers.projectAssociationCondition)(resolved, project), {
            status: {
              $in: ['ReviewCompleted', 'Resolved']
            }
          })
        }, {
          // Observations
          scopes: [_SafetyObservation.scopes.Self.Create, _SafetyObservation.scopes.Self.Read],
          conditions: _objectSpread({}, (0, _helpers.projectAssociationCondition)(resolved, project))
        }, {
          scopes: [_SafetyObservation.scopes.Self.Delete, _SafetyObservation.scopes.Details.Update],
          conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {}, (0, _helpers.projectAssociationCondition)(resolved, project), {
            status: {
              $in: ['New', 'PendingCheck', 'Checked']
            }
          })
        }, {
          scopes: [_SafetyObservation.scopes.Ratings.Update, _SafetyObservation.scopes.Attachments.Manage, _SafetyObservation.scopes.Self.Sync],
          conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {}, (0, _helpers.projectAssociationCondition)(resolved, project))
        }, {
          scopes: [_SafetyObservation.scopes.Review.Check],
          conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {}, (0, _helpers.projectAssociationCondition)(resolved, project), {
            // rating: $ne: 'Positive'
            status: 'PendingCheck'
          })
        }, {
          scopes: [_SafetyObservation.scopes.Review.Uncheck],
          conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {}, (0, _helpers.projectAssociationCondition)(resolved, project), {
            rating: {
              $ne: 'Positive'
            },
            status: 'Checked'
          })
        }, {
          scopes: [_SafetyObservation.scopes.Self.Clone],
          conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {}, (0, _helpers.projectAssociationCondition)(resolved, project))
        }];
      }
    }, {
      name: 'Safety CS Project Manager',
      displayName: 'Safety (CS) Admin',
      description: '⛑CS Manager of safety',
      permissions: function ({
        user,
        parent: project,
        membership,
        resolved,
        db,
        cache
      }) {
        return [{
          scopes: [_Project.scopes.ReadSafety],
          conditions: _objectSpread({}, (0, _helpers.identityCondition)(resolved, 'project', project))
        }, {
          scopes: [_SafetyVisit.scopes.Self.Read],
          conditions: _objectSpread({
            status: {
              $in: ['ReviewComplete', 'Resolved']
            }
          }, (0, _helpers.projectAssociationCondition)(resolved, project))
        }, {
          scopes: [_SafetyObservation.scopes.Self.Clone],
          conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {}, (0, _helpers.projectAssociationCondition)(resolved, project))
        }, {
          scopes: [_SafetyObservation.scopes.Self.Read],
          conditions: _objectSpread({}, (0, _helpers.projectAssociationCondition)(resolved, project))
        }, {
          scopes: [_SafetyObservation.scopes.Self.Delete, _SafetyObservation.scopes.Details.Update, _SafetyObservation.scopes.Attachments.Manage],
          conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {}, (0, _helpers.projectAssociationCondition)(resolved, project), {
            status: {
              $in: ['New', 'PendingCheck', 'Checked']
            }
          }, resolved ? {
            isClone: true
          } : {
            originalObservationID: {
              $exists: true
            }
          })
        }, {
          scopes: [_SafetyVisit.scopes.Self.Read, _SafetyVisit.scopes.Members.Join],
          conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {}, (0, _helpers.projectAssociationCondition)(resolved, project), {
            status: {
              $in: ['InLobby']
            }
          })
        }];
      }
    }])]
  }
};
exports.default = _default;