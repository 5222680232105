###
Abstract mechnism for display key:value pairs in multiple layouts and common styles
TODO!: Merge columns and layout properties
###

# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import PropTypes from 'prop-types'
# Renderable
import { div, img, span, a } from 'react-dom-factories'

Fragment = React.createFactory React.Fragment

# Styles
import styles from './index.styl'


export default class PropertiesList extends React.Component
	@propTypes:
		columns: PropTypes.oneOf [
			0
			1
			2
			3
			4
		]
		layout: PropTypes.oneOf [
			'horizontal'
			'vertical'
		]
		spacing: PropTypes.oneOf [
			'compact'
			'none'
		]
		items: PropTypes.arrayOf PropTypes.shape
			label: PropTypes.node
			column: PropTypes.number
			content: PropTypes.node
	@defaultProps:
		columns: 2
		spacing: 'default'

	render: ->
		div {
			className: _.compact([
				styles.container
				styles["layout-#{@props.columns}"]
				styles["spacing-#{@props.spacing}"]
				@props.className
			]).join ' '
		}, _.map @props.items, (item) ->
			div {
				key: item.key || item.label
				className: styles.cell
				},
				div {
					className: cnames [
							styles.label
							'properties-list-item-label'
							styles["column-#{item.column}"]
						]
					}, item.label
				div {
					className: cnames [
							styles.content
							'properties-list-item-content'
							styles["column-#{item.column}"]
						]
					}, item.content
