import gql from 'graphql-tag'

export UploadScans = gql """
	mutation($scans: [ID!]!, $projectSlug: String!){
		legalDocumentCreateByScans(scans: $scans, projectSlug: $projectSlug) {
			id
			filename
		}
	}
"""

export GetScansStatus = gql """
	query($ids: [ID!]!) {
		systemTicketsByIDs(ids: $ids) {
			id
			status
		}
	}
"""
