###
document delete modal
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import adopt from 'libs/adopt'
import { getRefetchQueries } from 'libs/legal'

# Renderable
import { div } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import { Query as _Query, Mutation as _Mutation } from 'react-apollo'
Query = React.createFactory _Query
Mutation = React.createFactory _Mutation

import { NotificationConsumer } from '../../../application/components/NotificationManager'

import _BevyModal from '@bevy/modal'
BevyModal = React.createFactory _BevyModal

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

# Styles
import ErrorIcon from '@atlaskit/icon/glyph/error'

# Data
import {
	DeleteDocuments
} from './data'
import trLegal from 'data/local/legal'


REFETCH_QUERIES = [
	'DMS_Inbox_Accessory',
	'DMS_Registry_Accessory',
	'DMS_Hub_counts'
	]

DataLayer = adopt
	mutations:
		deleteDocuments: ({collectionQueries, setNotification, onConfirm, app}) ->
			mutation: DeleteDocuments
			refetchQueries: getRefetchQueries REFETCH_QUERIES, app
			onCompleted: ->
				if onConfirm? then onConfirm()
				setNotification({content: 'Document deleted', appearance: 'success'})
			onError: (error) ->
				errorMsg =
					if !_.isEmpty error.graphQLErrors
							(_.first error.graphQLErrors).message
					else
						trLegal.error.actionFailure
				setNotification
					content: errorMsg
					appearance: 'error'

export default class DocumentDeleteModal extends React.Component
	@propTypes =
		onClose: PropTypes.func.isRequired

	constructor: (props) ->
		super props

	render: ->
		NotificationConsumer {}, ({setNotification}) =>
			DataLayer {
				setNotification: setNotification
				onConfirm: @props.onConfirm
				app: @props.app
			}, (operations) =>
				BevyModal {
					'data-test-id': 'delete-document-modal'
					width: 'small'
					height: 180
					appearance: 'danger'
					onClose: @props.onClose
					header: 'Delete confirmation'
					headerIcon: ErrorIcon {size: 'medium', primaryColor: '#DE350B'}
					actions: [
						text: 'Delete'
						onClick: () =>
							operations.deleteDocuments.mutation
								variables:
									documentIDs:
										if @props.documentIDs?
											@props.documentIDs
										else if @props.documentID?
											@props.documentID
							@props.onClose()
					,
						text: 'Cancel'
						onClick: () => @props.onClose()
					]
					note: 'Once deleted, it can be only restored by contacting your administrator.'
				},
					'Are you sure you want to delete this document?'

