import _ from 'lodash'
import React from 'react'

# Renderable
import { div } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import {BevyModalsWrapper as _BevyModalsWrapper} from '@bevy/modal'
BevyModalsWrapper = React.createFactory _BevyModalsWrapper

import _AddProjectModal from 'parts/Projects/AddProjectModal'
AddProjectModal = React.createFactory _AddProjectModal


export default getModals = ({}) ->
	BevyModalsWrapper {},
		if @state.activeModal is 'addProjectModal'
			AddProjectModal
				onClose: => @setState activeModal: null


