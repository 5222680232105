###
Project companies to display companies connected with this project
###

# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import DataAttribute from '@bevy/data-attribute'
import adopt from 'libs/adopt'

import { NotificationConsumer } from '../../application/components/NotificationManager'
import { UserInfoConsumer } from 'libs/userInfo'

# Renderable
import { div } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import _ContentController from '@bevy/content-controller'
ContentController = React.createFactory _ContentController

import _Spinner from '@atlaskit/spinner'
Spinner = React.createFactory _Spinner

import {InternalErrorState as _InternalErrorState} from 'components/ErrorDisplay'
InternalErrorState = React.createFactory _InternalErrorState

# Data
import {
	RootQuery
} from './data'

# Styles
import styles from './index.styl'

ITEMS_PER_PAGE = 100

DataLayer = adopt
	queries:
		rootQuery: ({projectSlug, sortKey, sortOrder }) ->
			query: RootQuery
			variables:
				projectSlug: projectSlug
				pagination:
					limit: ITEMS_PER_PAGE
				sort:
					key: sortKey
					order: if sortOrder is 'asc' then 'Ascending' else 'Descending'

	mutations: {}

# Content controller elements
import getToolbar from './elements/toolbar'
import getTable from './elements/table'
import getModals from './elements/modals'
import { COMPANIES_COLUMNS } from './elements/columns'

export default class ProjectDocumentsView extends React.Component
	constructor: (props) ->
		super props

		@state =
			activeModal: null
			selected: []
			documentsTableColumns: @getInitialColumnsConfiguration()
			activeSearch: null
			sortKey: 'Name'
			sortOrder: 'asc'
			activeDocuments: []

	getToolbar: getToolbar
	getTable: getTable
	getModals: getModals

	getInitialColumnsConfiguration: () ->
		savedConfig = JSON.parse localStorage.getItem('tableView-projects-companies')
		if savedConfig?
			_.map savedConfig, (column) ->
				columnTemplate = _.find COMPANIES_COLUMNS, {key: column.key}
				{
					...columnTemplate
					selected: column.selected
				}
		else COMPANIES_COLUMNS

	changeTableConfiguration: (newTableConfig) =>
		if !newTableConfig? #restore default
			localStorage.removeItem 'tableView-projects-companies'
			@setState documentsTableColumns: COMPANIES_COLUMNS
		else
			localStorage.setItem('tableView-projects-companies', JSON.stringify newTableConfig)
			@setState documentsTableColumns: newTableConfig

	handleItemsSelect: (ids) =>
		@setState
			selected: _.concat [], ids

	handleSortChange: (key, order) =>
		@setState
			sortKey: key
			sortOrder: order
			fetchedAll: false

	render: ->
		selected = @state.selected
		NotificationConsumer {}, ({setNotification, updateNotification}) =>
			UserInfoConsumer {}, ({ability, me}) =>
				DataLayer {
					setNotification
					projectSlug: @props.match.params.project
					sortKey: @state.sortKey
					sortOrder: @state.sortOrder
				}, (operations) =>
					organizations = if operations.rootQuery.data?.project?
							operations.rootQuery.data.project.organizations
						else
							[]
					projectID = if operations.rootQuery.data?.project? then operations.rootQuery.data.project.id
					projectName = if operations.rootQuery.data?.project? then operations.rootQuery.data.project.name
					if operations.rootQuery.error?
						InternalErrorState {}
					else
						Fragment {},
								ContentController
									pageId: "#{if projectName? then projectName else 'project'} companies"
									'data-test-id': 'companies-content'
									className: cnames styles.page
									toolbar: @getToolbar {organizations, selected, ability,	me,	projectID}
									contentClassName: styles.content
									paneClassName: styles.pane
									layouts:
										regular:
											view:
												width: "#{(700 / 12)}%"
											accessory:
												width: "#{(500 / 12)}%"
									views:
										table: @getTable {
											items: organizations
											loading: operations.rootQuery.loading
											fetchMore: operations.rootQuery.fetchMore
											itemsCount: if operations.rootQuery.data?.project? then operations.rootQuery.data.project.organizationsCount else 100
											columnsSetup: @state.documentsTableColumns
											changeTableConfiguration: @changeTableConfiguration
											selected
											onSelect: @handleItemsSelect
											onSortChange: @handleSortChange
											sortKey: @state.sortKey
											sortOrder: @state.sortOrder
											getItemActions: (document) =>
												{secondary} = @getToolbar {organizations, selected, ability,	me,	projectID}
												secondary
										}

								@getModals {
									projectID
									selected
								}
