exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1LwLnKY2sa00gZQlH2ViA5 {\n  text-decoration: none;\n  color: inherit;\n}\n._1LwLnKY2sa00gZQlH2ViA5 > div {\n  padding-left: 12px;\n  padding-right: 12px;\n}\n._1LwLnKY2sa00gZQlH2ViA5 > div > span {\n  left: 0;\n  right: 0;\n}\n._1LwLnKY2sa00gZQlH2ViA5._2dYu9ImmPuRBnSa2HIl5bR {\n  background: #0747a6;\n  border-top-left-radius: 3px;\n  border-top-right-radius: 3px;\n}\n._1LwLnKY2sa00gZQlH2ViA5._2dYu9ImmPuRBnSa2HIl5bR div[role='tab'] > span {\n  background-color: #0747a6;\n}\n._1LwLnKY2sa00gZQlH2ViA5._2dYu9ImmPuRBnSa2HIl5bR > div {\n  border-color: #0747a6;\n  color: #fff !important;\n}\n._247pfPfGSoVqEECHyR5NoQ {\n  flex: 1;\n  display: flex;\n  max-width: 100%;\n  padding: 8px 8px 0;\n  overflow: hidden;\n}\n._247pfPfGSoVqEECHyR5NoQ div[role='tablist'] {\n  align-items: flex-end;\n  overflow-x: auto;\n}\n._247pfPfGSoVqEECHyR5NoQ div[role='tablist'] ._2xRz8wodDXw9SThG6qZfy {\n  margin-left: auto;\n}\n._247pfPfGSoVqEECHyR5NoQ.hrhZ9FI7Qrg2lukD5ecPr > div > div > div > ._1LwLnKY2sa00gZQlH2ViA5 {\n  font-size: 1.18rem;\n}\ndiv[role='tab'] {\n  padding: 4px;\n  margin: 0 4px;\n}\ndiv[aria-selected='false'] {\n  color: #8993a4;\n}\ndiv[aria-selected='true'] {\n  color: #091e42;\n}\ndiv[role='tabpanel'] {\n  padding: 0;\n}\n", ""]);
// Exports
exports.locals = {
	"link": "_1LwLnKY2sa00gZQlH2ViA5",
	"hubLink": "_2dYu9ImmPuRBnSa2HIl5bR",
	"base": "_247pfPfGSoVqEECHyR5NoQ",
	"aside": "_2xRz8wodDXw9SThG6qZfy",
	"hubNav": "hrhZ9FI7Qrg2lukD5ecPr"
};