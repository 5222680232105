import _ from 'lodash'
import React from 'react'

# Renderable
import { div } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import {BevyModalsWrapper as _BevyModalsWrapper} from '@bevy/modal'
BevyModalsWrapper = React.createFactory _BevyModalsWrapper

import _AddProjectGroupMembersModal from 'parts/ProjectGroups/AddProjectGroupMembersModal'
AddProjectGroupMembersModal = React.createFactory _AddProjectGroupMembersModal

import _DeleteProjectGroupMembersModal from 'parts/ProjectGroups/DeleteProjectGroupMembersModal'
DeleteProjectGroupMembersModal = React.createFactory _DeleteProjectGroupMembersModal

export default getModals = ({selected}) ->
	BevyModalsWrapper {},
		switch @state.activeModal
			when 'add'
				AddProjectGroupMembersModal
					projectGroupID: @props.id
					onClose: => @setState activeModal: null
			when 'delete'
				DeleteProjectGroupMembersModal
					projectGroupID: @props.id
					usersIDs: @state.selected
					onClose: =>
						@setState
							activeModal: null
							selected: []
