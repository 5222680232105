import _ from 'lodash'
import React from 'react'

# Renderable
import { div } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import {BevyModalsWrapper as _BevyModalsWrapper} from '@bevy/modal'
BevyModalsWrapper = React.createFactory _BevyModalsWrapper

import _AddProjectGroupModal from 'parts/ProjectGroups/AddProjectGroupModal'
AddProjectGroupModal = React.createFactory _AddProjectGroupModal

import _EditProjectGroupModal from 'parts/ProjectGroups/EditProjectGroupModal'
EditProjectGroupModal = React.createFactory _EditProjectGroupModal

import _DeleteProjectGroupsModal from 'parts/ProjectGroups/DeleteProjectGroupsModal'
DeleteProjectGroupsModal = React.createFactory _DeleteProjectGroupsModal

export default getModals = ({data, selected}) ->
	projectID = _.get data, 'rootQuery.data.project.id'
	BevyModalsWrapper {},
		switch @state.activeModal
			when 'add'
				AddProjectGroupModal {
					...@props
					projectID
					onNewGroupCreate: (id) =>
						@setState selected: id
					onClose: => @setState activeModal: null
				}
			when 'edit'
				EditProjectGroupModal {
					...@props
					projectGroupID: _.first selected
					projectID
					onClose: =>
						@setState activeModal: null
				}
			when 'delete'
				DeleteProjectGroupsModal {
					...@props
					projectID
					projectGroupIDs: selected
					onClose: =>
						@setState
							activeModal: null
							selected: []
				}
