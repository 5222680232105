###
opportunity delete modal
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import adopt from 'libs/adopt'

# Renderable
import { div } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import { NotificationConsumer } from '../../../application/components/NotificationManager'

import _BevyModal from '@bevy/modal'
BevyModal = React.createFactory _BevyModal

# Styles
import ErrorIcon from '@atlaskit/icon/glyph/error'

# Data
import {
	DeleteUser
} from './data'

REFETCH_QUERIES = ['ProjectUsersList']

DataLayer = adopt
	mutations:
		deleteUser: ({setNotification}) ->
			mutation: DeleteUser
			onCompleted: -> setNotification({content: 'User successfully deleted from the project', appearance: 'success'})

export default class UsersDeleteModal extends React.Component
	@propTypes =
		onClose: PropTypes.func.isRequired

	constructor: (props) ->
		super props

	render: ->
		NotificationConsumer {}, ({setNotification}) =>
			DataLayer {
				setNotification: setNotification
			}, (operations) =>
				BevyModal {
					onClose: @props.onClose
					header: 'Delete confirmation'
					headerIcon: ErrorIcon {size: 'medium', primaryColor: '#DE350B'}
					actions: [
						text: 'Delete'
						onClick: =>
							operations.deleteUser.mutation
								variables:
									removedProjects: [id: @props.projectID]
									userID: @props.userID
								refetchQueries: REFETCH_QUERIES
							@props.onClose()
					,
						text: 'Cancel'
						onClick: @props.onClose
					]
					# note: 'Once deleted, it can be only restored by contacting your administrator.'
				},
					'Are you sure you want to remove this user from this project?'

