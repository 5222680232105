###
Delete Project Group Member Modal
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import adopt from 'libs/adopt'

# Renderable
Fragment = React.createFactory React.Fragment

import { NotificationConsumer } from '../../../application/components/NotificationManager'

import _BevyModal from '@bevy/modal'
BevyModal = React.createFactory _BevyModal

# Styles
import ErrorIcon from '@atlaskit/icon/glyph/error'

# Data
import {
	DeleteUsersFromGroups
} from './data'

REFETCH_QUERIES = [
	'projectUserGroupDetail'
	'projectUserGroupsList'
	]

DataLayer = adopt
	mutations:
		deleteUsersFromGroups: ({setNotification, id}) ->
			mutation: DeleteUsersFromGroups
			variables: { id }
			refetchQueries: REFETCH_QUERIES
			onCompleted: ->
				setNotification({content: 'Members successfully deleted from group', appearance: 'success'})
			onError: (err) ->
				if err?.graphQLErrors? and (_.head err.graphQLErrors).message?
					setNotification ({content: (_.head err.graphQLErrors).message, appearance: 'error'})
				else
					setNotification ({content: 'Error removing users from Project Group', appearance: 'error'})

export default class DeleteProjectGroupMembersModal extends React.Component
	@propTypes =
		onClose: PropTypes.func.isRequired

	constructor: (props) ->
		super props

	render: ->
		NotificationConsumer {}, ({setNotification}) =>
			DataLayer {
				setNotification: setNotification
				id: @props.projectGroupID
			}, (operations) =>
				BevyModal {
					onClose: @props.onClose
					header: 'Delete confirmation'
					headerIcon: ErrorIcon {size: 'medium', primaryColor: '#DE350B'}
					actions: [
						text: 'Delete'
						onClick: =>
							operations.deleteUsersFromGroups.mutation
								variables:
									removedEntities:
										_.map @props.usersIDs, (user) ->
											id: user
							@props.onClose()
					,
						text: 'Cancel'
						onClick: @props.onClose
					]
				},
					"Are you sure you want to remove member#{if (_.size @props.usersIDs) > 1 then 's' else ''} from group?"

