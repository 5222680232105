###
Edit project
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import adopt from 'libs/adopt'

# Renderable
import { div, p } from 'react-dom-factories'
import DataAttribute from '@bevy/data-attribute'

Fragment = React.createFactory React.Fragment

import _Spinner from '@bevy/spinner'
Spinner = React.createFactory _Spinner

import _AddProjectModal from '../AddProjectModal'
AddProjectModal = React.createFactory _AddProjectModal

# Data
import {
	ProjectEditEnvironment
} from './data'

DataLayer = adopt
	queries:
		projectEditEnvironment: ({ projectID}) ->
			query: ProjectEditEnvironment
			variables:
				projectID: projectID

export default class ProjectEditModal extends React.Component
	@propTypes =
		onClose: PropTypes.func.isRequired

	getInitialData: (data) =>
		if data?
			phases = {}
			_.map data.projectByID.phases, (phase) ->
				phases["phase_#{phase.id}"] = phase.name
			result = {
				...data.projectByID
				...phases
				photo: if data.projectByID.photo?.original? then data.projectByID.photo.original
				spvID: if data.projectByID.spv? then data.projectByID.spv.id
				defaultValueSPV: if data.projectByID.spv?
					label: data.projectByID.spv.name
					value: data.projectByID.spv.id
			}
			result = _.omit result, ['id', '__typename', 'spv', 'phases']

	render: ->
		DataLayer
			projectID: @props.projectID
			, (operations) =>
				activeQuery = operations.projectEditEnvironment
				if activeQuery.loading then Spinner {}
				else
					AddProjectModal {
						...@props
						initialSetup: if activeQuery.data? then @getInitialData activeQuery.data
						editQuery: activeQuery
					}

