###
KPI Toolbar presents multiple kpis in common way
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
# Renderable
import { div } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import _KPI from '@bevy/kpi'
KPI = React.createFactory _KPI
# Styles
import styles from './index.styl'



export default class KPIToolbar extends React.Component
	@propTypes:
		compact: PropTypes.bool
		mini: PropTypes.bool

	# @defaultProps:

	constructor: (props) ->
		super props
	render: ->
		div {
			className: cnames [
				@props.className
				if @props.compact then styles.compact
				if @props.mini then styles.mini
				if @props.dms then styles.dms
				styles.wrapper
			]
			'data-test-id': @props['data-test-id']
			style: @props.style
		},
			_.map @props.items, (item, index) =>
				Fragment {key: item.key},
					KPI {
						dms: @props.dms
						..._.omit item, 'value'
						reversedColors: @props.reversedColors
						mini: @props.mini
						className: cnames [styles.item, item.className]
					}, item.value
					if @props.separator
						div {className: styles.separator}

