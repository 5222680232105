/**
 * GĹĂłwny moduĹ imapLiteApi zawiera tworzenie iframe + metody komunikacji miÄdzy
 * rĂłĹźnymi stronami(postMessage w celu unikniÄcia crossDomain)
 * @global
 * @namespace
 * @name ILITEAPI
 */

export var ILITEAPI = (function() {
    // glowne zmienne
    var apiCrossDomain = true,
        prefixModuleName = 'imapLiteApi-',
        apiJsName = '/imapLiteApi-core.js',
        iframe = null,
        lhurl = window.location.href,
        modules = [],
        rmodules = 0,
        map,
        cfg,
        liteConnected = false,
        bindMsgEvts = false,
        resizeBrowserEvent,
        resizeBrowserEvent = null,
        browserResize = false

    /**
     * link do imapLite na podstawie linku do api jest to sam host
     */
    var apiUrl = (function() {
        var src = null
        var scripts = [{src: 'https://mapy.geoportal.gov.pl/iMapLite/js/imapLiteApi-core.js'}]

        var len = scripts != null ? scripts.length : 0
        for (var i = 0; i < len; i++) {
            var script = scripts[i]
            try {
                if (script.src.indexOf(apiJsName) !== -1) {
                    src = script.src

                    break
                }
            } catch (err) {}
        }
        src = src.replace('js', '')
        src = src.replace(apiJsName, '')
        return src
    })()

    /**
     * zwraca url do imalLite
     */
    var getBrowserUrl = function() {
        var aurl = apiUrl
        if (ILITEAPI.icfg.imapLiteUrl) {
            aurl = ILITEAPI.icfg.imapLiteUrl
        }
        return aurl + 'browser.jsp?api=true'
    }

    /**
     * zabezpieczenie przed brakiem funkcji np. indexOf ie 8
     */
    function checkFunctions() {
        if (!Array.indexOf) {
            // brak indexOf
            Array.prototype.indexOf = function(obj) {
                for (var i = 0; i < this.length; i++) {
                    if (this[i] == obj) {
                        return i
                    }
                }
                return -1
            }
        }
    }

    /**
     * zabezpieczenie przed brakiem console
     */
    function checkConsole() {
        if (!window.console) {
            ;(function() {
                var names = [
                        'log',
                        'debug',
                        'info',
                        'warn',
                        'error',
                        'assert',
                        'dir',
                        'dirxml',
                        'group',
                        'groupEnd',
                        'time',
                        'timeEnd',
                        'count',
                        'trace',
                        'profile',
                        'profileEnd'
                    ],
                    i,
                    l = names.length

                window.console = {}

                for (i = 0; i < l; i++) {
                    window.console[names[i]] = function() {}
                }
            })()
        }
    }

    function loadModules() {}

    /**
     * Metoda ktĂłra tworzy widok aplikacji z mapÄ w elemencie div strony html, o podanym identyfikatorze.
     * @name init
     * @memberOf ILITEAPI
     * @function
     * @param {Object} initCfg - Obiekt json z opcjami startowymi aplikacji
     * @param {String} initCfg.divId - identyfikator(atrybut id) elementu na stronie html, na ktĂłrej odbywa siÄ osadzania imapLite.
     * @param {Int|String} initCfg.width - SzerokoĹÄ div z mapÄ w postaci liczby lub np. â100%â
     * @param {Int|String} initCfg.height - WysokoĹÄ div z mapÄ w postaci liczby lub np. â100%â
     * @param {Array} [initCfg.activeGpMaps] - lista dostÄpnych kompozycji mapowych â tablica identyfikatorĂłw kompozycji odnoszÄcych siÄ do kompozycji zdefiniowanych w elemencie gpmaps w pliku konfiguracyjnym aplikacji (cfg.json)
     * @param {String} [initCfg.activeGpMapId] - identyfikator kompozycji ktĂłra Ĺaduje siÄ jako pierwsza(startowa kompozycja) â odnosi siÄ do wybranej kompozycji zdefiniowanej w elemencie gpmaps w pliku konfiguracyjnym aplikacji (cfg.json)
     * @param {Array} [initCfg.activeGpActions] - lista dostÄpnych akcji â tablica identyfikatorĂłw akcji odnoszÄcych siÄ do akcji zdefiniowanych w elemencie gpactions w pliku konfiguracyjnym aplikacji (cfg.json)
     * @param {Int} [initCfg.scale] - startowy poziom skalowy mapy
     * @param {Object|Array} [initCfg.marker] - startowy punkt do ktĂłrego centruje siÄ mapa. PrzykĹad poniĹźej. Zamiast pojedynczego markera moĹźna podaÄ tablicÄ takich obiektĂłw. Wtedy na mapie pojawi siÄ wiÄcej niĹź jeden marker(jeĹli tablica bÄdzie zawieraÄ wiÄcej niĹź jeden obiekt).Obiekt zawiera opcje dymku.
     * @param {String} [initCfg.marker.title] - tytuĹ dymka
     * @param {String} [initCfg.marker.content] - zawartoĹÄ dymka(prosty html, ze znacznikami &lt;b&gt; i &lt;a&gt;)
     * @param {Bool} [initCfg.marker.show] - pokazanie siÄ automatycznie dymka, po utworzeniu markera
     * @param {int} [initCfg.marker.deleteTime=-1] - Czas po ktĂłrym zostanie usuniÄty marker z mapy; brak oznacza brak automatycznego usuniÄcia markera
     * @param {String} [initCfg.marker.id='1'] - Identyfikator markera. SĹuĹźy on do usuniÄcia konkretnego markera z mapy. Jednak obecna wersja pozwala ustawienie jednego markera na mapie, dlatego ten parametr moĹźe na razie byÄ pomijany.
     * @param {Object} [initCfg.initialExtent] - Extent startowy mapy. PrzykĹad poniĹźej.
     * @param {Bool} [initCfg.useMenu] - false â wyĹÄczy pokazywanie menu na mapce; domyĹlnie true;
     * @param {Array} [initCfg.selection] - Obiekt do ustawiania selekcji po inicjalizacji mapy.
     * @param {Bool} initCfg.selection.onlyPreview - true â wĹacza tryb wyĹwietlania mapy z selekcja bez moĹźliwoĹci jej zmiany poprzez klikanie na mapie
     * @param {Array} initCfg.selection.ids - to tablica zmienna typu string, a dokĹadnie identyfikatorĂłw; jest wartoĹÄ pola typu oidField, np. OBJECTID na warstwie; jedna zmienna, moĹźe byÄ grupÄ wartoĹci wymienionych po przecinku np.; "ids":["1,2,3,4,5,6,7,8,9,10", â11â,â12â]
     * @param {String} initCfg.selection.id - identyfikator warstwy pod ktĂłrÄ w aplikacji zostanie zaĹadowana warstwa z selekcjami
     * @param {String} initCfg.selection.oidField - nazwa pola bÄdÄcego identyfikatorem obiektu na warstwie
     * @param {String} initCfg.selection.oidFieldType - string/numer â okreĹla typ pola bÄdÄcego identyfikatorem  na warstwie
     * @param {String} initCfg.selection.url - url do serwisu z warstwa do selekcji(z niej sa pobierane obiekty podane w ids)
     * @param {String} initCfg.selection.geometryType - point/poligon/polyline; typ geometrii warstwy
     * @param {Bool} initCfg.selection.zoomToSelect - true â oznacza Ĺźe jeĹli zaznaczymy obszar to mapa siÄ do niego przybliĹźy
     * @param {Array} [initCfg.gpplugins] - JeĹli chcemy doĹÄczyÄ jakiĹ plugin, a nie mamy go doĹÄczonego w konfiguracji iMapLite, to moĹźemy go dodaÄ podczas inicjonowania mapy, wĹaĹnie poprzez podanei tablicy pluginĂłw.
     * @param {callback} [callback] - Jest to funkcja javascript, ktĂłra bÄdzie wykonana po zaĹadowaniu API oraz wyĹwietleniu mapy.
     * @example <caption>PrzykĹadowy definicji markera</caption>
     * "center" : {
     *       "x" :  8437470,
     *       "y" :   5881868,
     *       "scale" : 10000,
     *       "title" : âtytuĹ dymkaâ,
     *       "content" : âopis dymkaâ,
     *       "show" : true
     *      }
     * @example <caption>PrzykĹadowy definicji startowego extentu mapy</caption>
     * "initialExtent" : {
     *       "xmin" :  8437470,
     *       "ymin" :   5881868,
     *       "xmax" : 8449579,
     *       "ymax" : 5895526
     *      }
     */
    function init(icfg, callback) {
        checkConsole()
        checkFunctions()
        ILITEAPI.icfg = icfg
        ILITEAPI.icfg.api = true
        var mlen = modules != null && modules !== 'undefined' ? modules.length : 0
        var allcall = function() {
            if (ILITEAPI.modules) {
                map = ILITEAPI.modules.esrimap

                for (var key in ILITEAPI.modules) {
                    var m = ILITEAPI.modules[key]
                    m.init(function() {})
                }

            }
            afterLoadAllModules(callback)
        }
        if (mlen > 0) {
            rmodules = 0

            for (var m = 0; m < mlen; m++) {
                var mn = prefixModuleName + modules[m] + '.js'
                var murl = apiUrl + 'js/' + mn
                loadScript(murl, function() {

                    rmodules++
                    if (rmodules == mlen) {
                        allcall()
                    }
                })
            }
        } else {
            allcall()
        }
    }

    /**
     * Wstawia w punkcie o wspĂłĹrzÄdnych x i y marker/pinezkÄ
     * @name showMarker
     * @memberOf ILITEAPI
     * @function
     * @param {Int|Object} x|marker - WspĂłĹrzÄdna x markera.|Jako parametr wstawiamy obiekt json z przynajmniej jednym atrybutem. Musi to byÄ atrybut id. Np. ILITEAPI.showMarker({"id" : "marker0"}). Takie wywoĹanie spowoduje wycentrowanie mapy do punktu, w ktĂłrym wstawiony  jest marker o zadanym identyfikatorze. OprĂłcz id, moĹźna podaÄ scale, co spowoduje przybliĹźenie siÄ do danego punktu w danej skali.
     * @param {Int} y - WspĂłĹrzÄdna y markera.
     * @param {Object} [opts]
     * @param {String} [opts.id='1'] - identyfikator markera; Jest parametrem wymaganym w przypadku kiedy np. inicjalizujemy aplikacjÄ poprzez pokazanie markera na starcie aplikacji i chcemy go potem usunÄÄ uĹźywajÄc metody deleteMarker. Brak id, spowoduje automatyczne nadanie identyfikatora w aplikacji.
     * @param {String} [opts.title] - tytuĹ dymka; przy wyszukiwaniu brak opcji powoduje wyĹwietlenie standardowego opisu adresu
     * @param {String} [opts.content] - zawartoĹÄ dymka(prosty html, ze znacznikami &lt;b&gt; i &lt;a&gt;);przy wyszukaniu adresu brak content oznacza standardowy opis dla adresu.
     * @param {Bool} [opts.show] - pokazanie siÄ automatycznie dymka, po utworzeniu markera
     * @param {int} [opts.deleteTime=-1] - Czas po ktĂłrym zostanie usuniÄty marker z mapy; brak oznacza brak automatycznego usuniÄcia markera
     * @param {Bool} opts.show - JeĹli ustawimy zmiennÄ show na true to pokaĹźe siÄ rĂłwnieĹź dymek. ZastÄpi on dymek, ktĂłry ewentualnie byĹ wĹÄczony wczeĹniej(poprzez inicjalizacjÄ API, wyszukanie adresy lub wstawienie startowe markera).
     * @param {Int} [sr] - odwzorowanie; dopuszczalne wartoĹci: 2180(ukĹad 1992),4326(WGS84)
     */
    function showMarker(x, y, sr, opts) {
        bindMessageEvents()

        var connIntr = setInterval(function() {
            if (liteConnected) {
                window.clearInterval(connIntr)

                var mattrs = {}

                if (x instanceof Object && x.id) {
                    mattrs = {
                        id: x.id
                    }

                    if (x.scale) {
                        mattrs.scale = x.scale
                    }

                    if (x.sr) {
                        mattrs.sr = x.sr
                    }
                } else {
                    if (opts) {
                        opts.center = true
                    }
                    mattrs = {
                        x: x,
                        y: y,
                        sr: sr,
                        opts: opts
                    }
                }

                var json = {
                    fname: 'showMarker',
                    arguments: mattrs
                }
                sendMessage(json)
            }
        }, 250)
    }

    /**
     * Wstawia naraz wiele markerĂłw o w podanych punktach x,y
     * @name showManyMarker
     * @memberOf ILITEAPI
     * @function
     * @param {Array} markers - Jest to tablica obiektĂłw o postaci znanej z metody showMarker
     * @example <caption>PrzykĹad wartosci parametru markres</caption>
     * [
     *  {
     *  "x":591920.9699999997,
     *  "y":259048.22000000067,
     *  "sr":2180,
     *  "opts":{
     *      "id" : "marker1",
     *      "title" : "marker nr 1",
     *      "content" : "marker nr 1 - tresc",
     *      "show":true,
     *      "center":false
     *      }
     *  },
     *  {
     *  "x":521920.9699999997,
     *  "y":239048.22000000067,
     *  "sr":2180,
     *  "opts":{
     *      "id" : "marker2",
     *      "title" : "marker nr 2",
     *      "content" : "marker nr 2 - tresc"
     *      }
     *  }
     * ]
     */
    function showManyMarker(markers) {
        bindMessageEvents()

        var connIntr = setInterval(function() {
            if (liteConnected) {
                window.clearInterval(connIntr)

                var json = {
                    fname: 'showManyMarker',
                    arguments: {
                        markers: markers
                    }
                }
                sendMessage(json)
            }
        }, 250)
    }

    /**
     * Wyszukuje adres lub listÄ adresĂłw speĹniajÄca zadane parametry
     * wyszukiwania. W przypadku istnienia jednego wyniku nastÄpuje wstawienia
     * markera/pinezki w wyszukanym punkcie. W przypadku istnienia wielu
     * wynikĂłw, konieczny jest wybĂłr rezultatu z listy. W wybranek lokalizacji
     * nastÄpuje wstawienie markera/pinezki.
     * @name searchAddress
     * @memberOf ILITEAPI
     * @function
     * @param {String|Object} search - Parametr podawany w postaci peĹnego tekstu lub w postaci obiektu. Np: âWrocĹaw Legnicka 22â lub {"city" :  "WrocĹaw","street" :  "Legnicka","number" :  â22â}
     * @param {Object} [opts] - JeĹli ustawimy zmiennÄ show na true to pokaĹźe siÄ rĂłwnieĹź dymek. ZastÄpi on dymek, ktĂłry ewentualnie byĹ wĹÄczony wczeĹniej (wyszukanie adresu lub wstawienie startowego markera).
     * @param {String} [opts.title] - tytuĹ dymka; przy wyszukiwaniu brak opcji powoduje wyĹwietlenie standardowego opisu adresu
     * @param {String} [opts.content] - zawartoĹÄ dymka(prosty html, ze znacznikami &lt;b&gt; i &lt;a&gt;);przy wyszukaniu adresu brak content oznacza standardowy opis dla adresu.
     * @param {Bool} [opts.show] - pokazanie siÄ automatycznie dymka, po utworzeniu markera
     * @param {int} [opts.deleteTime=-1] - Czas po ktĂłrym zostanie usuniÄty marker z mapy; brak oznacza brak automatycznego usuniÄcia markera
     * @param {String} [opts.id='1'] - Identyfikator markera. SĹuĹźy on do usuniÄcia konkretnego markera z mapy. Jednak obecna wersja pozwala ustawienie jednego markera na mapie, dlatego ten parametr moĹźe na razie byÄ pomijany.
     *
     */
    function searchAddress(search, opts) {
        bindMessageEvents()

        var connIntr = setInterval(function() {
            if (liteConnected) {
                window.clearInterval(connIntr)

                if (opts) {
                    opts.center = true
                }

                var json = {
                    fname: 'searchAddress',
                    arguments: {
                        search: search,
                        opts: opts
                    }
                }
                sendMessage(json)
            }
        }, 250)
    }

    /**
     * wyszukiwanie kilku adresĂłw na raz
     */
    function searchManyAddress(search, opts) {
        bindMessageEvents()

        var connIntr = setInterval(function() {
            if (liteConnected) {
                window.clearInterval(connIntr)

                if (opts) {
                    opts.center = true
                }

                var json = {
                    fname: 'searchManyAddress',
                    arguments: {
                        search: search,
                        opts: opts
                    }
                }
                sendMessage(json)
            }
        }, 250)
    }

    /**
     * funkcja do wyszukiwania z moĹźliwoscia wskazania funckji do wyszukanai
     * skonfigurowanej w aplikacji(np. searchAddress,searchPRNG,itd.
     */
    function search(functionName, search, opts, callback) {
        bindMessageEvents()

        var connIntr = setInterval(function() {
            if (liteConnected) {
                window.clearInterval(connIntr)


                var json = {
                    fname: 'search',
                    arguments: {
                        useCallback: false,
                        fname: functionName,
                        search: search,
                        opts: opts
                    }
                }
                if (callback) {
                    json.arguments.useCallback = true
                    var cfname = functionName + '_callback'
                    ILITEAPI.icfg.callbacks[cfname] = function(rdata) {
                        callback(rdata)
                        //po uĹźyciu usuwam z pamieci funckje zwrotna
                        delete ILITEAPI.icfg.callbacks[cfname]
                    }
                }
                sendMessage(json)
            }
        }, 250)
    }

    /**
     * Wyszukuje naraz wiele obiektĂłw(np. adresy).
     * @name searchManyObjects
     * @memberOf ILITEAPI
     * @function
     * @param {Array} search - Jest to tablica obiektĂłw ktĂłre podajemy w metodzie searchAddress. Jedyna rĂłĹźnicÄ jest dodatkowy parametr podawany w obiekcie opts.
     * @param {Object} opts
     * @param {String} opts.layerDesc - identyfikator warstwy; sĹuĹźy do dodatkowego skonfigurowania wyszukiwania; odnosi siÄ do pozycji configu w obiekcie gplayers; dziÄki temu moĹźemy wskazaÄ np. url do usĹugi;
     * @example <caption>PrzykĹad parametrĂłw wywoĹania</caption>
     * [
     *  {
     *      "search" : "WrocĹaw Legnicka 20",
     *      "opts":
     *          {
     *              "layerDesc":"geopard.Adresy"
     *          }
     *  },
     *  {
     *      "search" : "WrocĹaw Rynek 10",
     *      "opts":
     *          {
     *              "layerDesc":"geopard.Adresy"
     *          }
     *  },
     *  {
     *      "search": "Wroclaw GrabiszyĹska 100",
     *      "opts":
     *          {
     *              "show":true,
     *              "layerDesc":"geopard.Adresy"
     *          }
     *  }
     * ]
     */
    function searchManyObjects(search, opts) {
        bindMessageEvents()

        var connIntr = setInterval(function() {
            if (liteConnected) {
                window.clearInterval(connIntr)

                if (opts) {
                    opts.center = true
                }

                var json = {
                    fname: 'searchManyObjects',
                    arguments: {
                        search: search,
                        opts: opts
                    }
                }
                sendMessage(json)
            }
        }, 250)
    }

    /**
     * Wyszukuje adres lub listÄ adresĂłw speĹniajÄca zadane parametry
     * wyszukiwania. W przypadku istnienia jednego wyniku nastÄpuje wstawienia
     * markera/pinezki w wyszukanym punkcie. W przypadku istnienia wielu
     * wynikĂłw, konieczny jest wybĂłr rezultatu z listy. W wybranej lokalizacji
     * nastÄpuje wstawienie markera/pinezki.
     */
    function searchPlot(search, opts) {
        bindMessageEvents()

        var connIntr = setInterval(function() {
            if (liteConnected) {
                window.clearInterval(connIntr)

                if (opts) {
                    opts.center = true
                }

                var json = {
                    fname: 'searchPlot',
                    arguments: {
                        search: search,
                        opts: opts
                    }
                }
                sendMessage(json)
            }
        }, 250)
    }

    /**
     * wyszukiwanie adresu i odpowiadajacych mu dzialek lub odwrotnie -
     * wyszukiwanie dzialki i odpowiadajacej jej adres
     */
    function searchAddressAndPlot(search, opts) {
        bindMessageEvents()

        var connIntr = setInterval(function() {
            if (liteConnected) {
                window.clearInterval(connIntr)

                if (opts) {
                    opts.center = true
                }

                var json = {
                    fname: 'searchAddressAndPlot',
                    arguments: {
                        search: search,
                        opts: opts
                    }
                }
                sendMessage(json)
            }
        }, 250)
    }

    /**
     * Usuwa marker o identyfikatorze id z mapy.
     * @name deleteMarker
     * @memberOf ILITEAPI
     * @function
     * @param {String} id - Identyfikator markera na mapie ktĂłry zostanie usuniÄty
     * @param {Int} [deleteTime] - Czas w ms, po ktĂłrym zostanie usuniÄty marker z mapy. Brak oznacza natychmiastowe usuniecie.
     */
    function deleteMarker(id, deleteTime) {
        bindMessageEvents()

        var connIntr = setInterval(function() {
            if (liteConnected) {
                window.clearInterval(connIntr)

                var json = {
                    fname: 'deleteMarker',
                    arguments: {
                        id: id,
                        deleteTime: deleteTime
                    }
                }
                sendMessage(json)
            }
        }, 250)
    }

    /**
     * zwraca true jeĹli ma byÄ Ĺadowany imapLite (ma byÄ Ĺadowany kiedy podano
     * aktywna gpmape lub przynajmniej marker)
     */
    function isInitalImapLite() {
        if (ILITEAPI.icfg) {
            if (ILITEAPI.icfg.activeGpMapId === 'undefined' && ILITEAPI.icfg.marker !== 'undefined') {
                return true
            } else if (ILITEAPI.icfg.activeGpMapId) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }

    /**
     * czynnoĹci po zaĹadowaniu wszystkich dodatkowych moduĹĂłw, jeĹli sÄ uĹźywane
     */
    function afterLoadAllModules(apicall) {
        deleteFrame()
        var call = function() {
            bindMessageEvents()
        }

        if (!ILITEAPI.icfg) {
            ILITEAPI.icfg = {}
        }

        if (!ILITEAPI.icfg.callbacks) {
            ILITEAPI.icfg.callbacks = {}
        }

        ILITEAPI.icfg.callbacks['api_callback'] = apicall
        createIframe(ILITEAPI.icfg.divId, call)
    }

    /**
     * odnajduje originHost na potzreby odbierania informacji wysylanych z
     * aplikacji imap-lite(imap-lite.js) do imap-lite-api(core)
     */
    function createOriginHost() {
        try {
            var wnd = iframe.ownerDocument
            return getSourceWndHost(wnd, apiUrl)
        } catch (err) {
            return apiUrl
        }
    }

    function deleteFrame() {
        var iframes = document.getElementsByTagName('iframe')
        for (var i = 0; i < iframes.length; i++) {
            if (iframes[i].id == 'ifrm') {
                iframes[i].parentNode.removeChild(iframes[i])
                break
            }
        }
    }

    function getIframe(divId) {
        if (this.iframe == null || this.iframe === 'undefined') {
            this.iframe = createIframe(divId)
        }
        return this.iframe
    }

    /**
     * utworzenie iframe
     */
    function createIframe(divId, callback) {
        try {
            iframe = document.createElement('iframe')
            iframe.setAttribute('id', 'ifrm')

            if (isInitalImapLite() === true) {
                var browserUrl = getBrowserUrl()

                iframe.setAttribute('src', browserUrl)

                var func = function() {

                    if (callback) {
                        callback()
                    }
                }
                if (iframe.addEventListener) {
                    iframe.addEventListener('load', func, true)
                } else if (iframe.attachEvent) {
                    iframe.attachEvent('onload', func)
                }
            }
            var w = ILITEAPI.icfg.width
            var h = ILITEAPI.icfg.height

            var style = 'width:'
            if (w && h) {
                if (typeof w === 'string') {
                    style += w
                } else {
                    style += w + 'px'
                }
                style += ';height:'
                if (typeof h === 'string') {
                    style += h
                } else {
                    style += h + 'px'
                }
            }
            iframe.setAttribute('style', style)
            var el = document.getElementById(divId)
            el.setAttribute('style', style)
            el.appendChild(iframe)
        } catch (err) {
            console.error('nie utworzono iframe')
            iframe = null
        }

        ILITEAPI.icfg.originHost = createOriginHost()

        return iframe
    }

    /**
     * wysyĹa zapytanie do iframe json - to json z opcjami zapytanie, jednak
     * jest wysylany jako string
     */
    function sendMessage(json) {
        var wnd = iframe.contentWindow
        var msg = JSON.stringify(json, function(key, val) {
            if (typeof val === 'function') {
                return val + ''
                // implicitly `toString` it
            }
            return val
        })
        var host = getImapLiteHost()

        wnd.postMessage(msg, host)
    }

    /**
     * zwraca host aplikacji imapLite z iframe
     */
    function getImapLiteHost() {
        try {
            var local = iframe.src.split('/')
            local.pop()
            local = local.join('/')
            return local
        } catch (err) {}
        return ''
    }

    function getSourceWndHost(wnd, host) {
        try {
            var local = wnd.location.protocol + '//'
            var local2 = wnd.location.host
            local2 += wnd.location.pathname
            var pops = local2.split('/')
            pops.pop()
            return local + pops.join('/')
        } catch (err) {}
        // jeĹli uruchamiamylocalnie, to wnd.sorurce moĹźe byc pusty wtedy
        // umozliwiamy komunikacje
        return ''
    }

    // Called sometime after postMessage is called
    function receiveMessage(event) {
        //
        var host = getImapLiteHost()
        var loc = getSourceWndHost(event.source, host)
        if (apiCrossDomain === false && loc !== host) {

            return
        }
        var js = null
        try {
            js = JSON.parse(event.data, function(key, val) {
                if (
                    'selection_callback' === key ||
                    'selection_all_callback' === key ||
                    'selection_one_callback' === key
                ) {
                    return eval('(' + val + ')')
                }
                return val
            })
        } catch (err) {}
        if ((!js || js == null) && 'imapLiteInit' === event.data) {
            addBrowserResizeEvent()
            liteConnected = true
            if (!ILITEAPI.icfg.callbacks) {
                ILITEAPI.icfg.callbacks = {}
            }
            if (ILITEAPI.icfg.adres_callback) {
                ILITEAPI.icfg.callbacks['adres_callback'] = ILITEAPI.icfg.adres_callback
                ILITEAPI.icfg.adres_callback = true
            }
            if (ILITEAPI.icfg.dzialka_callback) {
                ILITEAPI.icfg.callbacks['dzialka_callback'] = ILITEAPI.icfg.dzialka_callback
                ILITEAPI.icfg.dzialka_callback = true
            }
            if (ILITEAPI.icfg.search_callback) {
                ILITEAPI.icfg.callbacks['search_callback'] = ILITEAPI.icfg.search_callback
                ILITEAPI.icfg.search_callback = true
            }

            if (ILITEAPI.icfg.marker_callback) {
                ILITEAPI.icfg.callbacks['marker_callback'] = ILITEAPI.icfg.marker_callback
                ILITEAPI.icfg.marker_callback = true
            }

            var json = {
                fname: 'apiInit',
                arguments: {
                    icfg: ILITEAPI.icfg
                }
            }
            sendMessage(json)
        } else {
            try {
                if (js.fname && js.fname.indexOf('callback') != -1) {
                    if (ILITEAPI.icfg.callbacks[js.fname] && ILITEAPI.icfg.callbacks[js.fname] != null) {
                        ILITEAPI.icfg.callbacks[js.fname](js.arguments)
                    }
                }
            } catch (err) {}
        }
    }

    /**
     * podpiecie sie pod zdarzenie wysĹania wiadomoĹci na poczatku sprawdza czy
     * podpiecie sie juz odbylo
     */
    function bindMessageEvents() {
        if (!bindMsgEvts) {

            if (window.addEventListener) {
                window.addEventListener('message', receiveMessage, false)
            } else if (window.attachEvent) {
                window.attachEvent('onmessage', receiveMessage)
            }
        } else {

        }
    }

    /**
     * podpiecie sie pod zdarzenie wysĹania wiadomoĹci
     */
    function unbindMessageEvents() {
        bindMsgEvts = false

        try {
            if (window.addEventListener) {
                window.removeEventListener('message', receiveMessage, false)
            } else if (window.attachEvent) {
                window.detachEvent('message', receiveMessage)
            }
        } catch (err) {}
    }

    /**
     * Ĺadowanie dynamiczne biblioteki javascipt
     */
    function loadScript(url, callback) {
        // adding the script tag to the head as suggested before
        var head = document.getElementsByTagName('head')[0]
        var script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = url

        // then bind the event to the callback function
        // there are several events for cross browser compatibility
        script.onreadystatechange = callback
        script.onload = callback

        // fire the loading
        head.appendChild(script)
    }

    /**
     * zaĹadowanie w ramach potrzeby bilioteki javascript
     */
    function require(file, callback) {
        var script = document.getElementsByTagName('script')[0],
            newjs = document.createElement('script')

        // IE
        newjs.onreadystatechange = function() {
            if (newjs.readyState === 'loaded' || newjs.readyState === 'complete') {
                newjs.onreadystatechange = null
                callback()
            }
        }

        newjs.onload = function() {
            callback()
        }

        newjs.src = file
        script.parentNode.insertBefore(newjs, script)
    }

    /**
     * Ĺadowanie danych w postaci json
     */
    function getData(url, data, success) {
        $.post(url, data, success, 'json')
        // $.ajax({
        // type : 'GET',
        // url : url,
        // dataType : "json",
        // beforeSend : function() {
        // if (ILITEAPI.showLoader) {
        // ILITEAPI.showLoader
        // }
        // },
        // complete : function() {
        // if (ILITEAPI.hideLoader) {
        // ILITEAPI.hideLoader();
        // }
        // },
        // error : function(jqXHR, textStatus, errorThrown) {
        //
        // console.error(errorThrown);
        // },
        // success : success,
        // crossDomain : true,
        // cache : false
        // });
    }

    /**
     * przestrzeĹ nazw dla moduĹĂłw imapLitApi
     *
     * @param {Object} ns_string
     */
    function namespace(ns_string) {
        var parts = ns_string.split('.'),
            parent = ILITEAPI,
            i

        if (parts[0] === 'ILITEAPI') {
            parts = parts.slice(1)
        }

        for (i = 0; i < parts.length; i += 1) {
            if (typeof parent[parts[i]] === 'undefined') {
                parent[parts[i]] = {}
            }
            parent = parent[parts[i]]
        }
        return parent
    }

    // Return boolean TRUE/FALSE
    function isiPhone() {
        return navigator.platform.indexOf('iPhone') != -1 || navigator.platform.indexOf('iPod') != -1
    }

    /**
     * funkcja pomocnicza do dowania eventow ma to byc obejscie problemow roznic
     * w przegladarkach
     */
    function addEvent(elem, type, eventHandle) {
        if (elem == null || elem == undefined) return
        if (elem.addEventListener) {
            elem.addEventListener(type, eventHandle, false)
        } else if (elem.attachEvent) {
            elem.attachEvent('on' + type, eventHandle)
        } else {
            elem['on' + type] = eventHandle
        }
    }

    /**
     * funkcja pomocnicza do usuwania eventow ma to byc obejscie problemow
     * roznic w przegladarkach
     */
    function removeEvent(elem, type, eventHandle) {
        if (elem == null || elem == undefined) return
        if (elem.removeEventListener) {
            elem.removeEventListener(type, eventHandle)
        } else if (elem.detachEvent) {
            elem.detachEvent('on' + type, eventHandle)
        } else {
            elem['on' + type] = function() {}
        }
    }

    /**
     * dodanie zdarzenia zmiany wymiaru przegladarki
     */
    function addBrowserResizeEvent() {
        if (browserResize === true && resizeBrowserEvent == null) {
            resizeBrowserEvent = function() {
                var w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
                var h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight

                var fw = 0
                var fh = 0

                var divId = ILITEAPI.icfg.divId
                var div = document.getElementById(divId)

                if (div && div != null) {
                    fw = div.clientWidth
                    fh = div.clientHeight
                }

                var minW = ILITEAPI.icfg.minWidth
                var minH = ILITEAPI.icfg.minHeight

                var left = div.offsetLeft
                var top = div.offsetTop


                var rW = left + fw
                var rH = top + fh

                var nW = rW
                var nH = rH

                if (minW) {
                } else {


                    if (rW > w) {

                        nW = rW - (rW - w)
                    }
                }

                if (minH) {
                } else {

                    if (rH > h) {

                        nH = rH - (rH - h)
                    }
                }

                changeDivSize(div, nW, nH)



            }
            addEvent(window, 'resize', resizeBrowserEvent)
        }
    }

    /**
     * wyslanie do ImapLite rzadania zmiany rozmiaru diva co rowniez ma wymusic
     * resize mapy
     */
    function changeDivSize(div, nw, nh) {
        div.style.width = nw + 'px'
        div.style.height = nh + 'px'

        if (iframe != null) {
            var style = 'width:' + nw + 'px;height:' + nh + 'px'
            iframe.setAttribute('style', style)
        }
        var json = {
            fname: 'changeDivSize',
            arguments: {
                nw: nw,
                nh: nh
            }
        }
        sendMessage(json)
    }

    /**
     * Uruchamia geolokalizacjÄ
     * @name geolocalize
     * @memberOf ILITEAPI
     * @function
     * @param {Object} [obj] - DziaĹa podobnie, jak narzÄdzie geolokalizacji wywoĹane z menu aplikacji. JedynÄ rĂłĹźnicÄ jest to, Ĺźe jeĹli nie zadamy do tej metody Ĺźadnego parametru, to wynik lokalizacji pojawi siÄ na mapie i mapa zostanie wycentrowana na nim , ale nie przybliĹźy siÄ do niego. Aby mapa siÄ przybliĹźyĹa, do punktu lokalizacji to trzeba do metody podaÄ parametr w postaci: {âscaleâ:2000}
     */
    function geolocalize(opts) {
        var json = {
            fname: 'geolocalize',
            arguments: opts
        }
        sendMessage(json)
    }

    function fullMapExtent() {
        var json = {
            fname: 'fullMapExtent',
            arguments: null
        }
        sendMessage(json)
    }

    function showQueryWidget() {
        var json = {
            fname: 'showQueryWidget',
            arguments: null
        }
        sendMessage(json)
    }

    /**
     * ustawia markery w podanych punktach i z podanym iopcjami
     */
    function setSelection(selection) {
        bindMessageEvents()

        var connIntr = setInterval(function() {
            if (liteConnected) {
                window.clearInterval(connIntr)

                var json = {
                    fname: 'setSelection',
                    arguments: {
                        selection: selection
                    }
                }
                sendMessage(json)
            }
        }, 250)
    }

    /**
     * stan selekcji
     */
    function getSelectionState(selId, handler) {
        bindMessageEvents()

        var connIntr = setInterval(function() {
            if (liteConnected) {
                ILITEAPI.icfg.callbacks['selection_callback'] = handler
                window.clearInterval(connIntr)

                var json = {
                    fname: 'getSelectionState',
                    arguments: {
                        id: selId,
                        handler: handler
                    }
                }
                sendMessage(json)
            }
        }, 250)
    }

    function addOneObjectSelectionChange(selId, handler) {
        bindMessageEvents()
        var connIntr = setInterval(function() {
            if (liteConnected) {
                ILITEAPI.icfg.callbacks['selection_one_callback'] = handler
                window.clearInterval(connIntr)

                var json = {
                    fname: 'addOneObjectSelectionChange',
                    arguments: {
                        id: selId,
                        handler: handler
                    }
                }
                sendMessage(json)
            }
        }, 250)
    }

    function removeOneObjectSelectionChange(selId) {
        bindMessageEvents()
        var connIntr = setInterval(function() {
            if (liteConnected) {
                ILITEAPI.icfg.callbacks['selection_one_callback'] = null
                window.clearInterval(connIntr)

                var json = {
                    fname: 'removeOneObjectSelectionChange',
                    arguments: {
                        id: selId
                    }
                }
                sendMessage(json)
            }
        }, 250)
    }

    function addAllObjectSelectionChange(selId, handler) {
        bindMessageEvents()
        var connIntr = setInterval(function() {
            if (liteConnected) {
                ILITEAPI.icfg.callbacks['selection_all_callback'] = handler
                window.clearInterval(connIntr)

                var json = {
                    fname: 'addAllObjectSelectionChange',
                    arguments: {
                        id: selId,
                        handler: handler
                    }
                }
                sendMessage(json)
            }
        }, 250)
    }

    function removeAllObjectSelectionChange(selId) {
        bindMessageEvents()
        var connIntr = setInterval(function() {
            if (liteConnected) {
                ILITEAPI.icfg.callbacks['selection_all_callback'] = null
                window.clearInterval(connIntr)

                var json = {
                    fname: 'removeAllObjectSelectionChange',
                    arguments: {
                        id: selId
                    }
                }
                sendMessage(json)
            }
        }, 250)
    }

    /**
     * dodaje handkler do danego zdarzenai i danego narzedzia rysowania
     *
     * @param {Object} toolId
     * @param {Object} eventName
     * @param {Object} handler
     */
    function addDrawToolEventHandler(eventName, handler) {
        bindMessageEvents()
        var connIntr = setInterval(function() {
            if (liteConnected) {
                ILITEAPI.icfg.callbacks[eventName + '_callback'] = handler
                window.clearInterval(connIntr)

                var json = {
                    fname: 'addDrawToolEventHandler',
                    arguments: {
                        eventName: eventName
                    }
                }
                sendMessage(json)
            }
        }, 250)
    }

    /**
     * usuwanie handlera narzÄdzia rysowania
     *
     * @param {Object} toolId
     * @param {Object} eventName
     */
    function removeDrawToolEventHandler(eventName) {
        bindMessageEvents()
        var connIntr = setInterval(function() {
            if (liteConnected) {
                ILITEAPI.icfg.callbacks[eventName + '_callback'] = null
                window.clearInterval(connIntr)

                var json = {
                    fname: 'removeDrawToolEventHandler',
                    arguments: {
                        eventName: eventName
                    }
                }
                sendMessage(json)
            }
        }, 250)
    }

    return {
        icfg: {},
        isiPhone: isiPhone,
        init: init,
        map: {},
        namespace: namespace,
        require: require,
        geolocalize: geolocalize,
        fullMapExtent: fullMapExtent,
        showQueryWidget: showQueryWidget,
        showMarker: showMarker,
        showManyMarker: showManyMarker,
        search: search,
        searchAddress: searchAddress,
        searchManyAddress: searchManyAddress,
        searchManyObjects: searchManyObjects,
        searchAddressAndPlot: searchAddressAndPlot,
        searchPlot: searchPlot,
        deleteMarker: deleteMarker,
        setSelection: setSelection,
        getSelectionState: getSelectionState,
        addOneObjectSelectionChange: addOneObjectSelectionChange,
        removeOneObjectSelectionChange: removeOneObjectSelectionChange,
        addAllObjectSelectionChange: addAllObjectSelectionChange,
        removeAllObjectSelectionChange: removeAllObjectSelectionChange,
        addDrawToolEventHandler: addDrawToolEventHandler,
        removeDrawToolEventHandler: removeDrawToolEventHandler
    }
})()
