###
Simple textfield component with clear button
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
# Renderable
import { div } from 'react-dom-factories'


import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import _FieldTextStateless from '@atlaskit/textfield'
FieldTextStateless = React.createFactory _FieldTextStateless

# Styles
import styles from './index.styl'
import { closeCircled } from 'react-icons-kit/ionicons/closeCircled'


export default class SearchField extends React.Component
	@propTypes:
		className: PropTypes.string
		value: PropTypes.oneOfType [
			PropTypes.string
			PropTypes.number
		]
		isLabelHidden: PropTypes.bool
		shouldFitContainer: PropTypes.bool
		compact: PropTypes.bool
		onChange: PropTypes.func
	@defaultProps: {}
	constructor: (props) ->
		super props
	render: ->
		div {
			className: cnames [
				@props.className
				styles.wrapper
			]
		},
			div {
				className: cnames [
					styles.clearIndicator
					if _.isEmpty(@props.value) then styles.hidden
				]
				onClick: @props.onClear
			},
				Icon
					icon: closeCircled
					size: 12
			FieldTextStateless
				isLabelHidden: @props.isLabelHidden
				label: if @props.isLabelHidden then 'hidden'
				value: @props.value
				shouldFitContainer: @props.shouldFitContainer
				compact: @props.compact
				onChange: @props.onChange
