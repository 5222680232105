import gql from 'graphql-tag'

export RootQuery = gql """
	query DMS_Hub_counts(
		$projectSlug: String!,
		$inboxFilters: LegalDocumentsFilters,
		$registryFilters: LegalDocumentsFilters,
		){
		project(slug: $projectSlug) {
			id
			name
			slug
			code
			city
			country
			inboxCount: legalDocumentsCount(filters: $inboxFilters)
			registryCount: legalDocumentsCount(filters: $registryFilters)
		}
	}
"""
