###
opportunity delete modal
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import adopt from 'libs/adopt'

# Renderable
import { div } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import { NotificationConsumer } from '../../../application/components/NotificationManager'

import _BevyModal from '@bevy/modal'
BevyModal = React.createFactory _BevyModal

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

# Styles
import ErrorIcon from '@atlaskit/icon/glyph/error'

# Data
import {
	DeleteOrganizations
} from './data'

REFETCH_QUERIES = [
	'ProjectCompaniesList'
	]

DataLayer = adopt
	mutations:
		deleteOrganizations: ({setNotification, onConfirm}) ->
			mutation: DeleteOrganizations
			refetchQueries: REFETCH_QUERIES
			onCompleted: onConfirm

export default class OpportunityDeleteModal extends React.Component
	@propTypes =
		onClose: PropTypes.func.isRequired

	constructor: (props) ->
		super props

	render: ->
		NotificationConsumer {}, ({setNotification}) =>
			DataLayer {
				setNotification: setNotification
				onConfirm: @props.onConfirm
			}, (operations) =>
				BevyModal {
					onClose: @props.onClose
					header: 'Delete confirmation'
					headerIcon: ErrorIcon {size: 'medium', primaryColor: '#DE350B'}
					actions: [
						text: 'Delete'
						onClick: () =>
							operations.deleteOrganizations.mutation
								variables:
									ids: if @props.organizationsIDs?
											@props.organizationsIDs
										else if @props.organizationID?
											[@props.organizationID]
									projectsIDs:
										if @props.projectsIDs?
											@props.projectsIDs
										else if @props.projectID?
											[@props.projectID]
							@props.onClose()
					,
						text: 'Cancel'
						onClick: @props.onClose
					]
					note: 'Once deleted, it can be only restored by contacting your administrator.'
				},
					'Are you sure you want to delete this organization?'

