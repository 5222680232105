exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".w8-WstSF3wMPUKfa9phCF {\n  background-color: #fff;\n  display: inline-block;\n  border-radius: 4px;\n  overflow: hidden;\n  padding: 10px;\n  box-shadow: rgba(9,30,66,0.25) 0px 4px 8px -2px, rgba(9,30,66,0.31) 0px 0px 1px;\n}\n.w8-WstSF3wMPUKfa9phCF:hover {\n  cursor: pointer;\n  background-color: #eee;\n}\n._7uXiTfBacnrETYt2agPTM {\n  display: flex;\n  flex-direction: row;\n}\n._7uXiTfBacnrETYt2agPTM span,\n._7uXiTfBacnrETYt2agPTM a {\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n  color: #000;\n  text-decoration: none;\n}\n._7uXiTfBacnrETYt2agPTM ._2_FAJm6NAF6Ow1xfVVpUgg {\n  border-radius: 3px;\n  width: 120px;\n  height: 120px;\n  vertical-align: middle;\n}\n._7uXiTfBacnrETYt2agPTM ._3WnYUBZ0Pw5xiYTGqJ_rws {\n  display: block;\n}\n._7uXiTfBacnrETYt2agPTM .nSSMX-X_6XamHmPwucAND {\n  margin-left: 10px;\n  flex-grow: 1;\n}\n._7uXiTfBacnrETYt2agPTM .RzGl4IhuWsuf6mZ_5hfCb {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 12px;\n}\n._7uXiTfBacnrETYt2agPTM .nVtRdqtJSgOUy1xci0ytc {\n  font-size: 20px;\n  font-weight: 400;\n}\n._7uXiTfBacnrETYt2agPTM ._2MMvXo6PzRVrl7Rn7nNU8m {\n  font-size: 16px;\n  margin-bottom: 6px;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n}\n._7uXiTfBacnrETYt2agPTM ._2MMvXo6PzRVrl7Rn7nNU8m .wnMv0BA3B869khtKyXM4F {\n  color: #b4b5b8;\n  margin-right: 4px;\n}\n._7uXiTfBacnrETYt2agPTM ._2MMvXo6PzRVrl7Rn7nNU8m .wnMv0BA3B869khtKyXM4F:after {\n  content: ':';\n}\n", ""]);
// Exports
exports.locals = {
	"cardWrapper": "w8-WstSF3wMPUKfa9phCF",
	"card": "_7uXiTfBacnrETYt2agPTM",
	"thumbnail": "_2_FAJm6NAF6Ow1xfVVpUgg",
	"status": "_3WnYUBZ0Pw5xiYTGqJ_rws",
	"cardContent": "nSSMX-X_6XamHmPwucAND",
	"main": "RzGl4IhuWsuf6mZ_5hfCb",
	"name": "nVtRdqtJSgOUy1xci0ytc",
	"item": "_2MMvXo6PzRVrl7Rn7nNU8m",
	"label": "wnMv0BA3B869khtKyXM4F"
};