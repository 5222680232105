###
Add Project Management member Modal
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import adopt from 'libs/adopt'
import { convertToOptions } from 'libs/legal'

import { NotificationConsumer } from '../../../application/components/NotificationManager'
import { UserInfoConsumer } from 'libs/userInfo'

# Renderable
import { div, span } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import _BevyModal from '@bevy/modal'
BevyModal = React.createFactory _BevyModal

import {
	TextField as _TextField,
	LoadableSelectField as _LoadableSelectField
	Inline as _Inline
} from 'components/FormItems'
Inline = React.createFactory _Inline
TextField = React.createFactory _TextField
LoadableSelectField = React.createFactory _LoadableSelectField

import _ErrorDisplay from 'components/ErrorDisplay'
ErrorDisplay = React.createFactory _ErrorDisplay

# Styles
import styles from './index.styl'

# Data
import {
	GetMembers
	ProjectManagementAdd
	ProjectManagementEdit
} from './data'

DataLayer = adopt
	queries: {}
	mutations:
		projectManagementAdd: ({setNotification}) ->
			mutation: ProjectManagementAdd
			onCompleted: -> setNotification({content: 'Added member of project management', appearance: 'success'})
			onError: -> setNotification ({content: 'Error on adding member of project management', appearance: 'error'})
		projectManagementEdit: ({setNotification}) ->
			mutation: ProjectManagementEdit
			onCompleted: -> setNotification({content: 'Edited member of project management', appearance: 'success'})
			onError: -> setNotification ({content: 'Error on editing member of project management', appearance: 'error'})


export default class AddProjectManagementModal extends React.Component
	@propTypes =
		onClose: PropTypes.func.isRequired

	constructor: (props) ->
		super props
		@state =
			role: @props.role
			id: @props.id

	canProceed: =>
		everyRequiredFieldsFilled =
			if !_.isEmpty(@state.id) and !_.isEmpty(@state.role)
				true
			else
				false
		madeAnyChange =
			if @props.id?
				@props.role isnt @state.role
			else
				true
		everyRequiredFieldsFilled and madeAnyChange

	getVariables4Mutation: =>
		variables =
			projectID: @props.projectID
			phaseID: @props.phaseID
			type: @props.type
			id: @state.id
			role: @state.role

	onCloseProxy: (appropriateMutation) =>
		if _.isFunction appropriateMutation
			appropriateMutation
				variables: @getVariables4Mutation()
				refetchQueries: ['ProjectDashboard']
		@props.onClose()

	renderContent: =>
		Fragment {},
			LoadableSelectField
				label: 'User'
				isDisabled: @props.id?
				query:
					query: GetMembers
					variables:
						id: @props.projectID
						pagination:
							limit: 20
				makeOptionsFromData: (data) =>
					convertToOptions _.map (_.get data, 'projectByID.members'), 'user'
				values: [@state.id]
				onChange: (item) => @setState id: item.value
				hasMore: @state.hasMore
				handleDataUpdate: (prev, fetchMoreResult) =>
					if _.isEmpty fetchMoreResult.projectByID.members
						@setState hasMore: false
					projectByID: {
						...prev.projectByID
						members: [
							...prev.projectByID.members,
							...fetchMoreResult.projectByID.members
						]
					}
			TextField {
				label: 'Role'
				onChange: (value) => @setState role: value
				value: @state.role
			}

	render: ->
		console.log @state
		UserInfoConsumer {}, ({ability, me}) =>
			NotificationConsumer {}, ({setNotification}) =>
				DataLayer
					setNotification: setNotification
					, (operations) =>
						BevyModal
							onClose: @onCloseProxy
							header: if !@props.id? then 'Add Project Management member' else 'Edit Project Management member'
							width: 'small'
							actions: [
								text: 'Save'
								isDisabled: !@canProceed()
								onClick: () => @onCloseProxy(
									if !@props.id?
										operations.projectManagementAdd.mutation
									else
										operations.projectManagementEdit.mutation
								)
							,
								text: 'Cancel'
								onClick: @onCloseProxy
							]
						,
							if @props.editQuery?
								ErrorDisplay {query: @props.editQuery}, () =>
									@renderContent()
							else
								@renderContent()


