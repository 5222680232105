###
Project legal page to display list of documents drafts
###

# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import DataAttribute from '@bevy/data-attribute'
import {matchPath} from 'react-router-dom'
import adopt from 'libs/adopt'
import * as legalHelpers from 'libs/legal'
import moment from 'moment-mini'

import qs from 'qs'
qsSettings =
	strictNullHandling: true
	encodeValuesOnly: true
	parseArrays: false
	# arrayFormat: 'comma' #when active arrays are badly parsed from url
	encode: false

# Renderable
import { div, p, ul, li, small, h1, span, h2, button, input, img, a } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import _ContentController from '@bevy/content-controller'
ContentController = React.createFactory _ContentController

import _Button from '@bevy/button'
Button = React.createFactory _Button

import _FiltersBar from './parts/FiltersBar'
FiltersBar = React.createFactory _FiltersBar

import _UploadWrapper from 'components/UploadWrapper'
UploadWrapper = React.createFactory _UploadWrapper

import { NotificationConsumer } from '../../application/components/NotificationManager'
import { UserInfoConsumer } from 'libs/userInfo'
import { RefetchConsumer } from 'libs/refetch'
import { SideNavFiltersConsumer } from 'libs/filtersDMS'

import { LEGAL_COLUMNS } from 'parts/Legal/DocumentsTableColumns/columns'

# Data
import DataLayer from './elements/dataLayer'
import documentStatuses from 'data/local/documentStatuses'
import { registryColumns } from 'parts/Legal/DocumentsList/columns'
import translations from 'data/local/translations'
import trLegal from 'data/local/legal'
import {INBOX_PATH, REGISTRY_PATH} from 'data/paths'

# Styles
import styles from './index.styl'


# Content controller elements
import getTable from './elements/table'
import getModals from './elements/modals'
import getHeader from './elements/header'


export default class ProjectDocumentsView extends React.Component
	constructor: (props) ->
		super props
		@fileInput = React.createRef()
		@orginalDocuments = []
		@documentsWithLinks = []
		@state =

			activeModal: null
			documentsDownloading: []
			documentsTableColumns: @getInitialColumnsConfiguration()
			activeSearch: null
			sortKey: 'CreatedAt'
			sortOrder: 'desc'
			selectedDocuments: []
			activeDocuments: []
			dropOverlay: false
			isFetchingMore: false
			fetchedAll: false

	getTable: getTable
	getModals: getModals
	getHeader: getHeader

	componentDidMount: (props) ->
		# @addRefetch 'DMS_Registry_Table': @refetchListDocuments

	componentWillUnmount: (props) ->
		# @setActiveFolder {}

	getInitialColumnsConfiguration: () ->
		savedConfig = JSON.parse localStorage.getItem('tableView-projects-legal-documents-legal')
		if savedConfig?
			_.map savedConfig, (column) ->
				columnTemplate = _.find LEGAL_COLUMNS, {key: column.key}
				{
					...columnTemplate
					width: column.width
					selected: column.selected
				}
		else LEGAL_COLUMNS

	changeTableConfiguration: (newTableConfig) =>
		if !newTableConfig? #restore default
			localStorage.removeItem 'tableView-projects-legal-documents-legal'
			@setState documentsTableColumns: LEGAL_COLUMNS
		else
			localStorage.setItem('tableView-projects-legal-documents-legal', JSON.stringify newTableConfig)
			@setState documentsTableColumns: newTableConfig

	searchUpdate: ({data, selectedDocuments}) ->
		(value) =>
			@setState activeSearch: value

	handleQRPrint: (format, legalDocument) =>
		@setState "isLoadingQR#{_.capitalize format}": true
		legalHelpers.handleQRPrint {
			format
			legalDocument
			onQRPrintEnd: => @setState "isLoadingQR#{_.capitalize format}": false
		}

	handleDocumentsSelect: (ids) => @setState selectedDocuments: _.concat [], ids

	handleSetFilters: (filters) =>
		parsedQuery = qs.parse(window.location.search.replace(/^\?/, ''), qsSettings)
		if (!parsedQuery.filters?)
			newSearchQuery = {
				...parsedQuery
				filters: filters
			}
		else if !_.includes parsedQuery.filters, filters
			if _.isEmpty filters then parsedQuery.filters = {}
			newSearchQuery = {
				..._.omit parsedQuery, 'filters'
				filters: {...parsedQuery.filters, ...filters}
			}
		if newSearchQuery?
			@props.history.push search: "?#{qs.stringify(newSearchQuery, qsSettings)}"

	getFiltersFromQueryString: =>
		filters = {
			createdBefore: moment(@props.match.params.sendTime).endOf('day').unix()
			createdAfter: moment(@props.match.params.sendTime).startOf('day').unix()
		}

	handleSortChange: (key, order) =>
		@setState
			sortKey: key
			sortOrder: order
			fetchedAll: false
	render: ->
		selectedDocuments = @state.selectedDocuments
		UserInfoConsumer {}, ({ability, me}) =>
			DataLayer {
				columnQueries: _.map @state.documentsTableColumns, 'query'
				filteredDocumentsFilters: @getFiltersFromQueryString()
				searchQuery: @state.activeSearch
				sortKey: @state.sortKey
				sortOrder: @state.sortOrder
			}, (operations) =>
				ContentController
					header: @getHeader()
					pageId: "#{if projectName? then projectName else 'project'} legal registry"
					className: cnames styles.page
					contentClassName: styles.content
					paneClassName: styles.pane
					layouts:
						regular:
							view:
								width: "#{(700 / 12)}%"
							accessory:
								width: "#{(500 / 12)}%"
					dms: true
					views:
						table:
							@getTable {
								data: operations
								columnsSetup: @state.documentsTableColumns
								changeTableConfiguration: @changeTableConfiguration
								selectedDocuments
								onSelect: @handleDocumentsSelect
								onSortChange: @handleSortChange
								sortKey: @state.sortKey
								sortOrder: @state.sortOrder
							}
