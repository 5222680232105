exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._22BE9hIoa20eXsEZ6aeF5w {\n  display: flex;\n}\n._3wT7Kxm8-AH6UpBbkaILmT {\n  min-height: 200px;\n  flex-grow: 1;\n  flex-basis: 0;\n  padding-right: 20px;\n  border-right: solid 1px #f4f5f7;\n}\n._3wT7Kxm8-AH6UpBbkaILmT:last-of-type {\n  border: 0;\n  padding-left: 20px;\n  padding-right: 0;\n}\n._3wT7Kxm8-AH6UpBbkaILmT:first-of-type {\n  padding-left: 0;\n}\n.MVUrlDzaftsy73SDfEAl- {\n  margin-bottom: 20px;\n}\n._2NvoUjY6UJzmFhujw2mpFj {\n  flex-grow: 1;\n}\n._2NvoUjY6UJzmFhujw2mpFj div {\n  min-height: auto;\n  padding-top: 0;\n  padding-bottom: 0;\n}\n._2ZDtDnx-1bd7N2h8J_jGB0 div {\n  min-height: 28px;\n  padding-top: 0;\n  padding-bottom: 0;\n}\n._2ZDtDnx-1bd7N2h8J_jGB0 input {\n  font-size: 1rem;\n}\n._19vCvlrmHrP0sw3FDNskxX {\n  cursor: pointer;\n  display: inline-block;\n  position: absolute;\n  transform: translate(4px, -1px);\n}\n._19vCvlrmHrP0sw3FDNskxX._3ukybGMYY_4YR-9R6YtUTW {\n  transform: translate(10px, -1px);\n}\n._1lnDDZUTMkexhwcortsOJm {\n  flex-grow: 1;\n}\n._2VHjDcRYiNFLfpcoxDZnvz {\n  display: flex;\n}\n._2VHjDcRYiNFLfpcoxDZnvz > div {\n  margin-right: 5px;\n}\n._2VHjDcRYiNFLfpcoxDZnvz > div:last-of-type {\n  margin-right: 0;\n}\n._35owS17DgKo0uf6oLh5VUe {\n  font-size: 1.18rem;\n  color: #6b778c;\n  padding: 4px 0;\n  border-bottom: 1px solid #f4f5f7;\n}\n", ""]);
// Exports
exports.locals = {
	"base": "_22BE9hIoa20eXsEZ6aeF5w",
	"column": "_3wT7Kxm8-AH6UpBbkaILmT",
	"section": "MVUrlDzaftsy73SDfEAl-",
	"formItem": "_2NvoUjY6UJzmFhujw2mpFj",
	"formItemText": "_2ZDtDnx-1bd7N2h8J_jGB0",
	"iconTooltip": "_19vCvlrmHrP0sw3FDNskxX",
	"moreMargin": "_3ukybGMYY_4YR-9R6YtUTW",
	"fieldGrow": "_1lnDDZUTMkexhwcortsOJm",
	"horizontal": "_2VHjDcRYiNFLfpcoxDZnvz",
	"sectionHeader": "_35owS17DgKo0uf6oLh5VUe"
};