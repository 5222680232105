"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.scopes = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _helpers = require("../helpers");

var _GlobalHub = require("./GlobalHub");

var _Project = require("./Project");

var _ProjectUserGroup = require("./ProjectUserGroup");

var _Organization = require("./Organization");

var _LegalDocument = require("./LegalDocument");

var _SafetyVisit = require("./SafetyVisit");

var _SafetyObservation = require("./SafetyObservation");

var _Platform = require("./Platform");

var _UserGroup = require("./UserGroup");

var _SafetyVisitMember = require("./SafetyVisitMember");

var _LegalPath = require("./LegalPath");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

// Generated by CoffeeScript 2.4.1
var SafetyBranchAdmin, UserScopes;

/*
User permissions model
*/
var scopes = (0, _helpers.makeDefaultSubject)('User', {
  Manage: {
    actions: 'crud'
  },
  Create: {
    actions: 'create'
  },
  Update: {
    actions: 'update'
  },
  UpdateInfo: {
    actions: 'update',
    fields: 'info'
  },
  Read: {
    actions: 'read'
  },
  Delete: {
    actions: 'delete'
  },
  LogAs: {
    actions: 'logAs'
  }
});
exports.scopes = scopes;
UserScopes = scopes;

SafetyBranchAdmin = async function ({
  user,
  parent,
  membership,
  resolved,
  db,
  cache,
  branch
}) {
  var collection, projectIDs, projects, projectsCondition;
  collection = await db.collection('projects');
  projects = await collection.find({
    branch
  }, {
    projection: {
      _id: 1
    }
  }).toArray();
  projectIDs = _lodash.default.map(projects, '_id');
  projectsCondition = resolved ? {
    'project.id': {
      $in: _lodash.default.map(projectIDs, function (id) {
        return (0, _helpers.makeGlobalID)('project', id);
      })
    }
  } : {
    projectID: {
      $in: projectIDs
    }
  };
  return [{
    scopes: [_Project.scopes.ReadSafety, _Project.scopes.ReadPersonnel],
    conditions: {
      branch: branch
    }
  }, {
    scopes: [UserScopes.Manage, UserScopes.Read, _GlobalHub.scopes.ReadPersonnel]
  }, {
    scopes: [_SafetyVisit.scopes.Self.Destroy, _SafetyVisit.scopes.Self.Restore],
    conditions: _objectSpread({}, projectsCondition, {
      isDeleted: true
    })
  }, {
    scopes: [_SafetyObservation.scopes.Self.Destroy, _SafetyObservation.scopes.Self.Restore],
    conditions: _objectSpread({}, projectsCondition, {
      isDeleted: true
    })
  }, {
    scopes: [_SafetyVisit.scopes.Self.Read, _SafetyVisit.scopes.Self.Sync, _SafetyObservation.scopes.Self.Sync],
    conditions: _objectSpread({}, projectsCondition)
  }, {
    scopes: [_SafetyVisitMember.scopes.Self.Accept, _SafetyVisitMember.scopes.Self.Reject],
    conditions: {
      role: 'Pending Member'
    }
  }, {
    // TODO!: Missing check on projectID
    // ...projectsCondition
    scopes: [_SafetyVisitMember.scopes.Self.Remove],
    conditions: {
      role: 'Member'
    }
  }, {
    // TODO!: Missing check on projectID
    // ...projectsCondition
    scopes: [_SafetyVisit.scopes.Members.Invite],
    conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {
      status: {
        $in: ['InLobby']
      }
    }, projectsCondition)
  }, {
    scopes: [_SafetyVisit.scopes.Attachments.Manage],
    conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {
      status: {
        $nin: ['Resolved']
      }
    }, projectsCondition)
  }, {
    // ,
    // 	scopes: [
    // 		SafetyVisitScopes.Review.Start
    // 	]
    // 	conditions: {
    // 		...isSelfDeletedCondition
    // 		status: $in: ['PendingReview']
    // 		...projectsCondition
    // 	}
    // ,
    // 	scopes: [
    // 		SafetyVisitScopes.Review.Cancel
    // 	]
    // 	conditions: {
    // 		...isSelfDeletedCondition
    // 		status: $in: ['InReview']
    // 		...projectsCondition
    // 	}
    // ,
    // 	scopes: [
    // 		SafetyVisitScopes.Review.Finish
    // 	]
    // 	conditions: {
    // 		...isSelfDeletedCondition
    // 		status: $in: ['InReview']
    // 		...projectsCondition
    // 	}
    scopes: [_SafetyVisit.scopes.Type.Update],
    conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {
      status: {
        $in: ['InLobby', 'InProgress', 'PendingReview', 'InReview']
      }
    }, projectsCondition)
  }, {
    scopes: [_SafetyVisit.scopes.Progress.Start],
    conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {
      status: {
        $in: ['InLobby']
      },
      type: {
        $exists: true
      }
    }, projectsCondition)
  }, {
    scopes: [_SafetyVisit.scopes.Progress.End],
    conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {
      status: {
        $in: ['InProgress']
      }
    }, projectsCondition)
  }, {
    scopes: [_SafetyVisit.scopes.Self.Delete, _SafetyVisit.scopes.Members.Add],
    conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {}, projectsCondition)
  }, {
    scopes: [_SafetyVisit.scopes.Comments.Create],
    conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {
      status: {
        $in: ['ReviewCompleted', 'Resolved']
      }
    }, projectsCondition)
  }, {
    // Observations
    scopes: _lodash.default.compact([branch !== 'CDE' ? _SafetyObservation.scopes.Self.Create : void 0, _SafetyObservation.scopes.Self.Read]),
    conditions: _objectSpread({}, projectsCondition)
  }, {
    scopes: [_SafetyObservation.scopes.Self.Delete, _SafetyObservation.scopes.Details.Update],
    conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {
      status: {
        $in: ['New', 'PendingCheck', 'Checked']
      }
    }, projectsCondition)
  }, {
    scopes: [_SafetyObservation.scopes.Ratings.Update, _SafetyObservation.scopes.Attachments.Manage],
    conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {}, projectsCondition)
  }, {
    scopes: [_SafetyObservation.scopes.Review.Check],
    conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {
      // rating: $ne: 'Positive'
      status: 'PendingCheck'
    }, projectsCondition)
  }, {
    scopes: [_SafetyObservation.scopes.Review.Uncheck],
    conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {
      rating: {
        $ne: 'Positive'
      },
      status: 'Checked'
    }, projectsCondition)
  }, {
    scopes: [_SafetyObservation.scopes.Self.Clone],
    conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {}, projectsCondition)
  }];
};

var _default = {
  // Name of the GraphQL subject
  name: 'User',
  // Database collection
  collection: 'users',
  // Name used in making global id's
  // TODO: Service vs User for global id
  globalIDName: 'user',
  // Available actions on root leve
  actions: ['crud', 'create', 'read', 'update', 'delete'],
  // Alias for create, read, update, delete
  // Model fields with appropiate actions
  fields: {},
  // Searchable properties, in GraphQL as a full text search
  // NOTE: This information should be available / calculated based on <fields> property
  searchable: ['givenName', 'surname', 'mail'],
  // Available scopes with implicit subject as a model.name
  scopes,
  // Additional fields requested when fetching items for attached and members roles
  projection: {},
  // Type of models that can become a member of this model without specified role
  accepts: [],
  // Entity based roles default and assignable
  roles: {
    // Roles granted to all users
    default: [{
      name: 'Self',
      description: 'Self-awareness role of each user',
      permissions: function ({
        user,
        parent,
        membership,
        resolved,
        db,
        cache
      }) {
        return [{
          scopes: [UserScopes.Read],
          // UserScopes.UpdateInfo
          conditions: resolved ? {
            'id': (0, _helpers.makeGlobalID)('user', user._id)
          } : {
            '_id': user._id
          }
        }, {
          scopes: [_GlobalHub.scopes.ReadPersonnel, UserScopes.Read]
        }];
      }
    }],
    // Roles assignable to the entity that will be transfered to the User
    attached: [{
      name: 'Platform admin',
      description: 'Administrator of platform',
      permissions: function ({
        user,
        parent,
        membership,
        resolved,
        db,
        cache
      }) {
        return [{
          scopes: [_GlobalHub.scopes.ReadAll, UserScopes.Read, UserScopes.LogAs, _Project.scopes.Update, _Project.scopes.Create, _Organization.scopes.Delete, _UserGroup.scopes.Manage, UserScopes.Manage, _ProjectUserGroup.scopes.Manage, _LegalPath.scopes.Read, _LegalPath.scopes.Use, _LegalPath.scopes.Update]
        }];
      }
    }, {
      name: 'Platform superadmin',
      description: 'God-like administrator of platform',
      permissions: function ({
        user,
        parent,
        membership,
        resolved,
        db,
        cache
      }) {
        return [{
          scopes: [_GlobalHub.scopes.ReadAll, UserScopes.Manage, UserScopes.LogAs, _Project.scopes.Update, _Project.scopes.Create, _LegalDocument.scopes.UpdateCommited, _Organization.scopes.Delete, _UserGroup.scopes.Manage, UserScopes.Manage, _ProjectUserGroup.scopes.Manage, _Project.scopes.ReadAllHubs, _SafetyVisit.scopes.FullManage, _SafetyObservation.scopes.FullManage, _SafetyVisit.scopes.Self.Sync, _SafetyObservation.scopes.Self.Sync, _LegalPath.scopes.Read, _LegalPath.scopes.Use, _LegalPath.scopes.Update, _LegalDocument.scopes.Manage, _Organization.scopes.Manage, UserScopes.Read]
        }];
      }
    }, {
      name: 'Personnel Manager',
      description: 'Manages users in platform',
      permissions: function ({
        user,
        parent,
        membership,
        resolved,
        db,
        cache
      }) {
        return [{
          scopes: [_GlobalHub.scopes.ReadAll, UserScopes.Manage]
        }];
      }
    }, {
      // UserScopes.LogAs
      name: 'Safety Manager',
      description: '⛑Manager of all safety properties in all projects',
      permissions: function ({
        user,
        parent,
        membership,
        resolved,
        db,
        cache
      }) {
        return [{
          scopes: [_Project.scopes.ReadSafety]
        }, {
          scopes: [_SafetyVisit.scopes.Self.Read]
        }, {
          scopes: [_SafetyVisit.scopes.Self.Delete, _SafetyVisit.scopes.Members.Add, _SafetyVisit.scopes.Self.Sync, _SafetyObservation.scopes.Self.Sync],
          conditions: _objectSpread({}, _helpers.isSelfDeletedCondition)
        }, {
          scopes: [_SafetyVisitMember.scopes.Self.Accept, _SafetyVisitMember.scopes.Self.Reject],
          conditions: {
            role: 'Pending Member'
          }
        }, {
          scopes: [_SafetyVisitMember.scopes.Self.Remove],
          conditions: {
            role: 'Member'
          }
        }, {
          scopes: [_SafetyVisit.scopes.Members.Invite],
          conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {
            status: {
              $in: ['InLobby']
            }
          })
        }, {
          scopes: [_SafetyVisit.scopes.Attachments.Manage],
          conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {
            status: {
              $nin: ['Resolved']
            }
          })
        }, {
          // ,
          // 	scopes: [
          // 		SafetyVisitScopes.Review.Start
          // 	]
          // 	conditions: {
          // 		...isSelfDeletedCondition
          // 		status: $in: ['PendingReview']
          // 	}
          // ,
          // 	scopes: [
          // 		SafetyVisitScopes.Review.Cancel
          // 	]
          // 	conditions: {
          // 		...isSelfDeletedCondition
          // 		status: $in: ['InReview']
          // 	}
          // ,
          // 	scopes: [
          // 		SafetyVisitScopes.Review.Finish
          // 	]
          // 	conditions: {
          // 		...isSelfDeletedCondition
          // 		status: $in: ['InReview']
          // 	}
          scopes: [_SafetyVisit.scopes.Type.Update],
          conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {
            status: {
              $in: ['InLobby', 'InProgress', 'PendingReview', 'InReview']
            }
          })
        }, {
          scopes: [_SafetyVisit.scopes.Progress.Start],
          conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {
            status: {
              $in: ['InLobby']
            },
            type: {
              $exists: true
            }
          })
        }, {
          scopes: [_SafetyVisit.scopes.Progress.End],
          conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {
            status: {
              $in: ['InProgress']
            }
          })
        }, {
          scopes: [_SafetyVisit.scopes.Comments.Create],
          conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {
            status: {
              $in: ['ReviewCompleted', 'Resolved']
            }
          })
        }, {
          // Observations
          scopes: [_SafetyObservation.scopes.Self.Create, _SafetyObservation.scopes.Self.Read]
        }, {
          scopes: [_SafetyObservation.scopes.Self.Delete, _SafetyObservation.scopes.Details.Update],
          conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {
            status: {
              $in: ['New', 'PendingCheck', 'Checked']
            }
          })
        }, {
          scopes: [_SafetyObservation.scopes.Ratings.Update, _SafetyObservation.scopes.Attachments.Manage],
          conditions: _objectSpread({}, _helpers.isSelfDeletedCondition)
        }, {
          scopes: [_SafetyObservation.scopes.Review.Check],
          conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {
            // rating: $ne: 'Positive'
            status: 'PendingCheck'
          })
        }, {
          scopes: [_SafetyObservation.scopes.Review.Uncheck],
          conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {
            rating: {
              $ne: 'Positive'
            },
            status: 'Checked'
          })
        }];
      }
    }, {
      name: 'Safety Manager RD',
      description: '⛑Manager of all safety properties in all RD based projects',
      permissions: async function (args) {
        var cache, db, membership, parent, resolved, user;
        ({
          user,
          parent,
          membership,
          resolved,
          db,
          cache
        } = args);
        return await SafetyBranchAdmin(_objectSpread({}, args, {
          branch: 'RD'
        }));
      }
    }, {
      name: 'Safety Manager CDE',
      description: '⛑Manager of all safety properties in all CDE based projects',
      permissions: async function (args) {
        var cache, db, membership, parent, resolved, user;
        ({
          user,
          parent,
          membership,
          resolved,
          db,
          cache
        } = args);
        return await SafetyBranchAdmin(_objectSpread({}, args, {
          branch: 'CDE'
        }));
      }
    }, {
      name: 'Staging environment tester',
      description: 'Role with access to platform in <Staging> environment',
      permissions: function ({
        user,
        parent,
        membership,
        resolved,
        db,
        cache
      }) {
        return [{
          scopes: [_Platform.scopes.Staging]
        }];
      }
    }, {
      name: 'Test environment tester',
      description: 'Role with access to platform in <Test> environment',
      permissions: function ({
        user,
        parent,
        membership,
        resolved,
        db,
        cache
      }) {
        return [{
          scopes: [_Platform.scopes.Test]
        }];
      }
    }, {
      name: 'Development environment tester',
      description: 'Role with access to platform in <Development> environment',
      permissions: function ({
        user,
        parent,
        membership,
        resolved,
        db,
        cache
      }) {
        return [{
          scopes: [_Platform.scopes.Development]
        }];
      }
    }, {
      name: 'Lawyers digest receiver',
      description: 'Role for non-legal users that gives them access to lawyers email digest',
      permissions: function ({
        user,
        parent,
        membership,
        resolved,
        db,
        cache
      }) {
        return [];
      }
    }],
    // Roles assignable to the members (Users) of this entity
    members: []
  }
};
exports.default = _default;