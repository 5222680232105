import gql from 'graphql-tag'

export RootQuery = gql """
query GroupsList {
	userGroups {
		id
		name
		slug
		roles {
      name
    }
		picture { mini }
		members {
			... on ActorAccess{
        user {
        	... on Person{
            id
          }
        }
      }
		}
	}
}
"""

