import gql from 'graphql-tag'


export DeleteOrganizations = gql """
	mutation($ids: [ID!]!, $projectsIDs: [ID!]!) {
		organizationsDelete(
			ids: $ids,
			projectsIDs: $projectsIDs
		) {
			id
		}
	}
"""
