###
Add Project Group Modal
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import adopt from 'libs/adopt'
import { convertToOptions } from 'libs/legal'
import { NotificationConsumer } from '../../../application/components/NotificationManager'
import { UserInfoConsumer } from 'libs/userInfo'

# Renderable
Fragment = React.createFactory React.Fragment

import _BevyModal from '@bevy/modal'
BevyModal = React.createFactory _BevyModal

import {
	TextField as _TextField,
	TextAreaField as _TextAreaField,
	LoadableSelectField as _LoadableSelectField
	SelectField as _SelectField
} from 'components/FormItems'
TextField = React.createFactory _TextField
TextAreaField = React.createFactory _TextAreaField
LoadableSelectField = React.createFactory _LoadableSelectField
SelectField = React.createFactory _SelectField

# Data
import {
	GetProjectInfo
	GetRoles
	GetPhases
	AddProjectUserGroup
	EditProjectUserGroup
} from './data'

REFETCH_QUERIES = [
	'projectUserGroupsList'
	]

DataLayer = adopt
	queries:
		getProjectInfo: ({projectSlug}) ->
			query: GetProjectInfo
			variables:
				slug: projectSlug
	mutations:
		addGroup: ({history, setNotification, onUpdateComplete, projectSlug, onNewGroupCreate}) ->
			mutation: AddProjectUserGroup
			onCompleted: (data) ->
				setNotification({content: 'Project Group added', appearance: 'success'})
				history.push "/projects/#{projectSlug}/groups/#{data.projectUserGroupCreate.id}"
				onNewGroupCreate data.projectUserGroupCreate.id
			onError: (err) ->
				console.log err
				setNotification ({content: 'Error on creating Project Group', appearance: 'error'})
		updateGroup: ({setNotification, onUpdateComplete}) ->
			mutation: EditProjectUserGroup
			onCompleted: (data) ->
				setNotification({content: 'Project Group edited', appearance: 'success'})
			onError: -> setNotification ({content: 'Error on editing Project Group', appearance: 'error'})

export default class AddProjectGroupModal extends React.Component
	@propTypes =
		onClose: PropTypes.func.isRequired

	constructor: (props) ->
		super props
		@state = {
			...@props.initialSetup
			projectID: if @props.projectID? then @props.projectID
			projectGroupID: if @props.projectGroupID? then @props.projectGroupID
			}

	canProceed: (isProjectMultiphase) =>
		mustHaveFields = _.compact [ 'name', if isProjectMultiphase then 'phaseIDs']
		everyRequiredFieldsFilled = _.every mustHaveFields, (field) => !_.isEmpty @state["#{field}"]
		madeAnyChange = if @props.initialSetup?
				_.some @props.initialSetup, (value, key) => value isnt @state["#{key}"]
			else
				true
		everyRequiredFieldsFilled and madeAnyChange

	combineRolesAndPhaseIDs: (roles, phaseIDs, isProjectMultiphase) ->
		_.reduce roles, (acc, role) =>
			if isProjectMultiphase
					_.map phaseIDs, (phaseID) =>
						acc.push
							role: role
							info: phaseID: phaseID
			else
				acc.push
					role: role
			acc
		, []

	getVariables4Mutation: (operations)=>
		isProjectMultiphase = _.get operations, 'getProjectInfo.data.project.isMultiphase'
		variables = _.omit @state, ['roles', 'phaseIDs']
		if !@props.editQuery? #add
			variables.addedRoles = @combineRolesAndPhaseIDs @state.roles, @state.phaseIDs, isProjectMultiphase
		else #edit
			oldRolesObjects = _.get @props.editQuery, 'data.projectUserGroup.roles'
			oldRoles = _.map oldRolesObjects, 'name'
			oldPhaseIDs = _.flatten _.compact _.uniqWith (_.map oldRolesObjects, 'info.phaseID'), _.isEqual
			addedRoles = _.difference(@state.roles, oldRoles)
			removedRoles = _.difference(oldRoles, @state.roles)
			addedPhases = _.difference(@state.phaseIDs, oldPhaseIDs)
			removedPhases = _.difference(oldPhaseIDs, @state.phaseIDs)
			#TODO: rewrite to support indepentent selecting phases for roles
			if _.isEmpty(addedRoles) and !_.isEmpty(addedPhaseIDs) # add phases to all roles
				addedRoles = oldRoles
			else if !_.isEmpty(addedRoles) and _.isEmpty(addedPhaseIDs) # add role to all phases
				addedPhaseIDs = oldPhaseIDs
			else if _.isEmpty(removedRoles) and !_.isEmpty(removedPhaseIDs) # remove phases from all roles
				removedRoles = oldRoles
			else if !_.isEmpty(removedRoles) and _.isEmpty(removedPhaseIDs) # remove role from all phases
				removedPhaseIDs = oldPhaseIDs
			variables.addedRoles = @combineRolesAndPhaseIDs addedRoles, addedPhaseIDs, isProjectMultiphase
			variables.removedRoles = @combineRolesAndPhaseIDs removedRoles, removedPhaseIDs, isProjectMultiphase
		if @props.projectGroupID
			variables.id = @props.projectGroupID
		if @props.projectID
			variables.projectID = @props.projectID
		variables

	onCloseProxy: (appropriateMutation, operations) =>
		if _.isFunction appropriateMutation
			appropriateMutation
				variables: @getVariables4Mutation(operations)
				refetchQueries: REFETCH_QUERIES
		@props.onClose()

	render: ->
		UserInfoConsumer {}, ({ability, me}) =>
			NotificationConsumer {}, ({setNotification}) =>
				DataLayer
					history: @props.history
					setNotification: setNotification
					projectSlug: @props.match.params.project
					onNewGroupCreate: @props.onNewGroupCreate
					, (operations) =>
						isProjectMultiphase = _.get operations, 'getProjectInfo.data.project.isMultiphase'
						BevyModal
							onClose: @onCloseProxy
							header: if _.isEmpty @props.initialSetup then 'Add group' else 'Edit group'
							actions: [
								text: 'Save'
								isDisabled: !@canProceed isProjectMultiphase
								onClick: () =>
									mutation =
										if _.isEmpty @props.initialSetup
											operations.addGroup.mutation
										else
											operations.updateGroup.mutation
									@onCloseProxy mutation, operations
							,
								text: 'Cancel'
								onClick: @onCloseProxy
							]
						,
							Fragment {},
								TextField
									label: 'Name'
									isRequired: true
									onChange: (value) =>
										@setState name: value
									value: @state.name

								if isProjectMultiphase
									LoadableSelectField
										label: 'Project phases'
										values: @state.phaseIDs
										isMulti: true
										hideSelectedOptions: false
										onChange: (values) =>
											@setState phaseIDs:  _.map values, 'value'
										query:
											query: GetPhases
											variables:
												projectID: @props.projectID
										makeOptionsFromData: (data) =>
											phases = _.get(data, 'projectByID.phases')
											if phases? then convertToOptions phases
										hasMore: @state.hasMorePhases
										handleDataUpdate: (prev, fetchMoreResult) =>
											if _.isEmpty fetchMoreResult then @setState hasMorePhases: false
											{
												...prev
												...fetchMoreResult
											}

								LoadableSelectField
									label: 'Project roles'
									values:	@state.roles
									isMulti: true
									hideSelectedOptions: false
									onChange: (values) =>
										@setState roles:  _.map values, 'value'
									query:
										query: GetRoles
										variables:
											pagination:
												limit: 20
									makeOptionsFromData: (data) =>
										if data?.permissionsModel? then convertToOptions (_.map data.permissionsModel.entityRoles, 'name') else []
									hasMore: @state.hasMoreRoles
									handleDataUpdate: (prev, fetchMoreResult) =>
										if _.isEmpty fetchMoreResult then @setState hasMoreRoles: false
										{
											...prev
											...fetchMoreResult
										}

								TextAreaField
									label: 'Description'
									onChange: (value) => @setState description: value
									value: @state.description
									minimumRows: 1
