###
Common way of representing floor information on the left of the building
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
# Renderable
import { div, p } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import _Button from '@bevy/button'
Button = React.createFactory _Button

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

# Styles
import styles from './index.styl'

import {
	close
} from '@bevy/theme/icons'


export default class HeaderBar extends React.Component
	@propTypes: {}
	@defaultProps: {}
	render: ->
		div {className: styles.modeContainer},
			Button {
				...@props.exitButton
				className: cnames @props.exitButton.className, styles.button
				iconAfter: Icon close
			}
			div {className: styles.title}, @props.title
