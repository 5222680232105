exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._3g_KcKV7MYTEUYu6FH6GkM {\n  display: grid;\n  grid-template-columns: 1fr 2fr;\n  grid-template-rows: auto 1fr;\n  grid-template-areas: \"header header\" \"content details\";\n  height: 100%;\n  grid-gap: 10px 10px;\n}\n._2UmZZ731NlHPkoqHJ0agBF {\n  grid-area: header;\n}\n._2kkAlnUbeUiI1mnyExuH7H {\n  grid-area: content;\n  align-items: flex-start;\n  overflow: hidden;\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  align-items: stretch;\n}\n._2kkAlnUbeUiI1mnyExuH7H ._1twvnbunRjkHXBGy8VpQfU {\n  position: relative;\n  overflow-y: auto;\n  flex: 1;\n}\n._2kkAlnUbeUiI1mnyExuH7H ._1twvnbunRjkHXBGy8VpQfU ._1-SoGhvFxApESFy7D0V1t7 {\n  padding-right: 10px;\n}\n._1r5MLxzOpqK7dfmg9pHhu {\n  grid-area: details;\n  overflow: hidden;\n  display: flex;\n  flex-direction: column;\n  position: relative;\n}\n._2k2HYIe1BoYEXOgDIpL7Nz {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n}\n._2k2HYIe1BoYEXOgDIpL7Nz > div {\n  text-align: center;\n  max-width: 66%;\n}\n._2k2HYIe1BoYEXOgDIpL7Nz img {\n  max-width: 66%;\n  margin: 0 auto 20px;\n  display: block;\n}\n._3cZ7uNTdoS_R0ESOqhvJMR {\n  border-top: none;\n}\n", ""]);
// Exports
exports.locals = {
	"page": "_3g_KcKV7MYTEUYu6FH6GkM",
	"header": "_2UmZZ731NlHPkoqHJ0agBF",
	"content": "_2kkAlnUbeUiI1mnyExuH7H",
	"listView": "_1twvnbunRjkHXBGy8VpQfU",
	"bevy-content-list-scroll-container": "_1-SoGhvFxApESFy7D0V1t7",
	"details": "_1r5MLxzOpqK7dfmg9pHhu",
	"emptyState": "_2k2HYIe1BoYEXOgDIpL7Nz",
	"toolbox": "_3cZ7uNTdoS_R0ESOqhvJMR"
};