
###
Global notification manager
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'

# Renderable
import { div, button } from 'react-dom-factories'

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

Fragment = React.createFactory React.Fragment

import _Button from '@bevy/button'
Button = React.createFactory _Button

import _Spinner from '@bevy/spinner'
Spinner = React.createFactory _Spinner

# Styles
import styles from './index.styl'
import {
	checkCircle
	alertCircle
	alertTriangle
	xCircle
	} from 'react-icons-kit/feather'


NotificationPopup = ({key, content, footer, appearance = 'normal', onDismiss}) ->
	if content?
		if appearance is 'custom'
				if typeof content is 'string' then content else content(onDismiss)
		else
			div {key: key, className: cnames styles.toast, styles[appearance]},
				div {className: styles.header},
					if iconMap(appearance)?
						Icon {className: styles.icon, icon: iconMap(appearance), size: 20}
					div {className: styles.text},
						if typeof content is 'string' then content else content(onDismiss)
					Button {className: styles.dismissButton, spacing: 'compact', onClick: onDismiss}, 'Dismiss'
				if footer?
					div {className: styles.footer}, footer

iconMap = (appearance) ->
	switch appearance
		when 'normal' then alertCircle
		when 'success' then checkCircle
		when 'warning' then alertTriangle
		when 'error' then xCircle


NotificationsContext = React.createContext()
export NotificationManagerProvider = React.createFactory NotificationsContext.Provider
export NotificationConsumer = React.createFactory NotificationsContext.Consumer


export default class NotificationManager extends React.Component
	# @propTypes:
	constructor: (props) ->
		super props
		@state =
			notifications: []

	setNotification: ({content, footer, appearance, autoDismiss = true, time = 15000 }) =>
		key = _.uniqueId('notification_')
		updatedNotifications = [...@state.notifications, {key, content, footer, appearance}]
		@setState {notifications: updatedNotifications}, () =>
			if autoDismiss
				setTimeout =>
					@dismissNotification(key)
				, time
		key

	updateNotification: ({key, time = 15000, newNotificationProps...}) =>
		notificationToUpdate = _.find @state.notifications, (notification) -> notification.key is key
		if notificationToUpdate?
			updatedNotifications = _.map @state.notifications, (item) ->
				if item.key is notificationToUpdate.key then {...item, ...newNotificationProps} else item
			@setState notifications: updatedNotifications, () =>
				if newNotificationProps.autoDismiss
					setTimeout =>
						@dismissNotification(key)
					, time


	dismissNotification: (key) =>
		reducedNotifications = _.reject(@state.notifications, {key: key})
		@setState notifications: reducedNotifications

	render: ->
		NotificationManagerProvider
			value:
				setNotification: @setNotification
				updateNotification: @updateNotification
				dismissNotification: @dismissNotification
		,
			if !_.isEmpty @state.notifications
				div {className: styles.notificationsContainer},
					_.map @state.notifications, (notification) =>
						NotificationPopup {
							...notification
							onDismiss: () => @dismissNotification(notification.key)
						}
			@props.children
