import _ from 'lodash'

export default ({data}) ->
	projectUserGroup = _.get data, 'projectUserGroup'
	if _.isEmpty projectUserGroup then {}
	else
		{name, roles, description, picture, project} = projectUserGroup
		tags: _.map roles, (role) ->
			rolePhaseIDs = _.get role, 'info.phaseIDs'
			phases = _.map rolePhaseIDs, (phaseID) ->
				_.find project.phases, {id: phaseID}
			phases = _.join (_.sortBy phases), ', '
			result =
				if project.isMultiphase and !_.isEmpty phases
					"#{role.name} (#{phases})"
				else
					role.name
			name: result
		title: name
		description: description
		owner:
			src: picture.mini
