import _ from 'lodash'
import React from 'react'
import moment from 'moment-mini'
import {FormatterConsumer} from 'libs/formatter'
import cnames from 'classnames'
import DataAttribute from '@bevy/data-attribute'

# Renderables
import { div, span, img, p } from 'react-dom-factories'

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import { Link as _Link } from 'react-router-dom'
Link = React.createFactory _Link

import _Tooltip from '@bevy/tooltip'
Tooltip = React.createFactory _Tooltip

# Data
import trLegal from 'data/local/legal'

import styles from './columns.styl'

name =
	key: 'name'
	label: 'Name'
	sortKey: 'Name'
	width: 450
	isSortable: true
	render: ({ item }) ->
		Tooltip
			className: styles.tooltip
			content: item.name
			showTimeout: 200
			hideTimeout: 5000
		,
			span {className: cnames styles.compactText},
				if item.name? then item.name else '-'

abbreviation =
	key: 'abbreviation'
	label: 'Abbreviation'
	sortKey: 'Abbreviation'
	width: 70
	isSortable: true
	render: ({ item }) ->
		span {className: cnames styles.compactText},
			if item.abbreviation? then item.abbreviation else '-'

type =
	key: 'type'
	label: 'Type'
	sortKey: 'Type'
	width: 60
	isSortable: true
	render: ({ item }) ->
		span {className: cnames styles.compactText},
			if item.type? then item.type else '-'

inUse =
	key: 'inUse'
	label: 'In use'
	sortKey: 'inUse'
	width: 40
	isSortable: true
	render: ({ item }) ->
		span {className: cnames styles.compactText},
			if item.inUse then '✓' else 'X'

website =
	key: 'website'
	label: 'Website'
	width: 200
	render: ({ item }) ->
		span {className: cnames styles.compactText},
			if item.website? then item.website else '-'

nip =
	key: 'nip'
	label: 'NIP'
	width: 80
	render: ({ item }) ->
		span {className: cnames styles.compactText},
			if item.nip? then item.nip else '-'

regon =
	key: 'regon'
	label: 'REGON'
	width: 80
	render: ({ item }) ->
		span {className: cnames styles.compactText},
			if item.regon? then item.regon else '-'

krs =
	key: 'krs'
	label: 'KRS'
	width: 80
	render: ({ item }) ->
		span {className: cnames styles.compactText},
			if item.krs? then item.krs else '-'


addressArray = [
	key: 'street'
	label:'Street'
	width: 100
,
	key: 'buildingNumber'
	label: 'Building Number'
	width: 90

,
	key: 'postalCode'
	label: 'Postal Code'
	width: 80
,
	key: 'city'
	label: 'City'
	width: 60
]

addressColumns = _.map addressArray, (addressItem) ->
	{
		key: addressItem.key
		# query: 'addressItems {type number}'
		selectedByDefault: true
		label: addressItem.label
		# isSortable: true
		sortKey: addressItem.key
		width: if addressItem.width? then addressItem.width else 120
		render: ({ item }) ->
			number = if item.hedquarters? then item.hedquarters[addressItem.key]
			div {className: cnames styles.compactText},
			if number? then number else '-'
	}



export COMPANIES_COLUMNS = do ->
	columns =
		[
			{ ...name, selectedByDefault: true }
			{ ...abbreviation, selectedByDefault: true}
			{ ...type, selectedByDefault: true}
			{ ...inUse, selectedByDefault: true}
			{ ...website, selectedByDefault: true}
			{ ...nip, selectedByDefault: true}
			{ ...regon, selectedByDefault: true}
			{ ...krs, selectedByDefault: true}

			# { ...id, selectedByDefault: true }
			...addressColumns
		]
	_.map columns, (column) -> {...column, selected: column.selectedByDefault}
