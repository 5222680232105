import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'

import winston from 'winston'
import * as Sentry from '@sentry/browser'
import hash from 'object-hash'

serviceEnpoints =
	local: 'http://localhost:8001/dev'
	test: 'https://cyoryftm30.execute-api.eu-central-1.amazonaws.com/test'
	development: 'https://jktixcgh8k.execute-api.eu-central-1.amazonaws.com/development'
	staging: 'https://u8c0fne9i2.execute-api.eu-central-1.amazonaws.com/staging'
	production: 'https://8op5v0e8q3.execute-api.eu-central-1.amazonaws.com/production'

RESOLVER_URI = serviceEnpoints[__STAGE__ || 'local']
###
NOTE: Websockets logger will be added in future
###
# wsEndpoints =
# 	local: 'ws://localhost:3001'
# 	test: 'wss://cyoryftm30.execute-api.eu-central-1.amazonaws.com/test'
# 	development: 'wss://jktixcgh8k.execute-api.eu-central-1.amazonaws.com/development'
# 	staging: 'wss://u8c0fne9i2.execute-api.eu-central-1.amazonaws.com/staging'
# 	production: 'wss://8op5v0e8q3.execute-api.eu-central-1.amazonaws.com/production'
# WEBSOCKET_URI = wsEndpoints[__STAGE__ || 'local']

logger = winston.createLogger
	level: 'info'
	format: winston.format.json()
	defaultMeta:
		service: 's360-application'
	transports: [
		new winston.transports.Console format: winston.format.simple()
	]
componentsRegistry = {}
searchParents = (element, selector) ->
	parent = element
	elements = []
	while parent?
		if parent.matches? and parent.matches selector
			elements.unshift parent
		else if !parent.matches?
			logger.warn 'Unknown element without matches function'
			break
		parent = parent.parentElement
	elements

export default logger
export LoggerWrapper = class LoggerWrapper extends React.Component
	@propTypes:
		children: PropTypes.func.isRequired
		id: PropTypes.string.isRequired
		name: PropTypes.string
		description: PropTypes.string
		level: PropTypes.oneOf [
			'emerg'
			'alert'
			'crit'
			'error'
			'warning'
			'warn'
			'notice'
			'info'
			'debug'
		]
	constructor: (props) ->
		super props
		@id = hash props
		componentsRegistry[@id] = _.omit props, [
			'children'
		]
	render: ->
		@props.children {
			'data-logger-id': @id
		}
export SentrySettings =
	beforeSend: (event, error) ->
		originalError = _.get error, 'originalException.originalError'
		if originalError is 'Error: Retry error during user update'
			null
		else
			event
	beforeBreadcrumb: (breadcrumb, hint) ->
		if hint?.name? and hint.name is 'click'
			if hint?.event?.target?
				if _.isElement hint.event.target
					elements = _.map searchParents(hint.event.target, '[data-logger-id]'), (element) ->
						data = componentsRegistry[element.dataset.loggerId]
						data
					breadcrumb.message = _.join _.map(elements, 'id'), ' > '
					breadcrumb.data = _.last elements
				else
					logger.warn 'Unknown event target'
		else if breadcrumb.category is 'fetch' and hint? and hint.input[0] is RESOLVER_URI
			graphqlOperation = JSON.parse hint.input[1].body
			breadcrumb.category = 'graphql'
			breadcrumb.type = 'default'
			breadcrumb.message = graphqlOperation.operationName || '🤷‍♂️ Unnamed operation'
			breadcrumb.data = _.reduce graphqlOperation.variables, (acc, val, key) ->
				acc[key] = JSON.stringify val
				acc
			, {}
		else if breadcrumb.category is 'console'
			if breadcrumb.message in ['Action: render', '[HMR] connected']
				return null
			else if _.startsWith breadcrumb.message, 'React-Hot-Loader: react-'
				return null
			else if _.startsWith breadcrumb.message, 'Warning: componentWillUpdate has been renamed'
				return null
			else if _.startsWith breadcrumb.message, 'Warning: componentWillReceiveProps has been renamed'
				return null
			else if _.startsWith breadcrumb.message, 'Warning: Each child in a list should have a unique'
				return null
			else if _.startsWith breadcrumb.message, 'Warning: componentWillMount has been renamed'
				return null
			else if _.startsWith breadcrumb.message, 'The @atlaskit/field-base package'
				return null
			else if _.startsWith breadcrumb.message, '@atlaskit/field-text-area has been deprecated'
				return null
		return breadcrumb
