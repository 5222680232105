import gql from 'graphql-tag'
import {
	Project
	User
	Document
} from './query.graphql'


export DocumentEditEnvironment = gql """
	query($documentID: ID!){
		legalDocument(id: $documentID) {
			id
			projectID
			state
			name
			project { id, buildingsSets { name, code } }
			description
			combination {
				building
				dates { type, isRequired }
				identifiers { type, isRequired }
				entities { type, isMulti, isRequired }
			}
			path {
				id
				ancestors { id, name, code }
				code
				name
			}
			phases { id name }
			correspondent {id name abbreviation}
			company {id name}
			form {id name abbrev}
			topic {id name}
			parentDocument {id}
			buildings
			freeText
			dates { type, value }
			identifiers { type, value }
			entities {
				recipient { id, name }
				participants { id, name }
			}
			scan { id, secured { original } }
		}
	}
"""
