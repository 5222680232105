"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.scopes = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _helpers = require("../helpers");

var _Project = require("./Project");

var _SafetyVisit = require("./SafetyVisit");

var _SafetyObservation = require("./SafetyObservation");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/*
SafetyObservation permissions model
*/
var scopes = (0, _helpers.makeDefaultSubject)('SafetyObservation', {
  Self: (0, _helpers.makeDefaultFields)('self', {
    Create: {
      actions: 'create'
    },
    Read: {
      actions: 'read'
    },
    Update: {
      actions: 'update'
    },
    Delete: {
      actions: 'delete'
    },
    Destroy: {
      actions: 'destroy'
    },
    Restore: {
      actions: 'restore'
    },
    Share: {
      actions: 'share'
    },
    Clone: {
      actions: 'clone'
    },
    Sync: {
      actions: 'sync'
    }
  }),
  Attachments: (0, _helpers.makeDefaultFields)('attachments', {
    Read: {
      actions: 'read'
    },
    Create: {
      actions: 'create'
    },
    Delete: {
      actions: 'delete'
    },
    Update: {
      actions: 'update'
    },
    Manage: {
      actions: ['read', 'create', 'delete', 'update']
    }
  }),
  Review: (0, _helpers.makeDefaultFields)('review', {
    Check: {
      actions: 'check'
    },
    Uncheck: {
      actions: 'uncheck'
    },
    Manage: {
      actions: ['check', 'uncheck']
    }
  }),
  Ratings: (0, _helpers.makeDefaultFields)('ratings', {
    Update: {
      actions: 'update'
    }
  }),
  Details: (0, _helpers.makeDefaultFields)('details', {
    Update: {
      actions: 'update'
    }
  }),
  Comments: (0, _helpers.makeDefaultFields)('comments', {
    Read: {
      actions: 'read'
    },
    Create: {
      actions: 'create'
    }
  })
});
exports.scopes = scopes;
var _default = {
  // Name of the GraphQL subject
  name: 'SafetyObservation',
  // Database collection
  collection: 'ehsObservations',
  // Name used in making global id's
  globalIDName: 'ehsObservation',
  // Available actions on root leve
  actions: [],
  // Model fields with appropiate actions
  fields: {},
  // Available scopes with implicit subject as a model.name
  scopes,
  // Additional fields requested when fetching items for attached and members roles
  projection: {
    projectID: 1
  },
  // Type of models that can become a member of this model without specified role
  accepts: [],
  // Entity based roles default and assignable
  roles: {
    // Roles granted to all users
    default: [{
      name: 'Observation Author',
      permissions: async function ({
        user,
        membership,
        resolved,
        db,
        cache
      }) {
        var collection, projectIDs;
        collection = await db.collection('ehsObservations');
        projectIDs = await collection.distinct('projectID', _objectSpread({
          ownerID: user._id
        }, _helpers.isSelfDeletedCondition));
        return _lodash.default.compact([!_lodash.default.isEmpty(projectIDs) ? {
          scopes: [_Project.scopes.ReadSafety],
          conditions: _objectSpread({}, resolved ? {
            id: {
              $in: _lodash.default.map(projectIDs, function (id) {
                return (0, _helpers.makeGlobalID)('project', id);
              })
            }
          } : {
            _id: {
              $in: projectIDs
            }
          })
        } : void 0, {
          scopes: [_SafetyObservation.scopes.Self.Read],
          conditions: _objectSpread({}, resolved ? {
            'owner.id': (0, _helpers.makeGlobalID)('user', user._id)
          } : {
            ownerID: user._id
          }, {
            $and: [{
              $or: [{
                visitID: null
              }, {
                visitID: {
                  $exists: false
                }
              }]
            }, _helpers.isSelfDeletedCondition]
          })
        }]);
      }
    }, {
      name: 'Observation Owner',
      permissions: async function ({
        user,
        membership,
        resolved,
        db,
        cache
      }) {
        var collection, projectIDs;
        collection = await db.collection('ehsObservations');
        projectIDs = await collection.distinct('projectID', _objectSpread({
          ownerID: user._id
        }, _helpers.isSelfDeletedCondition));
        return _lodash.default.compact([!_lodash.default.isEmpty(projectIDs) ? {
          scopes: [_Project.scopes.ReadSafety],
          conditions: _objectSpread({}, resolved ? {
            id: {
              $in: _lodash.default.map(projectIDs, function (id) {
                return (0, _helpers.makeGlobalID)('project', id);
              })
            }
          } : {
            _id: {
              $in: projectIDs
            }
          })
        } : void 0, {
          scopes: [_SafetyObservation.scopes.Self.Read],
          conditions: _objectSpread({}, resolved ? {
            'owner.id': (0, _helpers.makeGlobalID)('user', user._id)
          } : {
            ownerID: user._id
          }, {
            $and: [{
              $or: [{
                visitID: null
              }, {
                visitID: {
                  $exists: false
                }
              }]
            }, _helpers.isSelfDeletedCondition]
          })
        }, {
          scopes: [_SafetyObservation.scopes.Self.Delete, _SafetyObservation.scopes.Details.Update, _SafetyObservation.scopes.Attachments.Manage, _SafetyObservation.scopes.Ratings.Update],
          conditions: _objectSpread({}, resolved ? {
            'owner.id': (0, _helpers.makeGlobalID)('user', user._id)
          } : {
            ownerID: user._id
          }, {
            $and: [{
              $or: [{
                visitID: null
              }, {
                visitID: {
                  $exists: false
                }
              }]
            }, _helpers.isSelfDeletedCondition],
            status: {
              $in: ['New', 'PendingCheck']
            }
          })
        }]);
      }
    }],
    // Roles assignable to the entity that will be transfered to the User
    attached: [],
    // Roles assignable to the members (Users) of this entity
    members: [...(0, _helpers.makeSetOfAccepts)(['User', 'UserGroup'], [{
      name: 'Safety observation reader',
      displayName: 'Read',
      acl: true,
      inline: true,
      permissions: function ({
        user,
        parent: observation,
        membership,
        resolved,
        db,
        cache
      }) {
        return [];
      }
    }, {
      name: 'Safety observation editor',
      displayName: 'Write',
      acl: true,
      inline: true,
      permissions: function ({
        user,
        parent: observation,
        membership,
        resolved,
        db,
        cache
      }) {
        return [];
      }
    }])]
  }
};
exports.default = _default;