###
Page layer for handling data & permission errors
###

# Libs
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import _ from 'lodash'
import { UserInfoConsumer } from 'libs/userInfo'

# Renderable
import _EmptyState from '@atlaskit/empty-state'
EmptyState = React.createFactory _EmptyState

import _Spinner from '@bevy/spinner'
Spinner = React.createFactory _Spinner

import {
	InternalErrorState as _InternalErrorState
	NetworkErrorState as _NetworkErrorState
	InvalidProjectState as _InvalidProjectState
} from 'components/ErrorDisplay'
InternalErrorState = React.createFactory _InternalErrorState
NetworkErrorState = React.createFactory _NetworkErrorState
InvalidProjectState = React.createFactory _InvalidProjectState

import trPlatform from 'data/local/platform'


export default class HubLoading extends React.Component
	@propTypes:
		loading: PropTypes.bool
		data: PropTypes.shape
			project: PropTypes.shape
				id: PropTypes.string
		hubKey: PropTypes.string
		refetch: PropTypes.func


	render: ->
		if @props.error?
			if _.find(@props.error.graphQLErrors, (error) -> error.extensions?.code is 'UNAUTHENTICATED')? # user has no permission for project
				EmptyState
					header: trPlatform.error.noPermissionForProject.header
					description: trPlatform.error.noPermissionForProject.description
					imageUrl: require 'assets/empty-states/empty.svg'
					maxImageWidth: 300
					maxImageHeight: 300
			else if @props.error.networkError?.statusCode is 400 # bad request
				InternalErrorState {}
			else # network error
				NetworkErrorState
					onRefresh: if @props.refetch? then () => @props.refetch()
		else if !@props.loading and !@props.data.project? # there is no such project
			InvalidProjectState {}
		else
			UserInfoConsumer {}, ({ability, me}) =>
				if @props.hubKey? and @props.data?.project? and ability.cannot 'read', @props.data.project, @props.hubKey # user has no permission for hub
					EmptyState
						header: trPlatform.error.noPermissionForHub.header
						description: trPlatform.error.noPermissionForHub.description
						imageUrl: require 'assets/empty-states/empty.svg'
						maxImageWidth: 300
						maxImageHeight: 300
				else
					@props.children
