###
Second substep of basic step
###

# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import moment from 'moment-mini'

# Renderable
import { div, p, ul, li, small, h1, span, h2, img } from 'react-dom-factories'

import { Query as _Query } from 'react-apollo'
Query = React.createFactory _Query
Fragment = React.createFactory React.Fragment

import _Select, {NoOptionsMessage}  from '@bevy/select'
Select = React.createFactory _Select

import _ErrorDisplay from 'components/ErrorDisplay'
ErrorDisplay = React.createFactory _ErrorDisplay

import { Field as _Field } from '@atlaskit/form'
Field = React.createFactory _Field

# Data
import { getDocumentsCategories } from '../../data'
import trLegal from 'data/local/legal'

# Styles
import styles from './index.styl'


export default class CategorySelector extends React.Component
	calculateCombinations: (paths, allTopics, allForms) ->
		combinations = []
		for path in paths
			if path.combinations?
				for combination in path.combinations
					form = _.find allForms, id: combination.formID
					topic =  _.find allTopics, id: combination.topicID
					combinations.push {form, topic, path}
		combinations

	getPath4Combination: (form, topic, combinations) ->
		com = _.find combinations, (item) ->
			(item.form.id is form.id) and (item.topic.id is topic.id)
		com.path

	render: ->

		Query
			query: getDocumentsCategories
			fetchPolicy: 'network-only'
			notifyOnNetworkStatusChange: true
			variables:
				projectID: @props.projectID
			, ({loading, data, error}) =>
				ErrorDisplay { loading, error }, () =>
					combinations = @calculateCombinations data.projectByID.legalDocumentPaths, data.legalDocumentTopics, data.legalDocumentForms
					commonSelectProps = (fieldName) =>
						className: styles.formItem
						menuPortalTarget: document.body
						isSearchable: true
						isClearable: true
						formatOptionLabel: (option) -> option.name
						getOptionLabel: (option) -> option.name
						getOptionValue: (option) -> option
						# noOptionsMessage: () =>
						# 	if fieldName? and @props.projectLeader?
						# 		NoOptionsMessage
						# 			fieldName: fieldName
						# 			contactPerson: {...@props.projectLeader, role: 'Project Leader'}

					possibleForms = if @props.value.topic?
						combinations_ = _.filter combinations, (item) => item.topic.id is @props.value.topic.id
						ids = _.map combinations_, 'form.id'
						_.map ids, (id) -> _.find data.legalDocumentForms, id: id
					else
						data.legalDocumentForms

					possibleTopics = if @props.value.form?
						combinations_ = _.filter combinations, (item) => item.form.id is @props.value.form.id
						ids = _.map combinations_, 'topic.id'
						_.map ids, (id) -> _.find data.legalDocumentTopics, id: id
					else
						data.legalDocumentTopics

					possibleTopics = _.sortBy possibleTopics, 'orderingNumber'

					possibleHierarchyOfTopics = _.groupBy possibleTopics, ({id: topicID}) ->
						# NOTE: This will break if topic is placed in multiple places in structure
						singlePathForTopic = _.head(_.map _.filter(combinations, ({topic}) -> topic.id is topicID), 'path')
						if !_.isUndefined singlePathForTopic
							topPath = _.head(singlePathForTopic.ancestors)
						else
							topPath =
								code: null
								name: null
						"#{topPath.code} #{topPath.name}"

					possibleHierarchyOfTopics = _.omit possibleHierarchyOfTopics, 'null null'
					orderOfGroups = _.orderBy _.keys(possibleHierarchyOfTopics), (top) -> parseInt(_.head(top.split(/\s/)))
					formatGroupLabel = (data) ->
						div {className: styles.groupStyles},
							Fragment {},
								span {}, data.label
					groupedOptions =
						_.map orderOfGroups, (group) ->
							label: group
							options: possibleHierarchyOfTopics[group]


					Fragment {},
						if @props.enableRecategorize
							Fragment {},
								do =>
									isMissing = @props.highlightMissingValues and (_.isEmpty @props.value.topic)
									Field
										label:
											div {className: cnames styles.label, if isMissing then styles.missingLabel},
												trLegal.props.topic
												if isMissing
													div {className: styles.missingInfo},
														'Document subject field have to be selected to register document'

										name: trLegal.props.topic
									, =>
										Select {
											...commonSelectProps trLegal.props.topic
											options: groupedOptions
											formatGroupLabel: formatGroupLabel
											appearance: if isMissing then 'danger'
											placeholder:
												div {className: styles.placeholderText},
													"Select #{_.toLower trLegal.props.topic}..."
											value: @props.value.topic
											onChange: (value) =>
												if value? and @props.value.form?
													path = @getPath4Combination @props.value.form, value, combinations
													@props.onChange { ...@props.value, topic: value, path: path}
												else
													@props.onChange { ...@props.value, topic: value, path: null}
										}

								do =>
									isMissing = @props.highlightMissingValues and (_.isEmpty @props.value.form)
									Field
										label:
											div {className: cnames styles.label, if isMissing then styles.missingLabel},
												trLegal.props.type
												if isMissing
													div {className: styles.missingInfo},
														'Document Type field have to be selected to register document'
										name: trLegal.props.type
									, =>
										Select {
											...commonSelectProps trLegal.props.type
											placeholder:
												div {className: styles.placeholderText},
													"Select #{_.toLower trLegal.props.type}..."
											appearance: if isMissing then 'danger'
											options: _.orderBy possibleForms, 'name'
											value: @props.value.form
											onChange: (value) =>
												if value? and @props.value.topic?
													path = @getPath4Combination value, @props.value.topic, combinations
													@props.onChange { ...@props.value, form: value, path: path}
												else
													@props.onChange { ...@props.value, form: value, path: null}
										}

						do => if @props.availableParents?
							value = if @props.value.parentDocument?
								_.find @props.availableParents, (parent) => parent.id is @props.value.parentDocument.id
							isMissing = @props.highlightMissingValues and !value?
							Field
								label:
									div {className: cnames styles.label, if isMissing then styles.missingLabel},
										trLegal.props.mainContract
										if isMissing
											div {className: styles.missingInfo},
												'Document main contract field have to be selected to register document'
								name: trLegal.props.mainContract
							, =>
								Select
									className: styles.formItem
									menuPortalTarget: document.body
									placeholder:
										div {className: styles.placeholderText},
											"Select #{_.toLower trLegal.props.mainContract}..."
									isSearchable: true
									isClearable: true
									appearance: if isMissing then 'danger'
									options: @props.availableParents
									formatOptionLabel: (option) ->
										number = _.get _.find(option.identifiers, type: 'Number'), 'value'
										receivalDate = _.find option.dates, type: 'ReceivalDate'
										form = option.form.name
										topic = option.topic.name
										name = option.asignee.name
										contractor = option.correspondent.name
										return (_.compact [
											number
											form
											topic
											if receivalDate?.value? then moment.unix(receivalDate.value).utc().format('DD/MM/YYYY')
											name
											contractor
										]).join ' | '
									getOptionValue: (option) -> option
									value: value
									onChange: (value) => @props.onChange { ...@props.value, parentDocument: value}
