###
Login screen
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
# Renderable
import { div, img, p, h1 } from 'react-dom-factories'

Fragment = React.createFactory React.Fragment

import { Query as _Query } from 'react-apollo'
Query = React.createFactory _Query

import _Button from '@bevy/button'
Button = React.createFactory _Button

# Styles
import styles from './index.styl'



export default class LoginScreen extends React.Component

	render: ->
		div {className: styles.page},
			div {className: styles.background}
			div {className: styles.loginWindow},
				div {className: styles.welcomeMessage},
					h1 {}, 'Welcome to Skanska 360'
					p {}, 'Perform your tasks in the Skanska 360 digital platform'
				Button
					appearance: 'primary'
					shouldFitContainer: true
					onClick: @props.onLogin
					className: styles.button
				, 'Log in to the system'
