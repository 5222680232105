exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2WTS2MVfNeVnfgeaOlvRE8 {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  margin-bottom: 30px;\n}\n._2sphndyB87zoAKbn382kmv {\n  position: relative;\n}\n._1yNs7og2QNp5GlKhZrtl_s {\n  margin: auto 10px;\n}\n._2x0o457Rqj40QT0X85cbQd {\n  display: flex;\n  flex-direction: column;\n  background: #fafbfc;\n  color: #8993a4;\n  padding: 10px;\n}\n._2VNm_ibfplvRaa74hxqhpV {\n  position: relative;\n  align-items: center;\n  padding: 4px 0;\n  height: 21px;\n}\n.iLlKigjFOtGNU7bQ5Qf6y {\n  position: absolute;\n  right: 0;\n  bottom: 3px;\n  cursor: pointer;\n  border-radius: 4px;\n  padding: 7px;\n}\n.iLlKigjFOtGNU7bQ5Qf6y:hover {\n  background-color: #ebecf0;\n}\n._2b9O9Q0ohmB2dKm4_JW28J {\n  margin-left: -2px;\n  margin-right: 6px;\n  padding: 0 0 4px;\n  height: 24px;\n  border-bottom: 1px solid #000;\n}\n._34EmWhvUEhv8T2pK8O9Z53 {\n  margin-left: -2px;\n  margin-right: 6px;\n  padding: 4px 0;\n}\n.v2LMG5Gam4XUCVLi4L9fq {\n  padding: 4px 0;\n  height: 20px;\n  border-bottom: 1px solid #000;\n}\n", ""]);
// Exports
exports.locals = {
	"modalHeader": "_2WTS2MVfNeVnfgeaOlvRE8",
	"relative": "_2sphndyB87zoAKbn382kmv",
	"item": "_1yNs7og2QNp5GlKhZrtl_s",
	"roles": "_2x0o457Rqj40QT0X85cbQd",
	"tableItem": "_2VNm_ibfplvRaa74hxqhpV",
	"removeAction": "iLlKigjFOtGNU7bQ5Qf6y",
	"selectItem": "_2b9O9Q0ohmB2dKm4_JW28J",
	"selectLast": "_34EmWhvUEhv8T2pK8O9Z53",
	"tableItemLastBorder": "v2LMG5Gam4XUCVLi4L9fq"
};