import gql from 'graphql-tag'

# QUERIES
# export GetUsersNotInGroup = gql """
# query GetUsersNotInGroup(
# 	$excludeGroupsIDs: [ID!],
# 	$search: String,
# 	$pagination: PaginationInput
# 	) {
# 	users(
# 		filters: {
# 			excludeGroupsIDs: $excludeGroupsIDs
# 			excludeTypes: ["Service"]
# 		},
# 		search: $search,
# 		pagination: $pagination
# 		) {
# 		... on Person {
# 			id
# 			name
# 			email
# 		}
# 	}
# }
# """


export GetUsersNotInGroup = gql """
query GetUsersNotInGroup (
		$id: ID!
		$search: String,
		$pagination: PaginationInput
		) {
		userGroup(id: $id) {
			id
			name
			description
			picture {
				mini
			}
			notMembers(pagination: $pagination, search: $search) {
				... on Service{
					id
					name
					email
				}
				... on Person{
					id
					name
					email
      	}
			}
		}
}
"""

# MUTATIONS
export AddUsersToGroup = gql """
	mutation($id: ID!, $addedEntities: [MemberInputAdd!], $removedEntities: [MemberInputRemove!]) {
		userGroupUpdateMembers(id: $id, addedEntities: $addedEntities, removedEntities: $removedEntities) {
			id
		}
	}
"""
