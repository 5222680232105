import gql from 'graphql-tag'

export DeleteProjectManagement = gql """
	mutation(
		$projectID: ID!
		$type: String!,
		$id: ID!,
		$phaseID: ID!
		) {
		projectManagementDelete(
			projectID: $projectID
			type: $type
			id: $id
			phaseID: $phaseID
		) {
			id
			management {
				type
				phase { id name }
				members {
					user {
						...on Person {
							id name picture {mini}
							}
						}
					role
				}
			}
		}
	}
"""
