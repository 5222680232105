import _ from 'lodash'
import React from 'react'

import { div } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import {
	add
} from '@bevy/theme/icons'
import styles from '../index.styl'

export default ({members, selected, ability, me, logAs}) ->
	selectedMembers = _.filter members, (user) -> _.some selected, (selectedID) -> selectedID is user.id
	secondary = _.compact [
		if ((_.size selected) is 1) and (ability.can 'update', selectedMembers[0])
			key: 'edit'
			content: 'Edit'
			onClick: => @setState activeModal: 'edit'
			'data-test-id': 'toolbar-button-edit-user'
	,
		if ((_.size selected) is 1) and ((_.first selected).id isnt me.id) and (ability.can 'logAs', 'User')
			key: 'logAs'
			content: 'Login as'
			onClick: ->
				member = _.first selectedMembers
				console.log member
				logAs {id: member.id, name: member.name}
			'data-test-id': 'toolbar-button-logAs-user'
	]
	{ secondary }

