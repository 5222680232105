import adopt from 'libs/adopt'
import { getRefetchQueries } from 'libs/legal'

# Data
import {
	DocumentRegisterEnvironment
	ListLegalDocuments
	DocumentRegisterMutation
	DocumentCategorizeMutation
	getDetailsFields
	CreateOrganizationsMutation
	UpdateScan
} from '../data'
import trLegal from 'data/local/legal'

import { INBOX_PATH } from 'data/paths'


legalDocumentsCollectionQueries = [
		'ProjectNavigation',
		'DMS_Hub_counts',
		'ProjectLegalDocumentsActivity',
		'ProjectLegalDocumentsAssigned',
		'ProjectLegalDocumentPreview',
		'ListDocumentScans',
		'DMS_Inbox_Accessory',
		'DMS_Registry_Accessory',
		]

export default DataLayer = adopt
	queries:
		documentRegisterEnvironment: ({projectSlug}) ->
			query: DocumentRegisterEnvironment
			variables:
				projectSlug: projectSlug
	mutations:
		registerDocument: ({onRegisterCompleted, setNotification, isMulti, app}) ->
			mutation: DocumentRegisterMutation
			refetchQueries: getRefetchQueries legalDocumentsCollectionQueries, app
			onCompleted: (data) ->
				onRegisterCompleted()
				setNotification
					content: "Document#{if isMulti then 's' else ''} was sucessfully added!"
					appearance: 'success'
			onError: (error) ->
				setNotification
					content: trLegal.error.actionFailure
					appearance: 'error'
		categorizeDocument: ({props, onRegisterCompleted, setNotification, isEdition, isMulti, shouldRegisterAnother, prevDocumentLegalState, app}) ->
			mutation: DocumentCategorizeMutation
			refetchQueries: getRefetchQueries legalDocumentsCollectionQueries, app
			onCompleted: (data) ->
				onRegisterCompleted()
				setNotification
					content: "Document#{if isMulti then 's' else ''} was sucessfully #{if isEdition? then 'edited' else 'categorized'}!"
					appearance: 'success'
				if !shouldRegisterAnother and
				((_.size data.legalDocumentUpdateCategory) is 1) and
				(_.head data.legalDocumentUpdateCategory).state is 'Draft' and  #new document state
				prevDocumentLegalState is 'Commited' #old document state
					props.history.push "/projects/#{props.match.params.project}/legal/#{INBOX_PATH}/#{(_.head data.legalDocumentUpdateCategory).id}"
			onError: (error) ->
				setNotification
					content: trLegal.error.actionFailure
					appearance: 'error'
		createOrganizations: ({setNotification, onCreateOrganizations}) ->
			mutation: CreateOrganizationsMutation
			onCompleted: (data) -> onCreateOrganizations(data.organizationsCreate, data.project)
			onError: (error) ->
				setNotification
					content: trLegal.error.actionFailure
					appearance: 'error'
		updateScan: ({documentID, setNotification, app}) ->
			mutation: UpdateScan
			refetchQueries: getRefetchQueries legalDocumentsCollectionQueries, app
			onCompleted: (data) =>
				setNotification
					content: 'Scan uploaded'
					appearance: 'success'
			onError: (error) ->
				setNotification
					content: trLegal.error.actionFailure
					appearance: 'error'
