###
Edit shortcut Modal
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import adopt from 'libs/adopt'

# Renderable
import { div, span, input, img } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import _Spinner from '@bevy/spinner'
Spinner = React.createFactory _Spinner

import _BevyModal from '@bevy/modal'
BevyModal = React.createFactory _BevyModal

import {
	TextField as _TextField
} from 'components/FormItems'
TextField = React.createFactory _TextField

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import _AddShortcutModal from '../AddShortcutModal'
AddShortcutModal = React.createFactory _AddShortcutModal

import {
	Shortcut
} from './data'

DataLayer = adopt
	queries: 
		shortcut: ({projectID, shortcutID, setState}) => 
			query: Shortcut
			skip: !shortcutID?
			variables: {
				projectID: projectID
			}
			onCompleted: (data) =>
				item = _.find data.projectByID.navigationShortcuts, (shortcut) -> shortcut.id is shortcutID
				setState
					name: item.name
					url: item.url

export default class EditShortcutModal extends React.Component
	constructor: (props) ->
		super props
		@state = 
			name: ""
			url: ""
	getVariables4Mutation: =>
		variables =
			name: @state.name
			url: @state.url
			projectID: @props.projectID

	onCloseProxy: (appropriateMutation) =>
		if _.isFunction appropriateMutation
			appropriateMutation
				variables: @getVariables4Mutation()
		@props.onClose()

	canProceed: =>
		(!_.isEmpty @state.name) and (!_.isEmpty @state.url)

	render: =>
		DataLayer {
			projectID: @props.projectID
			shortcutID: @props.shortcutID
			setState: (state) => @setState state
		}, (operations) =>
			if operations.shortcut.data?
				item = _.find operations.shortcut.data.projectByID.navigationShortcuts, (shortcut) => shortcut.id is @props.shortcutID
				AddShortcutModal 
					projectID: @props.projectID, 
					onClose: @props.onClose
					shortcutID: @props.shortcutID
					shortcutName: item.name
					shortcutUrl: item.url
			else
				null