
###
S360 Logged as user banner
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'

# Renderable
import { span, div } from 'react-dom-factories'

import _Banner from '@atlaskit/banner'
Banner = React.createFactory _Banner

import _Button from '@bevy/button'
Button = React.createFactory _Button

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

# Styles
import styles from './index.styl'
import {
	alertTriangle
} from 'react-icons-kit/feather'

export default class SearchDrawer extends React.Component
	@propTypes:
		onDone: PropTypes.func
		isOpen: PropTypes.bool
		userName: PropTypes.string.isRequired
	render: ->
		div {
			className: styles.wrapper
		},
			Banner
				isOpen: @props.isOpen
				appearance: if @props.warning? then 'warning'
				icon: Icon
					icon: alertTriangle
					size: 22
					className: styles.icon
			,
				if @props.warning
					div {className: styles.container},
						span {className: styles.header}, @props.warning
				else
					div {className: styles.container},
						span {className: styles.header}, "You are now logged in as #{@props.userName}"
						Button
							# appearance: 'subtle'
							onClick: @props.onDone
							className: styles.button
						, 'Switch back'

		# className: styles.icon
