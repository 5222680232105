import _ from 'lodash'

export default ({data, format}) ->
	if !_.isEmpty data.detailsQuery.data
		{userGroup} = data.detailsQuery.data
		items: _.compact [
			key: 'totalCount'
			description: 'Members'
			tooltip: 'Total group members count'
			value: _.size userGroup.members
		]
