import gql from 'graphql-tag'

export RootQuery = gql """
	query ProjectsList {
		projects {
				id
				name
				slug
				city
				country
				photo {
					mini
				}
		}
	}
"""
