import _ from 'lodash'
import React from 'react'

import { div } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import {
	add
} from '@bevy/theme/icons'
import styles from '../index.styl'

export default ({selected, ability, data}) ->
	projectUserGroups = _.get data, 'rootQuery.data.project.projectUserGroups'
	firstSelectedProjectUserGroup = _.find projectUserGroups, {id: _.head selected}
	primary =
		key: 'add'
		content: 'Add group'
		iconBefore: Icon add
		isDisabled: ability.cannot 'update', 'ProjectUserGroup'
		onClick: => @setState activeModal: 'add'
		'data-test-id': 'toolbar-button-add-new-project-group'
	secondary = _.compact [
		if _.size(selected) is 1 and firstSelectedProjectUserGroup? and ability.can 'update', firstSelectedProjectUserGroup
			key: 'edit'
			content: 'Edit'
			onClick: => @setState activeModal: 'edit'
			'data-test-id': 'toolbar-button-edit-project-group'
	,
		if _.size(selected) is 1 and firstSelectedProjectUserGroup? and ability.can 'delete', firstSelectedProjectUserGroup
			key: 'delete'
			content: 'Delete'
			onClick: => @setState activeModal: 'delete'
			'data-test-id': 'toolbar-button-delete-project-group'
	]
	{ primary, secondary }

