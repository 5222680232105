###
Edit organization
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import adopt from 'libs/adopt'

# Renderable
import { div, p } from 'react-dom-factories'
import DataAttribute from '@bevy/data-attribute'

Fragment = React.createFactory React.Fragment

import _Spinner from '@bevy/spinner'
Spinner = React.createFactory _Spinner

import _AddCompanyModal from '../AddCompanyModal'
AddCompanyModal = React.createFactory _AddCompanyModal

# Data
import {
	OrganizationBasicEditEnvironment
	OrganizationAdvancedEditEnvironment

} from './data'

DataLayer = adopt
	queries:
		organizationBasicEditEnvironment: ({activeBasic, organizationID}) ->
			query: OrganizationBasicEditEnvironment
			skip: !activeBasic
			variables:
				organizationID: organizationID
		organizationAdvancedEditEnvironment: ({activeAdvanced, organizationID}) ->
			query: OrganizationAdvancedEditEnvironment
			skip: !activeAdvanced
			variables:
				organizationID: organizationID

export default class OrganizationEditModal extends React.Component
	@propTypes =
			onClose: PropTypes.func.isRequired

	getInitialData: (data) =>
		if data?
			result = _.omit data.organization, ['id', '__typename']
		if @props.advancedView?
			result = {
				...result.hedquarters
				..._.omit result, 'hedquarters'
			}
		result

	render: ->
		DataLayer
			organizationID: @props.organizationID
			activeBasic: !@props.advancedView?
			activeAdvanced: @props.advancedView?
			, (operations) =>
				activeQuery = if !@props.advancedView?
					operations.organizationBasicEditEnvironment
				else
					operations.organizationAdvancedEditEnvironment
				if activeQuery.loading then Spinner {}
				else
					AddCompanyModal {
						...@props
						initialSetup: if activeQuery.data? then @getInitialData activeQuery.data
						editQuery: activeQuery
					}

