import gql from 'graphql-tag'

export GroupQuery = gql """
		query GroupDetail (
		$id: ID!
		$pagination: PaginationInput
		) {
		userGroup(id: $id) {
			id
			name
			slug
			description
			picture {
				mini
			}
			roles {
				name
				info
			}
			members(pagination: $pagination) {
			... on ActorAccess{
        user {
        	... on Person{
            id
						name
						email
						picture {
							mini
						}
						department
						position
						telephone
						company { id name }
						memberships(types: [Project]) {
							...on ProjectAccess {
								project {
									id
									slug
									name
								}
								roles {
									name
									info
								}
							}
						}
          }
        }
      }
		}
	}
}

"""
