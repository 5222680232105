import _ from 'lodash'
import React from 'react'
import moment from 'moment-mini'
import {FormatterConsumer} from 'libs/formatter'
import cnames from 'classnames'
import DataAttribute from '@bevy/data-attribute'

# Renderables
import { div, span, img, p } from 'react-dom-factories'

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import { Link as _Link } from 'react-router-dom'
Link = React.createFactory _Link

import _Tooltip from '@bevy/tooltip'
Tooltip = React.createFactory _Tooltip

# Data
import trLegal from 'data/local/legal'

import styles from './index.styl'
import {
	messageSquare
	paperclip
} from 'react-icons-kit/feather'


hasScan =
	key: 'HasScan'
	query: 'hasScan'
	header:
		content: 'Scan'
		width:
			min: 40
			max: 40
		sortKey: 'HasScan'
	cell: (doc) -> div {className: cnames styles.compactText, if doc.isFresh then styles.fresh},
		if doc.hasScan then Icon {icon: paperclip, size: 11}

description =
	key: 'description'
	query: 'description'
	header:
		content: 'Note'
		width:
			min: 40
			max: 40
		sortKey: 'description'
	cell: (doc) -> div {className: cnames styles.compactText, if doc.isFresh then styles.fresh},
		if !_.isEmpty doc.description then Icon {icon: messageSquare, size: 11}

age =
	key: 'Age'
	query: 'createdAt'
	header:
		content: trLegal.props.age
		sortKey: 'CreatedAt'
		width: min: 60
		isSortable: true
	cell: (doc) ->
		FormatterConsumer {}, (format) ->
			span {className: cnames styles.compactText, if doc.isFresh then styles.fresh},
				do =>
					diff = moment().diff(moment.unix(doc.createdAt), 'days')
					if diff > 1 then  "#{diff} days"
					else if diff is 1 then "#{diff} day"
					else 'today'

form =
	key: 'Form'
	query: 'form {id name}'
	header:
		content: trLegal.props.type
		sortKey: 'Form'
		isSortable: true
		width: min: 100
	cell: (doc) ->
		div {
			className: cnames styles.compactText, if doc.isFresh then styles.fresh
			'data-test-id': DataAttribute 'documents-list-form-cell'
		},
			if doc.form? then _.capitalize(doc.form.name)
			else span {className: styles.textSpecial}, 'Scan'

topic =
	key: 'Topic'
	query: 'topic {id name} scan { id filename }'
	header:
		content: trLegal.props.topic
		sortKey: 'Topic'
		isSortable: true
		width:
			min: 200
			max: 300
	cell: (doc) ->
		div
			className: cnames styles.compactText, if doc.isFresh then styles.fresh
			onClick: (e) -> e.stopPropagation()
			'data-test-id': DataAttribute 'documents-list-topic-cell'
		, do ->
			Link {className: styles.link, to: doc.link},
				if doc.topic?
					_.capitalize(doc.topic.name)
				else if doc.scan?.filename?
					doc.scan.filename

correspondent =
	key: 'Correspondent'
	query: 'correspondent {id name}'
	header:
		content: trLegal.props.source
		sortKey: 'Correspondent'
		width:
			min: 200
			max: 300
		isSortable: true
	cell: (doc) ->
		div {className: cnames styles.compactText, if doc.isFresh then styles.fresh},
			if doc.correspondent? then _.capitalize(doc.correspondent.name)
			else if doc.scan?.source? then doc.scan.source
			else '-'

name =
	key: 'name'
	query: 'name'
	header:
		content: 'Download name'
		sortKey: 'Name'
		width: min: 220
		isSortable: false
	cell: (doc) ->
		Tooltip
			className: styles.tooltip
			content: doc.name
			showTimeout: 200
			hideTimeout: 5000
		,
			div {className: cnames styles.compactText, if doc.isFresh then styles.fresh},
				doc.name

status =
	key: 'status'
	query: 'hasScan hasCategory hasDetails hasReview'
	header:
		content: 'Status'
		sortKey: 'Status'
		width: min: 80
		isSortable: true
	cell: (doc) ->
		span {className: cnames styles.compactText, if doc.isFresh then styles.fresh},
			if doc.hasReview and doc.hasDetails and doc.hasCategory and doc.hasScan
				'Awaiting approval'
			else if doc.hasDetails and doc.hasCategory and doc.hasScan
				'To review'
			else if doc.hasCategory and doc.hasScan
				'To specify'
			else if doc.hasScan
				'To categorize'
			else if doc.hasCategory
				'Scan required'
			else
				'-'

mainContract =
	key: 'MainContract'
	query: 'parentDocument {id identifiers {type value}}'
	header:
		content: trLegal.props.mainContract
		sortKey: 'MainContract'
		width: min: 120
		isSortable: true
	cell: (doc, displayDocument) ->
		if doc.parentDocument? and doc.parentDocument.href?
			Link
				className: cnames styles.compactText, styles.link, if doc.isFresh then styles.fresh
				to: doc.parentDocument.href
			,
				_.find(doc.parentDocument.identifiers, {type: 'Number'}).value
		else div {className: cnames styles.compactText, styles.centered}, '-'

# originalDocument =
# 	key: 'OriginalDocument'
# 	query: 'originalDocument {id}'
# 	selectedByDefault: false
# 	header:
# 		content: trLegal.props.originalDocument
# 		sortKey: 'OriginalDocument'
# 		width: min: 50
# 		isSortable: true
# 	cell: (doc, displayDocument) ->
# 		if doc.originalDocument?
# 				div
# 					className: cnames styles.compactText, styles.centered, styles.link
# 					onClick: -> displayDocument doc.originalDocument
# 				, Icon {icon: copy, size: 12}
# 			else div {className: cnames styles.compactText, styles.centered},

# Dates
documentDate =
	key: 'DocumentDate'
	query: 'dates {type value}'
	header:
		content: trLegal.props.creationDate
		sortKey: 'CreationDate'
		width: min: 100
		isSortable: true
	cell: (doc) ->
		date = _.find doc.dates, type: 'CreationDate'
		FormatterConsumer {}, (format) ->
			span {className: cnames styles.compactText, if doc.isFresh then styles.fresh},
				if date? then format.date date.value else '-'

receivalDate =
	key: 'ReceivalDate'
	query: 'dates {type value}'
	header:
		content: trLegal.props.receivalDate
		sortKey: 'ReceivalDate'
		width: min: 80
		isSortable: true
	cell: (doc) ->
		FormatterConsumer {}, (format) ->
			span {className: cnames styles.compactText, if doc.isFresh then styles.fresh},
				format.date _.find(doc.dates, type: 'ReceivalDate').value

validationDate =
	key: 'ValidationDate'
	query: 'dates {type value}'
	header:
		content: 'Validity'
		sortKey: 'ValidationDate'
		width: min: 100
		isSortable: true
	cell: (doc) ->
		FormatterConsumer {}, (format) ->
			date = _.find doc.dates, type: 'ValidationDate'
			span {className: cnames styles.compactText, if doc.isFresh then styles.fresh},
				if date? then format.date date.value else '-'

expirationDate =
	key: 'ExpirationDate'
	query: 'dates {type value}'
	header:
		content: 'Expiration'
		sortKey: 'ExpirationDate'
		width: min: 100
		isSortable: true
	cell: (doc) ->
		FormatterConsumer {}, (format) ->
			date = _.find doc.dates, type: 'ExpirationDate'
			span {className: cnames styles.compactText, if doc.isFresh then styles.fresh},
				if date? then format.date date.value else '-'

registeredAt =
	key: 'RegisteredAt'
	query: 'createdAt'
	header:
		content: trLegal.props.createdAt
		sortKey: 'CreatedAt'
		width: min: 100
		isSortable: true
	cell: (doc) ->
		FormatterConsumer {}, (format) ->
			span {className: cnames styles.compactText, if doc.isFresh then styles.fresh}, format.date doc.createdAt

modifiedAt =
	key: 'ModifiedAt'
	query: 'modifiedAt'
	header:
		content: trLegal.props.modifiedAt
		sortKey: 'ModifiedAt'
		width: min: 100
		isSortable: true
	cell: (doc) ->
		FormatterConsumer {}, (format) ->
			span {className: cnames styles.compactText, if doc.isFresh then styles.fresh},
				if doc.modifiedAt? then format.date doc.modifiedAt else '-'


# Entities
asignee =
	key: 'Assignee'
	query: '''
		asignee {
			... on Person { id, name }
			... on Service { id, name }
		}
	'''
	header:
		content: trLegal.props.assignee
		isSortable: true
		sortKey: 'Assignee'
		width: min: 100
	cell: (doc) -> div {className: cnames styles.compactText, if doc.isFresh then styles.fresh}, doc.asignee.name

registeredBy =
	key: 'RegisteredBy'
	query: '''
		createdBy {
			... on Person { id, name }
			... on Service { id, name }
		}
	'''
	header:
		content: trLegal.props.createdBy
		sortKey: 'CreatedBy'
		isSortable: true
		width: min: 100
	cell: (doc) -> div {className: cnames styles.compactText, if doc.isFresh then styles.fresh}, doc.createdBy.name

recipient =
	key: 'Recipient'
	query: 'entities { recipient { id name } }'
	header:
		content: trLegal.props.recipient
		# sortKey: 'CreatedBy'
		# isSortable: true
		width: min: 100
	cell: (doc) -> div {className: cnames styles.compactText, if doc.isFresh then styles.fresh},
		if doc.recipient? then doc.recipient.name else '-'


# Project
company =
	key: 'Company'
	query: 'company {id name}'
	header:
		content: trLegal.props.company
		sortKey: 'Company'
		width: min: 100
		isSortable: false
	cell: (doc) -> div {className: cnames styles.compactText, if doc.isFresh then styles.fresh},
		if doc.company? then doc.company.name

buildings =
	key: 'Buildings'
	query: 'buildings'
	header:
		content: trLegal.props.buildings
		sortKey: 'Buildings'
		width: min: 45
		isSortable: false
	cell: (doc) -> div {className: cnames styles.compactText, if doc.isFresh then styles.fresh},
		if doc.buildings? and !_.isEmpty doc.buildings then _.join (_.sortBy doc.buildings), ', ' else '-'

phases =
	key: 'Phases'
	query: 'phases {id name }'
	header:
		content: 'Phases'
		sortKey: 'Phases'
		width: min: 45
		isSortable: false
	cell: (doc) ->
		div {className: cnames styles.compactText, if doc.isFresh then styles.fresh},
			if doc.phases? and !_.isEmpty doc.phases
				phases = _.map doc.phases, 'name'
				_.join (_.sortBy phases), ', '
			else
				'-'

freeText =
	key: 'FreeText'
	query: 'freeText'
	header:
		content: 'Free text'
		sortKey: 'FreeText'
		width: min: 80
		isSortable: false
	cell: (doc) -> div {className: cnames styles.compactText, if doc.isFresh then styles.fresh},
		if doc.freeText? then doc.freeText else '-'

# Identifiers
identifiers = [
	key: 'Number'
	label: trLegal.props.number
,
	key: 'CaseNumber'
	label: trLegal.props.caseNumber
,
	key: 'LandRegisterNumber'
	label: trLegal.props.landRegisterNumber
	minWidth: 160
,
	key: 'NIPNumber'
	label: trLegal.props.NIPNumber
,
	key: 'KRSNumber'
	label: trLegal.props.KRSNumber
,
	key: 'VatNumber'
	label: trLegal.props.VatNumber
,
	key: 'REGONNumber'
	label: trLegal.props.REGONNumber
,
	key: 'ExternalID'
	label: trLegal.props.ExternalID
,
	key: 'ParcelNumber'
	label: trLegal.props.parcelNumber
,
	key: 'PercintNumber'
	label: trLegal.props.precinctNumber
	minWidth: 160
]

identifiersColumns = _.map identifiers, (identifier) ->
	{
		key: identifier.key
		query: 'identifiers {type value}'
		selectedByDefault: false
		header:
			content: identifier.label
			isSortable: true
			sortKey: identifier.key
			width: min: if identifier.minWidth? then identifier.minWidth else 120
		cell: (doc) ->
			number = _.find doc.identifiers, {type: identifier.key}
			div {className: cnames styles.compactText, if doc.isFresh then styles.fresh},
			if number? then number.value else '-'
	}

export inboxColumns = do ->
	columns =
		[
			# default columns
			{ ...hasScan, selectedByDefault: true }
			{ ...description, selectedByDefault: true }
			{ ...age, selectedByDefault: true }
			{ ...form, selectedByDefault: true }
			{ ...topic, selectedByDefault: true }
			{ ...correspondent, selectedByDefault: true }
			{ ...asignee, selectedByDefault: true }
			{ ...receivalDate, selectedByDefault: true }
			{ ...status, selectedByDefault: true }
			{ ...name, selectedByDefault: true }

			# dates
			{ ...registeredAt, selectedByDefault: false }
			{ ...modifiedAt, selectedByDefault: false }
			{ ...documentDate, selectedByDefault: false }
			{ ...validationDate, selectedByDefault: false }
			{ ...expirationDate, selectedByDefault: false }
			# entities
			# { ...recipient, selectedByDefault: false }
			{ ...registeredBy, selectedByDefault: false }
			# project
			{ ...buildings, selectedByDefault: true }
			{ ...phases, selectedByDefault: false }
			{ ...freeText, selectedByDefault: false }
			{ ...company, selectedByDefault: false }
			# identifiers
			# { ...mainContract, selectedByDefault: false }
			...identifiersColumns
		]
	_.map columns, (column) -> {...column, selected: column.selectedByDefault}

export registryColumns = do ->
	columns =
		[
			# default columns
			{ ...form, selectedByDefault: true }
			{ ...topic, selectedByDefault: true }
			{ ...correspondent, selectedByDefault: true }
			{ ...buildings, selectedByDefault: true }
			{ ...phases, selectedByDefault: true }
			{ ...freeText, selectedByDefault: true }
			{ ...documentDate, selectedByDefault: true }
			{ ...receivalDate, selectedByDefault: true}
			{ ...mainContract, selectedByDefault: true }
			{ ...validationDate, selectedByDefault: true }
			{ ...expirationDate, selectedByDefault: true }
			# dates
			{ ...registeredAt, selectedByDefault: false }
			{ ...modifiedAt, selectedByDefault: false}
			# entities
			{ ...asignee, selectedByDefault: false }
			{ ...registeredBy, selectedByDefault: false}
			{ ...recipient, selectedByDefault: false}
			# project
			{ ...company, selectedByDefault: false}
			# identifiers
			...identifiersColumns
			{ ...name, selectedByDefault: false }
		]
	_.map columns, (column) -> {...column, selected: column.selectedByDefault}
