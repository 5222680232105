import _ from 'lodash'
import React from 'react'
import qs from 'qs'
qsSettings =
	strictNullHandling: true
	encodeValuesOnly: true
	parseArrays: false
	# arrayFormat: 'comma' #when active arrays are badly parsed from url
	encode: false


context = React.createContext()
SideNavFiltersProviderFactory = React.createFactory context.Provider
export SideNavFiltersConsumer = React.createFactory context.Consumer

export default class SideNavFiltersProvider extends React.Component
	constructor: (props) ->
		super props
		@state =
			foldersListName: ''
			foldersList: []
			activeFolder: {}
			activePaths: null

	handleFoldersList: (listName, items) =>
		if (_.isEmpty @state.foldersList) or (listName isnt @state.foldersListName)
			@setState
				foldersList: items
				foldersListName: listName

	handleActiveFolder: (folder, cb) =>
		parsedQuery = qs.parse(window.location.search.replace(/^\?/, ''), qsSettings)
		if (!parsedQuery.folder?) or (parsedQuery.folder isnt folder.slug)
			newSearchQuery = {folder: folder.slug }
			@props.history.push search: "?#{qs.stringify(newSearchQuery, qsSettings)}"
			@setState activeFolder: folder


	handleActivePaths: (paths, cb) =>
		parsedQuery = qs.parse(window.location.search.replace(/^\?/, ''), qsSettings)
		parsedPaths = []
		if parsedQuery.paths? then _.mapValues parsedQuery.paths, (value) -> parsedPaths.push(value)
		if (_.isEmpty parsedPaths) or !(_.every parsedPaths, (parsedPath, index) -> parsedPath is paths[index])
			newSearchQuery = {paths: paths}
			@props.history.push search: "?#{qs.stringify(newSearchQuery, qsSettings)}"
			@setState {activePaths: paths}, cb
	componentWillUnmount: (props) ->
		@handleActiveFolder {}
	render: ->
		SideNavFiltersProviderFactory
			value:
				foldersList: @state.foldersList
				activeFolder: @state.activeFolder
				activePaths: @state.activePaths
				addFoldersList: @handleFoldersList
				setActiveFolder: @handleActiveFolder
				setActivePaths: @handleActivePaths
		,
			@props.children
