import _ from 'lodash'
import adopt from 'libs/adopt'

# Data
import {
	ListDocuments
} from '../data'

import trLegal from 'data/local/legal'

REFETCH_QUERIES = [
	'DMS_Registry_Table',
	'DMS_Inbox_Accessory',
	'DMS_Registry_Accessory',
]

DOCS_PER_PAGE = 100


export default DataLayer = adopt
	queries: {
		documentsList: ({ sendTime, columnQueries, searchQuery, sortKey, sortOrder, filteredDocumentsFilters }) ->
			fetchPolicy: 'cache-and-network'
			query: ListDocuments(columnQueries.join(' '), searchQuery)
			variables:
				filteredDocumentsFilters: filteredDocumentsFilters
				pagination:
					limit: DOCS_PER_PAGE
				sort:
					key: sortKey
					order: if sortOrder is 'asc' then 'Ascending' else 'Descending'
			partialRefetch: true
	}
