###
Wrapper for select to make it loadable
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'

import { Query as _Query } from 'react-apollo'
Query = React.createFactory _Query

export default (COMPONENT) ->
	class LoadableSelect extends React.Component
		@propTypes: {}
		@defaultProps:
			fetchMoreLimit: 25

		constructor: (props) ->
			super props
			@state =
				skip: true

		toggleSkip: (type) =>
			if @state.skip then @setState skip: false

		handleInputChange: (refetch, inputValue) =>
			refetch {
					...@props.query.variables
					search: inputValue
					pagination:
						limit: @props.fetchMoreLimit
						skip: 0 #FIXME
				}

		handleScrollToBottom: (loading, fetchMore, options) =>
			new Promise (resolve, reject) =>
				if loading or (@props.hasMore? and !@props.hasMore)
					resolve()
				else
					fetchMore
						variables:{
							...@props.query.variables
							# search: @state.input
							pagination:
								limit: @props.fetchMoreLimit
								skip: _.size options
						}
						updateQuery: (prev, { fetchMoreResult }) =>
							resolve()
							@props.handleDataUpdate prev, fetchMoreResult

		render: ->
			Query {
				...@props.query
				skip: @state.skip
			}, ({data, loading, refetch, fetchMore, error}) =>
				options =
					if !_.isEmpty data
						@props.makeOptionsFromData data
					else
						[]
				React.createElement COMPONENT, {
					error
					...(_.omit @props, ['query', 'values', 'handleValueToDisplay', 'makeOptionsFromData', 'handleDataUpdate', 'me'])
					onMenuOpen: @toggleSkip
					onMenuScrollToBottom: () => @handleScrollToBottom loading, fetchMore, options
					options
					onInputChange: (value, inputType) =>
						if (inputType.action is 'input-change')
							@handleInputChange refetch, value
							value
					isLoading: loading
					value: _.compact _.map @props.values, (currentFilter) =>
						if currentFilter? and @state.skip isnt false
							@setState skip: false
						if (!_.isEmpty options) and currentFilter?
							if @props.handleValueToDisplay?
								@props.handleValueToDisplay currentFilter, options
							else
								itemToDisplay = _.find options, (item) -> item.value is currentFilter
								if itemToDisplay?
									label: itemToDisplay.label
									value: itemToDisplay.value
				}
