exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".DXju1bPNphuTZyMYSy0Be div {\n  min-height: auto;\n  padding-top: 0;\n  padding-bottom: 0;\n}\n._17dRaXDkqf-GhfVdWQLhPk {\n  color: #8993a4;\n  margin-top: 8px;\n  font-size: 0.8rem;\n}\n._17dRaXDkqf-GhfVdWQLhPk ._10SzS5iTKAF7P_TJV196kC {\n  margin-top: 2px;\n  padding: 8px;\n  border: 1px solid #ebecf0;\n}\n.Xd2fj1oxch45aRDKAELD9 {\n  font-size: 1rem;\n}\n.Xd2fj1oxch45aRDKAELD9._1frD3s4uqqXH2QFOvvYQsz {\n  color: #ff5630;\n}\n.iwVLHShIZYnk1-uoYg8Xd {\n  display: inline;\n  padding-left: 6px;\n  font-weight: 400;\n  font-size: 0.8rem;\n}\n._1eg4j2lJTbxel8iR8rHEPE {\n  color: #8993a4;\n}\n._1tyveb6pqdwLrbPafQssYV {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  font-size: 1rem;\n}\n", ""]);
// Exports
exports.locals = {
	"formItem": "DXju1bPNphuTZyMYSy0Be",
	"categoryCode": "_17dRaXDkqf-GhfVdWQLhPk",
	"code": "_10SzS5iTKAF7P_TJV196kC",
	"label": "Xd2fj1oxch45aRDKAELD9",
	"missingLabel": "_1frD3s4uqqXH2QFOvvYQsz",
	"missingInfo": "iwVLHShIZYnk1-uoYg8Xd",
	"placeholderText": "_1eg4j2lJTbxel8iR8rHEPE",
	"groupStyles": "_1tyveb6pqdwLrbPafQssYV"
};