###
Delete Project Group Modal
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import adopt from 'libs/adopt'
import { NotificationConsumer } from '../../../application/components/NotificationManager'

# Renderable
Fragment = React.createFactory React.Fragment

import _BevyModal from '@bevy/modal'
BevyModal = React.createFactory _BevyModal

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

# Styles
import ErrorIcon from '@atlaskit/icon/glyph/error'

# Data
import {
	DeleteProjectUserGroups
} from './data'

REFETCH_QUERIES = [
	'projectUserGroupsList'
	]

DataLayer = adopt
	mutations:
		deleteProjectUserGroups: ({history, setNotification, projectSlug}) ->
			mutation: DeleteProjectUserGroups
			refetchQueries: REFETCH_QUERIES
			onCompleted: (data) ->
				setNotification
					content: "Project Group#{if (_.size data.userGroupsDelete) > 1 then 's' else ''} deleted"
					appearance: 'success'
				history.push "/projects/#{projectSlug}/groups"
			onError: (err) ->
				if err?.graphQLErrors? and (_.head err.graphQLErrors).message?
					setNotification ({content: (_.head err.graphQLErrors).message, appearance: 'error'})
				else
					setNotification ({content: 'Error deleting Project Group', appearance: 'error'})
export default class GroupDeleteModal extends React.Component
	@propTypes =
		onClose: PropTypes.func.isRequired

	render: ->
		NotificationConsumer {}, ({setNotification}) =>
			DataLayer {
				history: @props.history
				setNotification: setNotification
				onConfirm: @props.onConfirm
				projectSlug: @props.match.params.project
			}, (operations) =>
				BevyModal {
					onClose: @props.onClose
					header: 'Delete confirmation'
					headerIcon: ErrorIcon {size: 'medium', primaryColor: '#DE350B'}
					actions: [
						text: 'Delete'
						onClick: () =>
							operations.deleteProjectUserGroups.mutation
								variables:
									ids: @props.projectGroupIDs || [@props.groupID]
							@props.onClose()
					,
						text: 'Cancel'
						onClick: @props.onClose
					]
					note: 'Once deleted, it can be only restored by contacting your administrator.'
				},
					"Are you sure you want to delete this project group#{if (_.size @props.projectGroupIDs) > 1 then 's' else ''}?"

