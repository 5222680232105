import gql from 'graphql-tag'


#TODO refactor
export ListDocuments = (queryFields, search) -> gql """
	query ListDocuments(
		$pagination: PaginationInput
		$filteredDocumentsFilters: LegalDocumentsFilters
		$sort: LegalDocumentsSort!
	){
		legalDocumentsCreatedCounterToday( pagination: $pagination, filters: $filteredDocumentsFilters, sort: $sort){
			counterAll
			startDate
			endDate
			projects {
				slug
				projectName
				counter
				legalDocuments {
					id
					projectID
					createdAt
					#{queryFields}
					isUrgent
					isFresh
					isMuted
					hasScan
					hasCategory
					hasReview
					hasDetails
					scan {
						id
						filename
						secured {
							original
						}
					}
				}
			}
		}
	}
"""
