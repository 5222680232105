exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1B20gt6eubypUzQ4J_8UGR {\n  display: flex;\n  align-items: flex-start;\n  flex-shrink: 0;\n}\n._1B20gt6eubypUzQ4J_8UGR._3daFyS4I1Ez2mNSj0oP0XT {\n  margin-bottom: 18px;\n}\n._1B20gt6eubypUzQ4J_8UGR ._1808ft-EOTR9jRXG6E_dhC {\n  margin-top: 6px;\n  margin-right: 6px;\n}\n._1B20gt6eubypUzQ4J_8UGR ._2NsNyPcQr8wHpmWT0WrdoM {\n  flex: 1;\n}\n._1B20gt6eubypUzQ4J_8UGR ._2NsNyPcQr8wHpmWT0WrdoM ._2qvKj0Vd5B68QikWNMFS90 {\n  color: #8993a4;\n  margin: 0;\n  font-size: 0.8rem;\n}\n._1B20gt6eubypUzQ4J_8UGR ._3EL2enPPwu7sYspdYDwYUi {\n  margin: 0;\n  font-size: 1.5rem;\n}\n._1B20gt6eubypUzQ4J_8UGR ._1HrPWBkUldO4ANPioFRUBV {\n  margin-left: auto;\n}\n", ""]);
// Exports
exports.locals = {
	"base": "_1B20gt6eubypUzQ4J_8UGR",
	"separate": "_3daFyS4I1Ez2mNSj0oP0XT",
	"action": "_1808ft-EOTR9jRXG6E_dhC",
	"info": "_2NsNyPcQr8wHpmWT0WrdoM",
	"description": "_2qvKj0Vd5B68QikWNMFS90",
	"title": "_3EL2enPPwu7sYspdYDwYUi",
	"actions": "_1HrPWBkUldO4ANPioFRUBV"
};