###
wrapper for React Select
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'

# Renderable
import { div } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import _Select , {components, CreatableSelect as _CreatableSelect} from '@atlaskit/select'
CreatableSelectC = React.createFactory _CreatableSelect
Select = React.createFactory _Select
DropdownIndicator = React.createFactory components.DropdownIndicator
Input = React.createFactory components.Input
Control = React.createFactory components.Control
ValueContainer = React.createFactory components.ValueContainer

import LoadableSelectComponent from './loadable'
import NoOptionsMessage from './noOptionsMessage'

import _Badge from '@atlaskit/badge'
Badge = React.createFactory _Badge

import _Tooltip from '@bevy/tooltip'
Tooltip = React.createFactory _Tooltip

# Styles
import styles from './index.styl'
import customStyles, {ellipsis} from './customStyles'


class BevySelect extends React.Component
	constructor: (props) ->
		super props

	DropdownIndicator: (props) =>
		if props.selectProps.isEditable? and (!props.selectProps.isEditable) and props.selectProps.isDisabled
			null
		else
			DropdownIndicator {...props, className: cnames props.className, styles.dropdownIndicator }

	Input: (props) =>
		div {className: styles.inputWrapper}, Input props

	Control: (props) =>
		if @props.tooltipContent?
			Tooltip {content: if @props.tooltipContent? then @props.tooltipContent else "#{_.lowerCase @props.placeholder}"},
				Control props
		else
			Control props

	ValueContainer: (props) =>
		if (@props.isMulti) and (!_.isEmpty props.selectProps.value) and !(@props.resetMultiFormatting?)
			@renderMultiValueContainer props
		else
			ValueContainer props

	Menu: ( props ) =>
		menuComponent = if @props.components?.Menu? then @props.components.Menu else components.Menu
		if @props.onMenuScrollToBottom?
			menuKey = if _.isEmpty @props.options then 'empty' else 'full'
			React.createElement menuComponent, {...props, key: menuKey}
		else
			React.createElement menuComponent, props

	renderMultiValueContainer: (props) =>
		value = props.selectProps.value
		Fragment {},
			div style: { paddingLeft: '3px', width: '100%', ...ellipsis},
				_.join (_.map value, (item) -> if item.label? then item.label else item.name), '; '
			if (_.size value) > 1 then @renderBadge _.size value

	renderBadge: (value) =>
		div {style: customStyles().badge()}, Badge {appearance: 'primary'}, value

	render: ->
		if @props.error?
			errorDisplay = {
				MenuList: (props) => @props.error
			}
		Select {
			...(_.omit @props, ['components', 'styles'])
			styles: {...customStyles(@props), ...@props.styles}
			components: {
				@Input
				@DropdownIndicator
				@Control
				@ValueContainer
				@Menu
				...(_.omit @props.components, 'Menu')
				...errorDisplay
			}
		}

class CreatableSelect extends BevySelect
	constructor: (props) ->
		super props

	render: ->
		if @props.error?
			errorDisplay = {
				MenuList: (props) => @props.error
			}
		CreatableSelectC {
			...(_.omit @props, ['components', 'styles'])
			styles: {...customStyles(@props), ...@props.styles}
			components: {
				@Input
				@DropdownIndicator
				@Control
				@ValueContainer
				@Menu
				...(_.omit @props.components, 'Menu')
				...errorDisplay
			}
		}

export LoadableSelect = LoadableSelectComponent BevySelect
export LoadableCreatableSelect = LoadableSelectComponent CreatableSelect
export {components, CreatableSelect, NoOptionsMessage}
export default BevySelect
