###
Edit Project User Group
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import adopt from 'libs/adopt'

# Renderable
import _Spinner from '@bevy/spinner'
Spinner = React.createFactory _Spinner

import _AddGroupModal from '../AddProjectGroupModal'
AddProjectGroupModal = React.createFactory _AddGroupModal

# Data
import {
	ProjectGroupEditEnvironment
} from './data'

REFETCH_QUERIES = [
	'projectUserGroupsList'
	]

DataLayer = adopt
	queries:
		projectGroupEditEnvironment: ({ projectGroupID}) ->
			query: ProjectGroupEditEnvironment
			variables:
				projectGroupID: projectGroupID

export default class EditProjectGroupModal extends React.Component
	@propTypes =
		onClose: PropTypes.func.isRequired

	getInitialData: (data) =>
		group = _.get data, 'projectUserGroup'
		if !_.isEmpty group
			selectedRolePhases = _.map group.roles, 'info.phase'
			uniqPhases = _.compact _.uniqWith selectedRolePhases, _.isEqual
			{
				..._.pick group, ['name', 'description']
				roles: _.map group.roles, 'name'
				phases: _.head uniqPhases #TODO: support various phases for roles (independently)
			}

	render: ->
		DataLayer {
			projectGroupID: @props.projectGroupID
		}, (operations) =>
			activeQuery = operations.projectGroupEditEnvironment
			if activeQuery.loading then Spinner {}
			else
				AddProjectGroupModal {
					...@props
					initialSetup: if activeQuery.data? then @getInitialData activeQuery.data
					editQuery: activeQuery
				}

