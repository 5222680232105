import _ from 'lodash'
import { ApolloLink, Observable } from 'apollo-link'


export default class RefreshPermissionsLink extends ApolloLink
	constructor: (@refresh) ->
		super()
	request: (operation, forward) ->
		new Observable (observer) =>
			isCompleted = false
			isRealized = false
			sub = forward(operation).subscribe
				next: (result) =>
					headers = _.get operation.getContext(), 'response.headers'
					complete = ->
						isRealized = true
						observer.next result
						if isCompleted
							observer.complete()
					if headers?
						refreshRequest = headers.get('Refresh-Permissions')
						if refreshRequest?
							await @refresh refreshRequest
							complete()
						else
							complete()
					else
						complete()
				error: (err) -> observer.error err
				complete: () ->
					if isRealized
						observer.complete()
					else
						isCompleted = true
			return ->
				if sub
					sub.unsubscribe()
