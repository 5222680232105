exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2VsrbZabdkIYWBZ-jkF1xP {\n  background: transparent;\n}\n._2VsrbZabdkIYWBZ-jkF1xP:hover {\n  background: transparent;\n}\n._2VsrbZabdkIYWBZ-jkF1xP ._32dzSdyIiEnjgZHurvUt4U {\n  padding: 2px;\n}\n._13wAyvOv0T0FVWJT2iMvyT:after,\n._2B0-41PjqPC6jW3bELOQTc:after,\n._2Y3tyWWLsp3LyuA5ci2SDX:after,\n.SG8kLwfHFovuw7ChWsu9F:after {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: rotate(-90deg);\n  transform-origin: left;\n  color: #fff;\n  font-weight: bold;\n  pointer-events: none;\n  font-size: 2.5rem;\n  opacity: 0.7;\n  text-transform: uppercase;\n}\n._13wAyvOv0T0FVWJT2iMvyT {\n  background-color: #0747a6 !important;\n}\n._13wAyvOv0T0FVWJT2iMvyT:after {\n  content: 'local';\n}\n._2B0-41PjqPC6jW3bELOQTc {\n  background-color: #00875a !important;\n}\n._2B0-41PjqPC6jW3bELOQTc:after {\n  content: 'development';\n}\n._2Y3tyWWLsp3LyuA5ci2SDX {\n  background-color: #ff991f !important;\n}\n._2Y3tyWWLsp3LyuA5ci2SDX:after {\n  content: 'test';\n}\n.SG8kLwfHFovuw7ChWsu9F {\n  background-color: #ff5630 !important;\n}\n.SG8kLwfHFovuw7ChWsu9F:after {\n  content: 'staging';\n}\n", ""]);
// Exports
exports.locals = {
	"ProjectIndicator": "_2VsrbZabdkIYWBZ-jkF1xP",
	"ProjectIndicatorAvatar": "_32dzSdyIiEnjgZHurvUt4U",
	"MenuLocal": "_13wAyvOv0T0FVWJT2iMvyT",
	"MenuDevelopment": "_2B0-41PjqPC6jW3bELOQTc",
	"MenuTest": "_2Y3tyWWLsp3LyuA5ci2SDX",
	"MenuStaging": "SG8kLwfHFovuw7ChWsu9F"
};