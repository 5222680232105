###
Drawer with all entrypoints to S360 platform. Drawer should contain all applications,
recently used applications and workflows.
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import { UserInfoConsumer } from 'libs/userInfo'
# Renderable
import { div, h2 } from 'react-dom-factories'

import _AppSwitcher from '@bevy/app-switcher'
AppSwitcher = React.createFactory _AppSwitcher

import _Spinner from '@bevy/spinner'
Spinner = React.createFactory _Spinner

import { Query as _Query } from 'react-apollo'
Query = React.createFactory _Query

import {InternalErrorBanner as _InternalErrorBanner} from 'components/ErrorDisplay'
InternalErrorBanner = React.createFactory _InternalErrorBanner

# Data
import { RootQuery } from './data'
import homeImg from 'assets/placeholder.png'
import buildingImg from 'assets/cityscape.png'
import documentImg from 'assets/archive.png'

# Styles
import styles from './index.styl'
import {
	home
	box
	briefcase
	alignJustify
	fileText
} from 'react-icons-kit/feather'


export default class ApplicationSwitcherDrawer extends React.Component
	@propTypes:
		close: PropTypes.func
		isOpen: PropTypes.bool
		headerComponent: PropTypes.func
	@defaultProps:
		headerComponent: h2

	constructor: (props) ->
		super(props)
		@state = {}

	render: ->
		Query
			query: RootQuery
		, ({data, loading, error}) =>
			if loading
				Spinner {}
			else if error or !data?
				div {className: styles.errorWrapper}, InternalErrorBanner {}
			else
				UserInfoConsumer {}, ({ability, me}) =>
					applications = _.compact [
						if ability.can 'update', 'User'
							name: 'Users'
							icon: home
							description: 'Global users'
							href: '/users'
					,
						if ability.can 'update', 'UserGroup'
							name: 'Groups'
							icon: home
							description: 'Global groups'
							href: '/groups'
					]
					projects = _.map data.projects, (project) ->
						{
							...project
							icon: box
							description: project.city
							href: "/projects/#{project.slug}"
							image: if project.photo?.original? then project.photo.original
						}

					AppSwitcher
						className: styles.appSwitcher
						accessPoints: _.compact [
							name: 'Global'
							items: applications
						,
							name: 'Projects'
							items: _.map projects, (project) =>
								{
									...project
									apps: @props.projectApplications project
								}
						]
						onExpand: @props.onExpand
						onCollapse: @props.onCollapse
						onSelect: @props.close
						onAddToFavourites: () ->
