import gql from 'graphql-tag'

export UserPanelInfo = gql """
	query {
		me {
			...on Person {
				id
				name
				email
				picture {
					original
				}
			}
		}
	}
"""
