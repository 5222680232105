import _ from 'lodash'
import React from 'react'

# Renderable
import { div } from 'react-dom-factories'

Fragment = React.createFactory React.Fragment

import {BevyModalsWrapper as _BevyModalsWrapper} from '@bevy/modal'
BevyModalsWrapper = React.createFactory _BevyModalsWrapper

import _AddShortcutModal from 'parts/Projects/AddShortcutModal'
AddShortcutModal = React.createFactory _AddShortcutModal

import _EditShortcutModal from 'parts/Projects/EditShortcutModal'
EditShortcutModal = React.createFactory _EditShortcutModal

import _DeleteShortcutModal from 'parts/Projects/DeleteShortcutModal'
DeleteShortcutModal = React.createFactory _DeleteShortcutModal


export default getModals = ({data, onClose }) ->
	switch @state.activeModal
		when 'add'
			BevyModalsWrapper {}, 
				AddShortcutModal 
					onClose: onClose
					projectID: data.project.id
		when 'edit'
			BevyModalsWrapper {}, 
				EditShortcutModal 
					onClose: onClose
					projectID: data.project.id
					shortcutID: @state.shortcutID
		when 'delete'
			BevyModalsWrapper {},
				DeleteShortcutModal
					onClose: onClose
					projectID: data.project.id
					shortcutID: @state.shortcutID