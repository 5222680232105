import _ from 'lodash'

export default (name) ->
	if !__ENV__? or __ENV__ isnt 'production'
		name
	else
		null

export renderDataAttributes = (props, prefix = '') ->
	_.reduce props, (acc, value, key) ->
		if _.startsWith key, "data-#{prefix}"
			acc[key] = value
		acc
	, {}
