import gql from 'graphql-tag'


# QUERIES
export GetRoles = gql """
	query GetRoles {
		permissionsModel(model:UserGroup){
    	entityRoles{
      	name
    	}
  	}
	}
"""

# MUTATIONS
export AddGroup = gql """
	mutation addGroup($name: String!, $description: String, $roles: [String!]) {
		userGroupsCreate(
			name: $name
			description: $description
			roles: $roles
		) {
			id
			name
			description
			roles {
				name
			}
		}
	}
"""

export EditGroup = gql """
	mutation addGroup($id: ID!, $name: String, $description: String, $roles: [String!]) {
		userGroupsUpdate(
			id: $id
			name: $name
			description: $description
			roles: $roles
		) {
			id
			name
			description
			roles {
				name
			}
		}
	}
"""
