import gql from 'graphql-tag'

export LoggedInUser = gql """
	query {
		me {
			...on Person {
				id
				name
				picture { mini }
				permissions { subject, actions, conditions, fields }
			}
		}
	}
"""

export PermissionSubscription = gql """
	subscription {
		userPermissions {
			id
		}
	}
"""
